import React, { createContext, useContext, useState } from 'react';
import { dashboardDataGet } from '../../http/dashboard';
import { DashboardParams, DashBoardSummaryResponse } from '@/src/types/crud/dashboard/dashboard.summary';
import { DashBoardChart } from '@/src/types/crud/dashboard/dashboard.chart';
import { CommonCrud } from '@/src/types';
import { ActionUnit2, commonSetError, initialApiContext2 } from '../common';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';

declare namespace Types {
  export type DashBoardSummary = CommonCrud<DashBoardSummaryResponse>;
  export type DashBoardCharts = CommonCrud<DashBoardChart>;
  export namespace RequestAct {
    export type DashBoardSummary = (params: DashboardParams) => void;
  }
}

interface IDashboardData {
  fetchDashboardData: ActionUnit2<Types.DashBoardSummary> & { requestAct: Types.RequestAct.DashBoardSummary };
}

const initialState: IDashboardData = {
  fetchDashboardData: { ...initialApiContext2<Types.DashBoardSummary, Types.RequestAct.DashBoardSummary>() },
};

const DashboardDataContext: React.Context<IDashboardData> = createContext(initialState);
function useDashboardData() {
  return useContext(DashboardDataContext);
}

const DashboardContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [dashboardSummary, setDashBoardSummary] = useState<CommonCrud<DashBoardSummaryResponse>>(initialState.fetchDashboardData.current);

  const getDashBoardSummary: Types.RequestAct.DashBoardSummary = (params: DashboardParams) => {
    setDashBoardSummary({ ...initialState.fetchDashboardData.current, state: STATE_ENUM.LOADING });

    dashboardDataGet(params)
      .then(({ status, data }) => {
        setDashBoardSummary({ state: STATE_ENUM.READY, status, data });
      })
      .catch((error) => commonSetError(error, setDashBoardSummary));
  };

  const dashboardStore: IDashboardData = {
    fetchDashboardData: {
      current: dashboardSummary,
      setState: setDashBoardSummary,
      requestAct: getDashBoardSummary,
    },
  };

  return <DashboardDataContext.Provider value={dashboardStore}>{children}</DashboardDataContext.Provider>;
};

export { DashboardContextProvider, useDashboardData };
