import React, { createContext, useContext, useEffect, useState } from 'react';
import { userOrdersHistoryListGet } from '../../http/userOrderHistory';
import { UserOrderHistoryList } from '@/src/types/crud/userOrderHistory/userOrderHistory';
import { CommonCrud, sortType } from '@/src/types';
import { ActionUnit2, commonSetError, initialApiContext2, initialStateContext } from '../common';
import dayjs from 'dayjs';
import { apiPathV2 } from '@/src/app.constants';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';

declare namespace Types {
  export type SelectedMonth = number;
  export type SelectedYear = number;
  export type UserOrdersHistoryList = CommonCrud<UserOrderHistoryList>;
  export namespace RequestAct {
    export type UserOrdersHistoryList = (userId: string, year: number, month: number, includeLasOrder: boolean, orderBy?: string, sort?: sortType) => void;
  }
}

interface IOrdersUserOrderHistoryList {
  fetchUserOrderHistoryList: ActionUnit2<Types.UserOrdersHistoryList> & { requestAct: Types.RequestAct.UserOrdersHistoryList };
  year: ActionUnit2<Types.SelectedMonth>;
  month: ActionUnit2<Types.SelectedYear>;
}

const initialState: IOrdersUserOrderHistoryList = {
  month: { ...initialStateContext<Types.SelectedMonth>() },
  year: { ...initialStateContext<Types.SelectedYear>() },
  fetchUserOrderHistoryList: { ...initialApiContext2<Types.UserOrdersHistoryList, Types.RequestAct.UserOrdersHistoryList>() },
};

const UserOrderHistoryListContext: React.Context<IOrdersUserOrderHistoryList> = createContext(initialState);
function useUserOrderHistoryList() {
  return useContext(UserOrderHistoryListContext);
}

const UserOrderHistoryContextProvider = ({ children }: { children: React.ReactNode }) => {
  const now = dayjs();

  const [UserOrderHistoryList, setUserOrdersHistoryList] = useState<CommonCrud<UserOrderHistoryList>>(initialState.fetchUserOrderHistoryList.current);
  const [year, setYear] = useState<number>(now.year());
  const [month, setMonth] = useState<number>(now.month() + 1);

  const getUserOrderHistoryList: Types.RequestAct.UserOrdersHistoryList = (
    userId: string,
    year: number,
    month: number,
    includeLastOrder: boolean,
    orderBy?: string,
    sort?: sortType
  ) => {
    setUserOrdersHistoryList({ ...initialState.fetchUserOrderHistoryList.current, state: STATE_ENUM.LOADING });
    setMonth(month);
    setYear(year);

    userOrdersHistoryListGet(apiPathV2.userOrderHistory, userId, { year, month, includeLastOrder, orderBy, sort })
      .then(({ status, data }) => {
        setUserOrdersHistoryList({ state: STATE_ENUM.READY, status, data });
        if (includeLastOrder) {
          setMonth(data.lastOrder.month);
          setYear(data.lastOrder.year);
        }
      })
      .catch((error) => commonSetError(error, setUserOrdersHistoryList));
  };

  const userOrdersHistoryStore: IOrdersUserOrderHistoryList = {
    fetchUserOrderHistoryList: {
      current: UserOrderHistoryList,
      setState: setUserOrdersHistoryList,
      requestAct: getUserOrderHistoryList,
    },
    year: {
      current: year,
      setState: setYear,
    },
    month: {
      current: month,
      setState: setMonth,
    },
  };

  useEffect(() => {
    return () => {
      setUserOrdersHistoryList(initialState.fetchUserOrderHistoryList.current);
    };
  }, []);

  return <UserOrderHistoryListContext.Provider value={userOrdersHistoryStore}>{children}</UserOrderHistoryListContext.Provider>;
};

export { UserOrderHistoryContextProvider, useUserOrderHistoryList };
