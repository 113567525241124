import React, { useState, useEffect } from 'react';

// mui
import { Autocomplete, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// types + constants
import { RestaurantMenuSchema } from '@/types/schema/restaurantMenu';
// context
import { useMenuContextV2 } from '@/services/index';
import { styles } from '@/src/styles/theme';
import ClearIcon from '@mui/icons-material/Clear';

export const MenuItemAutocomplete = (props: any) => {
  const { callback } = props;
  const classes = styles();

  const { menuListForOrder } = useMenuContextV2();
  const availableMenuItems = menuListForOrder.current.data?.menuAddonList || [];
  const [options, setOptions] = useState(availableMenuItems);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (menuListForOrder.current.state === 'ready') {
      setLoading(false);
      setOptions(availableMenuItems);
    }
  }, [menuListForOrder.current]);

  return (
    <>
      <Autocomplete
        loading={loading}
        id="autocomplete-menu-items"
        sx={{ mb: '32px' }}
        options={options}
        getOptionLabel={(option) => `${option.thName} [${option.price}]`}
        popupIcon={<KeyboardArrowDownIcon id="show-all-menu-icon" />}
        clearIcon={<ClearIcon id="autocomplete-clear" />}
        componentsProps={{
          popupIndicator: {
            id: `btn-arrow-autocomplete-menu-items`,
          },
          clearIndicator: {
            id: `btn-clear-autocomplete-menu-items`,
          },
        }}
        renderInput={(params) => <TextField {...params} size={'small'} label="Enter keyword or select a menu (TH)" />}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <div className={classes.wrap_text} id={`autocomplete-menu-item-${options.indexOf(option)}`}>{`${option.thName} [${option.price}]`}</div>
          </li>
        )}
        onChange={(event: React.SyntheticEvent<Element, Event>, value: RestaurantMenuSchema | null, reason: string) => {
          callback(event, value, reason);
        }}
      />
    </>
  );
};
