/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { ThemeProvider } from '@mui/styles';

import { theme } from '@/styles/theme/index';
import { LoadingIcon } from '@/components/index';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '@/services/index';
import { canCreateOrSupportCookies, childrenWithProps, cookieOptions, getCookies, removeCookies } from '@/utils/index';
import { BrowserHistory } from 'history';
import { AnonymProps } from '@/types/index';

type Stages = 'logout' | 'loading' | 'error' | 'success' | 'noCookieSupport';

/**
 * Avoid component infinite loops
 */
const redirectLoopForceLogout = (maxTry = 3): boolean => {
  const currentCount = Number((window as any).redirects_index || 0);
  if (currentCount >= maxTry) return true;
  else {
    (window as any).redirects_index = currentCount + 1;
    return false;
  }
};

interface AdminProps extends AnonymProps {
  children: React.ReactChildren;
  history: BrowserHistory;
}
const AdminGuardRoute: React.FC<AdminProps> = (props): any => {
  const navigate = useNavigate();

  const search = useLocation().search;
  // NOTE logout set from loginMenu component
  const logout = parseInt(new URLSearchParams(search).get('logout') || '');
  const { children } = props;
  // to avoid circular issue do not pass children again
  const otherProps: any = props;

  const [auth, setAuth] = useState(null as any);
  const [mount, setMount] = useState(0);
  const [apiAuthCheckReady, setApiAuthCheck] = useState<boolean>(false);
  const { currentUser } = useAppContext();
  const [cookies, setCookie, removeCookie] = useCookies(['jwtToken', 'accessToken']);

  const updatedChildren = childrenWithProps(React, children, { ...otherProps });
  const isCookieEnabled = canCreateOrSupportCookies();

  useEffect(() => {
    if (!isCookieEnabled) {
      return;
    }
    // clean up controller
    if (logout === 1) return;
    if (auth) return;
    if (mount) return;
    // eslint-disable-next-line no-useless-return
    const jwtToken = getCookies('jwtToken', cookies);
    const unAuthUserLoaded = auth === null && currentUser?.user && jwtToken && !apiAuthCheckReady;

    if (unAuthUserLoaded) {
      setMount(mount + 1);
      currentUser.jwtToken = jwtToken;

      currentUser.apiAuthCheck(currentUser.jwtToken).then((n) => {
        if (n) {
          // console.log('[stage][two] ', 'apiAuthCheck ok');
          window.sessionStorage.setItem('mlo_ms_login_pending', 'false');
        } else {
          removeCookies('jwtToken');
          removeCookies('accessToken');
          // console.log('[stage][two][error] ', 'apiAuthCheck error, cookies removed');
        }
        // this will set to either true/false
        setAuth(n);
      });
      setApiAuthCheck(true);
    } else setAuth(false);
  }, [setAuth, auth, mount, setMount, logout, apiAuthCheckReady, setApiAuthCheck, currentUser]);

  const stages: Stages = [
    !isCookieEnabled && 'noCookieSupport',
    logout && 'logout',
    auth === null && 'loading',
    auth === false && 'error',
    auth === true && 'success',
  ].filter((n) => !!n)[0] as any;

  // console.log('[stage][AdminGuardRoute]', stages);

  switch (stages) {
    case 'noCookieSupport': {
      // console.log('[stage][two] ', 'noCookieSupport');
      return <Navigate to="/error?code=424" />;
    }

    case 'logout': {
      const redirectPath = logout ? `/redirect?logout=1` : `/redirect`;
      // console.log('[stage][two] ', 'logout');
      return <Navigate to={redirectPath} />;
    }
    case 'error': {
      // console.log('[stage][two] ', 'error redirect');
      // console.log('[stage][two][debugger] ', 'error redirect');
      if (redirectLoopForceLogout(3)) {
        removeCookies('jwtToken');
        removeCookies('accessToken');
        window.sessionStorage.setItem('mlo_ms_login_pending', 'true');

        return <Navigate to="/error?code=423" />;
      }
      return <Navigate to="/redirect" />;
    }
    case 'loading': {
      // console.log('[stage][two] ', 'loading');
      return (
        <ThemeProvider theme={theme}>
          <LoadingIcon setCenter={true}></LoadingIcon>
        </ThemeProvider>
      );
    }
    case 'success': {
      // console.debug('[stage][two] ', 'success');
      return updatedChildren;
    }
    default: {
      return <>No route match</>;
    }
  }
};

export { AdminGuardRoute };
