/* eslint-disable no-unused-vars */
import { Deffer, UserSchema } from '@/types/index';
import { User } from 'firebase/auth';
import { AuthCheck } from '@/services/http';
import { cookieOptions, defer, removeCookies, setCookies } from '@/utils/index';
import Cookies from 'universal-cookie';
import { apiPathV1 } from '@/app.constants/apiCallPaths/api.path.v1';
/**
 * Auto extenuate class to instance
 *
 */
export const userModel = (userData: User): UserSchema => {
  class UserModel {
    user: User = undefined as any;
    displayName = '';

    /**  set externally with login*/
    jwtToken = '';
    //  auth = undefined;
    cookies: Cookies = new Cookies();
    firebaseToken = defer();

    constructor(usr: User) {
      if (Object.keys(usr || {}).length > 1) {
        // ATTENTION do touch it!!
        const u: any = usr;
        u._accessToken = u.accessToken;
        delete u.accessToken;
        // end

        // not all props can assign this way, such as methods
        Object.assign(this, u || {});
        this.user = usr;

        /** initialize get the latest firebase token soon as possible */
        this.latestTokenCheck();

        // this.getIdTokenResult = data.getIdTokenResult;
        // this.accessToken = data.accessToken || this.accessToken;
        // this.auth = data.auth || this.auth;
        // this.displayName = data.displayName || this.displayName;
        // this.email = data.email || this.email;
        // this.emailVerified = data.emailVerified || this.emailVerified;
        // this.isAnonymous = data.isAnonymous || this.isAnonymous;
        // this.metadata = data.metadata || this.metadata;
        // this.phoneNumber = data.phoneNumber || this.phoneNumber;
        // this.photoURL = data.photoURL || this.photoURL;
        // this.proactiveRefresh = data.proactiveRefresh || this.proactiveRefresh;
        // this.providerData = data.providerData || this.providerData;
        // this.providerId = data.providerId || this.providerId;
        // this.reloadListener = data.reloadListener || this.reloadListener;
        // this.reloadUserInfo = data.reloadUserInfo || this.reloadUserInfo;
        // this.stsTokenManager = data.stsTokenManager || this.stsTokenManager;
        // this.tenantId = data.tenantId || this.tenantId;
        // this.uid = data.uid || this.uid;
        // this.refreshToken = data.refreshToken || this.refreshToken;
      }
    }

    latestTokenCheck(): Promise<string | null> {
      return this.user
        .getIdTokenResult()
        .then((firebaseToken) => {
          this.firebaseToken.resolve(firebaseToken.token);
          this.firebaseToken.value = firebaseToken.token;
          setCookies('firebaseIdToken', firebaseToken.token);
          // doesnt work as expected, its to quick
          // this.cookies.remove('accessToken');
          // this.cookies.set('accessToken', this.firebaseToken.value, cookieOptions());
          // console.log('[accessToken][latestTokenCheck]', 'accessToken updated');
          return this.firebaseToken.value;
        })
        .catch(() => {
          this.firebaseToken.resolve('');
          this.firebaseToken.value = '';
          removeCookies('firebaseIdToken');
          return null;
        });
    }

    /** get MS/azure accessToken as quickly as possible*/
    azureAccessToken(): string {
      return this.cookies.get('accessToken') || '';
    }

    /**
     * 1. Check latest firebaseIdToken not to mistaken by jwtToken! (2 different tokens)
     * 2. verify your current session calling AuthCheck
     * 3. provide true/false, never reject
     * @param {string} jwtToken you can get this from cookie
     */
    async apiAuthCheck(jwtToken: string): Promise<boolean> {
      const self: UserSchema = this as any;
      if (jwtToken) this.jwtToken = jwtToken;

      if (!this.jwtToken) {
        console.error('[apiAuthCheck] ', 'jwtToken not provided');
        return Promise.resolve(false);
      }
      return this.firebaseToken.promise
        .then((token: any) => {
          return AuthCheck(apiPathV1.authCheck /* , { token: this.jwtToken, firebaseIdToken: token }*/)
            .then((n) => {
              if (!n) {
                console.error('[auth/check] ', 'return empty response with 200 status, not good');
                return false;
              } else return true;
            })
            .catch((err) => {
              console.error('[auth/check] ', err.toString());
              return false;
            });
        })
        .catch((err) => {
          console.error('[getIdTokenResult] ', err.toString());
          return false;
        });
    }

    get nameAbbr(): string {
      if (this.displayName) {
        const name = this.displayName as string;
        return name.split(' ')[0].charAt(0) + name.split(' ')[name.split(' ').length - 1].charAt(0);
      } else {
        return '';
      }
    }

    get fullNameAbbr(): string {
      if (this.displayName) {
        const fullname = this.displayName as string;
        return fullname.split(' ')[0] + ' ' + fullname.split(' ')[fullname.split(' ').length - 1];
      } else {
        return '';
      }
    }
  }

  const user: UserSchema = new UserModel(userData) as any;
  return user;
};
