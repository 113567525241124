import { apiPathV1 } from '@/src/app.constants/apiCallPaths/api.path.v1';
import { apiPathV2 } from '@/src/app.constants/apiCallPaths/api.path.v2';
import { DEFAULT_ROW_PER_PAGE } from '@/src/app.constants/common';
import { CommonCrud, CommonCrudWithError, DeleteFailByOrdersError, PaginatedCrud, QueryParamsGetList } from '@/src/types';
import { GetRestaurantGroupsListV2, RestaurantGroupsRequest } from '@/src/types/crud/restaurantGroup/restaurantGroup';
import { OptionGroupOrderByType } from '@/src/types/crud/restaurantGroup/restaurantGroup.queries';
import {
  RestaurantGroupCreateResponse,
  RestaurantGroupDeleteResponse,
  RestaurantGroupUpdateResponse,
} from '@/src/types/crud/restaurantGroup/restaurantGroup.response';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import { getCookieByName } from '@/src/utils';
import { useContext, useEffect, useState, createContext } from 'react';
import {
  RestaurantGroupsCountOrderGet,
  RestaurantGroupsCreate,
  RestaurantGroupsDelete,
  RestaurantGroupsListGet,
  RestaurantGroupsUpdate,
} from '@/services/http/groups.v2';
import { ActionUnit2, commonSetError, initialApiContext2 } from '../common';

declare namespace Types {
  export type RestaurantGroupsList = PaginatedCrud<GetRestaurantGroupsListV2>;
  export type RestaurantGroupCreate = CommonCrud<RestaurantGroupCreateResponse>;
  export type RestaurantGroupUpdate = CommonCrud<RestaurantGroupUpdateResponse>;
  export type RestaurantGroupDelete = CommonCrudWithError<RestaurantGroupDeleteResponse, CommonCrud<DeleteFailByOrdersError>>;
  export type GroupOrderCount = CommonCrud<{ amount: number }>;
  export namespace RequestAct {
    export type RestaurantGroupsList = (restaurantId: string, params?: QueryParamsGetList<OptionGroupOrderByType>) => void;
    export type RestaurantGroupCreate = (restaurantId: string, payload: RestaurantGroupsRequest) => void;
    export type RestaurantGroupUpdate = (restaurantId: string, groupId: string, payload: RestaurantGroupsRequest) => void;
    export type RestaurantGroupDelete = (restaurantId: string, groupId: string) => void;
    export type GroupOrderCount = (restaurantId: string, groupId: string) => void;
  }
  export namespace CustomAct {
    export type RestaurantGroupIdUpdateList = (groupId: string, thName: string, enName: string, groupRadio: boolean) => void;
    export type RestaurantGroupIdDeleteList = (groupId: string) => void;
  }
}

interface IGroupContextV2 {
  restaurantGroupList: ActionUnit2<Types.RestaurantGroupsList> & { requestAct: Types.RequestAct.RestaurantGroupsList };
  restaurantGroupCreate: ActionUnit2<Types.RestaurantGroupCreate> & { requestAct: Types.RequestAct.RestaurantGroupCreate };
  restaurantGroupUpdate: ActionUnit2<Types.RestaurantGroupUpdate> & { requestAct: Types.RequestAct.RestaurantGroupUpdate };
  restaurantGroupDelete: ActionUnit2<Types.RestaurantGroupDelete> & { requestAct: Types.RequestAct.RestaurantGroupDelete };
  restaurantGroupOrderCount: ActionUnit2<Types.GroupOrderCount> & { requestAct: Types.RequestAct.GroupOrderCount };
  getRestaurantGroupIdUpdateList: Types.CustomAct.RestaurantGroupIdUpdateList;
  getRestaurantGroupIdDeleteList: Types.CustomAct.RestaurantGroupIdDeleteList;
}

const initialState: IGroupContextV2 = {
  restaurantGroupList: { ...initialApiContext2<Types.RestaurantGroupsList, Types.RequestAct.RestaurantGroupsList>() },
  restaurantGroupCreate: { ...initialApiContext2<Types.RestaurantGroupCreate, Types.RequestAct.RestaurantGroupCreate>() },
  restaurantGroupUpdate: { ...initialApiContext2<Types.RestaurantGroupUpdate, Types.RequestAct.RestaurantGroupUpdate>() },
  restaurantGroupDelete: { ...initialApiContext2<Types.RestaurantGroupDelete, Types.RequestAct.RestaurantGroupDelete>() },
  restaurantGroupOrderCount: { ...initialApiContext2<Types.GroupOrderCount, Types.RequestAct.GroupOrderCount>() },
  getRestaurantGroupIdUpdateList: (groupId: string, thName: string, enName: string, groupRadio: boolean) => {},
  getRestaurantGroupIdDeleteList: (groupId: string) => {},
};

const GroupContextV2: React.Context<IGroupContextV2> = createContext(initialState);

function useGroupContextV2() {
  return useContext(GroupContextV2);
}

const GroupContextV2Provider = ({ children }: { children: React.ReactNode }) => {
  const jwtToken = getCookieByName('jwtToken');

  const [restaurantGroupList, setRestaurantGroupsList] = useState<Types.RestaurantGroupsList>(initialState.restaurantGroupList.current);
  const [restaurantGroupCreate, setRestaurantGroupCreate] = useState<Types.RestaurantGroupCreate>(initialState.restaurantGroupCreate.current);
  const [restaurantGroupUpdate, setRestaurantGroupUpdate] = useState<Types.RestaurantGroupUpdate>(initialState.restaurantGroupUpdate.current);
  const [restaurantGroupDelete, setRestaurantGroupDelete] = useState<Types.RestaurantGroupDelete>(initialState.restaurantGroupDelete.current);
  const [restaurantGroupOrderCount, setRestaurantGroupOrderCount] = useState<Types.GroupOrderCount>(initialState.restaurantGroupOrderCount.current);

  // action for RestaurantGroupList
  const getRestaurantGroupsList: Types.RequestAct.RestaurantGroupsList = (restaurantId, params = { page: 0, itemPerPage: DEFAULT_ROW_PER_PAGE }) => {
    setRestaurantGroupsList({ ...initialState.restaurantGroupList.current, state: STATE_ENUM.LOADING });
    RestaurantGroupsListGet<Types.RestaurantGroupsList>(apiPathV2.groupList, restaurantId, params)
      .then(({ status, data, pagination }) => setRestaurantGroupsList({ state: STATE_ENUM.READY, data, status, pagination }))
      .catch((error) => commonSetError(error, setRestaurantGroupsList));
  };

  // action for create restaurantGroup
  const createRestaurantGroup: Types.RequestAct.RestaurantGroupCreate = (restaurantId, payload) => {
    setRestaurantGroupCreate({ ...initialState.restaurantGroupCreate.current, state: STATE_ENUM.LOADING });
    RestaurantGroupsCreate<Types.RestaurantGroupCreate>(apiPathV1.groupCreate, restaurantId, payload)
      .then(({ status, data }) => setRestaurantGroupCreate({ state: STATE_ENUM.READY, data, status }))
      .catch((error) => commonSetError(error, setRestaurantGroupCreate));
  };

  // action for update restaurantGroup
  const updateRestaurantGroup: Types.RequestAct.RestaurantGroupUpdate = (restaurantId, groupId, payload) => {
    setRestaurantGroupUpdate({ ...initialState.restaurantGroupUpdate.current, state: STATE_ENUM.LOADING });
    RestaurantGroupsUpdate<Types.RestaurantGroupUpdate>(apiPathV1.groupUpdate, restaurantId, groupId, payload)
      .then(({ status, data }) => setRestaurantGroupUpdate({ state: STATE_ENUM.READY, data, status }))
      .catch((error) => commonSetError(error, setRestaurantGroupUpdate));
  };

  // action for delete restaurantGroup
  const deleteRestaurantGroup: Types.RequestAct.RestaurantGroupDelete = (restaurantId, groupId) => {
    setRestaurantGroupDelete({ ...initialState.restaurantGroupDelete.current, state: STATE_ENUM.LOADING });
    RestaurantGroupsDelete<Types.RestaurantGroupDelete>(apiPathV1.groupDelete, jwtToken, restaurantId, groupId)
      .then(({ status, data }) => setRestaurantGroupDelete({ state: STATE_ENUM.READY, data, status }))
      .catch((error) => commonSetError(error, setRestaurantGroupDelete));
  };

  const getRestaurantGroupOrderCount: Types.RequestAct.GroupOrderCount = (restaurantId, groupId) => {
    setRestaurantGroupOrderCount({ ...initialState.restaurantGroupOrderCount.current, state: STATE_ENUM.LOADING });
    RestaurantGroupsCountOrderGet<Types.GroupOrderCount>(apiPathV1.groupOrderCount, jwtToken, restaurantId, groupId)
      .then((data) => setRestaurantGroupOrderCount({ data: data.data, state: STATE_ENUM.READY, status: data.status }))
      .catch((error) => commonSetError(error, setRestaurantGroupOrderCount));
  };

  // action for change groups list after update
  const getRestaurantGroupIdUpdateList: Types.CustomAct.RestaurantGroupIdUpdateList = (groupId, thName, enName, groupRadio) => {
    const startArray = restaurantGroupList;
    if (startArray.data && startArray.data?.groups) {
      const restaurantGroupUpdateList = startArray.data.groups.map((restaurantGroup) =>
        restaurantGroup.id === groupId ? { ...restaurantGroup, thName, enName, groupRadio } : { ...restaurantGroup }
      );
      setRestaurantGroupsList({
        data: {
          groups: restaurantGroupUpdateList,
          restaurant: restaurantGroupList.data?.restaurant!,
        },
        state: STATE_ENUM.READY,
        pagination: restaurantGroupList.pagination,
      });
    }
  };

  // action for change restaurant groups list after delete
  const getRestaurantGroupIdDeleteList: Types.CustomAct.RestaurantGroupIdDeleteList = (groupId) => {
    const startArray = restaurantGroupList;
    if (startArray.data && startArray.data.groups) {
      const restaurantGroupDeleteList = startArray.data.groups.filter((restaurantGroup) => (restaurantGroup.id !== groupId ? { ...restaurantGroup } : false));
      setRestaurantGroupsList({
        data: {
          groups: restaurantGroupDeleteList,
          restaurant: restaurantGroupList.data?.restaurant!,
        },
        state: STATE_ENUM.READY,
        pagination: restaurantGroupList.pagination,
      });
    }
  };

  const groupStore: IGroupContextV2 = {
    restaurantGroupList: {
      current: restaurantGroupList,
      setState: setRestaurantGroupsList,
      requestAct: getRestaurantGroupsList,
    },
    restaurantGroupCreate: {
      current: restaurantGroupCreate,
      setState: setRestaurantGroupCreate,
      requestAct: createRestaurantGroup,
    },
    restaurantGroupUpdate: {
      current: restaurantGroupUpdate,
      setState: setRestaurantGroupUpdate,
      requestAct: updateRestaurantGroup,
    },
    restaurantGroupDelete: {
      current: restaurantGroupDelete,
      setState: setRestaurantGroupDelete,
      requestAct: deleteRestaurantGroup,
    },
    restaurantGroupOrderCount: {
      current: restaurantGroupOrderCount,
      setState: setRestaurantGroupOrderCount,
      requestAct: getRestaurantGroupOrderCount,
    },
    getRestaurantGroupIdUpdateList,
    getRestaurantGroupIdDeleteList,
  };

  useEffect(() => {
    return () => {
      setRestaurantGroupsList(initialState.restaurantGroupList.current);
      setRestaurantGroupCreate(initialState.restaurantGroupCreate.current);
      setRestaurantGroupUpdate(initialState.restaurantGroupUpdate.current);
      setRestaurantGroupDelete(initialState.restaurantGroupDelete.current);
      setRestaurantGroupOrderCount(initialState.restaurantGroupOrderCount.current);
    };
  }, []);

  return <GroupContextV2.Provider value={groupStore}>{children}</GroupContextV2.Provider>;
};

export { initialState as initialGroupsState, useGroupContextV2, GroupContextV2Provider };
export type { Types as GroupTypes };
