/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Routes, NavLink, NavLinkProps } from 'react-router-dom';
import { AppBar, Box, Toolbar, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from '@/images/logo.png';
import nav from './nav';
import { LoginMenu } from '@/components/index';

const APPLICATION_NAME = 'Manao Meals';
const PAGES = nav;

const useStyles = makeStyles({
  Navbar_link: {
    padding: '0',
    marginRight: '40px',
    backgroundColor: 'transparent',
    textDecoration: 'none',
    fontSize: '18px',
    fontFamily: 'Prompt',
    color: '#4CAF50',
    '&:hover, &.active': {
      color: '#9E9E9E !important',
      backgroundColor: 'transparent',
    },
  },
});
export const Header = (props: any) => {
  const classes = useStyles();

  return (
    <AppBar
      position="sticky"
      color="inherit"
      sx={{
        boxShadow: 3,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters id="toolbar">
          {/* DESKTOP size : Icon and App name */}
          <NavLink to="/dashboard" id="manao-meals-logo">
            <Box
              component="img"
              sx={{
                height: 30,
                mr: 2,
              }}
              alt={APPLICATION_NAME}
              src={Logo}
            />
          </NavLink>
          {/* DESKTOP size : Navigation button*/}
          <Box
            sx={{
              flexGrow: 1,
              flexDirection: 'row-reverse',
              display: { xs: 'none', md: 'flex' },
            }}
          >
            {/* path and routing should map on App.tsx and components/core/header/nav */}
            {PAGES.map((page, id) => (
              <NavLink key={id} id={'menu-' + page.path.replace('/', '')} to={page.path} className={classes.Navbar_link}>
                {page.name}
              </NavLink>
            ))}
          </Box>

          {/* User menu */}
          <LoginMenu {...props}></LoginMenu>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
