import { MethodSchema } from '@/types/schema';

export const apiMethodV1: MethodSchema = {
  get: 'get',
  post: 'post',
  put: 'put',
  delete: 'delete',
  patch: 'patch',
};
export const apiMethodDefault: MethodSchema = {
  get: 'get',
  put: 'put',
  post: 'post',
  delete: 'get',
  patch: 'patch',
};
