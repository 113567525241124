export const HistoryHoc = (Crt: any, upperProps?: any) => {
  return function Hoc(lowerProps: any) {
    const props = {
      ...(upperProps || {}),
      ...lowerProps,
      // ... more props
    };
    return <Crt {...props} />;
    // const [state, setState] = useRecoilState(appLoadingState);

    // // NOTE this is just a pretend
    // // we will integrate this with atop state instead of delay timer
    // setTimeout(() => {
    //   setState(PAGE_LOADED as any);
    // }, 1000);

    // const userNameLoadable = useRecoilValueLoadable(appLoadingState);
    // const pageState = userNameLoadable.contents as PageState;
    // const _props = {
    //   ...props,
    //   appState: pageState,
    // };

    // switch (pageState) {
    //   case 'PAGE_LOADED':
    //     return <Crt {..._props} />;
    //   case 'PAGE_LOADING':
    //     return <LinearProgress />;
    //   case 'PAGE_ERROR':
    //     throw userNameLoadable.contents;
    // }
  };
};
