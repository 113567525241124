import { HeadCell } from '@/types/enhancedTable.d';

export const CANCEL_ORDER_TIP_TEXT =
  'This action cancels all orders which will no longer show in the app.\nYou must select a new restaurant which opens for orders in the app.\nNew deadline will be 9 AM, on the same day.';

export const NEW_ORDER_TIP_TEXT = 'Click here to add an order to the list!';

export const PAGE_TITLE = 'Order summary for';

export const headCellsOrder: HeadCell[] = [
  {
    id: 'number',
    numeric: true,
    label: 'No.',
    disablePadding: false,
    sortAble: false,
    width: 0.1 / 6,
  },
  {
    id: 'staffName',
    label: 'Staff name',
    numeric: false,
    disablePadding: false,
    sortAble: true,
    width: 1.25 / 6,
  },
  {
    id: 'menuItems',
    numeric: false,
    label: 'Menu items',
    disablePadding: false,
    sortAble: true,
    width: 1 / 6,
  },
  {
    id: 'addOns',
    numeric: false,
    label: 'Add-ons',
    disablePadding: false,
    sortAble: true,
    width: 1.25 / 6,
  },
  {
    id: 'remark',
    numeric: false,
    label: 'Remark',
    disablePadding: false,
    sortAble: true,
    width: 0.8 / 6,
  },
  {
    id: 'price',
    numeric: true,
    label: 'Total',
    disablePadding: false,
    sortAble: true,
    width: 0.5 / 6,
  },
  {
    id: 'actions',
    numeric: false,
    label: '',
    disablePadding: false,
    sortAble: false,
    width: 0.1 / 6,
  },
];
