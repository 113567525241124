import { HeadCell } from '@/types/enhancedTable.d';

export const headCellsUserOrderHistory: HeadCell[] = [
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Date',
    sortAble: true,
    width: 1 / 10,
  },
  {
    id: 'restaurant',
    numeric: false,
    disablePadding: false,
    label: 'Restaurant',
    sortAble: true,
    width: 2 / 10,
  },
  {
    id: 'menuItems',
    numeric: false,
    disablePadding: false,
    label: 'Menu Items',
    sortAble: true,
    width: 1 / 10,
  },
  {
    id: 'addOns',
    numeric: false,
    disablePadding: false,
    label: 'Add-ons',
    sortAble: true,
    width: 2 / 10,
  },
  {
    id: 'remark',
    numeric: false,
    disablePadding: false,
    label: 'Remark',
    sortAble: false,
    width: 1 / 10,
  },
  {
    id: 'total',
    numeric: true,
    disablePadding: false,
    label: 'Total',
    sortAble: true,
    width: 1 / 10,
    align: 'center',
  },
];
