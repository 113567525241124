import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Backdrop, Fade, Box, Typography, Button } from '@mui/material';
import { printDisplayDate } from '@/utils/index';
import { apiPathV1 } from '@/app.constants/apiCallPaths/api.path.v1';
import { DeleteRestaurant, HiddenActionType, ModalType } from '@/types/index';

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 615,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

interface ModalRestaurantProps {
  openModal: boolean;
  type: ModalType;
  restaurantId: string;
  restaurantDeleteData: DeleteRestaurant;
  setRestaurantDelete(prefix: string, restaurantId: string): void;
  setRestaurantDeleteData: Dispatch<SetStateAction<DeleteRestaurant>>;
  setType: Dispatch<SetStateAction<ModalType>>;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  hiddenActionInSearchMode: HiddenActionType;
  setHiddenActionInSearchMode: Dispatch<SetStateAction<HiddenActionType>>;
}

export const ModalRestaurant = (props: ModalRestaurantProps) => {
  const {
    openModal,
    setOpenModal,
    restaurantId,
    restaurantDeleteData,
    setRestaurantDelete,
    setRestaurantDeleteData,
    setType,
    type,
    hiddenActionInSearchMode,
    setHiddenActionInSearchMode,
  } = props;
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [buttonCancelText, setButtonCancelText] = useState('');
  const [temp, setTemp] = useState('');
  const [dateArray, setDateArray] = useState('');

  useEffect(() => {
    if (type === 'DELETE') {
      setTitle('Are you sure you want to delete this restaurant?');
      setDescription('This action will permanently delete the restaurant and its menu items, and cannot be undone.');
      setButtonText('DELETE');
      setButtonCancelText('CANCEL');
      setTemp('#F44336');
    } else if (type === 'ADD') {
      setTitle('Please create add-ons before add a menu items.');
      setDescription('We suggest to create add-ons before adding new menu item then you can add a menu item by switch the tab on top.');
      setButtonText('CREATE ADD-ONS');
      setButtonCancelText('CANCEL');
      setTemp('#4CAF50');
    } else if (type === 'ACKNOWLEDGE') {
      setTitle('Notify');
      setDescription('Your restaurant has been ' + hiddenActionInSearchMode + ' but is not currently visible while searching restaurants.');
      setButtonText('ACKNOWLEDGE');
      setTemp('#4CAF50');
    } else if (type === 'CANTDELETE') {
      setTitle("The action can't be completed.");
      setDescription(
        'The restaurant is scheduled to order on (' + dateArray + '). To delete this restaurant, try change the restaurant scheduling or cancel all orders.'
      );
      setButtonText('OK');
      setTemp('#F44336');
    }
  }, [type, temp]);

  const handleClose = (reasonModalClose?: 'backdropClick' | 'escapeKeyDown') => {
    setOpenModal(false);
    if (reasonModalClose && hiddenActionInSearchMode) {
      setHiddenActionInSearchMode(undefined);
    }
  };

  const handleClickAcknowledge = () => {
    setOpenModal(false);
    setHiddenActionInSearchMode(undefined);
  };

  const handleClickDelete = () => {
    setOpenModal(false);
    setRestaurantDelete(apiPathV1.restaurantDelete, restaurantId);
  };

  const handleClickAdd = () => {
    navigate(restaurantId + '/menu');
  };

  useEffect(() => {
    if (restaurantDeleteData.state === 'error') {
      setType('CANTDELETE');
      setOpenModal(true);
      setRestaurantDeleteData({
        state: 'initial',
      } as DeleteRestaurant);

      // TODO: use error instead of data, when update restaurant store
      const dateList = restaurantDeleteData.data?.data.map((object: any) => {
        return object.date;
      });
      dateList.sort();

      const displayDateList = dateList.map((date: string) => {
        return printDisplayDate(date);
      });
      setDateArray(displayDateList.join(', '));
    }
  }, [restaurantDeleteData]);

  return (
    <Modal
      id={'modal-restaurant-' + restaurantId}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={(e, reason) => handleClose(reason)}
      closeAfterTransition
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={openModal}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h5" component="h2">
            {title}
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{ color: '#212121' }}>
            {description}
          </Typography>
          <Button
            style={{ backgroundColor: temp, color: '#ffffff' }}
            sx={{ mt: 2 }}
            id={type.toLowerCase()}
            onClick={() => {
              if (type === 'DELETE') handleClickDelete();
              else if (type === 'ADD') handleClickAdd();
              else if (type === 'ACKNOWLEDGE') handleClickAcknowledge();
              else if (type === 'CANTDELETE') handleClose();
            }}
          >
            {buttonText}
          </Button>
          {type === 'DELETE' || type === 'ADD' ? (
            <Button
              id="cancel"
              style={{ backgroundColor: '#ffff', color: temp, border: '1px solid ' + temp }}
              sx={{ mt: 2, ml: 2 }}
              onClick={() => handleClose()}
            >
              {buttonCancelText}
            </Button>
          ) : (
            <></>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};
