import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import { apiMethodV1 } from '@/app.constants/apiCallMethods/index';

/**
 * @private This api is protected and can only be accessed as role=ADMIN
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */

const ordersHistoryListGet = <T>(prefix: string, params?: { fromDate: string; toDate: string }, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.get, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
    params,
  };

  return instance.client
    .get(prefix, config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

export { ordersHistoryListGet };
