import { AxiosService } from '@/utils/axios';
import { apiMethodV1 } from '@/app.constants/apiCallMethods/index';
import { getCookieByName, paramsQueryString } from '@/src/utils';
import { apiPathV2 } from '@/src/app.constants';
import { RemoteConfig as DisplayConfig } from '@/src/types';
import { AxiosResponse } from 'axios';

export const getUserStaffList = async (prefix: string, jwtToken: string, params: { page: number; itemPerPage: number }, hardRejection = true) => {
  const instance = new AxiosService({ method: apiMethodV1.get, timeout: 12000 }, true, hardRejection);
  const queryUri = paramsQueryString(prefix, params);
  const config = instance.config;
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded';

  try {
    const res = await instance.client.get(queryUri, config);
    return res.data;
  } catch (error) {
    return instance.defaultHandleError;
  }
};

export const setSuspend = async (prefix: string, id: string, suspendValue: boolean, hardRejection = true) => {
  const instance = new AxiosService({ method: apiMethodV1.patch, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  try {
    const res = await instance.client.patch(`${prefix}${id}`, JSON.stringify({ isSuspended: suspendValue }), config);
    return res.data;
  } catch (error) {
    return instance.defaultHandleError;
  }
};

export const fetchDateFormat = async (hardRejection = true): Promise<DisplayConfig> => {
  let jwtToken: string | undefined = getCookieByName('jwtToken');

  let count = 0;
  while (!jwtToken) {
    await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait 100ms
    jwtToken = getCookieByName('jwtToken');
    count++;
    if (count > 5) break;
  }

  if (!jwtToken) return Promise.reject(new Error('[AuthCheck] jwtToken is not set, browser error'));

  const instance = new AxiosService({ method: apiMethodV1.get, timeout: 12000 }, true, hardRejection);
  const queryUri = paramsQueryString(apiPathV2.dateFormate, {});

  const config = {
    ...instance.config,
  };

  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<DisplayConfig>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<DisplayConfig>;
};
