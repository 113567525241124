import React, { useEffect, useMemo, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Stack, Button, Tooltip, FormGroup, Checkbox, FormControlLabel, Box, Typography } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { SelectRestaurant } from '../selectRestaurant';
import moment, { Moment } from 'moment';
import { useOrdersContextV2, useRestaurantBookingContext } from '@/services/index';
import { apiPathV1 } from '@/app.constants/apiCallPaths/api.path.v1';
import { TableRowMenuSettingInlineEditProps } from '@/types/ui/TableRowMenuSettingInlineEditProps';
import { common } from '@mui/material/colors';
import { LIMIT_TIME_ORDER_STRING } from '@/src/app.constants';
import { getDisplayConfig } from '@/src/utils';
import ModalAdminChangeRestaurant from '../modal/ModalAdminChangeRestaurant';
import ModalAdminRemoveRestaurant from '../modal/ModalAdminRemoveRestaurant';
import { statusRestaurantType } from '@/src/types';

export function TableRowMenuSettingInlineEdit(props: TableRowMenuSettingInlineEditProps) {
  const dateFormat = getDisplayConfig().dateFormat;

  const { booking, curDay, remainingDaysInWeek, edit, onEdit, checkLoad, setCheckLoad } = props;
  const [removeButtonDisableStatus, setRemoveButtonDisableStatus] = useState<boolean>(false);
  const [form, setForm] = useState({
    enName: booking?.enName ?? '',
    thName: booking?.thName ?? '',
    bookingId: booking?.id ?? '',
    bookingDate: booking?.date ?? '',
    restaurantId: booking?.restaurantId ?? '',
    holiday: booking?.status === 'Holiday' || false,
  });
  const [updateRestaurantBookingPayload, setUpdateRestaurantBookingPayload] = useState<{
    restaurantId: string;
    status: statusRestaurantType;
  }>({
    restaurantId: '',
    status: 'Open',
  });
  // eslint-disable-next-line no-unused-vars
  const [prevRestaurantId, setPrevRestaurantId] = useState('');
  const { ordersSummaryList, ordersCount } = useOrdersContextV2();
  const {
    setRestaurantBookingUpdate,
    setRestaurantBookingStatusUpdate,
    setRestaurantBookingCreate,
    setRestaurantBookingRemove,
    restaurantBookingCreateData,
    restaurantBookingUpdateData,
    restaurantBookingStatusUpdateData,
  } = useRestaurantBookingContext();

  const [openAllowAnAdminToChangeRestaurantModal, setOpenAllowAnAdminToChangeRestaurantModal] = useState<boolean>(false);
  const [openAllowAnAdminToRemoveRestaurantModal, setOpenAllowAnAdminToRemoveRestaurantModal] = useState<boolean>(false);

  const confirmChangeRestaurant = () => {
    if (form.holiday === true) {
      setRestaurantBookingStatusUpdate(apiPathV1.restaurantBookingStatusUpdate, { status: 'Holiday' }, curDay.format('YYYY-MM-DD'), () => {
        onEdit(false);
        setOpenAllowAnAdminToChangeRestaurantModal(false);
      });
    } else {
      setRestaurantBookingUpdate(apiPathV1.restaurantBookingUpdate, updateRestaurantBookingPayload, curDay.format('YYYY-MM-DD'), () => {
        setRemoveButtonDisableStatus(false);
        onEdit(false);
        setOpenAllowAnAdminToChangeRestaurantModal(false);
      });
      ordersSummaryList.setState((prevState) => {
        return { ...prevState, state: 'initial' };
      });
    }
    onEdit(false);
    setOpenAllowAnAdminToChangeRestaurantModal(false);
  };

  const cancelChangeRestaurant = () => {
    setCheckLoad(false);
    setForm({
      enName: booking?.enName ?? '',
      thName: booking?.thName ?? '',
      bookingId: booking?.id ?? '',
      bookingDate: booking?.date ?? '',
      restaurantId: booking?.restaurantId ?? '',
      holiday: booking?.status === 'Holiday' || false,
    });
    setUpdateRestaurantBookingPayload({
      restaurantId: booking?.restaurantId ?? '',
      status: 'Open',
    });
    setRemoveButtonDisableStatus(false);
    setOpenAllowAnAdminToChangeRestaurantModal(false);
  };

  const confirmRemoveRestaurant = () => {
    const curDayFormatted = curDay.format('YYYY-MM-DD');
    setRestaurantBookingRemove(apiPathV1.restaurantBookingUpdate, curDayFormatted);
    setOpenAllowAnAdminToRemoveRestaurantModal(false);
    setRemoveButtonDisableStatus(true);
  };

  const cancelRemoveRestaurant = () => {
    setCheckLoad(false);
    setForm({
      enName: booking?.enName ?? '',
      thName: booking?.thName ?? '',
      bookingId: booking?.id ?? '',
      bookingDate: booking?.date ?? '',
      restaurantId: booking?.restaurantId ?? '',
      holiday: booking?.status === 'Holiday' || false,
    });
    setUpdateRestaurantBookingPayload({
      restaurantId: booking?.restaurantId ?? '',
      status: 'Open',
    });
    setOpenAllowAnAdminToRemoveRestaurantModal(false);
  };

  const nonSaveAction = useMemo(() => {
    return (
      restaurantBookingUpdateData.state !== 'loading' &&
      restaurantBookingStatusUpdateData.state !== 'loading' &&
      restaurantBookingCreateData.state !== 'loading'
    );
  }, [restaurantBookingUpdateData, restaurantBookingStatusUpdateData, restaurantBookingCreateData]);

  const handleClickHoliday = (e: React.ChangeEvent<HTMLInputElement>) => {
    const curDayFormatted = curDay.format('YYYY-MM-DD');
    const isHolidayChecked = e.target.checked;

    setForm({ ...form, holiday: isHolidayChecked });
    setCheckLoad(isHolidayChecked);

    const updateRestaurantBooking = (status: string) => {
      setRestaurantBookingCreate(apiPathV1.restaurantBookingUpdate, { date: curDayFormatted, status }, () => onEdit(false));
    };

    if (isHolidayChecked) {
      if (booking?.id) {
        ordersCount.requestAct(booking.id, (amount: number) => {
          if (amount > 0) {
            setOpenAllowAnAdminToChangeRestaurantModal(true);
          } else {
            if (booking.status === 'Open') {
              setRestaurantBookingStatusUpdate(apiPathV1.restaurantBookingStatusUpdate, { status: 'Holiday' }, curDayFormatted, () => onEdit(false));
            } else {
              updateRestaurantBooking('Holiday');
            }
          }
        });
      } else {
        updateRestaurantBooking('Holiday');
      }
    } else {
      setRestaurantBookingStatusUpdate(apiPathV1.restaurantBookingStatusUpdate, { status: 'Removed' }, curDayFormatted, () => onEdit(false));
    }
  };

  const handleRemoveRestaurant = () => {
    // getOrdercount before open modal
    setCheckLoad(true);
    const curDayFormatted = curDay.format('YYYY-MM-DD');
    ordersCount.requestAct(curDayFormatted, () => {
      setOpenAllowAnAdminToRemoveRestaurantModal(true);
    });
  };

  const handleChangeRestaurant = (restaurantId: string) => {
    setCheckLoad(true);
    setRemoveButtonDisableStatus(true);
    const curDayFormatted = curDay.format('YYYY-MM-DD');
    setForm({ ...form, restaurantId });
    setUpdateRestaurantBookingPayload({
      restaurantId,
      status: 'Open',
    });
    // check booking id
    if (booking?.id) {
      // if status is holiday then only update data
      if (booking.status === 'Holiday') {
        setRestaurantBookingUpdate(
          apiPathV1.restaurantBookingUpdate,
          {
            restaurantId,
            status: 'Open',
          },
          curDayFormatted,
          () => {
            setRemoveButtonDisableStatus(false);
            onEdit(false);
          }
        );
      } else {
        ordersCount.requestAct(booking.id, (amount: number) => {
          if (amount > 0) {
            setOpenAllowAnAdminToChangeRestaurantModal(true);
          } else {
            setRestaurantBookingCreate(
              apiPathV1.restaurantBookingUpdate,
              {
                date: curDayFormatted,
                restaurantId,
              },
              () => {
                setRemoveButtonDisableStatus(false);
                onEdit(false);
              }
            );
          }
        });
      }
    } else {
      setRestaurantBookingCreate(
        apiPathV1.restaurantBookingUpdate,
        {
          date: curDayFormatted,
          restaurantId,
        },
        () => {
          setRemoveButtonDisableStatus(false);
          onEdit(false);
        }
      );
    }
  };

  const onCancel = () => {
    setForm({
      enName: booking?.enName ?? '',
      thName: booking?.thName ?? '',
      bookingId: booking?.id ?? '',
      bookingDate: booking?.date ?? '',
      restaurantId: booking?.restaurantId ?? '',
      holiday: booking?.status === 'Holiday' || false,
    });
    setUpdateRestaurantBookingPayload({
      restaurantId: booking?.restaurantId ?? '',
      status: 'Open',
    });
    if (booking?.status !== 'Holiday') {
      setRemoveButtonDisableStatus(false);
    }
    onEdit(false);
  };

  useEffect(() => {
    if (nonSaveAction) {
      if (!edit) {
        setForm({
          enName: booking?.enName ?? '',
          thName: booking?.thName ?? '',
          bookingId: booking?.id ?? '',
          bookingDate: booking?.date ?? '',
          restaurantId: booking?.restaurantId ?? '',
          holiday: booking?.status === 'Holiday' || false,
        });
        setUpdateRestaurantBookingPayload({
          restaurantId: booking?.restaurantId ?? '',
          status: 'Open',
        });
      }
    }
  }, [edit]);

  useEffect(() => {
    setForm({
      enName: booking?.enName ?? '',
      thName: booking?.thName ?? '',
      bookingId: booking?.id ?? '',
      bookingDate: booking?.date ?? '',
      restaurantId: booking?.restaurantId ?? '',
      holiday: booking?.status === 'Holiday' || false,
    });
  }, [booking]);

  const onEditState = () => {
    if (booking?.status === 'Holiday' || form.restaurantId === '') {
      setRemoveButtonDisableStatus(true);
    }
    onEdit(true);
    setPrevRestaurantId(form.restaurantId);
  };

  const checked = (day: Moment) => {
    const copy = day.clone();
    const getYesterdayDate = copy.clone().subtract(1, 'days').format('dddd');
    const subtractDateNumber = getYesterdayDate === 'Sunday' ? 3 : 1;
    return moment().isAfter(copy.subtract(subtractDateNumber, 'd').format('YYYY-MM-DD') + LIMIT_TIME_ORDER_STRING);
  };

  const isHoliday = () => {
    if ((booking?.status === 'Holiday' || false) && form?.holiday === true) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <TableRow
      tabIndex={-1}
      sx={{ width: 20 }}
      className={`editTableData ${edit && 'active'} ${booking?.status === 'Holiday' || false ? 'holiday' : ''}`}
      id="tr-menu-setting-edit"
    >
      <TableCell id="td-menu-setting-edit-col1" align="left" sx={{ padding: '8px 20px !important' }} className="editInputNoPointer">
        <Typography sx={{ color: checkLoad ? '#9E9E9E' : common.black }}>{curDay.format(dateFormat)}</Typography>
      </TableCell>
      <TableCell
        id="td-menu-setting-edit-col2"
        width="30%"
        align="left"
        sx={{ borderLeft: '1px solid #E0E0E0', pl: '45px !important' }}
        rowSpan={1}
        className={`editInputNoPointer`}
      >
        {form?.holiday || isHoliday() ? (
          <Box sx={{ borderLeft: '15px solid #FFFFFF' }}>
            <Typography sx={{ color: checkLoad ? '#9E9E9E' : common.black }}>Public holiday</Typography>{' '}
          </Box>
        ) : (
          <SelectRestaurant
            restaurantId={form?.restaurantId}
            onChange={(v: string) => {
              handleChangeRestaurant(v);
            }}
            checkLoad={checkLoad}
            edit={edit}
          ></SelectRestaurant>
        )}
      </TableCell>
      <TableCell id="td-menu-setting-edit-col3" align="left" rowSpan={1} className={`btnActionArea`}>
        <Stack direction="row" spacing={2} className={`btnArea ${remainingDaysInWeek}`}>
          <FormGroup className="editInputNoPointer">
            <FormControlLabel
              control={
                <Checkbox
                  id={'check-' + props.date}
                  checked={isHoliday() || form?.holiday}
                  onChange={(e) => {
                    handleClickHoliday(e);
                  }}
                  disabled={checkLoad}
                />
              }
              label="Mark as public holiday"
            />
          </FormGroup>
          <Button id={'cancel-' + props.date} variant="outlined" color="primary" size="small" className="w-20" onClick={() => onCancel()} disabled={checkLoad}>
            Cancel
          </Button>
          <Button
            id={'remove-' + props.date}
            variant="outlined"
            disabled={removeButtonDisableStatus || checkLoad}
            color="error"
            size="small"
            className="w-20"
            onClick={handleRemoveRestaurant}
          >
            Remove
          </Button>
        </Stack>
        <Stack direction="row" className={`btnAreaAction ${booking === undefined ? 'hidden' : ''}`}>
          <Tooltip title={checked(curDay) ? '' : 'Edit info'} placement="bottom-start">
            <span>
              <Button
                id={'edit-name-' + props.date}
                variant="outlined"
                color="primary"
                size="small"
                className="whenHoverPointer"
                onClick={() => onEditState()}
                disabled={checked(curDay) || checkLoad || props.booking?.status === 'Closed'}
              >
                <EditIcon></EditIcon>
              </Button>
            </span>
          </Tooltip>
        </Stack>
        <ModalAdminChangeRestaurant
          restaurantThName={form.thName}
          restaurantEnName={form.enName}
          open={openAllowAnAdminToChangeRestaurantModal}
          onConfirm={confirmChangeRestaurant}
          onCancel={cancelChangeRestaurant}
        />
        <ModalAdminRemoveRestaurant
          restaurantThName={form.thName}
          restaurantEnName={form.enName}
          totalOrder={ordersCount.current.data ? ordersCount.current.data.amount : 0}
          open={openAllowAnAdminToRemoveRestaurantModal}
          onConfirm={confirmRemoveRestaurant}
          onCancel={cancelRemoveRestaurant}
        />
      </TableCell>
    </TableRow>
  );
}
