import React, { useEffect, useRef, useState, Dispatch, SetStateAction } from 'react';
import { useSnackbarContext, useRestaurantContext, useAddOnContextV2 } from '@/services/index';
import { useParams } from 'react-router-dom';
import { TextField, Tooltip, Button, Stack, TableCell, TableRow, TableBody } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import Modal from '@/components/ui/modal/modaladdons';
import { QueryParamsGetList, RestaurantAddOnsSchema, addonOrderByType, order } from '@/types/index';
import { apiPathV1 } from '@/app.constants/apiCallPaths/api.path.v1';
import { LIMIT_EN_CHAR, LIMIT_TH_CHAR } from '@/app.constants/index';
import ModalCancelOrder from '../modal/modalCancelOrder';
import { PriceInput } from '../sharedPriceInputComponent';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';

interface EnhancedTableBodyRestaurantAddOnsProp {
  rows: RestaurantAddOnsSchema[];
  order: order | null;
  orderBy: addonOrderByType | null;
  setOrderBy: React.Dispatch<React.SetStateAction<addonOrderByType | null>>;
  setOrder: React.Dispatch<React.SetStateAction<order | null>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTotal: Dispatch<SetStateAction<number>>;
  setIsChange: Dispatch<SetStateAction<boolean>>;
  total: number;
  setPaginationParams: React.Dispatch<React.SetStateAction<QueryParamsGetList<any>>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

export function EnhancedTableBodyRestaurantAddOns(props: EnhancedTableBodyRestaurantAddOnsProp) {
  const { rows, order, orderBy, setOrderBy, setOrder, setOpen, setTotal, setIsChange, total, setPaginationParams, setPage } = props;
  // context
  const { setResponseStatus } = useSnackbarContext();
  const { setRestaurantDropdownList } = useRestaurantContext();
  const {
    overBudgetOrdersByAddOn,
    restaurantAddonUpdate,
    getRestaurantAddonIdUpdateList,
    restaurantAddonDelete,
    restaurantAddonList,
    getRestaurantAddonIdDeleteList,
    updateRestaurantAddonEditing,
  } = useAddOnContextV2();

  // hooks
  const { id } = useParams();

  const [editRowIndex, setEditRowIndex] = useState<undefined | number>(-1);
  const [type, setType] = useState('');
  const [openModal, setOpenModal] = useState(false);
  // state for modal confirmation when the new price affects orders to go over budget.
  const [openCancelOrdersModal, setOpenCancelOrdersModal] = useState<boolean>(false);
  // state for the waiting response from over-budget check, use to prevent more action while checking.
  const [isCheckingOverBudget, setCheckingOverBudget] = useState<boolean>(false);
  // add on id that currently check price and get over budget orders.
  const [currentCheckingAddOnId, setCurrentCheckingAddOnId] = useState<string | undefined>();
  // number of overBudget Orders that response from API.
  const [overBudgetOrdersCount, setOverBudgetOrdersCount] = useState<number>(0);
  const [restaurantAddonId, setRestaurantAddonId] = useState<string>('');
  const [restaurantId, setRestaurantId] = useState<undefined | string>(undefined);
  const [thaiText, setThaiText] = useState('');
  const [engText, setEngText] = useState('');
  const [priceText, setPrice] = useState('');
  const [prevAddon, setPrevAddon] = useState({
    prevThai: '',
    prevEng: '',
    prevPrice: '',
  });

  const [thNameErrorText, setThNameErrorText] = useState('');
  const [enNameErrorText, setEnNameErrorText] = useState('');
  const [priceErrorText, setPriceErrorText] = useState('');

  const [validate, setValidate] = useState(false);
  const [checkDup, setCheckDup] = useState(false);
  const [checkLoad, setCheckLoad] = useState(false);

  const countThaiText = useRef(0);
  const countEngText = useRef(0);
  const countPrice = useRef(0);

  const focusTextField = useRef<HTMLInputElement | null>(null);
  const loopTextField = useRef<HTMLInputElement | null>(null);

  // static
  const thaiChar = LIMIT_TH_CHAR;
  const engChar = LIMIT_EN_CHAR;

  const handleOpenDelete = (addonId: string, restaurantId: undefined | string) => {
    setType('DELETE');
    setOpenModal(true);
    setRestaurantAddonId(addonId);
    setRestaurantId(restaurantId);
  };

  const handleCancel = () => {
    setEditRowIndex(-1);
    setThNameErrorText('');
    setEnNameErrorText('');
    setPriceErrorText('');
    /** Set state INITIAL when user click cancel button */
    restaurantAddonUpdate.setState({ state: STATE_ENUM.INITIAL, data: undefined });
  };

  function handleEditClick(index: number, thName: string, enName: string, price: string) {
    setThNameErrorText('');
    setEnNameErrorText('');
    setPriceErrorText('');
    setThaiText(thName);
    setEngText(enName);
    setPrice(price);
    setPrevAddon({
      prevThai: thName,
      prevEng: enName,
      prevPrice: price,
    });
    setEditRowIndex(index);
    countEngText.current = enName.trim().length;
    countThaiText.current = thName.trim().length;
    countPrice.current = price.trim().length;

    /** Set state edit when user click edit button */
    updateRestaurantAddonEditing();
  }

  const handleCheckPriceBeforeSave = (addonId: string) => {
    if (id) {
      setCheckingOverBudget(true);
      setCheckLoad(true);
      setCurrentCheckingAddOnId(addonId);
      overBudgetOrdersByAddOn.requestAct(id, addonId, { price: parseInt(priceText) });
    }
  };
  const handleClickSave = (addonId: string) => {
    const payload = {
      thName: thaiText?.trim(),
      enName: engText?.trim(),
      price: parseInt(priceText),
    };
    setRestaurantAddonId(addonId);
    restaurantAddonUpdate.requestAct(id as string, addonId, payload);
    setValidate(false);
  };

  useEffect(() => {
    if (overBudgetOrdersByAddOn.current.state === STATE_ENUM.READY && isCheckingOverBudget && currentCheckingAddOnId) {
      // NOTE: if get the response success then check if there is the overBudget Orders or not.
      if (overBudgetOrdersByAddOn.current.data?.amount === 0) {
        handleClickSave(currentCheckingAddOnId);
        setCurrentCheckingAddOnId(undefined);
      } else {
        if (overBudgetOrdersByAddOn.current.data?.amount) setOverBudgetOrdersCount(overBudgetOrdersByAddOn.current.data?.amount);
        setOpenCancelOrdersModal(true);
      }
      setCheckingOverBudget(false);
    }
  }, [overBudgetOrdersByAddOn.current]);

  useEffect(() => {
    if (restaurantAddonUpdate.current.state === STATE_ENUM.READY) {
      setRestaurantDropdownList(apiPathV1.restaurantDropdownList, id, { types: ['addon', 'group'] });
      setResponseStatus({
        status: 'success',
        message: restaurantAddonUpdate.current?.status?.message ?? '',
      });
      restaurantAddonUpdate.setState({ state: STATE_ENUM.INITIAL, data: undefined });
      setOpen(true);
      setEditRowIndex(-1);
      setOrderBy(orderBy);
      setOrder(order);
      setCheckLoad(false);
      getRestaurantAddonIdUpdateList(restaurantAddonId, thaiText?.trim(), engText?.trim(), parseInt(priceText));
    } else if (restaurantAddonUpdate.current.state === STATE_ENUM.ERROR) {
      setResponseStatus({
        status: restaurantAddonUpdate.current.state,
        message: restaurantAddonUpdate.current?.status?.message ?? '',
      });
      restaurantAddonUpdate.setState({ state: STATE_ENUM.INITIAL, data: undefined });
      setOpen(true);
      setCheckLoad(true);
    }
  }, [restaurantAddonUpdate]);

  useEffect(() => {
    if (restaurantAddonDelete.current.state === STATE_ENUM.READY) {
      setIsChange(true);
      setTotal(total - 1);
      if (restaurantAddonList.current.data?.addons.length === 1 && restaurantAddonList.current.pagination?.page! > 0) {
        setPage(restaurantAddonList.current.pagination?.page! - 1);
        setPaginationParams((prevState) => {
          return { ...prevState, page: restaurantAddonList.current.pagination?.page! - 1 };
        });
      } else {
        setPage(restaurantAddonList.current.pagination?.page!);
        setPaginationParams((prevState) => {
          return { ...prevState, page: restaurantAddonList.current.pagination?.page! };
        });
      }
      if (restaurantAddonList.current.pagination?.total !== undefined) {
        restaurantAddonList.current.pagination.total--;
      }

      setRestaurantDropdownList(apiPathV1.restaurantDropdownList, id, { types: ['addon', 'group'] });
      setResponseStatus({
        status: 'success',
        message: restaurantAddonDelete.current?.status?.message ?? '',
      });

      restaurantAddonDelete.setState({ state: STATE_ENUM.INITIAL, data: undefined });
      setOpenModal(false);
      setOpen(true);
      setOrderBy(orderBy);
      setOrder(order);
      getRestaurantAddonIdDeleteList(restaurantAddonId);
    }
  }, [restaurantAddonDelete]);

  const checkDuplicateThai = () => {
    const tempArray = restaurantAddonList.current.data!.addons;
    return tempArray.find((item: RestaurantAddOnsSchema) => item.thName === thaiText?.trim() && prevAddon.prevThai !== thaiText?.trim());
  };

  const checkDuplicateEng = () => {
    const tempArray = restaurantAddonList.current.data!.addons;
    return tempArray.find(
      (item: RestaurantAddOnsSchema) => item.enName.toUpperCase() === engText?.trim().toUpperCase() && prevAddon.prevEng !== engText?.trim()
    );
  };

  const handleThaiNameTextChange = (event: any, index: number) => {
    countThaiText.current = event.target.value.trim().length;
    setEditRowIndex(index);
    setThaiText(event.target.value);
    if (event.target.value.length === 0 || !event.target.value.match(thaiChar)) {
      setThNameErrorText('Thai name is required.');
      setValidate(false);
    } else if (event.target.value.trim().length === 0) {
      setValidate(false);
      setThNameErrorText('Thai name is required.');
    } else if (event.target.value.length > 100) {
      setValidate(false);
      setThNameErrorText('Max 100 characters.');
    } else if (event.target.value.trim() === checkDuplicateThai()?.thName) {
      setThNameErrorText('This add-on already exists in this restaurant.');
    } else {
      if (enNameErrorText === '' && priceErrorText === '') {
        setValidate(true);
      }
      setThNameErrorText('');
    }
  };

  const handleEngNameTextChange = (event: any, index: number) => {
    countEngText.current = event.target.value.trim().length;
    setEditRowIndex(index);
    setEngText(event.target.value);
    if (event.target.value.length === 0 || !event.target.value.match(engChar)) {
      setEnNameErrorText('English name is required.');
      setValidate(false);
    } else if (event.target.value.trim().length === 0) {
      setValidate(false);
      setEnNameErrorText('English name is required.');
    } else if (event.target.value.length > 100) {
      setValidate(false);
      setEnNameErrorText('Max 100 characters.');
    } else if (event.target.value.trim() === checkDuplicateEng()?.enName) {
      setEnNameErrorText('This add-on already exists in this restaurant.');
    } else {
      if (thNameErrorText === '' && priceErrorText === '') {
        setValidate(true);
      }
      setEnNameErrorText('');
    }
  };

  const handleOutOfFocusThaiName = () => {
    if (countThaiText.current === 0) {
      setThaiText('');
    }
  };
  const handleOutOfFocusEngName = () => {
    if (countEngText.current === 0) {
      setEngText('');
    }
  };

  useEffect(() => {
    if (prevAddon.prevThai === thaiText && prevAddon.prevEng === engText && prevAddon.prevPrice === priceText) {
      setCheckDup(false);
    } else if (checkDuplicateThai() === undefined && checkDuplicateEng() === undefined) {
      setCheckDup(true);
    } else {
      if (thaiText.trim() === checkDuplicateThai()?.thName) {
        setThNameErrorText('This add-on already exists in this restaurant.');
        setCheckDup(false);
      }
      if (engText?.trim().toUpperCase() === checkDuplicateEng()?.enName.toUpperCase()) {
        setEnNameErrorText('This add-on already exists in this restaurant.');
        setCheckDup(false);
        if (checkDuplicateEng()?.enName === prevAddon.prevEng) {
          setEnNameErrorText('');
          setCheckDup(true);
        }
      }
    }
    if (
      countEngText.current !== 0 &&
      countThaiText.current !== 0 &&
      countPrice.current !== 0 &&
      thNameErrorText === '' &&
      enNameErrorText === '' &&
      priceErrorText === ''
    ) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  }, [thaiText, engText, priceText]);

  return (
    <TableBody id="tbody-restaurant-addons">
      {rows.map((row: RestaurantAddOnsSchema, index: number) => {
        return (
          <TableRow
            id={'tr-restaurant-addons-' + index}
            hover
            tabIndex={index}
            key={`${row.thName}+ ${index}`}
            sx={{ width: 20 }}
            className={`editTableData ${editRowIndex === index ? 'active' : null}`}
          >
            <TableCell id={'tr-restaurant-addons-col1-' + index} align="left" className="editInput">
              <TextField
                id={'input-restaurant-addons-thname-' + index}
                className="w-full"
                value={editRowIndex === index ? thaiText : row.thName}
                error={editRowIndex === index && thNameErrorText !== ''}
                onChange={(e) => {
                  handleThaiNameTextChange(e, index);
                }}
                onBlur={(e) => {
                  handleOutOfFocusThaiName();
                }}
                helperText={editRowIndex === index && thNameErrorText !== '' ? thNameErrorText : ''}
                tabIndex={-1}
                inputRef={editRowIndex === index ? focusTextField : null}
                ref={editRowIndex === index ? loopTextField : null}
                inputProps={{ maxLength: 101 }}
                disabled={checkLoad}
              />
            </TableCell>
            <TableCell id={'tr-restaurant-addons-col2-' + index} align="left" className="editInput">
              <TextField
                id={'input-restaurant-addons-enname-' + index}
                className="w-full"
                value={editRowIndex === index ? engText : row.enName}
                error={editRowIndex === index && enNameErrorText !== ''}
                onChange={(e) => {
                  handleEngNameTextChange(e, index);
                }}
                onBlur={(e) => {
                  handleOutOfFocusEngName();
                }}
                helperText={editRowIndex === index && enNameErrorText !== '' ? enNameErrorText : ''}
                inputProps={{ maxLength: 101 }}
                disabled={checkLoad}
              />
            </TableCell>
            <TableCell id={'tr-restaurant-addons-col3-' + index} align="left" className="editInput">
              <PriceInput
                price={priceText}
                priceErrorText={priceErrorText}
                setPrice={setPrice}
                setPriceErrorText={setPriceErrorText}
                setValidate={setValidate}
                countPrice={countPrice}
                editRowSetup={{
                  rowIndex: index,
                  rowPrice: row.price,
                  editRowIndex,
                  setEditRowIndex,
                }}
                disabled={checkLoad}
              />
            </TableCell>
            <TableCell id={'tr-restaurant-addons-col4-' + index} align="center" className="btnActionArea">
              <Stack direction="row" spacing={2} className="btnArea ">
                <Button
                  data-testid="save-button"
                  id="btn-save-addon"
                  variant="contained"
                  color="primary"
                  size="small"
                  className="w-20"
                  disabled={!validate || !checkDup || isCheckingOverBudget || checkLoad}
                  onClick={() => handleCheckPriceBeforeSave(row.id)}
                >
                  Save
                </Button>
                <Button
                  id="btn-cancel-addon"
                  variant="outlined"
                  color="primary"
                  size="small"
                  className="w-20"
                  onClick={() => handleCancel()}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Tab') {
                      loopTextField.current?.focus();
                    }
                  }}
                  disabled={isCheckingOverBudget || checkLoad}
                >
                  Cancel
                </Button>
              </Stack>
              <Stack direction="row-reverse" spacing={2} className="btnAreaAction">
                <Tooltip title="Delete" placement="bottom-start">
                  <Button
                    id="btn-delete-addon"
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => handleOpenDelete(row.id, id)} // row.id = addonId , id = restaurantId
                    disabled={isCheckingOverBudget || checkLoad}
                  >
                    <DeleteIcon></DeleteIcon>
                  </Button>
                </Tooltip>
                <Tooltip title="Edit info" placement="bottom-start">
                  <Button
                    id="btn-edit-addon"
                    variant="outlined"
                    color="primary"
                    size="small"
                    className="whenHoverPointer"
                    onClick={() => handleEditClick(index, row.thName, row.enName, row.price.toString())}
                    disabled={isCheckingOverBudget || checkLoad}
                    data-testid="update-button"
                  >
                    <EditIcon></EditIcon>
                  </Button>
                </Tooltip>
              </Stack>
            </TableCell>
          </TableRow>
        );
      })}

      {/* NOTE: this TableRow is hidden, just for allow table to place modal inside */}
      <TableRow>
        <TableCell sx={{ padding: '0px', border: 'none' }}>
          <Modal
            openModal={openModal}
            type={type}
            setOpenModal={setOpenModal}
            restaurantId={restaurantId}
            restaurantAddonId={restaurantAddonId}
            setRestaurantAddonDelete={restaurantAddonDelete.requestAct}
            restaurantAddonDeleteData={restaurantAddonDelete.current}
            setRestaurantAddonDeleteData={restaurantAddonDelete.setState}
            setType={setType}
          ></Modal>

          <ModalCancelOrder
            open={openCancelOrdersModal}
            overBudgetOrdersCount={overBudgetOrdersCount}
            onConfirm={() => {
              if (currentCheckingAddOnId) handleClickSave(currentCheckingAddOnId);
              setCurrentCheckingAddOnId(undefined);
              setOverBudgetOrdersCount(0);
              setOpenCancelOrdersModal(false);
            }}
            onCancel={() => {
              setOverBudgetOrdersCount(0);
              setOpenCancelOrdersModal(false);
              setCheckLoad(false);
            }}
          />
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
