import { Schedule } from '@mui/icons-material';
import { DesktopTimePicker, LocalizationProvider, TimeValidationError } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import React, { Dispatch, useState, SetStateAction } from 'react';

interface BasicTimePickerProps {
  setBookingId: Dispatch<string>;
  time: Date | null;
  disablePast?: boolean;
  setIsError?: Dispatch<SetStateAction<boolean>>;
}

export function BasicTimePicker(props: BasicTimePickerProps) {
  const { setBookingId, time, disablePast, setIsError } = props;
  const [selectedTime, setSelectedTime] = useState<moment.Moment | null>(moment(time));
  const [timeError, setTimeError] = useState(false);

  const handleChange = (updatedTime: moment.Moment | null) => {
    if (moment(updatedTime).format('THH:mm:SS.sssZ') !== moment(time).format('THH:mm:SS.sssZ')) {
      setSelectedTime(updatedTime);
      setBookingId(moment(updatedTime).format('THH:mm:00.000Z'));
    }
  };

  const handleError = (e: TimeValidationError) => {
    if (!setIsError) return;
    setIsError(e !== null);
    setTimeError(e !== null);
  };

  const disableTime = (updatedTime: moment.Moment) => {
    const updatedDate = moment(`${moment(time).format('YYYY-MM-DD')}${moment(updatedTime).format('THH:mm:ss.SSSZ')}`);
    if (moment(time).isSame(moment(), 'minutes')) {
      return true;
    }
    if (moment(time).isSame(moment(), 'day')) {
      return moment().isAfter(updatedDate, 'minutes');
    } else {
      return false;
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopTimePicker
        disablePast={disablePast && moment().isAfter(time, 'date')}
        onError={(e) => handleError(e)}
        closeOnSelect={false}
        shouldDisableTime={disableTime}
        skipDisabled
        minutesStep={30}
        defaultValue={selectedTime}
        onChange={handleChange}
        slots={{
          openPickerIcon: (props) => <Schedule {...props} sx={{ color: '#4CAF50' }} />,
        }}
        slotProps={{
          textField: {
            color: 'primary',
            size: 'small',
            sx: { width: '180px', height: '40px' },
            helperText: `${timeError ? 'Invalid scheduled time' : ''}`,
          },
        }}
      />
    </LocalizationProvider>
  );
}
