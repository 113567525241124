import React from 'react';
import { Box, Breadcrumbs, Link, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

type BreadcrumbsProps = {
  restaurantName: string;
  optionName?: string;
};

const RestaurantBreadcrumbs = (props: BreadcrumbsProps) => {
  const { restaurantName, optionName } = props;
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <Box sx={{ paddingTop: 4 }}>
      <Stack direction="row" spacing={2}>
        <Breadcrumbs id="full-width" className="w-full break-words" separator={<Typography color="text.primary">/</Typography>} aria-label="breadcrumb">
          <Link className="w-full" sx={{ cursor: 'pointer' }} onClick={() => navigate('/restaurants')}>
            Restaurants
          </Link>
          {!optionName ? (
            <Typography id="text" className="w-full" color="no-color">
              {restaurantName}
            </Typography>
          ) : (
            <Breadcrumbs className="w-full">
              <Link className="w-full" sx={{ cursor: 'pointer' }} onClick={() => navigate(`/restaurants/${id}/group`, { state: { restaurantName } })}>
                {restaurantName}
              </Link>
              <Typography className="w-full" color="no-color">
                {optionName}
              </Typography>
            </Breadcrumbs>
          )}
        </Breadcrumbs>
      </Stack>
    </Box>
  );
};

export default RestaurantBreadcrumbs;
