import { getDisplayConfig } from '@/src/utils';
import { useUserOrderHistoryList } from '@/store/userOderHistory.store';
import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import TableBodyNodata from '@/src/components/ui/tableBodyNoData';
import noOne from '@/images/noOne.png';

export function EnhancedTableBodyUserOrderHistory(props: any) {
  const { fetchUserOrderHistoryList } = useUserOrderHistoryList();
  const DATE_FORMAT = getDisplayConfig().dateFormat;
  const checkLoad = useState(false);
  // const [checkLoad, setCheckLoad] = useState(false);

  const useStyles = makeStyles({
    ellipsis: {
      width: '300px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  });
  const classes = useStyles();

  return (
    <>
      {fetchUserOrderHistoryList.current.state === 'loading' ? null : !fetchUserOrderHistoryList.current.data ||
        !fetchUserOrderHistoryList.current.data.historyList ||
        fetchUserOrderHistoryList.current.data.historyList.length === 0 ? (
        <TableBodyNodata page="user-order-history" src={noOne} title="No results found." />
      ) : (
        <TableBody>
          {fetchUserOrderHistoryList.current.data.historyList.map((item: any) => (
            <TableRow key={item.date}>
              <TableCell align="left">{dayjs(item.date).format(DATE_FORMAT)}</TableCell>
              <TableCell align="left" className="relative overflow-hidden">
                <div className={`px-[18px] py-3.5 top-[calc(50%-25px)] left-0 w-[calc(100%-36px)] absolute overflow-hidden whitespace-nowrap text-ellipsis`}>
                  {item.restaurant.restaurantThName} ({item.restaurant.restaurantEnName})
                </div>
              </TableCell>
              <TableCell align="left">
                <Typography className={classes.ellipsis}>{item.order.menu.menuThName}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className={classes.ellipsis}>
                  {item.order.addon.length > 0 ? item.order.addon.map((add: any) => add.addonThName).join(', ') : '-'}
                </Typography>
              </TableCell>
              <TableCell align="left" className="relative overflow-hidden">
                <div
                  className={`px-[18px] py-3.5 top-[calc(50%-25px)] left-0 w-[calc(100%-36px)] absolute overflow-hidden whitespace-nowrap  ${
                    checkLoad ? 'text-gray-300' : 'text-ellipsis'
                  }`}
                >
                  {item.order.remark.length > 0 ? item.order.remark : '-'}
                </div>
              </TableCell>
              <TableCell align="center">{item.order.price}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      )}
    </>
  );
}
