import { visuallyHidden } from '@mui/utils';
import { TableHead, TableRow, TableCell, TableSortLabel, Box, Checkbox, Popover, Tooltip } from '@mui/material';
import { HeadCell, HeaderCellSelectAll, QueryParamsGetList, order, sortType } from '@/src/types';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StarIcon from '@mui/icons-material/Star';
import ErrorIcon from '@mui/icons-material/Error';
import { makeStyles } from '@mui/styles';
import { SELECT_ALL_STATE_ENUM } from '../../hocs/useSelectAll';
import React from 'react';
import Typography from '@mui/material/Typography';

interface EnhancedTableHeadProps {
  headCells: HeadCell[];
  order: order | null;
  orderBy: any | null;
  setOrder: React.Dispatch<React.SetStateAction<order | null>>;
  setOrderBy: React.Dispatch<React.SetStateAction<any | null>>;
  setPaginationParams?: React.Dispatch<React.SetStateAction<QueryParamsGetList<any>>>;
  respectSortAble?: boolean;
  disabledSorting: boolean;
  selectAll?: HeaderCellSelectAll;
  isGreenHeader?: boolean;
}

const useStyles = makeStyles({
  posArrow: {
    flexDirection: 'row',
    '& .MuiTableSortLabel-icon': {
      position: 'absolute',
      left: '100%',
    },
  },
  customTooltip: {
    backgroundColor: 'white',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
    color: 'black',
  },
});

export function EnhancedTableHead(props: EnhancedTableHeadProps) {
  // eslint-disable-next-line no-unused-vars
  const { headCells, order, orderBy, setOrder, setOrderBy, setPaginationParams, respectSortAble, disabledSorting, selectAll } = props;
  const classes = useStyles();

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    if (selectAll?.onClear) {
      selectAll?.onClear();
    }
    if (setPaginationParams) {
      setPaginationParams((prevState) => {
        return { ...prevState, orderBy: property, sort: (isAsc ? 'desc' : 'asc') as sortType };
      });
    }
  };

  const createSortHandler = (property: keyof any) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  const [description, setDescription] = React.useState<HTMLElement | null>(null);

  const handleDescriptionOpen = (event: React.MouseEvent<HTMLElement>) => {
    setDescription(event.currentTarget);
  };

  const handleDescriptionClose = () => {
    setDescription(null);
  };

  const open = Boolean(description);

  const Description = () => (
    <Popover
      id="description"
      sx={{
        pointerEvents: 'none',
      }}
      open={open}
      anchorEl={description}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handleDescriptionClose}
      disableRestoreFocus
    >
      <Typography sx={{ p: 1 }}>The maximum rating is 5.0</Typography>
    </Popover>
  );

  const DescriptionForFallbackMeal = () => (
    <Typography sx={{ p: 1, fontSize: '14px' }}>
      A fallback meal is the pre-selected menu
      <br />
      item from the restaurant&apos;s menu. It will be
      <br />
      applied to any mobile user who has not yet
      <br />
      updated their fallback meal.
      <br />
      Once updated, the admin&apos;s actions will not
      <br />
      apply unless the menu item is deleted. If a
      <br />
      fallback meal is deleted, the user&apos;s new
      <br />
      fallback meal will be the one selected by the
      <br />
      admin.
      <br />
      The fallback meal will automatically be used
      <br />
      when there&apos;s a sudden change of
      <br />
      restaurant. Only one fallback meal can be
      <br />
      set per restaurant.
    </Typography>
  );

  const headCellCondition = (headCell) => {
    switch (headCell.id) {
      case 'favoriteCount':
        return <FavoriteIcon className="text-red-500" />;

      case 'ratingAvg':
        return (
          <>
            <StarIcon className="text-yellow-400" />
            <Typography
              aria-owns={open ? 'description' : undefined}
              aria-haspopup="true"
              onMouseEnter={handleDescriptionOpen}
              onMouseLeave={handleDescriptionClose}
            >
              <ErrorIcon className="text-gray-500 absolute inset-y-0 left-7 w-16" />
            </Typography>
            <Description />
          </>
        );

      case 'fallbackMeal':
        return (
          <>
            {headCell.label}
            <Tooltip title={<DescriptionForFallbackMeal />} placement="top" classes={{ tooltip: classes.customTooltip }}>
              <ErrorIcon className="text-gray-500 absolute inset-y-0 left-32 w-100" />
            </Tooltip>
          </>
        );

      default:
        return (
          <Box
            component="label"
            sx={{
              cursor: 'pointer',
            }}
          >
            {headCell.label}
          </Box>
        );
    }
  };

  return (
    <TableHead id="th-restaurant">
      <TableRow id="tr-restaurant-header">
        {selectAll && (
          <TableCell padding="checkbox">
            <Checkbox
              color="error"
              indeterminate={selectAll.selectAllStatus === SELECT_ALL_STATE_ENUM.INDETERMINATE}
              checked={selectAll.selectAllStatus === SELECT_ALL_STATE_ENUM.ALL}
              onChange={(_event, value) => selectAll.onSelectAllClick(value)}
              inputProps={{
                'aria-label': 'select all',
              }}
              id="checkbox-select-all"
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            id={'td-' + (headCell.id as string)}
            key={headCell.id as string}
            className={headCell.id === 'price' || headCell.id === 'actions' ? 'export-omit' : ''}
            align={headCell.align ? headCell.align : headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? (order === null ? undefined : order) : undefined}
            sx={{
              fontWeight: 'bold',
              width: headCell.width,
              padding: '16px 20px',
            }}
          >
            {headCell.sortAble ? (
              <TableSortLabel
                sx={{
                  '&.Mui-active': {
                    color: '#4CAF50',
                    '& .MuiTableSortLabel-icon': {
                      color: '#4CAF50',
                    },
                  },
                  color: orderBy === headCell.id ? '#4CAF50 ' : '#949494',
                  '& .MuiTableSortLabel-icon': {
                    color: orderBy === headCell.id ? '#4CAF50 ' : '',
                    opacity: 0.5,
                  },
                  '&:hover': {
                    color: '#006D46',
                    '& .MuiTableSortLabel-icon': {
                      opacity: 1,
                      color: '#006D46',
                    },
                  },
                }}
                active={headCell.id === 'number' || disabledSorting ? false : orderBy === headCell.id}
                hideSortIcon={disabledSorting}
                direction={orderBy === headCell.id ? (order === null ? undefined : order) : undefined}
                onClick={createSortHandler(headCell.id)}
                disabled={disabledSorting}
                className={headCell.numeric ? classes.posArrow : ''}
              >
                {headCellCondition(headCell)}

                {orderBy === headCell.id ? (
                  <>
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  </>
                ) : null}
              </TableSortLabel>
            ) : (
              /* MUI sorting */
              <label>{headCell.label}</label>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
