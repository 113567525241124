import { TableBody, TableCell, TableRow } from '@mui/material';
import React, { useMemo } from 'react';

export default function EnhancedTableBodyFallbackMealOrder(props: any) {
  const { rows, page, rowsPerPage } = props;
  const rowsPerPageShow = useMemo(() => {
    return rowsPerPage < 0 ? 0 : rowsPerPage;
  }, [rowsPerPage]);

  return (
    <TableBody id="tbody-order">
      {rows?.map((row: any, index: number) => {
        return (
          <TableRow id={'tr-order-' + index} hover tabIndex={-1} key={`${row.userId}+ ${index}`} sx={{ width: 20 }}>
            <TableCell id={'td-order-col1-' + index} align="right">
              {page * rowsPerPageShow + index + 1}
            </TableCell>
            <TableCell id={'td-order-col2-' + index} align="left">
              {row.userName}
            </TableCell>
            <TableCell id={'td-order-col3-' + index} align="left">
              {row.menu.menuThName}
            </TableCell>
            <TableCell id={'td-order-col4-' + index} align="left">
              {row.addon.map((data: any) => data.addonThName).join(', ')}
            </TableCell>
            <TableCell id={'td-order-col5-' + index} sx={{ maxWidth: '30vw', overflowWrap: 'break-word' }} align="left">
              {row.remark}
            </TableCell>
            <TableCell id={'td-order-col6-' + index} className="export-omit" align="right">
              {row.price}
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
