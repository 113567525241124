import React, { createContext, useContext, useEffect, useState } from 'react';
import { DEFAULT_ROW_PER_PAGE, apiPathV1, apiPathV2 } from '../../../app.constants';
import { ActionUnit2, commonSetError, initialApiContext2 } from '../../../services/store/common';
import {
  QueryParamsGetList,
  QueryMenuListSortBy,
  QueryOverBudgetOrderByMenu,
  RestaurantMenuBody,
  RestaurantMenuCreateBody,
  PaginatedCrud,
  CommonCrud,
  MenuOverviewList,
  MenuOverviewListResponse,
  MenuListResponse,
  MenuCreateResponse,
  MenuUpdateResponse,
  MenuDeleteResponse,
  OverBudgetOrdersByMenuResponse,
  CommonCrudWithError,
  DeleteFailByOrdersError,
  FallbackMealChangeResponse,
} from '../../../types';
import {
  FallbackMealChange,
  OrderOverBudgetByMenuCheckV2,
  RestaurantMenuCreateV2,
  RestaurantMenuDeleteV2,
  RestaurantMenuListForOrderGetV2,
  RestaurantMenuListGetV2,
  RestaurantMenuUpdateV2,
} from '../../http/menu.v2';
import { STATE_ENUM } from '../../../types/schema/enum/common.enum';
import { updateFallbackMealSchema } from '@/src/types/schema/updateFallbackMeal';

// const apiUrl = apiPathV2.fallbackMeal;

declare namespace Types {
  export type MenuList = PaginatedCrud<MenuOverviewListResponse>;
  export type MenuListForOrder = PaginatedCrud<MenuListResponse>;
  export type MenuCreate = CommonCrud<MenuCreateResponse>;
  export type MenuUpdate = CommonCrud<MenuUpdateResponse>;
  export type MenuDelete = CommonCrudWithError<MenuDeleteResponse, CommonCrud<DeleteFailByOrdersError>>;
  export type OverBudgetOrdersByMenu = CommonCrud<OverBudgetOrdersByMenuResponse>;
  export type FallbackMealChange = CommonCrud<FallbackMealChangeResponse>;
  export namespace RequestAct {
    export type MenuList = (restaurantId: string, params?: QueryParamsGetList<QueryMenuListSortBy>) => Promise<any>;
    export type MenuListForOrder = (restaurantId: string) => void;
    export type MenuCreate = (restaurantId: string, payload: RestaurantMenuCreateBody) => void;
    export type MenuUpdate = (restaurantId: string, menuId: string, payload: RestaurantMenuBody) => void;
    export type MenuDelete = (restaurantId: string, menuId: string) => void;
    export type OverBudgetOrdersByMenu = (restaurantId: string, menuId: string, params: QueryOverBudgetOrderByMenu) => void;
    export type FallbackMealChange = (payload: updateFallbackMealSchema) => void;
  }
  export namespace CustomAct {
    export type UpdateMenuListByIdUpdated = (updatedId: string, updatedMenu: MenuOverviewList) => void;
    export type UpdateMenuListByIdDeleted = (deletedId: string) => void;
    export type updateMenuEditing = () => void;
  }
}

interface IMenuContextV2 {
  menuList: ActionUnit2<Types.MenuList> & { requestAct: Types.RequestAct.MenuList };
  menuListForOrder: ActionUnit2<Types.MenuListForOrder> & { requestAct: Types.RequestAct.MenuListForOrder };
  menuCreate: ActionUnit2<Types.MenuCreate> & { requestAct: Types.RequestAct.MenuCreate };
  menuUpdate: ActionUnit2<Types.MenuUpdate> & { requestAct: Types.RequestAct.MenuUpdate };
  menuDelete: ActionUnit2<Types.MenuDelete> & { requestAct: Types.RequestAct.MenuDelete };
  overBudgetOrdersByMenu: ActionUnit2<Types.OverBudgetOrdersByMenu> & { requestAct: Types.RequestAct.OverBudgetOrdersByMenu };
  updateMenuListByIdUpdated: Types.CustomAct.UpdateMenuListByIdUpdated;
  updateMenuListByIdDeleted: Types.CustomAct.UpdateMenuListByIdDeleted;
  updateMenuEditing: Types.CustomAct.updateMenuEditing;
  fallbackMealChange: ActionUnit2<Types.FallbackMealChange> & { requestAct: Types.RequestAct.FallbackMealChange };
}

const initialState: IMenuContextV2 = {
  menuList: { ...initialApiContext2<Types.MenuList, Types.RequestAct.MenuList>() },
  menuListForOrder: { ...initialApiContext2<Types.MenuListForOrder, Types.RequestAct.MenuListForOrder>() },
  menuCreate: { ...initialApiContext2<Types.MenuCreate, Types.RequestAct.MenuCreate>() },
  menuUpdate: { ...initialApiContext2<Types.MenuUpdate, Types.RequestAct.MenuUpdate>() },
  menuDelete: { ...initialApiContext2<Types.MenuDelete, Types.RequestAct.MenuDelete>() },
  overBudgetOrdersByMenu: { ...initialApiContext2<Types.OverBudgetOrdersByMenu, Types.RequestAct.OverBudgetOrdersByMenu>() },
  updateMenuListByIdUpdated: (updatedId: string, updatedMenu: MenuOverviewList) => {},
  updateMenuListByIdDeleted: (deletedId: string) => {},
  updateMenuEditing: () => {},
  fallbackMealChange: { ...initialApiContext2<Types.FallbackMealChange, Types.RequestAct.FallbackMealChange>() },
};

const MenuContextV2: React.Context<IMenuContextV2> = createContext(initialState);

const MenuContextV2Provider = ({ children }: { children: React.ReactNode }) => {
  const [menuList, setMenuList] = useState<Types.MenuList>(initialState.menuList.current);
  const [menuListForOrder, setMenuListForOrder] = useState<Types.MenuListForOrder>(initialState.menuListForOrder.current);
  const [menuCreate, setMenuCreate] = useState<Types.MenuCreate>(initialState.menuCreate.current);
  const [menuUpdate, setMenuUpdate] = useState<Types.MenuUpdate>(initialState.menuUpdate.current);
  const [menuDelete, setMenuDelete] = useState<Types.MenuDelete>(initialState.menuDelete.current);
  const [overBudgetOrdersByMenu, setOverBudgetOrdersByMenu] = useState<Types.OverBudgetOrdersByMenu>(initialState.overBudgetOrdersByMenu.current);
  const [fallbackMealChange, setFallbackMealChange] = useState<Types.FallbackMealChange>(initialState.fallbackMealChange.current);

  const getMenuList: Types.RequestAct.MenuList = (restaurantId, params = { page: 0, itemPerPage: DEFAULT_ROW_PER_PAGE }) => {
    setMenuList({ ...initialState.menuList.current, state: 'loading' });
    return RestaurantMenuListGetV2<Types.MenuList>(apiPathV2.menuListWithAddonNoOptionAddon, restaurantId, params)
      .then(({ status, data, pagination }) => {
        setMenuList({ state: 'ready', status, data, pagination });
        return { status, data, pagination };
      })
      .catch((error) => commonSetError(error, setMenuList));
  };

  const getMenuListForOrder: Types.RequestAct.MenuListForOrder = (restaurantId) => {
    setMenuListForOrder({ ...initialState.menuListForOrder.current, state: 'loading' });
    RestaurantMenuListForOrderGetV2<Types.MenuListForOrder>(apiPathV2.menuListWithAddon, restaurantId)
      .then(({ status, data, pagination }) => setMenuListForOrder({ state: 'ready', status, data, pagination }))
      .catch((error) => commonSetError(error, setMenuListForOrder));
  };

  const createMenu: Types.RequestAct.MenuCreate = (restaurantId, payload) => {
    setMenuCreate({ ...initialState.menuCreate.current, state: 'loading' });
    RestaurantMenuCreateV2<Types.MenuCreate>(apiPathV1.menuCreate, restaurantId, payload)
      .then(({ status, data }) => setMenuCreate({ state: 'ready', status, data }))
      .catch((error) => commonSetError(error, setMenuCreate));
  };

  const updateMenu: Types.RequestAct.MenuUpdate = (restaurantId, menuId, payload) => {
    setMenuUpdate({ ...initialState.menuUpdate.current, state: 'loading' });
    RestaurantMenuUpdateV2<Types.MenuUpdate>(apiPathV1.menuUpdate, restaurantId, menuId, payload)
      .then(({ status, data }) => setMenuUpdate({ state: 'ready', status, data }))
      .catch((error) => commonSetError(error, setMenuUpdate));
  };

  const updateMenuEditing: Types.CustomAct.updateMenuEditing = () => {
    setMenuUpdate({ ...initialState.menuUpdate.current, state: STATE_ENUM.EDITING });
  };

  const deleteMenu: Types.RequestAct.MenuDelete = (restaurantId, menuId) => {
    setMenuDelete({ ...initialState.menuDelete.current, state: 'loading' });
    RestaurantMenuDeleteV2<Types.MenuDelete>(apiPathV1.menuDelete, restaurantId, menuId)
      .then(({ status, data }) => setMenuDelete({ state: 'ready', status, data }))
      .catch((error) => commonSetError(error, setMenuDelete));
  };

  const getOverBudgetOrdersByMenuPriceChange: Types.RequestAct.OverBudgetOrdersByMenu = (restaurantId, menuId, params) => {
    setOverBudgetOrdersByMenu({ state: 'loading' });
    OrderOverBudgetByMenuCheckV2<Types.OverBudgetOrdersByMenu>(apiPathV1.overBudgetOrdersByMenu, restaurantId, menuId, params)
      .then(({ status, data }) => setOverBudgetOrdersByMenu({ state: 'ready', status, data }))
      .catch((error) => commonSetError(error, setOverBudgetOrdersByMenu));
  };

  const updateMenuListByIdUpdated: Types.CustomAct.UpdateMenuListByIdUpdated = (updatedId, updatedMenu) => {
    const startArray = menuList;
    if (startArray.data && startArray.data?.menuAddonOverviewList) {
      const menuUpdatedList = startArray.data.menuAddonOverviewList.map((menu): MenuOverviewList => {
        return menu.id === updatedId ? { ...menu, ...updatedMenu } : { ...menu };
      });
      setMenuList({
        ...menuList,
        state: 'ready',
        data: {
          restaurant: menuList.data?.restaurant,
          menuAddonOverviewList: menuUpdatedList,
          fallBackMenu: menuList.data?.fallBackMenu,
        },
      });
    }
  };

  const updateMenuListByIdDeleted: Types.CustomAct.UpdateMenuListByIdDeleted = (deletedId: string) => {
    const startArray = menuList;
    if (startArray.data && startArray.data?.menuAddonOverviewList) {
      const menuDeletedList = startArray.data.menuAddonOverviewList.filter((menu) => menu.id !== deletedId);
      setMenuList({
        ...menuList,
        state: 'ready',
        data: {
          restaurant: menuList.data?.restaurant,
          menuAddonOverviewList: menuDeletedList,
          fallBackMenu: menuList.data?.fallBackMenu,
        },
      });
    }
  };

  const changeFallbackMeal: Types.RequestAct.FallbackMealChange = (payload) => {
    setFallbackMealChange({ ...initialState.fallbackMealChange.current, state: 'loading' });
    FallbackMealChange<Types.FallbackMealChange>(apiPathV2.fallbackMealAdmin, payload)
      .then(({ status, data }) => setFallbackMealChange({ state: 'ready', status, data }))
      .catch((error) => {
        commonSetError(error, setFallbackMealChange);
      });
  };

  const menuStore: IMenuContextV2 = {
    menuList: {
      current: menuList,
      setState: setMenuList,
      requestAct: getMenuList,
    },
    menuListForOrder: {
      current: menuListForOrder,
      setState: setMenuListForOrder,
      requestAct: getMenuListForOrder,
    },
    menuCreate: {
      current: menuCreate,
      setState: setMenuCreate,
      requestAct: createMenu,
    },
    menuUpdate: {
      current: menuUpdate,
      setState: setMenuUpdate,
      requestAct: updateMenu,
    },
    menuDelete: {
      current: menuDelete,
      setState: setMenuDelete,
      requestAct: deleteMenu,
    },
    overBudgetOrdersByMenu: {
      current: overBudgetOrdersByMenu,
      setState: setOverBudgetOrdersByMenu,
      requestAct: getOverBudgetOrdersByMenuPriceChange,
    },
    updateMenuListByIdUpdated,
    updateMenuListByIdDeleted,
    updateMenuEditing,
    fallbackMealChange: {
      current: fallbackMealChange,
      setState: setFallbackMealChange,
      requestAct: changeFallbackMeal,
    },
  };

  useEffect(() => {
    return () => {
      // NOTE: cancel all subscriptions and asynchronous tasks to cleanup function, prevent React state update on an unmounted component, cause memory leak
      setMenuList(initialState.menuList.current);
      setMenuListForOrder(initialState.menuListForOrder.current);
      setMenuCreate(initialState.menuCreate.current);
      setMenuUpdate(initialState.menuUpdate.current);
      setMenuDelete(initialState.menuDelete.current);
      setOverBudgetOrdersByMenu(initialState.overBudgetOrdersByMenu.current);
      setFallbackMealChange(initialState.fallbackMealChange.current);
    };
  }, []);

  return <MenuContextV2.Provider value={menuStore}>{children}</MenuContextV2.Provider>;
};

function useMenuContextV2() {
  return useContext(MenuContextV2);
}

export { initialState as initialMenuState, useMenuContextV2, MenuContextV2Provider };
export type { Types as MenuTypes };
