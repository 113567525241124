import { SelectChangeEvent, FormControl, Select, OutlinedInput, MenuItem, ListItemIcon, Checkbox, ListItemText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { RestaurantAddOnsSchema } from '@/types/schema/restaurantAddOns';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styles } from '@/src/styles/theme';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface SelectAddOnsProps {
  selectedAddOns: RestaurantAddOnsSchema[];
  setSelectAddonId: React.Dispatch<React.SetStateAction<string[]>>;
  editRowIndex: number | undefined;
  index: number;
  dropdownAddOnsData: { label: string; value: string }[];
  disabled;
  inputId?: string;
}

export default function SelectAddOns(props: SelectAddOnsProps) {
  const { selectedAddOns, setSelectAddonId, editRowIndex, index, dropdownAddOnsData, disabled } = props;

  const selectedAddOnsProps = selectedAddOns.map((item) => item.id);
  const [selectedAddOnsState, setSelectedAddOnsState] = useState<string[]>([]);

  const handleSelectAddOns = (event: SelectChangeEvent<string[]>, child: any) => {
    event.preventDefault();
    if (child?.props?.value === 'all') {
      if (selectedAddOnsState.length === dropdownAddOnsData.length) {
        // case: un-select all
        setSelectedAddOnsState([]);
        setSelectAddonId([]);
      } else {
        // case: select all
        const allAddonIds = dropdownAddOnsData.map((item) => item.value);
        setSelectedAddOnsState(allAddonIds);
        setSelectAddonId(allAddonIds);
      }
    } else {
      // case: select/un-select one
      setSelectedAddOnsState(event.target.value as string[]);
      setSelectAddonId(event.target.value as string[]);
    }
  };

  useEffect(() => {
    setSelectedAddOnsState(selectedAddOnsProps);
  }, [editRowIndex]);

  const classes = styles();

  return (
    <FormControl sx={{ width: '300px' }} variant="standard">
      <Select
        id="select-addon"
        multiple
        IconComponent={KeyboardArrowDownIcon}
        // NOTE: if the current row is editing then use selected state, if not then use selected props
        value={editRowIndex === index ? selectedAddOnsState : selectedAddOnsProps}
        onChange={handleSelectAddOns}
        input={<OutlinedInput id={props.inputId || 'select-multiple-chip'} />}
        renderValue={(selected) => {
          return selected
            .reduce((sum: string[], each: string) => {
              const eachLabel = dropdownAddOnsData.find((item) => item.value === each);
              return eachLabel ? [...sum, eachLabel.label] : sum;
            }, [])
            .join(', ');
        }}
        MenuProps={MenuProps}
        style={{ height: 36 }}
        color="secondary"
        disabled={disabled}
      >
        {dropdownAddOnsData.length > 0 && (
          <MenuItem id={'li-addon-' + index} value="all" sx={{ pl: 0 }}>
            <ListItemIcon>
              <Checkbox
                id={'checkbox-addon-' + index}
                checked={selectedAddOnsState.length === dropdownAddOnsData.length}
                indeterminate={selectedAddOnsState.length > 0 && selectedAddOnsState.length < dropdownAddOnsData.length}
              />
            </ListItemIcon>
            <ListItemText primary="All add-ons" />
          </MenuItem>
        )}
        {dropdownAddOnsData.length > 0 &&
          dropdownAddOnsData.map((item) => (
            <MenuItem id={item.value} key={item.value} value={item.value} sx={{ pl: 3 }} className={classes.wrap_text}>
              <Checkbox id={'checkbox-addon-' + item.value} checked={selectedAddOnsState.includes(item.value)} />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
