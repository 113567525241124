import React, { useEffect, useState } from 'react';
import { Box, Paper, Table, TableContainer } from '@mui/material';
import { ConditionalWrapper, EnhancedTableHead, EnhancedTablePaginationV2, LoadingIcon, PopupSnackbar, AddRestaurantGroups } from '@/components/index';
import { DEFAULT_ROW_PER_PAGE } from '@/app.constants/index';
import { order, optionGroupOrderByType, QueryParamsGetList, sortType } from '@/types/index';
import { EnhancedTableBodyRestaurantGroups } from '@/components/ui/tableBodyGroups';
import { headCellsRestaurantGroups } from '@/static/groups';
import { useGroupContextV2 } from '@/services/store/group.v2.store';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { cookieOptions } from '@/src/utils';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import TableBodyNodata from '@/src/components/ui/tableBodyNoData';
import noOne from '@/images/noOne.png';

export function Groups(props: any) {
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROW_PER_PAGE);
  const [groupPaginationParams, setGroupPaginationParams] = useState<QueryParamsGetList<optionGroupOrderByType>>({ page, itemPerPage: rowsPerPage });
  const [isInitialGetData, setIsInitialGetData] = useState(true);

  // SORTING / PAGINATION
  let [order, setOrder] = useState<order | null>(groupPaginationParams.sort || 'asc');
  let [orderBy, setOrderBy] = useState<optionGroupOrderByType | null>(groupPaginationParams.orderBy || 'thName');
  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const { restaurantGroupList } = useGroupContextV2();
  const [cookies, setCookie] = useCookies(['sortingOptionGroups']);
  const sorting: any = {};

  // NOTE: initial API call
  useEffect(() => {
    if (restaurantGroupList.current.state === STATE_ENUM.INITIAL && id) {
      if (typeof cookies.sortingOptionGroups !== 'undefined') {
        orderBy = cookies.sortingOptionGroups?.orderBy;
        order = cookies.sortingOptionGroups?.sort;
      }

      setOrderBy(groupPaginationParams.orderBy || orderBy);
      setOrder(groupPaginationParams.sort || order);

      setIsInitialGetData(false);
      restaurantGroupList.requestAct(id, {
        page,
        itemPerPage: rowsPerPage,
        orderBy: orderBy as optionGroupOrderByType,
        sort: order as sortType,
      });
    }
  }, [restaurantGroupList.current]);

  useEffect(() => {
    if (restaurantGroupList.current.state !== STATE_ENUM.INITIAL && id && !isInitialGetData) {
      if (typeof groupPaginationParams.orderBy !== 'undefined') sorting.orderBy = groupPaginationParams.orderBy;
      if (typeof groupPaginationParams.sort !== 'undefined') sorting.sort = groupPaginationParams.sort;

      setOrderBy(groupPaginationParams.orderBy || sorting.orderBy);
      setOrder(groupPaginationParams.sort || sorting.sort);

      setCookie('sortingOptionGroups' as any, JSON.stringify(sorting), cookieOptions());
      restaurantGroupList.requestAct(id, groupPaginationParams);
    }
  }, [groupPaginationParams]);
  useEffect(() => {
    if (restaurantGroupList.current.state === STATE_ENUM.READY && id && isInitialGetData) {
      if (typeof cookies.sortingOptionGroups !== 'undefined') {
        orderBy = cookies.sortingOptionGroups?.orderBy;
        order = cookies.sortingOptionGroups?.sort;
      }

      setIsInitialGetData(false);
      restaurantGroupList.requestAct(id, {
        page,
        itemPerPage: rowsPerPage,
        orderBy: orderBy as optionGroupOrderByType,
        sort: order as sortType,
      });

      setOrderBy(orderBy);
      setOrder(order);
      setTotal(restaurantGroupList.current.pagination?.total || 0);
    } else if (restaurantGroupList.current.state === STATE_ENUM.READY) {
      setTotal(restaurantGroupList.current.pagination?.total || 0);
    }
  }, [restaurantGroupList.current]);

  return (
    <Box>
      <AddRestaurantGroups
        setOpen={setOpen}
        setOrderBy={setOrderBy}
        setOrder={setOrder}
        setPage={setPage}
        total={total}
        setTotal={setTotal}
        page={page}
        rowsPerPage={rowsPerPage}
        groupPaginationParams={groupPaginationParams}
        setGroupPaginationParams={setGroupPaginationParams}
      />
      {/* TABLE */}
      <ConditionalWrapper condition={restaurantGroupList.current.state !== STATE_ENUM.LOADING} alternativeNode={<LoadingIcon setCenter={false}></LoadingIcon>}>
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
          <Paper elevation={0} sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="small"
                sx={{
                  minWidth: 750,
                }}
                id="option-group-table"
              >
                <EnhancedTableHead
                  headCells={headCellsRestaurantGroups}
                  order={order}
                  orderBy={orderBy}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  disabledSorting={restaurantGroupList.current.data?.groups.length === 0}
                  setPaginationParams={setGroupPaginationParams}
                  respectSortAble
                />
                {restaurantGroupList.current.state === STATE_ENUM.READY && restaurantGroupList.current.data!.groups.length > 0 ? (
                  <EnhancedTableBodyRestaurantGroups
                    rows={restaurantGroupList.current.data?.groups}
                    order={order}
                    orderBy={orderBy}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    {...props}
                    setOrderBy={setOrderBy}
                    setOrder={setOrder}
                    setOpen={setOpen}
                    total={total}
                    setTotal={setTotal}
                    setPage={setPage}
                    setPaginationParams={setGroupPaginationParams}
                  />
                ) : (
                  <TableBodyNodata page={'optionGroup'} src={noOne} title="No results found." />
                )}
              </Table>
              <EnhancedTablePaginationV2
                total={total}
                rowsPerPage={rowsPerPage}
                page={page}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                setPaginationParams={setGroupPaginationParams}
                disabledPagination={!restaurantGroupList.current.data?.groups.length}
              />
            </TableContainer>
            <PopupSnackbar open={open} setOpen={setOpen}></PopupSnackbar>
          </Paper>
        </Box>
      </ConditionalWrapper>
    </Box>
  );
}
