import React, { useContext, useState, useEffect } from 'react';

import { OrderDelete, OrdersSummaryListGet, OrderCreate, OrderDeleteAll, OrderCountGet } from '@/services/http/Order';
import {
  PostCreateOrder,
  DeleteOrder,
  GetOrdersSummaryList,
  OrderCreateBody,
  DeleteAllOrder,
  GetOrderCount,
  QueryParamsGetList,
  orderOrderByType,
} from '@/types/index';

import { OrdersContext } from './initial.store';
import moment from 'moment';
import { DEFAULT_ROW_PER_PAGE } from '@/src/app.constants';

export function useOrdersContext() {
  return useContext(OrdersContext);
}

const OrdersContextProvider = ({ children }: { children: React.ReactNode }) => {
  const contextInitialState = useOrdersContext();
  const now = moment().toDate();

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(now);
  const [OrdersSummaryList, setOrdersSummaryList] = useState<GetOrdersSummaryList>(contextInitialState.OrdersSummaryList);
  const [orderDeleteData, setOrderDeleteData] = useState<DeleteOrder>(contextInitialState.orderDeleteData);
  const [orderCreateData, setOrderCreateData] = useState<PostCreateOrder>(contextInitialState.orderCreateData);
  const [orderDeleteAllData, setOrderDeleteAllData] = useState<DeleteAllOrder>(contextInitialState.orderDeleteAllData);
  const [orderCountData, setOrderCountData] = useState<GetOrderCount>(contextInitialState.orderCountData);

  // action Orders Summary List
  const getOrdersSummaryList = (prefix: string, bookingId: string, params?: QueryParamsGetList<orderOrderByType>) => {
    setOrdersSummaryList({ ...contextInitialState.OrdersSummaryList, state: 'loading' });
    OrdersSummaryListGet<GetOrdersSummaryList>(
      prefix,
      bookingId,
      params || ({ page: 0, itemPerPage: DEFAULT_ROW_PER_PAGE } as QueryParamsGetList<orderOrderByType>)
    )
      .then((d) => {
        setOrdersSummaryList({
          bookingStatus: d.bookingStatus,
          date: d.date,
          restaurant: d.restaurant,
          totalPrice: d.totalPrice,
          pricePerPage: d.pricePerPage,
          order: d.order,
          state: 'ready',
          status: d.status,
          pagination: d.pagination,
          holidayDates: d.holidayDates,
        });
      })
      .catch((error) => {
        setOrdersSummaryList({ ...contextInitialState.OrdersSummaryList, state: 'error', error: error?.response, status: error?.response?.data?.status });
      });
  };

  // action for order delete
  const setOrderDelete = (prefix: string, bookingId: string, orderId: string) => {
    setOrderDeleteData({ ...contextInitialState.orderDeleteData, state: 'loading' });
    OrderDelete<DeleteOrder>(prefix, bookingId, orderId)
      .then((data) => {
        setOrderDeleteData({
          data: data.data,
          state: 'ready',
          status: data.status,
        });
      })
      .catch((error) => {
        setOrderDeleteData({ data: error?.response?.data, error: error?.response, state: 'error', status: error?.response?.data?.status });
      });
  };

  // action for order creation
  const setOrderCreate = (prefix: string, bookingId: string, body: OrderCreateBody) => {
    setOrderCreateData({ ...contextInitialState.orderCreateData, state: 'loading' });
    OrderCreate<PostCreateOrder>(prefix, bookingId, body)
      .then((data) => {
        setOrderCreateData({
          data: data.data,
          state: 'ready',
          status: data.status,
        });
      })
      .catch((error) => {
        setOrderCreateData({ data: error?.response?.data, error: error?.response, state: 'error', status: error?.response?.data?.status });
      });
  };

  // action delete for all order
  const setOrderDeleteAll = (prefix: string, bookingId: string) => {
    setOrderDeleteAllData({ ...contextInitialState.orderDeleteData, state: 'loading' });
    OrderDeleteAll<DeleteAllOrder>(prefix, bookingId)
      .then((data) => {
        setOrderDeleteAllData({
          data: data.data,
          state: 'ready',
          status: data.status,
        });
      })
      .catch((error) => {
        setOrderDeleteAllData({ data: error?.response?.data, error: error?.response, state: 'error', status: error?.response?.data?.status });
      });
  };

  // action get for order count
  const getOrderCount = (prefix: string, bookingId: string, callBackFunc?: (orderCountData: GetOrderCount) => void) => {
    setOrderCountData({ ...contextInitialState.orderCountData, state: 'loading' });
    OrderCountGet<GetOrderCount>(prefix, bookingId)
      .then((data) => {
        setOrderCountData({
          data: data.data,
          state: 'ready',
          status: data.status,
        });
        if (callBackFunc) {
          callBackFunc(data);
        }
      })
      .catch((error) => {
        setOrderCountData({ data: error.response.data, error: error.response, state: 'error', status: error.response.data.status });
      });
  };

  const ordersStore = {
    OrdersSummaryList,
    getOrdersSummaryList,
    setOrdersSummaryList,

    orderDeleteData,
    setOrderDelete,
    setOrderDeleteData,

    orderCreateData,
    setOrderCreate,
    setOrderCreateData,

    selectedDate,
    setSelectedDate,

    orderDeleteAllData,
    setOrderDeleteAll,
    setOrderDeleteAllData,

    orderCountData,
    getOrderCount,
    setOrderCountData,
  };

  useEffect(() => {
    return () => {
      /*
       NOTE: cancel all subscriptions and asynchronous tasks to cleanup function and fix Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
      */
      setOrderCreateData(contextInitialState.orderCreateData);
      setOrdersSummaryList(contextInitialState.OrdersSummaryList);
      setOrderDeleteData(contextInitialState.orderDeleteData);
      setOrderCountData(contextInitialState.orderCountData);
    };
  }, []);

  return <OrdersContext.Provider value={ordersStore}>{children}</OrdersContext.Provider>;
};

export { OrdersContextProvider };
