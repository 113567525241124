import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Typography, TextField, Box, Checkbox, FormControlLabel } from '@mui/material';
import { ImageUpload } from '@/components/ui/imageUpload/index';
import { LIMIT_TH_AND_EN_CHAR } from '@/app.constants/index';
import { BasicDatePicker, BasicTimePicker } from '@/src/components';
import moment from 'moment';
import { createNotificationSchema } from '@/src/types/schema/index';
import BrokenImageArea from '../../brokenImageArea/index';
import { NotificationStatus } from '@/src/types/schema/enum/notification.enum';

type Props = {
  onPayloadChange?: Dispatch<SetStateAction<any>>;
  onValidateChange: Dispatch<SetStateAction<boolean>>;
  onImageChange: Dispatch<SetStateAction<string>>;
  validate?: boolean;
};

type CurrentImageDataType = {
  preUploadFile: any;
  imgErrorText: string;
  previousImage: any;
  isUpdateImage: boolean;
  imageBase64: string;
};

export default function ModalAddNotification({ onValidateChange, validate, onPayloadChange, onImageChange }: Props) {
  const [editingNotificationTitle, setEditingNotificationTitle] = useState<string>('');
  const [editingNotificationDescription, setEditingNotificationDescription] = useState<string>('');

  const [openingSchedule, setOpeningSchedule] = useState<boolean>(false);
  const [selectedSchedule, setSelectedSchedule] = useState<Date | null>(null);

  const [timeError, setTimeError] = useState<boolean>(false);
  const [dateError, setDateError] = useState<boolean>(false);
  const [titleErrorText, setTitleErrorText] = useState<string>('');
  const [descriptionErrorText, setDescriptionErrorText] = useState<string>('');
  const [descriptionLabel, setDescriptionLabel] = useState<string>('Enter message');
  const allowChar = LIMIT_TH_AND_EN_CHAR;
  const countTitleText = useRef(0);
  const countDescriptionText = useRef(0);

  const initImageData: CurrentImageDataType = {
    preUploadFile: [],
    imgErrorText: '',
    previousImage: '',
    isUpdateImage: false,
    imageBase64: '',
  };
  const [currentImageData, setCurrentImageData] = useState<CurrentImageDataType>(initImageData);
  const [imageErrorText, setImageErrorText] = useState<string>('');

  const toggleOpeningSchedule = () => {
    setOpeningSchedule(!openingSchedule);

    const now = moment();

    if (now.minutes() >= 30) {
      now.add(1, 'hour').minutes(0).seconds(0).milliseconds(0);
      setSelectedSchedule(now.toDate());
    } else if (now.minutes() >= 0) {
      now.minutes(30).seconds(0).milliseconds(0);
      setSelectedSchedule(now.toDate());
    }
  };

  const handleDateChange = (id: any) => {
    setSelectedSchedule(moment(id).toDate());
    const updatedTime = moment(`${id}${moment(selectedSchedule).format('THH:mm:00.000Z')}`).toDate();
    setSelectedSchedule(updatedTime);
  };

  const handleTimeChange = (id: any) => {
    const updatedTime = moment(`${moment(selectedSchedule).format('YYYY-MM-DD')}${id}`).toDate();
    setSelectedSchedule(updatedTime);
  };

  const handleTitleChange = (event: any) => {
    const value = event.target.value;
    const MAX_CHAR = 240;
    countTitleText.current = value.trim().length;
    setEditingNotificationTitle(value);
    if (value.length === 0) {
      setTitleErrorText('Title is required.');
      onValidateChange(false);
    } else if (value.trim().length > MAX_CHAR) {
      setTitleErrorText(`Max ${MAX_CHAR} characters.`);
      onValidateChange(false);
    } else if (!value.trim().match(allowChar)) {
      setTitleErrorText('Only Thai or English are supported.');
      onValidateChange(false);
    } else {
      if (descriptionErrorText === '' && countDescriptionText.current !== 0 && !timeError && !dateError && !currentImageData.imgErrorText) {
        onValidateChange(true);
      }
      setTitleErrorText('');
    }
  };

  const handleDescriptionChange = (event: any) => {
    const value = event.target.value;
    const MAX_CHAR = 512;
    countDescriptionText.current = value.trim().length;
    const countInput = value.trim().replace(/\n/g, '').length;
    const match = value.match(/\r\n|\n\r|\n|\r/g);
    const countNewLine = match ? match.length : 0;
    if (countInput <= MAX_CHAR + 1) {
      setEditingNotificationDescription(value);
    } else {
      setEditingNotificationDescription(value.trim().slice(0, 513 + countNewLine));
    }
    if (countInput === 0) {
      setDescriptionErrorText('Description is required.');
      onValidateChange(false);
    } else if (countInput > MAX_CHAR) {
      setDescriptionErrorText(`Max ${MAX_CHAR} characters.`);
      onValidateChange(false);
    } else if (!value.trim().match(allowChar)) {
      setDescriptionErrorText('Only Thai or English are supported.');
      onValidateChange(false);
    } else {
      if (titleErrorText === '' && countTitleText.current !== 0 && !timeError && !dateError && !currentImageData.imgErrorText) {
        onValidateChange(true);
      }
      setDescriptionErrorText('');
    }
  };

  const handleOutOfFocusTitle = () => {
    if (countTitleText.current === 0) {
      setEditingNotificationTitle('');
      setTitleErrorText('');
    }
  };

  const handleOutOfFocusDescription = () => {
    if (countDescriptionText.current === 0) {
      setEditingNotificationDescription('');
      setDescriptionLabel('Enter message');
      setDescriptionErrorText('');
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!onPayloadChange) return;
      if (!validate) {
        onPayloadChange(null);
      }
      const payload = {
        title: editingNotificationTitle?.trim(),
        description: editingNotificationDescription?.trim(),
        imageUrl: currentImageData.isUpdateImage ? currentImageData.imageBase64 : currentImageData.previousImage,
        scheduledDate: `${openingSchedule ? `${moment(selectedSchedule).toISOString()}` : ''}`,
        status: openingSchedule ? NotificationStatus.QUEUED : NotificationStatus.SENT,
        deleted: false,
      } as createNotificationSchema;
      onPayloadChange(payload);
      onImageChange(currentImageData.preUploadFile[0]?.dataURL);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [validate, editingNotificationDescription, editingNotificationTitle, currentImageData, selectedSchedule]);

  useEffect(() => {
    if (currentImageData.imgErrorText) {
      onValidateChange(false);
      if (currentImageData.imgErrorText === 'Only .jpg and .png are supported.') {
        setImageErrorText('Only JPEG, JPG, and PNG are supported.');
      } else if (currentImageData.imgErrorText === 'Max 2 MB.') {
        setImageErrorText(`The file is too large for the destination \n file system. Max 2 MB.`);
      }
    } else {
      setImageErrorText('');
      if (
        titleErrorText === '' &&
        descriptionErrorText === '' &&
        countTitleText.current !== 0 &&
        countDescriptionText.current !== 0 &&
        !timeError &&
        !dateError
      ) {
        onValidateChange(true);
      } else if (timeError || dateError) {
        onValidateChange(false);
      }
    }
  }, [currentImageData, timeError, dateError]);

  useEffect(() => {
    if (!openingSchedule) {
      setSelectedSchedule(null);
      setTimeError(false);
      setDateError(false);
    }
  }, [openingSchedule]);

  return (
    <>
      <Box>
        <Box className="flex flex-col gap-4">
          <TextField
            error={!!titleErrorText}
            id="notification-title"
            label="Title"
            value={editingNotificationTitle}
            helperText={titleErrorText}
            size="small"
            variant="outlined"
            onChange={(e) => handleTitleChange(e)}
            onBlur={() => handleOutOfFocusTitle()}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            inputProps={{ maxLength: 241 }}
          />
          <TextField
            error={!!descriptionErrorText}
            id="notification-description"
            label={descriptionLabel}
            value={editingNotificationDescription}
            helperText={descriptionErrorText}
            multiline
            size="small"
            rows={8}
            variant="outlined"
            onFocus={() => setDescriptionLabel('Description')}
            onChange={(e) => handleDescriptionChange(e)}
            onBlur={() => handleOutOfFocusDescription()}
          />
          {imageErrorText ? (
            <BrokenImageArea id={''} currentImageData={currentImageData} setCurrentImageData={setCurrentImageData} imageErrorText={imageErrorText} />
          ) : (
            <Box className="flex flex-row justify-between" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
              <Box className="flex items-center">
                <ImageUpload
                  id={`notification`}
                  currentImageData={currentImageData}
                  setCurrentImageData={setCurrentImageData}
                  border
                  layout={{ width: 248, height: 140 }}
                />
              </Box>
              {!currentImageData.imageBase64 && (
                <Box id={`notification-add-image-error-text`} className="flex items-end">
                  <Typography variant="body2" sx={(theme) => ({ textAlign: 'right', color: theme.palette.text.disabled })}>
                    Only JPEG, JPG, and PNG are supported, <br />
                    and the file size must not be larger than 2MB.
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box className="flex flex-row justify-between items-center">
          <FormControlLabel
            id="check-box-notification-schedule"
            label="Schedule"
            control={<Checkbox onClick={toggleOpeningSchedule} />}
            sx={(theme) => ({ width: 'fit-content' })}
          />
          <Typography sx={(theme) => ({ textAlign: 'right', color: theme.palette.primary.main, fontSize: '10px' })}>
            *The notification will be sent immediately, or select schedule <br />
            if you would like to send out your notifications on a specific date/time.
          </Typography>
        </Box>
        {openingSchedule && (
          <Box sx={(theme) => ({ display: 'flex', flexDirection: 'row', gap: theme.spacing(1), marginBottom: theme.spacing(2) })}>
            <BasicDatePicker setBookingId={handleDateChange} date={selectedSchedule} setIsError={setDateError} disablePast />
            <BasicTimePicker setBookingId={handleTimeChange} time={selectedSchedule} setIsError={setTimeError} disablePast />
          </Box>
        )}
      </Box>
    </>
  );
}
