import React, { useEffect, useState } from 'react';
import { SelectChangeEvent, Select, MenuItem } from '@mui/material';
import moment from 'moment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// eslint-disable-next-line no-unused-vars
import { useOrdersHistoryContext } from '@/services/store/ordersHistory.store';
import { useOrderHistoryContextV2 } from '@/store/ordersHistory.v2.store';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';

export function BasicYearHistoryPicker(props: any) {
  const { Year, setYear } = props;
  const { fetchOrdersHistoryList } = useOrderHistoryContextV2();

  const handleChangeYear = (event: SelectChangeEvent) => {
    setYear(event.target.value as string);
  };
  const now = moment();
  const [arrayYear, setArrayYear] = useState([now.year().toString()]);

  useEffect(() => {
    if (fetchOrdersHistoryList.current.state === STATE_ENUM.READY) {
      setArrayYear(fetchOrdersHistoryList.current.data?.arrayYear);
    }
  }, [fetchOrdersHistoryList.current]);

  return (
    <Select
      IconComponent={KeyboardArrowDownIcon}
      labelId="demo-simple-select-label"
      id="select-year"
      value={Year || now.year().toString()}
      defaultValue={now.year().toString()}
      onChange={handleChangeYear}
      inputProps={{
        id: 'select-year-input',
      }}
    >
      {arrayYear.map((value, index) => (
        <MenuItem value={value} key={value} id={'year-' + index}>
          {value}
        </MenuItem>
      ))}
    </Select>
  );
}
