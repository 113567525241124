import React from 'react';
import { Typography, List, ListItem, ListItemText } from '@mui/material';

// use it on Modal description when cant delete.
export function DescriptionForCantDelete(props) {
  const { modalType, dateArray } = props;
  return (
    <>
      <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{ color: '#212121' }}>
        There are multiple orders of the {modalType} items you are trying to remove as following.
      </Typography>
      <List style={{ color: '#F44336' }}>
        {dateArray.map((value) => (
          <ListItem key={value.date}>
            <ListItemText primary={`${value.count} order(s) on ${value.date}`} />
          </ListItem>
        ))}
      </List>
      <Typography id="transition-modal-description" style={{ color: '#212121' }}>
        You cannot delete the {modalType} items unless you delete those orders or change the restaurant scheduling.
      </Typography>
    </>
  );
}
