import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { Modal, Fade, Box, Typography, Button, Divider } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  right: '50%',
  transform: 'translate(50%,-50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
};

interface ModalUIProps {
  id: string;
  type: string;
  header?: string;
  width?: number;
  openModal: boolean;
  disabledBtn?: boolean;
  onClose: () => void;
  onClickConfirm?: () => void;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  child?: any;
}

export default function ModalUI(props: ModalUIProps) {
  const { id, openModal, setOpenModal, type, child, header, onClose, onClickConfirm, disabledBtn, width } = props;

  const [buttonText, buttonCancelText, buttonColor]: [string, string, any] = useMemo<[string, string, any]>(() => {
    if (type === 'DELETE') {
      return ['DELETE', 'CANCEL', 'error'];
    } else if (type === 'ADD') {
      return ['ADD', 'CANCEL', 'primary'];
    } else if (type === 'UPDATE') {
      return ['SAVE', 'CANCEL', 'primary'];
    } else if (type === 'CANTDELETE') {
      return ['CLOSE', '', 'primary'];
    } else {
      return ['', '', 'primary'];
    }
  }, [type]);

  const handleClose = () => {
    setOpenModal(false);
    onClose();
  };

  return (
    <Modal
      id={`modal-${id}`}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={openModal}>
        <Box sx={style} width={width || 690}>
          {header && (
            <>
              <Typography variant="h4" sx={(theme) => ({ padding: `${theme.spacing(2)} ${theme.spacing(4)}` })}>
                {header}
              </Typography>
              <Divider orientation="horizontal" />
            </>
          )}
          <Box
            sx={(theme) => ({
              padding: theme.spacing(4),
            })}
          >
            {child}
            {type === 'DELETE' ? (
              <Button id={`btn-modal-${id}`} color={buttonColor} variant="contained" sx={{ mt: 2 }} onClick={onClickConfirm}>
                {buttonText}
              </Button>
            ) : type === 'ADD' ? (
              <Button id={`btn-modal-${id}`} color={buttonColor} variant="contained" sx={{ mt: 2 }} onClick={onClickConfirm} disabled={!disabledBtn}>
                {buttonText}
              </Button>
            ) : type === 'UPDATE' ? (
              <Button id={`btn-modal-${id}`} color={buttonColor} variant="contained" sx={{ mt: 2 }} onClick={onClickConfirm} disabled={!disabledBtn}>
                {buttonText}
              </Button>
            ) : (
              <Button color={buttonColor} variant="contained" sx={{ mt: 2 }} onClick={onClickConfirm}>
                {buttonText}
              </Button>
            )}
            {type !== 'CANTDELETE' ? (
              <Button id={`btn-modal-${id}-cancel`} color={buttonColor} variant="outlined" sx={{ mt: 2, ml: 2 }} onClick={handleClose}>
                {buttonCancelText}
              </Button>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
