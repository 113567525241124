import { responseStatusSchema } from '@/types/schema/responseStatus';
import { createContext } from 'react';

const SnackbarContext = createContext({
  responseStatus: {
    status: '',
    message: '',
  } as responseStatusSchema,
  setResponseStatus: (val: responseStatusSchema) => {},
});

export { SnackbarContext };
