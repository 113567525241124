import React, { Dispatch, SetStateAction, useState } from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { DateValidationError, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { getDisplayConfig } from '@/src/utils';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
interface BasicDatePickerProps {
  setBookingId: Dispatch<string>;
  date: Date | null;
  disablePast?: boolean;
  setIsError?: Dispatch<SetStateAction<boolean>>;
}

export function BasicDatePicker(props: BasicDatePickerProps) {
  const datePickerFormat = getDisplayConfig().datePickerFormat;
  const { setBookingId, date, disablePast, setIsError } = props;
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(moment(date));
  const [dateError, setDateError] = useState(false);

  const handleChange = (updatedDate: moment.Moment | null) => {
    if (!moment(updatedDate).isSame(moment(date), 'day')) {
      // NOTE: this component relies on both internal and external state, in case it's navigated to from another page.
      // TODO: Refactor to use a single state.
      setSelectedDate(updatedDate);
      setBookingId(moment(updatedDate).format('YYYY-MM-DD'));
    }
  };

  const handleError = (e: DateValidationError) => {
    if (!setIsError) return;
    setIsError(e !== null);
    setDateError(e !== null);
  };

  const disableDates = (date: moment.Moment) => {
    return moment(date).isoWeekday() === 6 || moment(date).isoWeekday() === 7;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopDatePicker
        disablePast={disablePast}
        format={datePickerFormat}
        value={selectedDate}
        onError={(e) => handleError(e)}
        onChange={handleChange}
        slots={{
          openPickerIcon: (props) => <CalendarMonthIcon {...props} sx={{ color: '#4CAF50' }} id="icon-picker" />,
          nextIconButton: (props) => <KeyboardArrowRightIcon {...props} id="next-month" />,
          previousIconButton: (props) => <KeyboardArrowLeftIcon {...props} id="previous-month" />,
        }}
        slotProps={{
          textField: {
            id: 'date-picker-input',
            color: 'primary',
            size: 'small',
            helperText: `${dateError ? 'Invalid date input' : ''}`,
            sx: { width: '180px', height: '40px' },
          },
        }}
        shouldDisableDate={disableDates}
      />
    </LocalizationProvider>
  );
}
