/** declare only http service related requests */

import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import {
  GetRestaurantList,
  PutRestaurant,
  UpdateRestaurant,
  DeleteRestaurant,
  RestaurantDetail,
  GetRestaurantDropdownList,
  QueryParamsGetList,
  QueryRestaurantDropdownList,
} from '@/types/crud';
import { RestaurantBody, restaurantOrderByType } from '@/types/index';
import { apiMethodV1 } from '@/app.constants/apiCallMethods/index';
import { paramsQueryString } from '@/src/utils';

/**
 * @private This api is protected and can only be accessed as role=ADMIN
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
const RestaurantListGet = (prefix: string, params: QueryParamsGetList<restaurantOrderByType>, hardRejection = true): Promise<GetRestaurantList> => {
  const instance = new AxiosService({ method: apiMethodV1.get, timeout: 12000 }, true, hardRejection);
  // token not yet set on backend
  const queryUri = paramsQueryString(prefix, params);

  const config = {
    ...instance.config,
  };

  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<GetRestaurantList>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetRestaurantList>;
};

/**
 * @private This api is protected and can only be accessed as role=ADMIN
 * @param prefix only partial url, base url already prefixed
 * @param restaurantId restaurant Id
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 * //// TODO: this API still being used by Mobile app, make sure that Web-admin do not use and remove this service
 */
const RestaurantDetailGet = (prefix: string, restaurantId: string, hardRejection = true): Promise<RestaurantDetail> => {
  const instance = new AxiosService({ method: apiMethodV1.get, timeout: 12000 }, true, hardRejection);
  // token not yet set on backend

  const config = {
    ...instance.config,
  };

  return instance.client
    .get(prefix + restaurantId, config)
    .then((d: AxiosResponse<RestaurantDetail>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<RestaurantDetail>;
};

/**
 * @private This api is protected and can only be accessed as role=ADMIN
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
const RestaurantCreate = (prefix: string, body: RestaurantBody, hardRejection = true): Promise<PutRestaurant> => {
  const instance = new AxiosService({ method: apiMethodV1.post, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .post(prefix, JSON.stringify(body), config)
    .then((d: AxiosResponse<PutRestaurant>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<PutRestaurant>;
};

const RestaurantUpdate = (prefix: string, restaurantId: string, body: RestaurantBody, hardRejection = true): Promise<UpdateRestaurant> => {
  const instance = new AxiosService({ method: apiMethodV1.put, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .put(prefix + restaurantId, JSON.stringify(body), config)
    .then((d: AxiosResponse<UpdateRestaurant>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<UpdateRestaurant>;
};

const RestaurantDelete = (prefix: string, restaurantId: string, hardRejection = true): Promise<DeleteRestaurant> => {
  const instance = new AxiosService({ method: apiMethodV1.delete, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .delete(prefix + restaurantId, config)
    .then((d: AxiosResponse<DeleteRestaurant>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<DeleteRestaurant>;
};

const RestaurantDropdownListGet = (
  prefix: string,
  restaurantId: string,
  params: QueryRestaurantDropdownList,
  hardRejection = true
): Promise<GetRestaurantDropdownList> => {
  const instance = new AxiosService({ method: apiMethodV1.post, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  const queryUri = paramsQueryString(`${prefix}${restaurantId}`, params);

  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<GetRestaurantDropdownList>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetRestaurantDropdownList>;
};

export { RestaurantListGet, RestaurantCreate, RestaurantUpdate, RestaurantDelete, RestaurantDetailGet, RestaurantDropdownListGet };
