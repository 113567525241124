import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import App from './App';
// import { onMessage, getMessaging } from 'firebase/messaging';
// import * as firebase from '@/services/firebase.service';
import { AppContextProvider } from '@/services/index';

ReactDOM.render(
  <React.StrictMode>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// logging our messaging notification
reportWebVitals();

// const messaging = getMessaging(firebase.app);
// onMessage(messaging, (payload) => {
//   console.log('firebase/Message received. ', payload);
// });
