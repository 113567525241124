export const apiPathV1 = {
  // Auth api paths
  authLogin: '/v1/auth/login',
  authCheck: '/v1/auth/check',
  authLogout: '/v1/auth/logout',

  // restaurant api paths
  restaurantList: '/v1/restaurants',
  restaurantDetail: '/v1/restaurants/',
  restaurantCreate: '/v1/restaurants',
  restaurantUpdate: '/v1/restaurants/',
  restaurantDelete: '/v1/restaurants/',
  restaurantDropdownList: '/v1/restaurants/select/',

  // addon api paths
  addonList: '/v1/addons/',
  addonCreate: '/v1/addons/',
  addonUpdate: '/v1/addons/',
  addonDelete: '/v1/addons/',
  overBudgetOrdersByAddOn: 'v1/addons/orders/over-budget/',

  // menu api paths
  menuList: '/v1/menu-items/',
  menuListWithAddon: '/v1/menu-items/addons/',
  menuListWithAddonNoOptionAddon: '/v1/menu-items/addons/overview/',
  menuCreate: '/v1/menu-items/',
  menuUpdate: '/v1/menu-items/',
  menuDelete: '/v1/menu-items/',
  overBudgetOrdersByMenu: 'v1/menu-items/orders/over-budget/',

  // group api paths
  groupList: '/v1/groups/',
  groupCreate: '/v1/groups/',
  groupUpdate: '/v1/groups/',
  groupDelete: '/v1/groups/',
  groupOrderCount: '/v1/groups/orders/count/',

  // optionAddon api paths
  optionList: '/v1/option-addons/',
  optionCreate: '/v1/option-addons/',
  optionUpdate: '/v1/option-addons/',
  optionDelete: '/v1/option-addons/',
  overBudgetOrdersByOption: 'v1/option-addons/orders/over-budget/',

  // restaurant-bookings api paths
  restaurantBookingList: '/v1/restaurant-bookings',
  restaurantBookingCreate: '/v1/restaurant-bookings',
  restaurantBookingCreateList: '/v1/restaurant-bookings/list',
  restaurantBookingUpdate: '/v1/restaurant-bookings/',
  restaurantBookingStatusUpdate: '/v1/restaurant-bookings/status/',

  // orders api paths
  ordersList: '/v1/orders/',
  orderCreate: '/v1/orders/',
  orderDelete: '/v1/orders/',
  orderAllDelete: '/v1/orders/',
  orderCount: '/v1/orders/amount/',

  // orders history api paths
  ordersHistoryList: 'v1/order-history/',

  // adminNotification api paths
  adminNotification: 'v1/admin-notification/',
};
