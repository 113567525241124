import { Outlet } from 'react-router-dom';
import { Header } from '../header';

const Layout = (props: any) => {
  return (
    <>
      <Header {...props}></Header>
      <Outlet {...props} />
    </>
  );
};

export { Layout };
