import React, { FC } from 'react';
import { Button, ButtonProps } from '@mui/material';

interface inheritProps extends ButtonProps {
  text: string;
}

export const ActionButton: FC<inheritProps> = ({ onClick, variant, children, size, disabled, text, startIcon, className, id }) => {
  return (
    <Button
      className={className ?? 'export-omit '}
      sx={{ p: '14.4px' }}
      disabled={disabled}
      size={size}
      disableElevation
      variant={variant}
      onClick={onClick}
      startIcon={startIcon}
      id={id}
    >
      {text}
      {children}
    </Button>
  );
};
