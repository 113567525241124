import React, { useEffect, useState, useRef, Dispatch, SetStateAction } from 'react';
import { Typography, TextField, Box, Checkbox, FormControlLabel } from '@mui/material';
import { notificationsSchema } from '@/src/types/schema/notifications';
import { RemoveImageArea } from '@/components/ui/removeImageArea';
import { ImageUpload } from '@/components/ui/imageUpload/index';
import { LIMIT_TH_AND_EN_CHAR } from '@/src/app.constants';
import moment from 'moment';
import { NotificationStatus } from '@/src/types/schema/enum/notification.enum';
import { BasicDatePicker, BasicTimePicker } from '@/src/components';
import BrokenImageArea from '../../brokenImageArea';
import { useNotificationsContextV2 } from '@/store/notifications.v2.store';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';

type Props = {
  notification: notificationsSchema;
  onPayloadChange?: Dispatch<SetStateAction<any>>;
  onValidateChange: Dispatch<SetStateAction<boolean>>;
  onImageChange: Dispatch<SetStateAction<string>>;
  validate?: boolean;
};

type CurrentImageDataType = {
  preUploadFile: any;
  imgErrorText: string;
  previousImage: any;
  isUpdateImage: boolean;
  imageBase64: string;
};

export default function ModalEditNotification({ notification, onValidateChange, validate, onPayloadChange, onImageChange }: Props) {
  const { notificationResponseList } = useNotificationsContextV2();
  const [editingNotificationTitle, setEditingNotificationTitle] = useState<string>(notification.title);
  const [editingNotificationDescription, setEditingNotificationDescription] = useState<string>(notification.description);

  const [openingSchedule, setOpeningSchedule] = useState<boolean>(notification.scheduledDate !== '');
  const [selectedSchedule, setSelectedSchedule] = useState<Date | null>(moment(notification.scheduledDate).toDate());

  const [timeError, setTimeError] = useState<boolean>(false);
  const [dateError, setDateError] = useState<boolean>(false);
  const [titleErrorText, setTitleErrorText] = useState<string>('');
  const [descriptionErrorText, setDescriptionErrorText] = useState<string>('');
  const [descriptionLabel, setDescriptionLabel] = useState<string>('Enter message');
  const allowChar = LIMIT_TH_AND_EN_CHAR;
  const countTitleText = useRef(notification.title.trim().length);
  const countDescriptionText = useRef(notification.description.trim().length);

  const initImageData: CurrentImageDataType = {
    preUploadFile: [],
    imgErrorText: '',
    previousImage: '',
    isUpdateImage: false,
    imageBase64: '',
  };
  const [currentImageData, setCurrentImageData] = useState<CurrentImageDataType>(initImageData);
  const [previousImage, setPreviousImage] = useState('');
  const [imageErrorText, setImageErrorText] = useState<string>('');

  const toggleOpeningSchedule = () => {
    setOpeningSchedule(!openingSchedule);

    if (notification.scheduledDate !== '') {
      setSelectedSchedule(new Date(notification.scheduledDate));
      return;
    }

    const now = moment();

    if (now.minutes() >= 30) {
      now.add(1, 'hour').minutes(0).seconds(0).milliseconds(0);
      setSelectedSchedule(now.toDate());
    } else if (now.minutes() >= 0) {
      now.minutes(30).seconds(0).milliseconds(0);
      setSelectedSchedule(now.toDate());
    }
  };

  const handleDateChange = (id: any) => {
    const updatedTime = moment(`${id}${moment(selectedSchedule).format('THH:mm:00.000Z')}`).toDate();
    setSelectedSchedule(updatedTime);
  };

  const handleTimeChange = (id: any) => {
    const updatedTime = moment(`${moment(selectedSchedule).format('YYYY-MM-DD')}${id}`).toDate();
    setSelectedSchedule(updatedTime);
  };

  const handleTitleChange = (event: any) => {
    const value = event.target.value;
    const MAX_CHAR = 240;
    countTitleText.current = value.trim().length;
    setEditingNotificationTitle(value);
    if (value.length === 0) {
      setTitleErrorText('Title is required.');
      onValidateChange(false);
    } else if (value.trim().length > MAX_CHAR) {
      setTitleErrorText(`Max ${MAX_CHAR} characters.`);
      onValidateChange(false);
    } else if (!value.trim().match(allowChar)) {
      setTitleErrorText('Only Thai or English are supported.');
      onValidateChange(false);
    } else {
      if (descriptionErrorText === '' && countDescriptionText.current !== 0 && !timeError && !dateError && !currentImageData.imgErrorText) {
        onValidateChange(true);
      }
      setTitleErrorText('');
    }
  };

  const handleDescriptionChange = (event: any) => {
    const value = event.target.value;
    const MAX_CHAR = 512;
    countDescriptionText.current = value.trim().length;
    const countInput = value.trim().replace(/\n/g, '').length;
    const match = value.match(/\r\n|\n\r|\n|\r/g);
    const countNewLine = match ? match.length : 0;
    if (countInput <= MAX_CHAR + 1) {
      setEditingNotificationDescription(value);
    } else {
      setEditingNotificationDescription(value.trim().slice(0, 513 + countNewLine));
    }
    if (countInput === 0) {
      setDescriptionErrorText('Description is required.');
      onValidateChange(false);
    } else if (countInput > MAX_CHAR) {
      setDescriptionErrorText(`Max ${MAX_CHAR} characters.`);
      onValidateChange(false);
    } else if (!value.trim().match(allowChar)) {
      setDescriptionErrorText('Only Thai or English are supported.');
      onValidateChange(false);
    } else {
      if (titleErrorText === '' && countTitleText.current !== 0 && !timeError && !dateError && !currentImageData.imgErrorText) {
        onValidateChange(true);
      }
      setDescriptionErrorText('');
    }
  };

  const handleOutOfFocusTitle = () => {
    if (countTitleText.current === 0) {
      setEditingNotificationTitle('');
      setTitleErrorText('');
    }
  };

  const handleOutOfFocusDescription = () => {
    if (countDescriptionText.current === 0) {
      setEditingNotificationDescription('');
      setDescriptionLabel('Enter message');
      setDescriptionErrorText('');
    }
  };

  useEffect(() => {
    if (
      editingNotificationTitle.trim() !== notification.title ||
      editingNotificationDescription.trim() !== notification.description ||
      moment(selectedSchedule).toISOString() !== notification.scheduledDate ||
      currentImageData.isUpdateImage
    ) {
      onValidateChange(!!validate);
    } else onValidateChange(false);
    const timer = setTimeout(() => {
      if (!onPayloadChange) return;
      if (!validate) {
        onPayloadChange(null);
      }

      const payload = {
        id: notification.id,
        title: editingNotificationTitle?.trim(),
        description: editingNotificationDescription?.trim(),
        imageUrl: currentImageData.isUpdateImage ? currentImageData.imageBase64 : previousImage,
        scheduledDate: `${openingSchedule ? `${moment(selectedSchedule).toISOString()}` : ''}`,
        status: openingSchedule ? NotificationStatus.QUEUED : NotificationStatus.SENT,
      } as notificationsSchema;
      onPayloadChange(payload);
      onImageChange(currentImageData.isUpdateImage ? currentImageData.preUploadFile[0]?.dataURL : currentImageData.previousImage);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [validate, editingNotificationDescription, editingNotificationTitle, currentImageData, selectedSchedule]);

  useEffect(() => {
    if (currentImageData.imgErrorText) {
      onValidateChange(false);
      if (currentImageData.imgErrorText === 'Only .jpg and .png are supported.') {
        setImageErrorText('Only JPEG, JPG, and PNG are supported.');
      } else if (currentImageData.imgErrorText === 'Max 2 MB.') {
        setImageErrorText(`The file is too large for the destination \n file system. Max 2 MB.`);
      }
    } else {
      if (notificationResponseList.current.state === STATE_ENUM.READY) {
        const previousImageData = notificationResponseList.current.data?.filter((item) => item.id === notification.id)[0].imageUrl as string;
        setPreviousImage(previousImageData);
      } else {
        setPreviousImage(currentImageData.previousImage);
      }
      setImageErrorText('');
      if (
        (currentImageData.isUpdateImage || moment(selectedSchedule).toISOString() !== notification.scheduledDate) &&
        titleErrorText === '' &&
        descriptionErrorText === '' &&
        countTitleText.current !== 0 &&
        countDescriptionText.current !== 0 &&
        !timeError &&
        !dateError
      ) {
        onValidateChange(true);
      } else if (timeError || dateError) {
        onValidateChange(false);
      }
    }
  }, [currentImageData, timeError, dateError, selectedSchedule, openingSchedule]);

  useEffect(() => {
    if (!openingSchedule) {
      setSelectedSchedule(null);
      setTimeError(false);
      setDateError(false);
    }
  }, [openingSchedule]);

  useEffect(() => {
    if (notification.imageUrl) {
      setCurrentImageData((currentImage) => ({ ...currentImage, previousImage: notification.imageUrl }));
    } else {
      setCurrentImageData(initImageData);
    }
  }, [notification.imageUrl]);

  return (
    <Box>
      <Box sx={(theme) => ({ gap: theme.spacing(2), display: 'flex', flexDirection: 'column' })}>
        <TextField
          id="notification-title"
          error={!!titleErrorText}
          label="Title"
          value={editingNotificationTitle}
          helperText={titleErrorText}
          size="small"
          variant="outlined"
          onChange={(e) => handleTitleChange(e)}
          onBlur={() => handleOutOfFocusTitle()}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          inputProps={{ maxLength: 241 }}
        />
        <TextField
          id="notification-description"
          error={!!descriptionErrorText}
          label={descriptionLabel}
          value={editingNotificationDescription}
          helperText={descriptionErrorText}
          multiline
          size="small"
          rows={8}
          variant="outlined"
          onFocus={() => setDescriptionLabel('Description')}
          onChange={(e) => handleDescriptionChange(e)}
          onBlur={() => handleOutOfFocusDescription()}
        />

        {imageErrorText ? (
          <BrokenImageArea id={''} currentImageData={currentImageData} setCurrentImageData={setCurrentImageData} imageErrorText={imageErrorText} />
        ) : notification.imageUrl && !currentImageData.isUpdateImage ? (
          <RemoveImageArea
            id={`notification-${notification.id}`}
            imageURL={notification.imageUrl}
            onClick={() => {
              setCurrentImageData({
                ...currentImageData,
                imgErrorText: '',
                previousImage: notification.imageUrl,
                isUpdateImage: true,
              });
            }}
            border
            layout={{
              width: 248,
              height: 140,
            }}
          />
        ) : (
          <Box className="flex flex-row justify-between">
            <Box className="flex items-center">
              <ImageUpload
                id={`notification-${notification.id}`}
                currentImageData={currentImageData}
                setCurrentImageData={setCurrentImageData}
                border
                layout={{ width: 248, height: 140 }}
              />
            </Box>
            {!currentImageData.imageBase64 && (
              <Box id={`notification-add-image-error-text`} className="flex items-end">
                <Typography variant="body2" sx={(theme) => ({ textAlign: 'right', color: theme.palette.text.disabled })}>
                  Only JPEG, JPG, and PNG are supported, <br />
                  and the file size must not be larger than 2MB.
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Box className="flex flex-row justify-between items-center">
        <FormControlLabel
          id="check-box-notification-schedule"
          label="Schedule"
          control={<Checkbox defaultChecked={notification.scheduledDate !== ''} onClick={toggleOpeningSchedule} />}
          sx={(theme) => ({ width: 'fit-content' })}
        />
        <Typography sx={(theme) => ({ textAlign: 'right', color: theme.palette.primary.main, fontSize: '10px' })}>
          *The notification will be sent immediately, or select schedule <br />
          if you would like to send out your notifications on a specific date/time.
        </Typography>
      </Box>
      {openingSchedule && (
        <Box sx={(theme) => ({ display: 'flex', flexDirection: 'row', gap: theme.spacing(1), marginBottom: theme.spacing(2) })}>
          <BasicDatePicker setBookingId={handleDateChange} date={selectedSchedule} setIsError={setDateError} disablePast />
          <BasicTimePicker setBookingId={handleTimeChange} time={selectedSchedule} setIsError={setTimeError} disablePast />
        </Box>
      )}
    </Box>
  );
}
