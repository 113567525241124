import React, { useEffect, useState } from 'react';
import { Box, Paper, Table, TableContainer } from '@mui/material';
import {
  EnhancedTableHead,
  EnhancedTableBodyRestaurantAddOns,
  EnhancedTablePaginationV2,
  LoadingIcon,
  AddRestaurantAddOns,
  PopupSnackbar,
  ConditionalWrapper,
} from '@/components/index';
import { DEFAULT_ROW_PER_PAGE } from '@/app.constants/index';
import { order, addonOrderByType, QueryParamsGetList } from '@/types/index';
import { headCellsRestaurantAddOns } from '@/static/restaurantAddOns';
import { useAddOnContextV2 } from '@/services/index';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { cookieOptions } from '@/src/utils';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import TableBodyNodata from '@/src/components/ui/tableBodyNoData';
import noOne from '@/images/noOne.png';

const defaultParamsGetList: QueryParamsGetList<addonOrderByType> = {
  page: 0,
  itemPerPage: DEFAULT_ROW_PER_PAGE,
  orderBy: 'thName',
  sort: 'asc',
};

export function AddOns(props: any) {
  const { id } = useParams();
  const [isInitialGetData, setIsInitialGetData] = useState(true);
  const [addonPaginationParams, setAddonPaginationParams] = useState(defaultParamsGetList);

  // SORTING / PAGINATION
  const [order, setOrder] = useState<order | null>(addonPaginationParams.sort || 'asc');
  const [orderBy, setOrderBy] = useState<addonOrderByType | null>(addonPaginationParams.orderBy || 'thName');
  const [page, setPage] = useState(addonPaginationParams.page || 0);
  const [rowsPerPage, setRowsPerPage] = useState(addonPaginationParams.itemPerPage || DEFAULT_ROW_PER_PAGE);
  const [open, setOpen] = useState(false);
  const [total, setTotal] = useState(0);
  const [isChange, setIsChange] = useState(false);
  const { restaurantAddonList } = useAddOnContextV2();
  const [cookies, setCookie] = useCookies(['sortingAddOn']);
  const sorting: any = {};

  // NOTE: initial API call
  useEffect(() => {
    if (id) {
      setIsInitialGetData(false);
      if (typeof cookies.sortingAddOn !== 'undefined') {
        addonPaginationParams.orderBy = cookies.sortingAddOn?.orderBy;
        addonPaginationParams.sort = cookies.sortingAddOn?.sort;
      }

      setOrderBy(addonPaginationParams.orderBy || orderBy);
      setOrder(addonPaginationParams.sort || order);
      restaurantAddonList.requestAct(id, addonPaginationParams);
    }
  }, []);

  useEffect(() => {
    if (restaurantAddonList.current.state !== STATE_ENUM.INITIAL && id && !isInitialGetData) {
      if (typeof addonPaginationParams.orderBy !== 'undefined') sorting.orderBy = addonPaginationParams.orderBy;
      if (typeof addonPaginationParams.sort !== 'undefined') sorting.sort = addonPaginationParams.sort;

      setCookie('sortingAddOn' as any, JSON.stringify(sorting), cookieOptions());
      restaurantAddonList.requestAct(id, addonPaginationParams);
    }
  }, [addonPaginationParams]);

  useEffect(() => {
    if (restaurantAddonList.current.state === STATE_ENUM.READY && !isChange) {
      setTotal(restaurantAddonList.current.pagination?.total || 0);
    }
  }, [restaurantAddonList]);

  return (
    <Box>
      <AddRestaurantAddOns
        setOpen={setOpen}
        setOrderBy={setOrderBy}
        setOrder={setOrder}
        setPage={setPage}
        setTotal={setTotal}
        setIsChange={setIsChange}
        total={total}
        pageNumber={page}
        rowsPerPage={rowsPerPage}
        addonPaginationParams={addonPaginationParams}
        setAddonPaginationParams={setAddonPaginationParams}
      />
      {/* TABLE */}
      <ConditionalWrapper condition={restaurantAddonList.current.state !== STATE_ENUM.LOADING} alternativeNode={<LoadingIcon setCenter={false}></LoadingIcon>}>
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
          {restaurantAddonList.current.state === STATE_ENUM.READY ? (
            <Paper elevation={0} sx={{ width: '100%', mb: 2 }}>
              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  size="small"
                  sx={{
                    minWidth: 750,
                  }}
                  id="add-on-table"
                >
                  <EnhancedTableHead
                    respectSortAble
                    headCells={headCellsRestaurantAddOns}
                    order={order}
                    orderBy={orderBy}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                    disabledSorting={restaurantAddonList.current.data?.addons.length === 0}
                    setPaginationParams={setAddonPaginationParams}
                  />
                  {restaurantAddonList.current.state === STATE_ENUM.READY && restaurantAddonList.current.data!.addons.length > 0 ? (
                    <EnhancedTableBodyRestaurantAddOns
                      rows={restaurantAddonList.current.data?.addons}
                      order={order}
                      orderBy={orderBy}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      {...props}
                      setOrderBy={setOrderBy}
                      setOrder={setOrder}
                      setOpen={setOpen}
                      setTotal={setTotal}
                      setIsChange={setIsChange}
                      total={total}
                      setPage={setPage}
                      setPaginationParams={setAddonPaginationParams}
                    />
                  ) : (
                    <TableBodyNodata page={'optionGroup'} src={noOne} title="No results found." />
                  )}
                </Table>
                <EnhancedTablePaginationV2
                  total={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                  setPaginationParams={setAddonPaginationParams}
                  disabledPagination={!restaurantAddonList.current.data?.addons.length}
                />
              </TableContainer>
              <PopupSnackbar open={open} setOpen={setOpen}></PopupSnackbar>
            </Paper>
          ) : (
            <LoadingIcon setCenter={false}></LoadingIcon>
          )}
        </Box>
      </ConditionalWrapper>
    </Box>
  );
}
