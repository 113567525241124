import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { EnhancedTablePaginationV2, LoadingIcon } from '@/src/components';
import { useUserStoreContext } from '@/store/user.v2.store';
import UserTableHeader from './userTableHeader';
import UserTableBody from './userTableBody';
import { TableCell, TableFooter, TableRow } from '@mui/material';

const styles = {
  tableData: {
    '& th': {
      padding: '16px 20px',
      fontWeight: 'bold',
    },
    '& td': {
      fontWeight: 'lighter !important',
    },
  },
};

const UserTableData = () => {
  const { staffs, pagination, loading } = useUserStoreContext();

  if (loading) {
    return <LoadingIcon setCenter={false}></LoadingIcon>;
  }

  return (
    <TableContainer>
      <Table aria-label="users table" sx={styles.tableData}>
        <UserTableHeader />
        <UserTableBody />
        <TableFooter>
          <TableRow sx={{ borderTop: '1px solid #E0E0E0' }}>
            <TableCell colSpan={7} sx={{ padding: 0 }}>
              <EnhancedTablePaginationV2
                disabledPagination={staffs?.length === 0}
                total={pagination.total}
                rowsPerPage={pagination.rowsPerPage}
                page={pagination.page}
                setPage={pagination.onSetPage}
                setRowsPerPage={pagination.onSetRowsPerPage}
                setPaginationParams={() => {}}
                countCurrentRows={0}
                addRowsPerPageOption={[
                  { value: 60, label: '60' },
                  { value: -1, label: 'All' },
                ]}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default UserTableData;
