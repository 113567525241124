/* eslint-disable no-unused-vars */
import React from 'react';

import { Box, Tooltip, IconButton, Avatar, Menu, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAppContext } from '@/services/index';

import { AnonymProps } from '@/types/index';
import { BrowserHistory } from 'history';
import { removeCookies, windowHardRedirectLogout, getCookieByName, fullSessionLogout } from '@/utils/index';
import { AuthLogout } from '@/services/http';
import { apiPathV1 } from '@/app.constants/apiCallPaths/api.path.v1';
import { AxiosError } from 'axios';

const useStyles = makeStyles({
  Avatar_style: {
    fontSize: '14px',
    backgroundColor: '#4CAF50',
    fontWeight: 'normal',
    letterSpacing: '1px',
  },
});

const SETTINGS = ['Logout'];

interface RedirectProps extends AnonymProps {
  history: BrowserHistory;
}

export function LoginMenu(props: RedirectProps) {
  const { history } = props;
  const { currentUser } = useAppContext();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickMenu = (eventName: string) => {
    handleCloseUserMenu();
    switch (eventName) {
      case 'Logout': {
        fullSessionLogout(history);
        break;
      }

      default:
        console.error('[logout]', ' no matching event for logout:', eventName);
        break;
    }
  };

  const classes = useStyles();

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings" id="tooltip-avatar">
        <IconButton onClick={handleOpenUserMenu} className="p-0" id="avatar">
          <Avatar alt="Avatar" className={classes.Avatar_style} sx={{ width: '33px', height: '33px' }}>
            {currentUser.nameAbbr}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '35px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Typography id="menu-user-name" textAlign="center" className="px-4 py-3 border-solid border-0 border-b border-gray-200">
          {currentUser.fullNameAbbr}
        </Typography>
        {SETTINGS.map((setting) => (
          <MenuItem
            id="menu-logout"
            className="py-3 border-solid border-0 border-b border-gray-200"
            key={setting}
            onClick={() => {
              handleClickMenu(setting);
            }}
          >
            <Typography textAlign="center">{setting}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
