import { AxiosService } from '@/utils/axios';
import { paramsQueryString } from '@/src/utils';
import { apiMethodV1 } from '@/app.constants/apiCallMethods';
import { apiPathV2 } from '@/src/app.constants/apiCallPaths/api.path.v2';
import { DashboardParams } from '@/src/types/crud/dashboard/dashboard.summary';

const apiURL = apiPathV2.dashboard;

export const dashboardDataGet = async (params: DashboardParams, hardRejection = true) => {
  const instance = new AxiosService({ method: apiMethodV1.get }, true, hardRejection);
  const queryUri = paramsQueryString(apiURL, params);
  const config = {
    ...instance.config,
  };

  try {
    const res = await instance.client.get(queryUri, config);
    return res.data;
  } catch (error) {
    return instance.defaultHandleError;
  }
};
