import { createContext, Dispatch, SetStateAction } from 'react';
import {
  GetRestaurantOptionAddonList,
  PostRestaurantOptionAddon,
  PutRestaurantOptionAddon,
  DeleteRestaurantOptionAddon,
  GetOrderOverBudgetByOptionPrice,
  QueryOrderOverBudgetByOptionPrice,
  QueryParamsGetList,
  optionAddonOrderByType,
} from '@/types/index';
import { RestaurantOptionAddonCreateBody } from '@/src/types/schema/body';
import { RestaurantOptionAddonBody } from '../../../types/common.d';

const OptionAddonContext: React.Context<{
  restaurantOptionAddonList: GetRestaurantOptionAddonList;
  setRestaurantOptionAddonList: (prefix: string, id: string, groupId: string, params?: QueryParamsGetList<optionAddonOrderByType>) => void;
  setRestaurantOptionAddonListData: Dispatch<SetStateAction<GetRestaurantOptionAddonList>>;

  restaurantOptionAddonCreateData: PostRestaurantOptionAddon;
  setRestaurantOptionAddonCreate: (prefix: string, restaurantId: string, groupId: string, payload: RestaurantOptionAddonCreateBody) => void;
  setRestaurantOptionAddonCreateData: Dispatch<SetStateAction<PostRestaurantOptionAddon>>;

  restaurantOptionAddonUpdateData: PutRestaurantOptionAddon;
  setRestaurantOptionAddonUpdate: (prefix: string, id: string, groupId: string, optionAddonId, payload: RestaurantOptionAddonBody) => void;
  setRestaurantOptionAddonUpdateData: Dispatch<SetStateAction<PutRestaurantOptionAddon>>;

  getRestaurantOptionAddonIdUpdateList: (optionAddonId: string, thName: string, enName: string, price: number) => void;

  restaurantOptionAddonDeleteData: DeleteRestaurantOptionAddon;
  setRestaurantOptionAddonDelete: (prefix: string, restaurantId: string, groupId: string, optionAddonId: string) => void;
  setRestaurantOptionAddonDeleteData: Dispatch<SetStateAction<DeleteRestaurantOptionAddon>>;

  getRestaurantOptionAddonIdDeleteList: (optionAddonId: string) => void;

  overBudgetOrdersByOption: GetOrderOverBudgetByOptionPrice;
  setOverBudgetOrdersByOption: (
    prefix: string,
    restaurantId: string,
    groupId: string,
    optionAddonId: string,
    params: QueryOrderOverBudgetByOptionPrice
  ) => void;
}> = createContext({
  restaurantOptionAddonList: {
    error: '',
    state: '',
    status: {
      code: 0,
      message: '',
    },
    data: {
      thName: '',
      enName: '',
      addon: [],
    },
  } as GetRestaurantOptionAddonList,

  setRestaurantOptionAddonList: (prefix: string, id: string, groupId: string, params?: QueryParamsGetList<optionAddonOrderByType>) => {},
  setRestaurantOptionAddonListData: (val: SetStateAction<GetRestaurantOptionAddonList>) => {},

  // RestaurantOptionAddonCreate
  restaurantOptionAddonCreateData: {} as PostRestaurantOptionAddon,
  setRestaurantOptionAddonCreateData: (val: SetStateAction<PostRestaurantOptionAddon>) => {},
  setRestaurantOptionAddonCreate: (prefix: string, restaurantId: string, groupId: string, payload: RestaurantOptionAddonCreateBody) => {},

  // RestaurantOptionAddonUpdate
  restaurantOptionAddonUpdateData: {} as PutRestaurantOptionAddon,
  setRestaurantOptionAddonUpdateData: (val: SetStateAction<PutRestaurantOptionAddon>) => {},
  setRestaurantOptionAddonUpdate: (prefix: string, id: string, groupId: string, optionAddonId, payload: RestaurantOptionAddonBody) => {},

  getRestaurantOptionAddonIdUpdateList: (optionAddonId: string, thName: string, enName: string, price: number) => {},

  // RestaurantOptionAddonDelete
  restaurantOptionAddonDeleteData: {} as DeleteRestaurantOptionAddon,
  setRestaurantOptionAddonDeleteData: (val: SetStateAction<DeleteRestaurantOptionAddon>) => {},
  setRestaurantOptionAddonDelete: (prefix: string, restaurantId: string, groupId: string, optionAddonId: string) => {},

  getRestaurantOptionAddonIdDeleteList: (optionAddonId: string) => {},

  overBudgetOrdersByOption: {} as GetOrderOverBudgetByOptionPrice,
  setOverBudgetOrdersByOption: (prefix: string, restaurantId: string, groupId: string, optionAddonId: string, params: QueryOrderOverBudgetByOptionPrice) => {},
});

export { OptionAddonContext };
