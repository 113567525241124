import { CommonCrud, CommonCrudWithError, HttpStatus, StoreState } from '@/src/types';
import { Dispatch, SetStateAction } from 'react';

export const initialStoreData: {
  error?: Error;
  data?: any;
  state: StoreState;
  status?: HttpStatus;
} = {
  error: undefined,
  data: undefined,
  status: undefined,
  state: 'initial',
};

export interface ActionUnit<T> {
  current: T;
  setState: Dispatch<SetStateAction<T>>;
  requestAct?: (...args: any) => void;
}

export const initialApiContext = <T extends CommonCrud<any>>(dataAddOn?: Partial<T['data']>, addOn?: Partial<T>): ActionUnit<T> => ({
  current: { ...initialStoreData, data: dataAddOn, ...addOn, state: 'initial' } as T,
  setState: (val: SetStateAction<T>) => {},
});

export const initialStateContext = <T>(addOn?: Partial<T>): ActionUnit<T> => ({
  current: { ...addOn } as T,
  setState: (val: SetStateAction<T>) => {},
});

export const commonSetError = <T extends CommonCrudWithError<any, any>>(error: any, setFunc: (val: SetStateAction<T>) => void): void =>
  setFunc({
    error: error.response?.data,
    status: error.response?.data?.status,
    state: 'error',
    data: undefined,
  } as T);

export interface ActionUnit2<T> {
  current: T;
  setState: Dispatch<SetStateAction<T>>;
}
export const initialApiContext2 = <T extends CommonCrudWithError<any, any>, T2 extends unknown>(
  dataAddOn?: Partial<T['data']>,
  addOn?: Partial<T>
): ActionUnit2<T> & { requestAct: T2 } => ({
  current: { ...initialStoreData, data: dataAddOn, ...addOn, state: 'initial' } as T,
  setState: (val: SetStateAction<T>) => {},
  requestAct: ((...args: any) => {}) as T2,
});
