import React from 'react';
import { Box, Button, CardMedia, Typography } from '@mui/material';

type CurrentImageDataType = {
  preUploadFile: any;
  imgErrorText: string;
  previousImage: any;
  isUpdateImage: boolean;
  imageBase64: string;
};

interface BrokenImageAreaProps {
  id: string;
  currentImageData: CurrentImageDataType;
  setCurrentImageData: React.Dispatch<React.SetStateAction<CurrentImageDataType>>;
  imageErrorText: string;
}

export default function BrokenImageArea({ id, currentImageData, setCurrentImageData, imageErrorText }: BrokenImageAreaProps) {
  return (
    <Box className="flex flex-row justify-between">
      <Box id={`${id}-image-area`} sx={{ display: 'flex', flexDirection: 'column', mr: '15px', alignItems: 'end' }}>
        <CardMedia
          id={`${id}-broken-image`}
          component="img"
          sx={{
            width: '188px',
            height: '72px',
            objectFit: 'contain',
            border: 'dashed',
            borderColor: 'red',
            borderWidth: '1px',
          }}
          image={require('@/src/images/broken-image.png')}
        />
        <Button
          id={`${id}-remove-broken-image-button`}
          variant="text"
          size={'small'}
          sx={{
            color: 'gray',
            textTransform: 'capitalize',
            textDecorationLine: 'underline',
            width: 'fit-content',
            height: 'fit-content',
            ':hover': { textDecorationLine: 'underline' },
          }}
          onClick={() => {
            setCurrentImageData({
              ...currentImageData,
              imgErrorText: '',
            });
          }}
        >
          Remove
        </Button>
      </Box>
      <Box id={`notification-add-image-error-text`} className="flex items-end">
        <Typography variant="body2" sx={(theme) => ({ textAlign: 'right', color: 'red', marginBottom: theme.spacing(2), whiteSpace: 'pre-line' })}>
          {imageErrorText}
        </Typography>
      </Box>
    </Box>
  );
}
