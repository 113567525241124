import React from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';

const modalStyle = {
  position: 'absolute',
  margin: 'auto',
  transform: 'translateX(-50%)',
  top: '60px',
  left: '50%',
  width: 495,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  display: 'grid',
  alignContent: 'stretch',
  justifyContent: 'space - around',
  alignItems: 'start',
};

interface ModalAdminRemoveRestaurantProps {
  restaurantEnName: string;
  restaurantThName: string;
  totalOrder: number;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export default function ModalAdminRemoveRestaurant(props: ModalAdminRemoveRestaurantProps) {
  const { restaurantEnName, restaurantThName, totalOrder, open, onConfirm, onCancel } = props;

  return (
    <Modal sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClose={onCancel} closeAfterTransition>
      <Box sx={modalStyle} className="mt-10">
        <Typography
          sx={{ color: '#000', fontSize: '20px', width: 'inherit', fontWeight: 'bold', marginBottom: '13px' }}
          id="transition-modal-title"
          variant="h5"
          component="h2"
        >
          Remove Restaurant
        </Typography>
        {totalOrder > 0 ? (
          <Typography variant="caption" sx={{ color: '#000', fontSize: '16px' }}>
            The restaurant {restaurantThName} ({restaurantEnName}) has active orders. Removing it will cancel all orders, and no fallback meals will be applied.
          </Typography>
        ) : (
          <Typography variant="body1" sx={{ color: '#000', fontSize: '16px' }}>
            Do you want to remove the restaurant from the schedule?
          </Typography>
        )}

        <Box
          sx={{
            display: 'flex',
            marginTop: '30px',
            width: 'inherit',
          }}
        >
          <Button id={'btn-modal-allow-change-restaurant-yes'} color={'error'} variant="contained" onClick={onConfirm} sx={{ marginRight: '20px' }}>
            Remove Restaurant
          </Button>
          <Button id={'btn-modal-allow-change-restaurant-no'} color={'error'} variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
