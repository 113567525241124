import * as React from 'react';
import { SelectChangeEvent, Select, MenuItem } from '@mui/material';
import moment from 'moment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export function BasicYearPicker(props: any) {
  const { Year, setYear } = props;

  const handleChangeYear = (event: SelectChangeEvent) => {
    setYear(event.target.value as string);
  };
  const now = moment();
  return (
    <Select
      IconComponent={KeyboardArrowDownIcon}
      labelId="demo-simple-select-label"
      id="select-year"
      value={Year || now.year().toString()}
      defaultValue={now.year().toString()}
      onChange={handleChangeYear}
      inputProps={{
        id: 'select-year-input',
      }}
    >
      <MenuItem id="year-0" value={now.year()}>
        {now.year()}
      </MenuItem>
      <MenuItem id="year-1" value={now.year() + 1}>
        {now.year() + 1}
      </MenuItem>
    </Select>
  );
}
