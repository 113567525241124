import React, { useState } from 'react';
import { Box, TextField, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { IPaginationData } from '@/store/user.v2.store/hooks/useUserPagination';

export type userOrderByType = 'name' | 'status';

interface SearchUserProps {
  setPaginationParams: IPaginationData & {
    total: number;
  };
}

export function SearchUser(prop: SearchUserProps) {
  const [preSearchText, setPreSearchText] = useState<string>();

  const handleSearchTextChange = (searchText: string) => {
    const searchTextAllow = searchText.replace(/`/g, '');
    setPreSearchText(searchTextAllow || undefined);
  };

  const handleClickClearSearch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setPreSearchText(undefined);
    prop.setPaginationParams.onSetPage(0);
    prop.setPaginationParams.onSetSearch('');
  };

  const handleSubmitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    prop.setPaginationParams.onSetPage(0);
    prop.setPaginationParams.onSetSearch(preSearchText!);
  };

  return (
    <Box component="form" className=" w-52 !mr-0 float-right relative" noValidate autoComplete="off" onSubmit={handleSubmitSearch}>
      <TextField
        type="text"
        size="small"
        InputProps={{
          className: '!px-2',
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: preSearchText ? (
            <InputAdornment position="end">
              <IconButton aria-label="clear-search" onClick={handleClickClearSearch}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ) : undefined,
        }}
        placeholder="Search Users"
        value={preSearchText || ''}
        onChange={(e) => handleSearchTextChange(e.target.value)}
      />
    </Box>
  );
}
