import { HeadCell } from '@/types/enhancedTable.d';

export const headCellsNotifications: HeadCell[] = [
  {
    id: 'no',
    numeric: true,
    label: 'No.',
    disablePadding: false,
    sortAble: false,
    width: 1 / 26,
  },
  {
    id: 'title',
    label: 'Title',
    numeric: false,
    disablePadding: false,
    sortAble: true,
    width: 4 / 26,
  },
  {
    id: 'description',
    label: 'Description',
    numeric: false,
    disablePadding: false,
    sortAble: false,
    width: 6 / 26,
  },
  {
    id: 'image',
    label: 'Image',
    numeric: false,
    disablePadding: false,
    sortAble: false,
    width: 1 / 26,
  },
  {
    id: 'createdBy',
    label: 'Created by',
    numeric: false,
    disablePadding: false,
    sortAble: true,
    width: 4 / 26,
  },
  {
    id: 'status',
    label: 'Status',
    numeric: false,
    disablePadding: false,
    sortAble: false,
    width: 3 / 26,
  },
  {
    id: 'scheduledDate',
    label: 'Scheduled Date/Time',
    numeric: false,
    disablePadding: false,
    sortAble: true,
    width: 4 / 26,
  },
  {
    id: 'actions',
    label: '',
    numeric: false,
    disablePadding: false,
    sortAble: false,
    width: 3 / 26,
  },
];
