import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import TableBody from '@mui/material/TableBody';
import moment from 'moment';
import { TableRowMenuSettingInlineEdit } from '../tableRowMenuSettingInlineEdit';
import { useRestaurantBookingContext, useSnackbarContext } from '@/services/index';
import { RestaurantBookingSchema } from '@/src/types/schema';
import { TableRowMenuSettingInlineEditProps } from '@/types/ui/TableRowMenuSettingInlineEditProps';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';

interface EnhancedTableBodyScheduleProps {
  Month: string;
  Year: string;
  setLoad: Dispatch<SetStateAction<boolean>>;
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
}

export function EnhancedTableBodySchedule(props: EnhancedTableBodyScheduleProps) {
  const { Month, Year, setLoad, setOpenPopup } = props;
  const daysInMonth = moment(`${Year}-${Month}-01`, 'YYYY-MMMM-DD').daysInMonth();
  const {
    restaurantBookingList,
    restaurantBookingUpdateData,
    getRestaurantBookingUpdateList,
    setRestaurantBookingUpdateData,
    restaurantBookingStatusUpdateData,
    setRestaurantBookingStatusUpdateData,
    getRestaurantBookingUpdateStatusList,
    restaurantBookingCreateData,
    setRestaurantBookingCreateData,
    getRestaurantBookingCreate,
    restaurantBookingRemoveData,
    setRestaurantBookingRemoveData,
  } = useRestaurantBookingContext();
  const { setResponseStatus } = useSnackbarContext();
  const [dayArray, setDayArray] = useState<TableRowMenuSettingInlineEditProps[]>([]);
  const [editRow, setEditRow] = useState(-1);
  const [checkLoad, setCheckLoad] = useState<boolean>(false);

  const onEditRow = (rowNo: number, enable: boolean) => {
    if (enable) setEditRow(rowNo);
    else setEditRow(-1);
  };

  // update restaurantBookingList after restaurant selected
  useEffect(() => {
    if (restaurantBookingUpdateData.state === 'ready') {
      setResponseStatus({
        status: 'success',
        message: restaurantBookingUpdateData?.status?.message ?? '',
      });
      setOpenPopup(true);

      getRestaurantBookingUpdateList(
        restaurantBookingUpdateData.data?.date ?? '',
        restaurantBookingUpdateData.data?.restaurantId ?? '',
        restaurantBookingUpdateData.data?.status ?? 'Open',
        restaurantBookingUpdateData.data?.thName ?? '',
        restaurantBookingUpdateData.data?.enName ?? ''
      );

      setRestaurantBookingUpdateData({
        state: 'initial',
        data: undefined,
      });
    } else if (restaurantBookingUpdateData.state === 'error') {
      setResponseStatus({
        status: restaurantBookingUpdateData.state,
        message: restaurantBookingUpdateData?.status?.message ?? '',
      });
      setOpenPopup(true);

      setRestaurantBookingUpdateData({
        state: 'initial',
        data: undefined,
      });
    }
  }, [restaurantBookingUpdateData]);

  // update restaurantBookingList after remove restaurant
  useEffect(() => {
    if (restaurantBookingRemoveData.state === 'ready') {
      setResponseStatus({
        status: 'success',
        message: 'Success. Your changes have been saved.',
      });
      setOpenPopup(true);
      setRestaurantBookingUpdateData({
        state: 'initial',
        data: undefined,
      });
      setRestaurantBookingStatusUpdateData({ state: 'initial', data: undefined });
      setRestaurantBookingRemoveData({ state: 'initial', data: undefined });
    } else if (restaurantBookingRemoveData.state === 'error') {
      setResponseStatus({
        status: restaurantBookingRemoveData.state,
        message: 'Request fail. Try again later',
      });
      setOpenPopup(true);
      setRestaurantBookingUpdateData({ state: 'initial', data: undefined });
      setRestaurantBookingStatusUpdateData({ state: 'initial', data: undefined });
      setRestaurantBookingRemoveData({ state: 'initial', data: undefined });
    }
  }, [restaurantBookingRemoveData]);

  // update restaurantBookingList after public holiday selected
  useEffect(() => {
    if (restaurantBookingStatusUpdateData.state === 'ready') {
      setResponseStatus({
        status: 'success',
        message: restaurantBookingStatusUpdateData?.status?.message ?? '',
      });
      setOpenPopup(true);

      getRestaurantBookingUpdateStatusList(restaurantBookingStatusUpdateData.data?.date ?? '', restaurantBookingStatusUpdateData.data?.status ?? 'Holiday');

      setRestaurantBookingStatusUpdateData({ state: 'initial', data: undefined });
    } else if (restaurantBookingStatusUpdateData.state === 'error') {
      setResponseStatus({
        status: restaurantBookingStatusUpdateData.state,
        message: restaurantBookingStatusUpdateData?.status?.message ?? '',
      });
      setOpenPopup(true);

      setRestaurantBookingStatusUpdateData({ state: 'initial', data: undefined });
    }
  }, [restaurantBookingStatusUpdateData]);

  // update restaurantBookingList after create a new restaurantBooking
  useEffect(() => {
    if (restaurantBookingCreateData.state === 'ready') {
      setResponseStatus({
        status: 'success',
        message: restaurantBookingCreateData?.status?.message ?? '',
      });
      setOpenPopup(true);

      if (restaurantBookingCreateData.data) getRestaurantBookingCreate(restaurantBookingCreateData.data);

      setRestaurantBookingCreateData({
        state: 'initial',
        data: undefined,
      });
    } else if (restaurantBookingCreateData.state === 'error') {
      setResponseStatus({
        status: restaurantBookingCreateData.state,
        message: restaurantBookingCreateData?.status?.message ?? '',
      });
      setOpenPopup(true);

      setRestaurantBookingCreateData({
        state: 'initial',
        data: undefined,
      });
    }
  }, [restaurantBookingCreateData]);

  useEffect(() => {
    const newDayArray = [] as TableRowMenuSettingInlineEditProps[];
    const today = moment();
    const m = moment(`${Year} ${Month}`, 'YYYY MMMM');
    const startDate = m.startOf('month') < today ? today.date() : 1;
    for (let date = startDate; date <= daysInMonth; date++) {
      const curDay = moment(Year + '-' + Month + '-' + date, 'YYYY-MMMM-DD');
      if (curDay.isoWeekday() === 6 || curDay.isoWeekday() === 7) continue;
      const remainingDaysInWeek = 7 - curDay.isoWeekday() - 1;
      const booking = restaurantBookingList?.data?.find((x: RestaurantBookingSchema) => x.date === curDay.format('YYYY-MM-DD'));

      newDayArray.push({
        date,
        booking,
        curDay,
        remainingDaysInWeek,
        edit: editRow === date,
      } as TableRowMenuSettingInlineEditProps);
    }

    setDayArray(newDayArray);
  }, [restaurantBookingList]);

  useEffect(() => {
    if (
      restaurantBookingUpdateData.state === STATE_ENUM.LOADING ||
      restaurantBookingStatusUpdateData.state === STATE_ENUM.LOADING ||
      restaurantBookingCreateData.state === STATE_ENUM.LOADING ||
      restaurantBookingRemoveData.state === STATE_ENUM.LOADING
    ) {
      setCheckLoad(true);
    } else {
      setCheckLoad(false);
    }
  }, [restaurantBookingUpdateData, restaurantBookingStatusUpdateData, restaurantBookingCreateData, restaurantBookingRemoveData]);

  return (
    <TableBody id="tbody-schedule">
      {dayArray.map((data) => (
        <TableRowMenuSettingInlineEdit
          date={data.date}
          booking={data.booking}
          curDay={data.curDay}
          remainingDaysInWeek={data.remainingDaysInWeek}
          key={data.date}
          edit={editRow === data.date}
          onEdit={(e: boolean) => onEditRow(data.date, e)}
          setLoad={setLoad}
          checkLoad={checkLoad}
          setCheckLoad={setCheckLoad}
        ></TableRowMenuSettingInlineEdit>
      ))}
    </TableBody>
  );
}
