// ATTENTION some debug information
import { ENVS } from '../app.constants';

const readBuildFile = () => {
  try {
    return new Date(require('./build_time.json').build_timestamp);
  } catch (err) {
    return { build_timestamp: '' };
  }
};
console.log('%c[APPLICATION][BUILD_TIME]', 'background: black; color: yellow; font-size: small');
console.log(readBuildFile());
console.log('---');
console.log('---');
if (ENVS.ENVIRONMENT !== 'PROD') {
  console.log('[DEBUG][ENVS]', ENVS);
}
