import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Fade, Box, Typography, Button } from '@mui/material';
import { printDisplayDate } from '@/utils/index';
import { DescriptionForCantDelete } from '../sharedUIComponents';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import { ActionUnit2, OptionAddonTypes } from '@/store/index';

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

interface ModalOptionAddonComponentProps {
  id: string;
  openModal: boolean;
  type: string;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  restaurantId: string;
  groupId: string;
  optionAddonId: string;
  setType: React.Dispatch<React.SetStateAction<string>>;
  optionAddonDelete: ActionUnit2<OptionAddonTypes.RestaurantOptionAddonDelete> & {
    requestAct: OptionAddonTypes.RequestAct.RestaurantOptionAddonDelete;
  };
}
export default function ModalComponent(props: ModalOptionAddonComponentProps) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [buttonCancelText, setButtonCancelText] = useState('');
  const [temp, setTemp] = useState('');
  const [color, setColor] = useState('');
  const [dateArray, setDateArray] = useState<{ date: string; count: number }[]>([]);
  const { type, openModal, setOpenModal, restaurantId, groupId, optionAddonId, setType, optionAddonDelete } = props;

  useEffect(() => {
    setTemp('#F44336');
    setColor('#ffffff');
    if (type === 'DELETE') {
      setTitle('Are you sure you want to delete this option addon?');
      setDescription('This action will permanently delete the addon and cannot be undone.');
      setButtonText('DELETE');
      setButtonCancelText('CANCEL');
    } else if (type === 'CANTDELETE') {
      setTitle("This action can't be completed.");
      setButtonText('OK');
    }
  }, [type, temp, color]);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleClickDelete = () => {
    setOpenModal(false);
    optionAddonDelete.requestAct(restaurantId, groupId, optionAddonId);
  };

  useEffect(() => {
    if (optionAddonDelete.current.state === 'error') {
      setType('CANTDELETE');
      setOpenModal(true);

      optionAddonDelete.setState({ state: STATE_ENUM.INITIAL });

      const displayDateList =
        optionAddonDelete.current?.error?.data?.map((object) => {
          return { date: printDisplayDate(object.date), count: object.count };
        }) || [];

      setDateArray(displayDateList.sort());
    }
  }, [optionAddonDelete.current]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={handleClose}
      closeAfterTransition
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={openModal}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h5" component="h2">
            {title}
          </Typography>
          {type === 'CANTDELETE' ? (
            <DescriptionForCantDelete modalType="option add-on" dateArray={dateArray} />
          ) : (
            <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{ color: '#212121' }}>
              {description}
            </Typography>
          )}
          {type === 'DELETE' ? (
            <Button id={'btn-modal-option-addon-delete-' + buttonText} style={{ backgroundColor: temp, color }} sx={{ mt: 2 }} onClick={handleClickDelete}>
              {buttonText}
            </Button>
          ) : (
            <Button id={'btn-modal-option-addon-close-' + buttonText} style={{ backgroundColor: temp, color }} sx={{ mt: 2 }} onClick={handleClose}>
              {buttonText}
            </Button>
          )}
          {type !== 'CANTDELETE' ? (
            <Button
              id={'btn-modal-option-addon-cancel-' + buttonText}
              style={{ backgroundColor: '#ffff', color: temp, border: '1px solid ' + temp }}
              sx={{ mt: 2, ml: 2 }}
              onClick={handleClose}
            >
              {buttonCancelText}
            </Button>
          ) : (
            <></>
          )}
        </Box>
      </Fade>
    </Modal>
  );
}
