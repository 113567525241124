// @ts-ignore
// @ts-nocheck

import { initializeApp } from 'firebase/app';
import { getAuth, OAuthProvider, signInWithRedirect, signOut, getRedirectResult, signInWithPopup } from 'firebase/auth';
// import { getDatabase, Database } from 'firebase/database';
import { firebaseTrigger as _firebaseTrigger } from './firebase.triggers';
// import { query, getDocs, collection, where, addDoc } from 'firebase/firestore';

import { firebaseConfig, microsoftTenantId } from './firebase.config';
import { getFirestore } from 'firebase/firestore';
import { cookieOptions, delay, removeCookies } from '@/utils/index';
import { CookieSetOptions } from 'universal-cookie';

const app = initializeApp(firebaseConfig);
const db = getFirestore(app /** can specify different db connection */);
const firebaseAuth = getAuth(app);

const microsoftProvider = new OAuthProvider('microsoft.com').setCustomParameters({
  tenant: microsoftTenantId,
});

microsoftProvider.addScope('Group.Read.All');
microsoftProvider.addScope('GroupMember.Read.All');
microsoftProvider.addScope('User.Read.All');

/**
 * @returns void
 * @description used to signIn by redirect to login page.
 * @related use getSignInWithMicrosoftResult() function to get the result of the redirected-signIn.
 */
const signInRedirectWithMicrosoft = () => {
  try {
    signInWithRedirect(firebaseAuth, microsoftProvider);
  } catch (error) {
    console.error('[signInRedirectWithMicrosoft]', error);
  }
};

const signOutWithMicrosoft = () => {
  try {
    microsoftProvider.setCustomParameters({ prompt: 'select_account' });
    signOut(firebaseAuth);
  } catch (error) {
    console.error('[signOutWithMicrosoft]', error);
  }
};

/**
 * @param setCookie function to set cookie.
 * @returns `Promise<boolean>`
 * @description used to set setCookie after get result after signIn with redirect.
 */
const getSignInWithMicrosoftResult = (setCookie: (name: 'accessToken' | 'jwtToken', value: any, options?: CookieSetOptions) => void): Promise<boolean> => {
  return getRedirectResult(firebaseAuth)
    .then(async function (result) {
      if (!result) return Promise.reject(new Error('[getRedirectResult] no results'));
      const credential = OAuthProvider.credentialFromResult(result);

      removeCookies('accessToken'); // first is non react so wont activate react/state
      setCookie('accessToken', credential.accessToken, cookieOptions());
      window.sessionStorage.setItem('mlo_ms_login_pending', 'false');

      // ATTENTION seems to work without additional request to firebase
      // we we experience firebase token issues, may need to enable it again
      // try {
      //   const firebaseIdToken: IdTokenResult = await firebaseAuth.currentUser?.getIdTokenResult(true);
      //   window.sessionStorage.setItem('mlo_ms_login_pending', 'false');

      //   removeCookies('firebaseIdToken'); // first is non react so wont activate react/state
      //   setCookie('firebaseIdToken', firebaseIdToken.token, cookieOptions());
      // } catch (err) {
      //   console.error('[getRedirectResult][firebaseIdToken]', err);
      // }

      await delay(500);
      return true;
    })
    .catch((error) => {
      // NOTE remove this if you uncomment above
      window.sessionStorage.setItem('mlo_ms_login_pending', 'false');
      removeCookies('firebaseIdToken');
      // -- end
      console.error('[getRedirectResult]', error);
      return false;
    });
};

/**
 * @param setCookie function to set cookie.
 * @returns void
 * @description used to signIn by popup new separated login page, then set setCookie after get result.
 */
const signInPopupWithMicrosoft = (setCookie: (name: 'accessToken' | 'jwtToken', value: any, options?: CookieSetOptions) => void): Promise<boolean> => {
  return signInWithPopup(firebaseAuth, microsoftProvider)
    .then((result) => {
      const credential = OAuthProvider.credentialFromResult(result);

      removeCookies('accessToken');
      setCookie('accessToken', credential.accessToken, cookieOptions());
      window.sessionStorage.setItem('mlo_ms_login_pending', 'false');

      // NOTE we dont need this logic since we already acquire the information from src/services/store/user.model.ts
      // ATTENTION seems to work without additional request to firebase
      // we we experience firebase token issues, may need to enable it again
      // firebaseAuth.currentUser
      //   ?.getIdTokenResult(true)
      //   .then((firebaseIdToken) => {
      //     window.sessionStorage.setItem('mlo_ms_login_pending', 'false');

      //     removeCookies('firebaseIdToken');
      //     setCookie('firebaseIdToken', firebaseIdToken.token, cookieOptions());
      //   })
      //   .catch((err) => {
      //     console.error('[signInPopupWithMicrosoft][firebaseAuth][getIdTokenResult]', err);
      //   });
      return true;
    })
    .catch((error) => {
      console.error('[signInPopupWithMicrosoft][signInWithPopup]', error);
      return false;
    });
};

const firebaseTrigger = (docPath: string) => _firebaseTrigger(db, docPath);
export { firebaseAuth, signInRedirectWithMicrosoft, signOutWithMicrosoft, getSignInWithMicrosoftResult, firebaseTrigger, signInPopupWithMicrosoft };
