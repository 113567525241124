import { QueryParamsGetList } from '@/src/types';
import { AxiosService } from '@/utils/axios';
import { paramsQueryString } from '@/src/utils';
import { apiMethodV1 } from '@/app.constants/apiCallMethods';
import { AxiosResponse } from 'axios';
import { notificationOrderByType } from '@/types/crud/notifications/notifications.queries';
import { apiPathV1 } from '@/src/app.constants/apiCallPaths/api.path.v1';
import { NotificationRequest } from '@/src/types/crud/notifications/notifications';

const apiURL = apiPathV1.adminNotification;

const NotificationListGet = <T>(params: QueryParamsGetList<notificationOrderByType>, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.get, timeout: 12000 }, true, hardRejection);
  const queryUri = paramsQueryString(apiURL, params);

  const config = {
    ...instance.config,
  };

  // for testing .get(queryUri, { ...config, baseURL: 'http://localhost:5001/lunch-nao-dev/asia-southeast1/api' })
  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

const NotificationCreate = <T>(prefix: string, body: NotificationRequest, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.post, timeout: 12000 }, true, hardRejection);
  // token not yet set on backend

  const config = {
    ...instance.config,
  };

  return instance.client
    .post(prefix, JSON.stringify(body), config)
    .then((res: AxiosResponse<T>) => {
      return res.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

const NotificationUpdate = <T>(prefix: string, body: NotificationRequest, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.put, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .put(prefix, JSON.stringify(body), config)
    .then((res: AxiosResponse<T>) => {
      return res.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

const NotificationDelete = <T>(notificationId: string, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.delete, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .delete(apiURL + notificationId, config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

const NotificationMultiDelete = <T>(ids: string[], hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.delete, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .delete(apiURL, config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

export { NotificationListGet, NotificationCreate, NotificationUpdate, NotificationDelete, NotificationMultiDelete };
