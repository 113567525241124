import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

export default function ModalForChangeMenuFallbackMeal({ open, handleClose, handleConfirm, newMeal, oldMeal }) {
  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="fallbackMeal" aria-describedby="modal-fallbackMeal">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '5px',
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Are you sure you want to set {newMeal} instead of {oldMeal} as the fallback meal?
          </Typography>
          <Typography id="modal-description" variant="body1" sx={{ mt: 3 }}>
            Note: Mobile users must update the menu item&apos;s add-ons and options themselves, if any.
          </Typography>
          <Button onClick={handleConfirm} variant="contained" sx={{ mt: 3, mr: 1 }}>
            CONFIRM
          </Button>
          <Button
            onClick={() => {
              handleClose();
            }}
            variant="outlined"
            sx={{ mt: 3 }}
          >
            CANCEL
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
