export const nav = [
  {
    name: '',
    path: '/',
  },
  {
    name: 'USERS',
    path: '/users',
  },
  {
    name: 'NOTIFICATIONS',
    path: '/notifications',
  },
  {
    name: 'RESTAURANTS',
    path: '/restaurants',
  },
  {
    name: 'SCHEDULE',
    path: '/schedule',
  },
  {
    name: 'ORDER HISTORY',
    path: '/orderhistory',
  },
  {
    name: 'ORDERS',
    path: '/orders',
  },
  {
    name: 'DASHBOARD',
    path: '/dashboard',
  },
];

export default nav;
