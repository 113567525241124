import ImageUploading, { ErrorsType, ImageListType } from 'react-images-uploading';
import { AddPhotoButton } from '../buttons/addPhotoButton';
import { RemoveImageArea } from '../removeImageArea';
import { CurrentImageDataType } from '../tableBodyRestaurantMenu/index';

const MAX_FILE_SIZE = 2048000; // 2MB
const IMAGE_ACCEPT_TYPE = ['jpg', 'png', 'jpeg'];

interface ImageUploadProps {
  id: string;
  currentImageData: CurrentImageDataType;
  setCurrentImageData: React.Dispatch<React.SetStateAction<CurrentImageDataType>>;
  border?: boolean;
  layout: {
    width: number;
    height: number;
  };
  useBuiltInCallError?: boolean;
  disabled?: boolean;
}

export function ImageUpload(props: ImageUploadProps) {
  const { id, currentImageData, setCurrentImageData, border, layout, useBuiltInCallError, disabled } = props;

  const handleImageChange = (imageList: ImageListType) => {
    let imgBase64: string = '';
    if (imageList.length !== 0) {
      // the imageList has 2 object inside 1. dataURL 2. file
      const splitDataURL = imageList[0].dataURL?.split(';base64,') as string[];
      imgBase64 = splitDataURL[1];
    }
    setCurrentImageData({ ...currentImageData, imageBase64: imgBase64, preUploadFile: imageList, isUpdateImage: true });
  };

  const handleImageFileError = (typesE: ErrorsType) => {
    const errType = Object.keys(typesE!);
    if (errType[0] === 'acceptType') {
      setCurrentImageData({ ...currentImageData, imgErrorText: 'Only .jpg and .png are supported.' });
    } else if (errType[0] === 'maxFileSize') {
      setCurrentImageData({ ...currentImageData, imgErrorText: 'Max 2 MB.' });
    } else {
      setCurrentImageData({ ...currentImageData, imgErrorText: '' });
    }
  };

  return (
    <ImageUploading
      inputProps={{ id: `${id}-upload-image-input` }}
      value={currentImageData.preUploadFile}
      acceptType={IMAGE_ACCEPT_TYPE}
      onError={handleImageFileError}
      maxFileSize={MAX_FILE_SIZE}
      onChange={handleImageChange}
    >
      {({ imageList, onImageUpload, onImageRemoveAll }) =>
        imageList.length === 0 ? (
          <AddPhotoButton
            id={id}
            onClick={onImageUpload}
            imgErrorText={currentImageData.imgErrorText}
            callError={useBuiltInCallError}
            border={border}
            disabled={disabled}
          />
        ) : (
          <RemoveImageArea
            id={id}
            onClick={() => {
              onImageRemoveAll();
              if (typeof currentImageData.previousImage === 'object') {
                setCurrentImageData({
                  ...currentImageData,
                  preUploadFile: [],
                  imgErrorText: '',
                  imageBase64: '',
                  isUpdateImage: !!(currentImageData.previousImage?.thumbnailLink && currentImageData.isUpdateImage),
                });
              } else if (typeof currentImageData.previousImage === 'string') {
                setCurrentImageData({
                  ...currentImageData,
                  preUploadFile: [],
                  imgErrorText: '',
                  imageBase64: '',
                  isUpdateImage: !!(currentImageData.previousImage && currentImageData.isUpdateImage),
                });
              } else {
                setCurrentImageData({
                  ...currentImageData,
                  preUploadFile: [],
                  imgErrorText: '',
                  imageBase64: '',
                  isUpdateImage: false,
                });
              }
            }}
            imageURL={imageList[0]?.dataURL}
            border={border}
            layout={{
              width: layout.width,
              height: layout.height,
            }}
            disabled={disabled}
          />
        )
      }
    </ImageUploading>
  );
}
