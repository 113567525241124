// TODO add separation of concern for actions and reducers for React.provider context
import { createContext, useContext, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { userModel } from './user.model';
import { User } from 'firebase/auth';
const AppContext = createContext({
  // eslint-disable-next-line no-new-func
  currentUser: userModel(new Function() as any),
  setCurrentUser: (val: User) => {},
});

const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentUser, setCurrentUser] = useState(userModel({} as any));

  const updateCurrentUser = (user: User) => {
    setCurrentUser(userModel(user as any));
  };

  const appStore = {
    currentUser,
    setCurrentUser: updateCurrentUser,
  };

  return (
    <CookiesProvider>
      <AppContext.Provider value={appStore}>{children}</AppContext.Provider>
    </CookiesProvider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppContextProvider, useAppContext };
