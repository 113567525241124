import React, { useEffect, useRef, Dispatch, SetStateAction, useState } from 'react';
import { Box, TextField, Tooltip, Button } from '@mui/material';
import { HiddenActionType, order, PutRestaurant, restaurantOrderByType, RestaurantSchema } from '@/types/index';
import { useSnackbarContext, useRestaurantContext } from '@/services/index';
import { apiPathV1 } from '@/app.constants/apiCallPaths/api.path.v1';
import { LIMIT_EN_CHAR, LIMIT_TH_CHAR } from '@/app.constants/index';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';

interface AddRestaurantProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setOrderBy: Dispatch<SetStateAction<restaurantOrderByType | null>>;
  setOrder: Dispatch<SetStateAction<order | null>>;
  setPage: Dispatch<SetStateAction<number>>;
  setTotal: Dispatch<SetStateAction<number>>;
  setIsChange: Dispatch<SetStateAction<boolean>>;
  total: number;
  search: string | undefined;
  languageSearch: 'EnTh' | 'Th' | 'En' | null;
  setHiddenActionInSearchMode: Dispatch<SetStateAction<HiddenActionType>>;
}

export function AddRestaurant(props: AddRestaurantProps) {
  const { setOpen, setOrderBy, setOrder, setTotal, setIsChange, total, search, languageSearch, setHiddenActionInSearchMode } = props;
  const { setResponseStatus } = useSnackbarContext();
  const { restaurantCreateData, setRestaurantCreate, setRestaurantCreateData, setRestaurantListData, setRestaurantListForSchedule, restaurantUpdateData } =
    useRestaurantContext();
  const [thNameErrorText, setThNameErrorText] = useState<string>('');
  const [enNameErrorText, setEnNameErrorText] = useState<string>('');
  const [thaiText, setThaiText] = useState<string>('');
  const [engText, setEngText] = useState<string>('');
  const [validate, setValidate] = useState<boolean>(false);
  const [checkSaveLoad, setCheckSaveLoad] = useState<boolean>(false);
  const countThaiText = useRef<number>(0);
  const countEngText = useRef<number>(0);
  const thaiChar = LIMIT_TH_CHAR;
  const engChar = LIMIT_EN_CHAR;

  const handleClickAdd = () => {
    const payload = {
      thName: thaiText,
      enName: engText,
    };
    setRestaurantCreate(apiPathV1.restaurantCreate, payload);
    setValidate(false);
  };

  useEffect(() => {
    if (restaurantCreateData.state === 'ready') {
      let isHiddenAction = false;
      if (search && languageSearch) {
        // NOTE: only search mode can have hidden actions
        let isFoundSearchInTh = false;
        let isFoundSearchInEn = false;
        if (languageSearch.includes('Th')) {
          const foundStartAt = thaiText.toLowerCase().indexOf(search.toLowerCase());
          if (foundStartAt !== -1) isFoundSearchInTh = true;
        }
        if (languageSearch.includes('En')) {
          const foundStartAt = engText.toLowerCase().indexOf(search.toLowerCase());
          if (foundStartAt !== -1) isFoundSearchInEn = true;
        }

        if (!isFoundSearchInTh && !isFoundSearchInEn) {
          isHiddenAction = true;
        }
      }

      if (isHiddenAction) {
        setHiddenActionInSearchMode('added');
      } else {
        setIsChange(true);
        setTotal(total + 1);
        const tempResult = {
          createdAt: restaurantCreateData?.data?.createdAt,
          enName: engText.trim(),
          id: restaurantCreateData?.data?.id,
          lastOrder: '0000-00-00T00:00:00.000Z',
          favoriteCount: 0,
          menuItems: 0,
          orderCount: 0,
          orderTotal: 0,
          thName: thaiText.trim(),
          updatedAt: restaurantCreateData?.data?.createdAt,
        } as RestaurantSchema;
        setRestaurantListData((prev) => {
          return { ...prev, data: [tempResult, ...prev.data] };
        });
      }

      setRestaurantListForSchedule(apiPathV1.restaurantList);
      setResponseStatus({
        status: 'success',
        message: restaurantCreateData?.status?.message ?? '',
      });
      setThaiText('');
      setEngText('');
      setRestaurantCreateData({
        state: 'initial',
      } as PutRestaurant);
      setOpen(true);
      setOrderBy(null);
      setOrder(null);
      // TODO: report bug found every table page > should not update page to 0 because data is still the same as current page when added
      // setPage(0);
      countThaiText.current = 0;
      countEngText.current = 0;
    } else if (restaurantCreateData.state === 'error') {
      setResponseStatus({
        status: restaurantCreateData.state,
        message: restaurantCreateData?.status?.message ?? 'Something went wrong. Please try again.',
      });
      setRestaurantCreateData({
        state: 'initial',
      } as PutRestaurant);
      setOpen(true);
    }
  }, [restaurantCreateData]);

  const handleThaiNameTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    countThaiText.current = event.target.value.trim().length;
    setThaiText(event.target.value);
    // TODO: Move validation to util function
    if (event.target.value.length === 0 || !event.target.value.match(thaiChar)) {
      setThNameErrorText('Thai name is required.');
      setValidate(false);
    } else if (event.target.value.trim().length === 0) {
      setValidate(false);
      setThNameErrorText('Thai name is required.');
    } else if (event.target.value.length > 100) {
      setValidate(false);
      setThNameErrorText('Max 100 characters.');
    } else {
      setThNameErrorText('');
    }
  };

  const handleEngNameTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    countEngText.current = event.target.value.trim().length;
    setEngText(event.target.value);
    // TODO: Move validation to util function
    if (event.target.value.length === 0 || !event.target.value.match(engChar)) {
      setEnNameErrorText('English name is required.');
      setValidate(false);
    } else if (event.target.value.trim().length === 0) {
      setValidate(false);
      setEnNameErrorText('English name is required.');
    } else if (event.target.value.length > 100) {
      setValidate(false);
      setEnNameErrorText('Max 100 characters.');
    } else {
      setEnNameErrorText('');
    }
  };

  const handleOutOfFocusThaiName = () => {
    // TODO: Move validation to util function
    if (countThaiText.current === 0) {
      setThaiText('');
      setThNameErrorText('');
    } else if (!thaiText.match(thaiChar)) {
      setThNameErrorText('Thai name is required.');
    } else if (countThaiText.current > 100) {
      setThNameErrorText('Max 100 characters.');
    } else {
      setThNameErrorText('');
    }
  };
  const handleOutOfFocusEngName = () => {
    // TODO: Move validation to util function
    if (countEngText.current === 0) {
      setEngText('');
      setEnNameErrorText('');
    } else if (!engText.match(engChar)) {
      setEnNameErrorText('English name is required.');
    } else if (countEngText.current > 100) {
      setEnNameErrorText('Max 100 characters.');
    } else {
      setEnNameErrorText('');
    }
  };

  useEffect(() => {
    // TODO: Move validation to util function
    if (countEngText.current !== 0 && countThaiText.current !== 0 && thNameErrorText === '' && enNameErrorText === '') {
      setValidate(true);
    } else {
      setValidate(false);
    }
  }, [thaiText, engText]);

  useEffect(() => {
    /** disabled add button when loading or editing action */
    if (restaurantUpdateData.state === STATE_ENUM.LOADING || restaurantUpdateData.state === STATE_ENUM.EDITING) {
      setCheckSaveLoad(true);
    } else {
      setCheckSaveLoad(false);
    }
  }, [restaurantUpdateData]);

  return (
    <Box
      py={1}
      component="form"
      sx={{
        '& > :not(style)': { m: '16px', ml: '0px' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        value={thaiText}
        className="mr-3"
        id="thaiName"
        label="Thai name"
        variant="outlined"
        size="small"
        color="secondary"
        sx={{
          width: '25ch',
        }}
        error={thNameErrorText !== ''}
        helperText={thNameErrorText}
        onChange={handleThaiNameTextChange}
        onBlur={handleOutOfFocusThaiName}
        inputProps={{ maxLength: 101 }}
        disabled={checkSaveLoad}
      />
      <TextField
        value={engText}
        id="engName"
        label="English name"
        variant="outlined"
        size="small"
        color="secondary"
        sx={{
          width: '25ch',
        }}
        error={enNameErrorText !== ''}
        helperText={enNameErrorText}
        onChange={handleEngNameTextChange}
        onBlur={handleOutOfFocusEngName}
        inputProps={{ maxLength: 101 }}
        disabled={checkSaveLoad}
      />

      <Tooltip title="Add restaurant" placement="bottom-start">
        <Button id="btn-add-restaurant" variant="contained" component="span" color="primary" onClick={handleClickAdd} disabled={!validate || checkSaveLoad}>
          Add
        </Button>
      </Tooltip>
    </Box>
  );
}
