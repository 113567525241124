import { SelectChangeEvent, FormControl, Select, OutlinedInput, MenuItem, ListItemIcon, Checkbox, ListItemText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { RestaurantGroupsSchema } from '@/types/schema/restaurantGroups';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styles } from '@/src/styles/theme';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface SelectOptionGroupsProps {
  selectedOptionGroups: RestaurantGroupsSchema[];
  setSelectOptionGroupId: React.Dispatch<React.SetStateAction<string[]>>;
  editRowIndex: number | undefined;
  index: number;
  dropdownOptionGroupsData: { label: string; value: string }[];
  disable?: boolean;
  inputId?: string;
}

export default function SelectOptionGroups(props: SelectOptionGroupsProps) {
  const { selectedOptionGroups, setSelectOptionGroupId, editRowIndex, index, dropdownOptionGroupsData, disable } = props;

  const selectedGroupsProps = selectedOptionGroups.map((item) => item.id);
  const [selectedGroupsState, setSelectedGroupsState] = useState<string[]>([]);

  const handleSelectOptionGroups = (event: SelectChangeEvent<string[]>) => {
    setSelectedGroupsState(event.target.value as string[]);
    setSelectOptionGroupId(event.target.value as string[]);
  };

  useEffect(() => {
    setSelectedGroupsState(selectedGroupsProps);
  }, [editRowIndex]);

  const classes = styles();

  return (
    <FormControl sx={{ width: '300px' }} variant="standard">
      <Select
        disabled={disable}
        id="select-option-group"
        multiple
        IconComponent={KeyboardArrowDownIcon}
        // NOTE: if the current row is editing then use selected state, if not then use selected props
        value={editRowIndex === index ? selectedGroupsState : selectedGroupsProps}
        onChange={handleSelectOptionGroups}
        input={<OutlinedInput id={props.inputId || 'select-multiple-chip'} />}
        renderValue={(selected) => {
          return selected
            .reduce((sum: string[], each: string) => {
              const eachLabel = dropdownOptionGroupsData.find((item) => item.value === each);
              return eachLabel ? [...sum, eachLabel.label] : sum;
            }, [])
            .join(', ');
        }}
        MenuProps={MenuProps}
        style={{ height: 36 }}
        color="secondary"
      >
        {dropdownOptionGroupsData.map((item) => (
          <MenuItem id={'select-option-group-' + item.value} key={item.value} value={item.value} sx={{ pl: 1 }} className={classes.wrap_text}>
            <ListItemIcon>
              <Checkbox checked={selectedGroupsState.includes(item.value)} />
            </ListItemIcon>
            <ListItemText primary={item.label} key={item.value} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
