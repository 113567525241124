import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TableBody from '@mui/material/TableBody';
import { TextField, Tooltip, Button, Stack, TableCell, TableRow, MenuItem, Select, Typography } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import Modal from '../modal/modalGroupDelete';
import { useSnackbarContext, useRestaurantContext, useGroupContextV2 } from '@/services/index';
import { apiPathV1 } from '@/app.constants/apiCallPaths/api.path.v1';
import { QueryParamsGetList, RestaurantGroupsSchemaV2, optionGroupOrderByType, order } from '@/types/index';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LIMIT_EN_CHAR, LIMIT_TH_CHAR } from '@/app.constants/index';
import ModalCancelOrderByGroupTypeChange from '../modal/modalCancelOrderByGroupTypeChange';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import { updateTablePaginationTotal } from '@/src/helpers/tableHelpers';
import { common } from '@mui/material/colors';

interface EnhancedTableBodyGroupProp {
  rows: RestaurantGroupsSchemaV2[];
  order: order | null;
  orderBy: optionGroupOrderByType | null;
  setOrderBy: React.Dispatch<React.SetStateAction<optionGroupOrderByType | null>>;
  setOrder: React.Dispatch<React.SetStateAction<order | null>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTotal: Dispatch<SetStateAction<number>>;
  setIsChange: Dispatch<SetStateAction<boolean>>;
  total: number;
  setPaginationParams: React.Dispatch<React.SetStateAction<QueryParamsGetList<any>>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

// TODO: Add props type
export function EnhancedTableBodyRestaurantGroups(props: EnhancedTableBodyGroupProp) {
  const { rows, order, orderBy, setOrderBy, setOrder, setOpen, setTotal, total, setPaginationParams, setPage } = props;
  const { id } = useParams();
  const [editRowIndex, setEditRowIndex] = React.useState<undefined | number>(-1);
  const [type, setType] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [restaurantId, setRestaurantId] = React.useState<undefined | string>(undefined);
  const [restaurantGroupId, setRestaurantGroupId] = useState('');

  const navigate = useNavigate();

  const countThaiText = useRef(0);
  const countEngText = useRef(0);
  const [thNameErrorText, setThNameErrorText] = React.useState('');
  const [enNameErrorText, setEnNameErrorText] = React.useState('');
  const [thaiText, setThaiText] = React.useState('');
  const [engText, setEngText] = React.useState('');
  const [validate, setValidate] = React.useState(false);
  const [checkLoad, setCheckLoad] = useState(false);

  const thaiChar = LIMIT_TH_CHAR;
  const engChar = LIMIT_EN_CHAR;
  const [checkDup, setCheckDup] = useState(false);

  const focusTextField = useRef<HTMLInputElement | null>(null);
  const loopTextField = useRef<HTMLInputElement | null>(null);
  const [selected, setSelected] = useState('');
  const [prevGroup, setPrevGroup] = React.useState({
    prevThai: '',
    prevEng: '',
    prevTypeGroup: '',
  });

  // context
  const { setResponseStatus } = useSnackbarContext();
  const {
    restaurantGroupList,
    restaurantGroupUpdate,
    restaurantGroupDelete,
    restaurantGroupOrderCount,
    getRestaurantGroupIdUpdateList,
    getRestaurantGroupIdDeleteList,
  } = useGroupContextV2();
  const { setRestaurantDropdownList } = useRestaurantContext();

  // state for open modal confirmation when the new group type affects orders.
  const [openCancelOrdersModal, setOpenCancelOrdersModal] = useState<boolean>(false);
  // state for the waiting response from new group type affects orders count, use to prevent more action while checking.
  const [isCheckingGroupType, setCheckingGroupType] = useState<boolean>(false);
  // group id that currently new group type affects orders count.
  const [currentCheckingGroupId, setCurrentCheckingGroupId] = useState<string | undefined>();
  // number of Orders that response from API.
  const [groupTypeChangeOrdersCount, setGroupTypeChangeOrdersCount] = useState<number>(0);

  // handlers
  const handleOpenDelete = (groupId: string, restaurantId: undefined | string) => {
    setType('DELETE');
    setOpenModal(true);
    setRestaurantGroupId(groupId);
    setRestaurantId(restaurantId);
  };
  const handleCancel = () => {
    setEditRowIndex(-1);
    setThNameErrorText('');
    setEnNameErrorText('');
    /** Set state INITIAL when user click cancel button */
    restaurantGroupUpdate.setState({
      state: STATE_ENUM.INITIAL,
    });
  };
  function handleEditClick(index: number, thName: string, enName: string, groupRadio: string) {
    setPrevGroup({
      prevThai: thName,
      prevEng: enName,
      prevTypeGroup: groupRadio,
    });
    setThNameErrorText('');
    setEnNameErrorText('');
    setThaiText(thName);
    setEngText(enName);
    setSelected(groupRadio);

    setEditRowIndex(index);
    countEngText.current = enName.trim().length;
    countThaiText.current = thName.trim().length;
    /** Set state edit when user click edit button */
    restaurantGroupUpdate.setState({
      state: STATE_ENUM.EDITING,
    });
  }

  const handleClickSave = (groupId: string) => {
    const payload = {
      thName: thaiText?.trim(),
      enName: engText?.trim(),
      groupRadio: selected === 'true',
    };
    setRestaurantGroupId(groupId);
    restaurantGroupUpdate.requestAct(id as string, groupId, payload);
    setValidate(false);
  };

  const handleCheckTypeChangeBeforeSave = (groupId: string) => {
    setCheckLoad(true);
    if (prevGroup.prevTypeGroup === selected) {
      handleClickSave(groupId);
    } else {
      if (id) {
        setCheckingGroupType(true);
        setCurrentCheckingGroupId(groupId);
        restaurantGroupOrderCount.requestAct(id, groupId);
      }
    }
  };

  useEffect(() => {
    if (restaurantGroupOrderCount.current.state === STATE_ENUM.READY && isCheckingGroupType && currentCheckingGroupId) {
      // NOTE: if get the response success then check if there is the overBudget Orders or not.
      if (restaurantGroupOrderCount.current.data?.amount === 0) {
        handleClickSave(currentCheckingGroupId);
        setCurrentCheckingGroupId(undefined);
      } else {
        if (restaurantGroupOrderCount.current.data?.amount) setGroupTypeChangeOrdersCount(restaurantGroupOrderCount.current.data?.amount);
        setOpenCancelOrdersModal(true);
      }
      setCheckingGroupType(false);
    }
  }, [restaurantGroupOrderCount.current]);

  useEffect(() => {
    if (restaurantGroupUpdate.current.state === STATE_ENUM.READY) {
      setRestaurantDropdownList(apiPathV1.restaurantDropdownList, id, { types: ['addon', 'group'] });
      setResponseStatus({
        status: 'success',
        message: restaurantGroupUpdate.current?.status?.message ?? '',
      });
      restaurantGroupUpdate.setState({
        state: STATE_ENUM.INITIAL,
      });
      setOpen(true);
      setEditRowIndex(-1);
      setOrderBy(orderBy);
      setOrder(order);
      setCheckLoad(true);
      getRestaurantGroupIdUpdateList(restaurantGroupId, thaiText?.trim(), engText?.trim(), selected === 'true');
    } else if (restaurantGroupUpdate.current.state === STATE_ENUM.ERROR) {
      setResponseStatus({
        status: restaurantGroupUpdate.current.state,
        message: restaurantGroupUpdate.current?.status?.message ?? '',
      });
      restaurantGroupUpdate.setState({
        state: STATE_ENUM.INITIAL,
        data: { enName: '', thName: '', groupRadio: false },
      });
      setOpen(true);
      setCheckLoad(true);
    }
    if (editRowIndex === -1) {
      setCheckLoad(false);
    }
  }, [restaurantGroupUpdate.current]);

  useEffect(() => {
    if (restaurantGroupDelete.current.state === STATE_ENUM.READY) {
      updateTablePaginationTotal(total - 1, setTotal, restaurantGroupList);
      if (restaurantGroupList.current.data?.groups.length === 1 && restaurantGroupList.current.pagination?.page! > 0) {
        setPage(restaurantGroupList.current.pagination?.page! - 1);
        setPaginationParams((prevState) => {
          return { ...prevState, page: restaurantGroupList.current.pagination?.page! - 1 };
        });
      } else {
        setPage(restaurantGroupList.current.pagination?.page!);
        setPaginationParams((prevState) => {
          return { ...prevState, page: restaurantGroupList.current.pagination?.page! };
        });
      }
      setRestaurantDropdownList(apiPathV1.restaurantDropdownList, id, { types: ['addon', 'group'] });
      setResponseStatus({
        status: 'success',
        message: restaurantGroupDelete.current?.status?.message ?? '',
      });
      restaurantGroupDelete.setState({
        state: STATE_ENUM.INITIAL,
      });
      setOpen(true);
      setOrderBy(orderBy);
      setOrder(order);
      getRestaurantGroupIdDeleteList(restaurantGroupId);
    }
  }, [restaurantGroupDelete.current]);

  // check duplicate

  const checkDuplicateThai = () => {
    const tempArray = restaurantGroupList.current.data?.groups;
    return tempArray?.find((item: RestaurantGroupsSchemaV2) => item.thName === thaiText?.trim() && prevGroup.prevThai !== thaiText?.trim());
  };

  const checkDuplicateEng = () => {
    const tempArray = restaurantGroupList.current.data?.groups;
    return tempArray?.find(
      (item: RestaurantGroupsSchemaV2) => item.enName?.toUpperCase() === engText?.trim().toUpperCase() && prevGroup.prevEng !== engText?.trim()
    );
  };

  // handle name
  const handleThaiNameTextChange = (event: any, index: number) => {
    countThaiText.current = event.target.value.trim().length;
    setEditRowIndex(index);
    setThaiText(event.target.value);
    if (event.target.value.length === 0 || !event.target.value.match(thaiChar)) {
      setThNameErrorText('Thai name is required.');
      setValidate(false);
    } else if (event.target.value.trim().length === 0) {
      setValidate(false);
      setThNameErrorText('Thai name is required.');
    } else if (event.target.value.length > 100) {
      setValidate(false);
      setThNameErrorText('Max 100 characters.');
    } else if (event.target.value.trim() === checkDuplicateThai()?.thName) {
      setThNameErrorText('This option group already exists in this restaurant.');
    } else {
      if (enNameErrorText === '') {
        setValidate(true);
      }
      setThNameErrorText('');
    }
  };
  const handleEngNameTextChange = (event: any, index: number) => {
    countEngText.current = event.target.value.trim().length;
    setEditRowIndex(index);
    setEngText(event.target.value);
    if (event.target.value.length === 0 || !event.target.value.match(engChar)) {
      setEnNameErrorText('English name is required.');
      setValidate(false);
    } else if (event.target.value.trim().length === 0) {
      setValidate(false);
      setEnNameErrorText('English name is required.');
    } else if (event.target.value.length > 100) {
      setValidate(false);
      setEnNameErrorText('Max 100 characters.');
    } else if (event.target.value.trim() === checkDuplicateEng()?.enName) {
      setEnNameErrorText('This option group already exists in this restaurant.');
    } else {
      if (thNameErrorText === '') {
        setValidate(true);
      }
      setEnNameErrorText('');
    }
  };

  const handleOutOfFocusThaiName = () => {
    if (countThaiText.current === 0) {
      setThaiText('');
    }
  };
  const handleOutOfFocusEngName = () => {
    if (countEngText.current === 0) {
      setEngText('');
    }
  };

  const handleChangeType = (event: any) => {
    setSelected(event.target.value);
  };

  const joinAddon = (row: any) => {
    const tmpAddons: string[] = [];
    if (row.addons.length === 0) {
      return 'Please add choice by clicking group name.';
    } else {
      row.addons.forEach((addon: any) => {
        tmpAddons.push(addon.thName + ' (' + addon.enName + ')');
      });
      return tmpAddons.join(', ');
    }
  };

  const handleClickRow = (groupId: string, optionName?: string) => {
    if (editRowIndex === -1) {
      navigate(groupId, { state: { optionName, restaurantName: restaurantGroupList.current.data?.restaurant?.enName } });
    }
  };

  useEffect(() => {
    if (prevGroup.prevThai === thaiText && prevGroup.prevEng === engText && prevGroup.prevTypeGroup === selected) {
      setCheckDup(false);
    } else if (checkDuplicateThai() === undefined && checkDuplicateEng() === undefined) {
      setCheckDup(true);
    } else {
      if (thaiText.trim() === checkDuplicateThai()?.thName) {
        setThNameErrorText('This option group already exists in this restaurant.');
        setCheckDup(false);
      }
      if (engText?.trim().toUpperCase() === checkDuplicateEng()?.enName?.toUpperCase()) {
        setEnNameErrorText('This option group already exists in this restaurant.');
        setCheckDup(false);
        if (checkDuplicateEng()?.enName === prevGroup.prevEng) {
          setEnNameErrorText('');
          setCheckDup(true);
        }
      }
    }
    if (countEngText.current !== 0 && countThaiText.current !== 0 && thNameErrorText.length === 0 && enNameErrorText.length === 0 && selected.length > 0) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  }, [thaiText, engText, selected]);

  return (
    <TableBody id="tbody-groups">
      {rows.map((row: RestaurantGroupsSchemaV2, index: number) => {
        return (
          <TableRow
            id={'tr-groups-' + index}
            hover
            tabIndex={index}
            key={`${row.enName}+ ${index}`}
            sx={{ width: 20 }}
            className={`editTableData ${editRowIndex === index ? 'active' : null}`}
          >
            <TableCell id={'td-groups-col1-' + index} align="left" className="editInput" onClick={() => handleClickRow(row.id, row.enName)}>
              <TextField
                id={'input-groups-thName-' + index}
                className="w-full"
                value={editRowIndex === index ? thaiText : row.thName}
                error={editRowIndex === index && thNameErrorText !== ''}
                onChange={(e) => {
                  handleThaiNameTextChange(e, index);
                }}
                onBlur={(e) => {
                  handleOutOfFocusThaiName();
                }}
                helperText={editRowIndex === index && thNameErrorText !== '' ? thNameErrorText : ''}
                tabIndex={-1}
                inputRef={editRowIndex === index ? focusTextField : null}
                ref={editRowIndex === index ? loopTextField : null}
                inputProps={{ maxLength: 101 }}
                disabled={checkLoad}
              />
            </TableCell>
            <TableCell id={'td-groups-col2-' + index} align="left" className="editInput" onClick={() => handleClickRow(row.id, row.enName)}>
              <TextField
                id={'input-groups-enName-' + index}
                className="w-full"
                value={editRowIndex === index ? engText : row.enName}
                error={editRowIndex === index && enNameErrorText !== ''}
                onChange={(e) => {
                  handleEngNameTextChange(e, index);
                }}
                onBlur={(e) => {
                  handleOutOfFocusEngName();
                }}
                helperText={editRowIndex === index && enNameErrorText !== '' ? enNameErrorText : ''}
                inputProps={{ maxLength: 101 }}
                disabled={checkLoad}
              />
            </TableCell>
            <TableCell id={'td-groups-col3-' + index} align="left" className="editInput" onClick={() => handleClickRow(row.id, row.enName)}>
              <Select
                labelId="react-dropdown-select-input"
                id="react-dropdown-select-input"
                disabled={isCheckingGroupType || checkLoad}
                value={editRowIndex === index ? selected : row.groupRadio.toString()}
                renderValue={(selected) => {
                  let Type = '';
                  if (selected === 'true') {
                    Type = 'Pick 1 item';
                  } else {
                    Type = 'Optional';
                  }
                  return Type;
                }}
                variant="outlined"
                color="secondary"
                IconComponent={KeyboardArrowDownIcon}
                onChange={handleChangeType}
                sx={{ width: '250px', height: '36px' }}
              >
                <MenuItem value={'false'}>Optional</MenuItem>
                <MenuItem value={'true'}>Pick 1 item</MenuItem>
              </Select>
            </TableCell>
            {row.addons?.length === 0 ? (
              <TableCell id={'td-groups-col4-' + index} align="left" sx={{ cursor: 'pointer' }} onClick={() => handleClickRow(row.id, row.enName)}>
                <Typography sx={{ color: '#9E9E9E' }}>{joinAddon(row)}</Typography>
              </TableCell>
            ) : (
              <TableCell id={'td-groups-col4-' + index} align="left" sx={{ cursor: 'pointer' }} onClick={() => handleClickRow(row.id, row.enName)}>
                <Typography sx={{ color: checkLoad ? '#9E9E9E' : common.black }}>{joinAddon(row)}</Typography>
              </TableCell>
            )}
            <TableCell id={'td-groups-col5-' + index} align="center" className="btnActionArea">
              <Stack direction="row" spacing={2} className="btnArea ">
                <Button
                  id={'btn-save'}
                  variant="contained"
                  color="primary"
                  size="small"
                  className="w-20"
                  disabled={!validate || !checkDup || isCheckingGroupType || checkLoad}
                  onClick={() => handleCheckTypeChangeBeforeSave(row.id)}
                >
                  Save
                </Button>
                <Button
                  id={'btn-cancel'}
                  variant="outlined"
                  color="primary"
                  size="small"
                  className="w-20"
                  onClick={() => handleCancel()}
                  disabled={isCheckingGroupType || checkLoad}
                >
                  Cancel
                </Button>
              </Stack>

              <Stack direction="row-reverse" spacing={2} className="btnAreaAction" justifyContent="flex-end">
                <Tooltip title="Delete" placement="bottom-start">
                  <Button
                    id="btn-delete-option-group"
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => handleOpenDelete(row.id, id)} // row.id = groupId , id = restaurantId
                    disabled={isCheckingGroupType || checkLoad}
                  >
                    <DeleteIcon></DeleteIcon>
                  </Button>
                </Tooltip>
                <Tooltip title="Edit info" placement="bottom-start">
                  <Button
                    id="btn-edit-option-group"
                    variant="outlined"
                    color="primary"
                    size="small"
                    className="whenHoverPointer"
                    onClick={() => handleEditClick(index, row.thName, row.enName, row.groupRadio.toString())}
                    disabled={isCheckingGroupType || checkLoad}
                  >
                    <EditIcon></EditIcon>
                  </Button>
                </Tooltip>
              </Stack>
            </TableCell>
          </TableRow>
        );
      })}

      {/* NOTE: this TableRow is hidden, just for allow table to place modal inside */}
      <TableRow id={'tr-groups-modal'}>
        <TableCell id={'td-groups-modal'} sx={{ padding: '0px', border: 'none' }}>
          <Modal
            id={'modal-groups'}
            openModal={openModal}
            type={type}
            setOpenModal={setOpenModal}
            restaurantGroupId={restaurantGroupId}
            restaurantId={restaurantId || ''}
            restaurantGroupDelete={restaurantGroupDelete}
            setType={setType}
          ></Modal>

          <ModalCancelOrderByGroupTypeChange
            open={openCancelOrdersModal}
            groupTypeChangeOrdersCount={groupTypeChangeOrdersCount}
            onConfirm={() => {
              if (currentCheckingGroupId) handleClickSave(currentCheckingGroupId);
              setCurrentCheckingGroupId(undefined);
              setGroupTypeChangeOrdersCount(0);
              setOpenCancelOrdersModal(false);
            }}
            onCancel={() => {
              setGroupTypeChangeOrdersCount(0);
              setOpenCancelOrdersModal(false);
              setCheckLoad(false);
            }}
          />
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
