import React, { useEffect, useState } from 'react';

import { FormControl, Box, Container, Paper, Table, TableContainer, Typography } from '@mui/material';

import { EnhancedTableBodyOrderHistory, LoadingIcon, BasicMonthPicker, EnhancedTableHead, ConditionalWrapper } from '@/components/index';
import { order } from '@/types/index';

import { headCellsOrderHistory } from '@/static/orderHistory/';

import { BasicYearHistoryPicker } from '@/components/ui/basicYearHistoryPicker';
import { ordersHistorySchema } from '@/types/schema/ordersHistory';

import noOne from '@/images/noOne.png';
import { useOrderHistoryContextV2 } from '@/src/services';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import TableBodyNodata from '../../components/ui/tableBodyNoData';

const PAGE_TITLE = 'Order history';

export function OrderHistory(props: any) {
  // SORTING / PAGINATION
  const { year, month, fetchOrdersHistoryList } = useOrderHistoryContextV2();
  const [order, setOrder] = useState<order | null>('asc');
  const [orderBy, setOrderBy] = useState<keyof ordersHistorySchema>('date');

  const onSetYear = (v: string) => {
    fetchOrdersHistoryList.requestAct(v, month.current);
  };

  const onSetMonth = (v: string) => {
    fetchOrdersHistoryList.requestAct(year.current, v);
  };

  useEffect(() => {
    if (fetchOrdersHistoryList.current.state === STATE_ENUM.INITIAL) {
      fetchOrdersHistoryList.requestAct(year.current, month.current);
    }
  }, []);

  return (
    <Container maxWidth="xl">
      <Box className="pt-8 flex flex-row justify-between items-start">
        <Typography id="page-title" variant="h1">
          {PAGE_TITLE}
        </Typography>
      </Box>
      <Box
        py={1}
        component="form"
        sx={{
          '& > :not(style)': { m: '16px', ml: '0px' },
        }}
        noValidate
        autoComplete="off"
      >
        <FormControl
          variant="outlined"
          size="small"
          color="secondary"
          sx={{
            width: '25ch',
          }}
        >
          <BasicMonthPicker Month={month.current} setMonth={onSetMonth}></BasicMonthPicker>
        </FormControl>
        <FormControl
          variant="outlined"
          size="small"
          color="secondary"
          sx={{
            width: '25ch',
          }}
        >
          <BasicYearHistoryPicker Year={year.current} setYear={onSetYear}></BasicYearHistoryPicker>
        </FormControl>
      </Box>
      {/* TABLE */}
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
        <ConditionalWrapper condition={fetchOrdersHistoryList?.current.state === 'ready' && !!fetchOrdersHistoryList.current.data?.historyList?.length}>
          <Paper elevation={0} sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="small"
                sx={{
                  minWidth: 750,
                }}
              >
                <EnhancedTableHead
                  headCells={headCellsOrderHistory}
                  order={order}
                  orderBy={orderBy}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  disabledSorting={true}
                />
                <EnhancedTableBodyOrderHistory rows={fetchOrdersHistoryList?.current.data?.historyList} order={order} orderBy={orderBy} />
              </Table>
            </TableContainer>
          </Paper>
        </ConditionalWrapper>
        <ConditionalWrapper
          condition={
            fetchOrdersHistoryList.current.state === 'error' ||
            (fetchOrdersHistoryList.current.state === 'ready' && !fetchOrdersHistoryList.current.data?.historyList?.length)
          }
        >
          <Paper elevation={0} sx={{ width: '100%', mb: 2 }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between"
              sx={{ minHeight: '50vh', border: 1, borderRadius: '4px', borderColor: '#E0E0E0' }}
            >
              <TableContainer sx={{ border: 'none' }}>
                <Table aria-labelledby="tableTitle" size="small">
                  <EnhancedTableHead
                    headCells={headCellsOrderHistory}
                    order={order}
                    orderBy={orderBy}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                    disabledSorting={fetchOrdersHistoryList.current.data?.historyList?.length === 0}
                  />
                  <TableBodyNodata page={'orderHistory'} src={noOne} title="No results found." />
                </Table>
              </TableContainer>
            </Box>
          </Paper>
        </ConditionalWrapper>
        <ConditionalWrapper condition={fetchOrdersHistoryList.current.state === 'loading'}>
          <LoadingIcon setCenter={false}></LoadingIcon>
        </ConditionalWrapper>
      </Box>
    </Container>
  );
}
