import React from 'react';
import { CardContent, Typography } from '@mui/material';
import { notificationsSchema } from '@/types/schema/notifications.d';

type Props = {
  notification?: notificationsSchema;
  title?: string;
  description?: string;
  open: boolean;
  onClose: () => void;
};

export default function ModalDeleteNotification({ notification, open, onClose, title, description }: Props) {
  return (
    <CardContent sx={(theme) => ({ padding: 0 })}>
      <Typography variant="h4" sx={(theme) => ({ marginBottom: theme.spacing(2) })}>
        {title ?? 'Are you sure you want to delete the notification?'}
      </Typography>
      <Typography variant="body1" sx={(theme) => ({ marginBottom: theme.spacing(0) })}>
        {description ?? 'This action will permanently delete the notification and its data, and cannot be undone.'}
      </Typography>
    </CardContent>
  );
}
