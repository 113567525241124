import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import {
  GetRestaurantBookingList,
  PostRestaurantBooking,
  PostRestaurantBookingList,
  PutRestaurantBooking,
  PutRestaurantBookingStatus,
  RemoveRestaurantBooking,
} from '@/types/crud';
import { apiMethodV1 } from '@/app.constants/apiCallMethods/index';
import { createRestaurantBookingSchema } from '@/types/schema/createRestaurantBooking';
import { updateRestaurantBookingSchema } from '@/types/schema/updateRestaurantBooking';
import { updateRestaurantBookingStatusSchema } from '@/types/schema/updateRestaurantBookingStatus';

/**
 * @private This api is protected and can only be accessed as role=ADMIN
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
const RestaurantBookingListGet = (prefix: string, params?: { fromDate: string; toDate: string }, hardRejection = true): Promise<GetRestaurantBookingList> => {
  const instance = new AxiosService({ method: apiMethodV1.get, timeout: 12000 }, true, hardRejection);
  // token not yet set on backend

  const config = {
    ...instance.config,
    params,
  };

  return instance.client
    .get(prefix, config)
    .then((d: AxiosResponse<GetRestaurantBookingList>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetRestaurantBookingList>;
};

const RestaurantBookingCreate = (prefix: string, body: createRestaurantBookingSchema, hardRejection = true): Promise<PostRestaurantBooking> => {
  const instance = new AxiosService({ method: apiMethodV1.post, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .post(prefix, JSON.stringify(body), config)
    .then((d: AxiosResponse<PostRestaurantBooking>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<PostRestaurantBooking>;
};

const RestaurantBookingCreateList = (prefix: string, body: createRestaurantBookingSchema[], hardRejection = true): Promise<PostRestaurantBookingList> => {
  const instance = new AxiosService({ method: apiMethodV1.post, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .post(prefix, JSON.stringify(body), config)
    .then((d: AxiosResponse<PostRestaurantBookingList>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<PostRestaurantBookingList>;
};

const RestaurantBookingUpdate = (
  prefix: string,
  body: updateRestaurantBookingSchema,
  bookingId: string,
  hardRejection = true
): Promise<PutRestaurantBooking> => {
  const instance = new AxiosService({ method: apiMethodV1.put, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .put(prefix + bookingId, JSON.stringify(body), config)
    .then((d: AxiosResponse<PutRestaurantBooking>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<PutRestaurantBooking>;
};

const RestaurantBookingStatusUpdate = (
  prefix: string,
  body: updateRestaurantBookingStatusSchema,
  bookingId: string,
  hardRejection = true
): Promise<PutRestaurantBookingStatus> => {
  const instance = new AxiosService({ method: apiMethodV1.put, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .put(prefix + bookingId, JSON.stringify(body), config)
    .then((d: AxiosResponse<PutRestaurantBookingStatus>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<PutRestaurantBookingStatus>;
};

const RestaurantBookingRemove = (prefix: string, bookingId: string, hardRejection = true): Promise<RemoveRestaurantBooking> => {
  const instance = new AxiosService({ method: apiMethodV1.delete, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .delete(prefix + bookingId, config)
    .then((d: AxiosResponse<RemoveRestaurantBooking>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<RemoveRestaurantBooking>;
};

export {
  RestaurantBookingListGet,
  RestaurantBookingCreate,
  RestaurantBookingCreateList,
  RestaurantBookingUpdate,
  RestaurantBookingStatusUpdate,
  RestaurantBookingRemove,
};
