import { ActionUnit2, commonSetError, initialApiContext2, initialStateContext } from '../common';
import { CommonCrud } from '@/src/types';
import { createContext, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { ordersHistoryListGet } from '../../http/orderHistory.v2';
import { apiPathV2 } from '@/src/app.constants/apiCallPaths/api.path.v2';
import { OrderHistoryList } from '@/src/types/crud/orderHistory/orderHistory';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';

declare namespace Types {
  export type SelectedMonth = string;
  export type SelectedYear = string;
  export type OrdersHistoryList = CommonCrud<OrderHistoryList>;
  export namespace RequestAct {
    export type OrdersHistoryList = (year: string, month: string) => void;
  }
}

interface IOrdersHistoryContextV2 {
  fetchOrdersHistoryList: ActionUnit2<Types.OrdersHistoryList> & { requestAct: Types.RequestAct.OrdersHistoryList };
  year: ActionUnit2<Types.SelectedMonth>;
  month: ActionUnit2<Types.SelectedYear>;
}

const initialState: IOrdersHistoryContextV2 = {
  month: { ...initialStateContext<Types.SelectedMonth>() },
  year: { ...initialStateContext<Types.SelectedYear>() },
  fetchOrdersHistoryList: { ...initialApiContext2<Types.OrdersHistoryList, Types.RequestAct.OrdersHistoryList>() },
};

const OrderHistoryContextV2: React.Context<IOrdersHistoryContextV2> = createContext(initialState);
function useOrderHistoryContextV2() {
  return useContext(OrderHistoryContextV2);
}

const OrderHistoryContextV2Provider = ({ children }: { children: React.ReactNode }) => {
  const now = moment();

  const [OrderHistoryList, setOrdersHistoryList] = useState<CommonCrud<OrderHistoryList>>(initialState.fetchOrdersHistoryList.current);
  const [year, setYear] = useState<Types.SelectedYear>(now.format('YYYY'));
  const [month, setMonth] = useState<Types.SelectedMonth>(now.format('MMMM'));

  const getOrderHistoryList: Types.RequestAct.OrdersHistoryList = (year: string, month: string) => {
    setOrdersHistoryList({ ...initialState.fetchOrdersHistoryList.current, state: STATE_ENUM.LOADING });
    setMonth(month);
    setYear(year);

    const m = moment(`${year} ${month}`, 'YYYY MMMM');
    const fromDate = m.startOf('month').format('yyyy-MM-DD');
    const toDate = m.endOf('month').format('yyyy-MM-DD');
    ordersHistoryListGet<Types.OrdersHistoryList>(apiPathV2.ordersHistory, { fromDate, toDate })
      .then(({ status, data }) => setOrdersHistoryList({ state: STATE_ENUM.READY, status, data }))
      .catch((error) => commonSetError(error, setOrdersHistoryList));
  };

  const ordersHistoryStore: IOrdersHistoryContextV2 = {
    fetchOrdersHistoryList: {
      current: OrderHistoryList,
      setState: setOrdersHistoryList,
      requestAct: getOrderHistoryList,
    },
    year: {
      current: year,
      setState: setYear,
    },
    month: {
      current: month,
      setState: setMonth,
    },
  };

  useEffect(() => {
    return () => {
      setOrdersHistoryList(initialState.fetchOrdersHistoryList.current);
    };
  }, []);

  return <OrderHistoryContextV2.Provider value={ordersHistoryStore}>{children}</OrderHistoryContextV2.Provider>;
};

export { useOrderHistoryContextV2, OrderHistoryContextV2Provider };
export type { Types as OrderHistoryTypes };
