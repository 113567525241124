import { HeadCell } from '@/types/enhancedTable.d';

export const headCellsOrderHistory: HeadCell[] = [
  {
    id: 'date',
    numeric: false,
    label: 'Date',
    disablePadding: false,
    sortAble: false,
    width: 1 / 6,
  },
  {
    id: 'name',
    label: 'Restaurant',
    numeric: false,
    disablePadding: false,
    sortAble: false,
    width: 2 / 6,
  },
  {
    id: 'orderTotal',
    numeric: true,
    label: 'Total orders',
    disablePadding: false,
    sortAble: false,
    width: 1 / 6,
  },
  {
    id: 'priceTotal',
    numeric: true,
    label: 'Total price',
    disablePadding: false,
    sortAble: false,
    width: 1 / 6,
  },
];
