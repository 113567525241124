/** declare only http service related requests */

import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import { QueryOverBudgetOrderByMenu, QueryParamsGetList, QueryMenuListSortBy } from '@/types/crud';
import { apiMethodV1 } from '@/app.constants/apiCallMethods/index';
import { RestaurantMenuCreateBody } from '@/types/schema/body';
import { RestaurantMenuBody } from '@/types/index';
import { paramsQueryString } from '@/src/utils';
import { createFallbackMealSchema } from '@/src/types/schema/createFallbackMeal';
import { updateFallbackMealSchema } from '@/src/types/schema/updateFallbackMeal';

/**
 * @private This api is protected and can only be accessed as role=ADMIN
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
const RestaurantMenuListGetV2 = <T>(
  prefix: string,
  restaurantId: string,
  params: QueryParamsGetList<QueryMenuListSortBy>,
  hardRejection = true
): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.get, timeout: 12000 }, true, hardRejection);
  // token not yet set on backend

  const config = {
    ...instance.config,
  };

  const queryUri = paramsQueryString(prefix + restaurantId, params);

  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

const RestaurantMenuListForOrderGetV2 = <T>(prefix: string, restaurantId: string, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.get, timeout: 12000 }, true, hardRejection);
  // token not yet set on backend

  const config = {
    ...instance.config,
  };
  return instance.client
    .get(prefix + restaurantId, config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

const RestaurantMenuCreateV2 = <T>(prefix: string, restaurantId: string | undefined, body: RestaurantMenuCreateBody, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.post, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .post(prefix + restaurantId, JSON.stringify(body), config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

const RestaurantMenuUpdateV2 = <T>(prefix: string, restaurantId: string, menuId: string, body: RestaurantMenuBody, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.put, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .put(prefix + restaurantId + '/' + menuId, JSON.stringify(body), config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

const RestaurantMenuDeleteV2 = <T>(prefix: string, restaurantId: string, menuId: string, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.delete, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .delete<T>(prefix + restaurantId + '/' + menuId, config)
    .then((d) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

const OrderOverBudgetByMenuCheckV2 = <T>(
  prefix: string,
  restaurantId: string,
  menuId: string,
  params: QueryOverBudgetOrderByMenu,
  hardRejection = true
): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.post, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  /** @api /menu-items/orders/over-budget/{restaurantId}/{menuId}?price= */
  const queryUri = paramsQueryString(`${prefix}${restaurantId}/${menuId}`, params);

  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

const FallbackMealCheck = <PostDefaultMenu>(prefix: string, body: createFallbackMealSchema, hardRejection = true): Promise<PostDefaultMenu> => {
  const instance = new AxiosService({ method: apiMethodV1.post, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .post(prefix, JSON.stringify(body), config)
    .then((res: AxiosResponse<PostDefaultMenu>) => {
      return res.data;
    })
    .catch(instance.defaultHandleError) as Promise<PostDefaultMenu>;
};

const FallbackMealChange = <UpdateDefaultMenu>(prefix: string, body: updateFallbackMealSchema, hardRejection = true): Promise<UpdateDefaultMenu> => {
  const instance = new AxiosService({ method: apiMethodV1.put, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .post(prefix, JSON.stringify(body), config)
    .then((res: AxiosResponse<UpdateDefaultMenu>) => {
      return res.data;
    })
    .catch(instance.defaultHandleError) as Promise<UpdateDefaultMenu>;
};

export {
  RestaurantMenuListGetV2,
  RestaurantMenuListForOrderGetV2,
  RestaurantMenuCreateV2,
  RestaurantMenuUpdateV2,
  RestaurantMenuDeleteV2,
  OrderOverBudgetByMenuCheckV2,
  FallbackMealCheck,
  FallbackMealChange,
};
