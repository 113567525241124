/** Validates user input inside MUI textfield
   * 
  @param input user input
  @param maxChars validates maximum number of chars allowed in field
  @param fieldName name of the user input field (for use with error message)
  @param required field is required flag
  @returns object with the validated input to be set, error flag and error message
**/

// TODO: add language validations

export const validateTextField = (input: string, maxChars: number = 100, fieldName: string = 'Field', required: boolean = false) => {
  if (input.length > maxChars) {
    // check length
    return { value: input.slice(0, maxChars + 1), error: true, msg: `Max ${maxChars} characters.` };
  } else if (input.match(/^ *$/) && required) {
    // check for whitespace only
    return { value: input, error: true, msg: `${fieldName} is required.` };
  }
  return { value: input, error: false, msg: '' };
};
