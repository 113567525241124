import { Box, Typography, useMediaQuery } from '@mui/material';
import React, { FC } from 'react';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import { DashBoardSummaryResponse } from '@/src/types/crud/dashboard/dashboard.summary';
import { formatNumber } from '@/src/utils/formatNumber/formatNumber';
import totalOrderIcon from '@/src/images/orders-icon-x2.png';
import totalRestaurantIcon from '@/src/images/restaurant-icon.png';

interface Props {
  data: DashBoardSummaryResponse | undefined;
}

const SummaryData: FC<Props> = ({ data }) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isSmallScreen2 = useMediaQuery('(max-width:1024px)');

  return (
    <Box>
      <Box sx={{ paddingRight: 2, mb: 2 }}>
        <Typography variant="h1">Summary</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
          width: '100%',
          flexWrap: isSmallScreen ? 'wrap' : 'nowrap',
        }}
      >
        {[
          {
            bgcolor: '#D5E3E1',
            color: '#3C7669',
            icon: <img src={totalRestaurantIcon} alt="Total Orders" style={{ width: 35, height: 35 }} />,
            label: 'Total Restaurants',
            value: formatNumber(data?.summary.restaurants || 0),
          },
          {
            bgcolor: '#D7EEEC',
            color: '#52AB9F',
            icon: <img src={totalOrderIcon} alt="Total Orders" style={{ width: 35, height: 35 }} />,
            label: 'Total Orders',
            value: formatNumber(data?.summary.orders || 0),
          },
          {
            bgcolor: '#FBE3D6',
            color: '#E35B0F',
            icon: <InsightsOutlinedIcon sx={{ fontSize: 35 }} />,
            label: 'Total Costs',
            value: `฿ ${formatNumber(data?.summary.costs || 0)}`,
          },
        ].map((item, index) => (
          <Box
            key={index}
            sx={{
              bgcolor: item.bgcolor,
              color: item.color,
              border: 2.5,
              borderRadius: 3,
              width: 450,
              padding: 1,
              height: 87,
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography mb={2} sx={{ fontWeight: 900, fontSize: isSmallScreen2 ? 16 : 20 }}>
                {item.label}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 60,
                height: 60,
                borderRadius: '50%',
                bgcolor: item.color,
                color: '#fff',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                right: 4,
              }}
            >
              {item.icon}
            </Box>
            <Box
              sx={{
                position: 'absolute',
                bottom: 8,
                left: 8,
              }}
            >
              <Typography sx={{ fontWeight: 900, fontSize: 30 }}>{item.value}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SummaryData;
