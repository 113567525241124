import {
  CommonCrud,
  CommonCrudWithError,
  DeleteFailByOrdersError,
  GetOrderOverBudgetByAddonOption,
  PaginatedCrud,
  QueryOrderOverBudgetByAddonOptionPrice,
  QueryParamsGetList,
} from '@/src/types';
import { ActionUnit2, commonSetError, initialApiContext2 } from '../common';
import { Addon, AddonRequest, GetRestaurantAddOnListV2, Group, Restaurant } from '@/src/types/crud/optionAddon/optionAddon';
import { OptionAddonOrderByType } from '@/src/types/crud/optionAddon/optionAddon.queries';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { OptionAddonCreateResponse, OptionAddonDeleteResponse, OptionAddonUpdateResponse } from '@/src/types/crud/optionAddon/optionAddon.response';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import { DEFAULT_ROW_PER_PAGE, apiPathV1 } from '@/src/app.constants';
import {
  RestaurantOptionAddonCreate,
  RestaurantOptionAddonDelete,
  RestaurantOptionAddonListGetV2,
  RestaurantOptionAddonUpdate,
} from '../../http/optionAddon.v2';
import { OrderOverBudgetByOptionCheck } from '../../http';

declare namespace Types {
  export type RestaurantOptionAddonList = PaginatedCrud<GetRestaurantAddOnListV2>;
  export type RestaurantOptionAddonCreate = CommonCrud<OptionAddonCreateResponse>;
  export type RestaurantOptionAddonUpdate = CommonCrud<OptionAddonUpdateResponse>;
  export type RestaurantOptionAddonDelete = CommonCrudWithError<OptionAddonDeleteResponse, CommonCrud<DeleteFailByOrdersError>>;
  export type OverBudgetOrdersByAddonOption = GetOrderOverBudgetByAddonOption;
  export namespace RequestAct {
    export type RestaurantOptionAddonList = (restaurantId: string, groupId: string, params?: QueryParamsGetList<OptionAddonOrderByType>) => void;
    export type RestaurantOptionAddonCreate = (restaurantId: string, groupId: string, body: AddonRequest) => void;
    export type RestaurantOptionAddonUpdate = (restaurantId: string, groupId: string, addonId: string, body: AddonRequest) => void;
    export type RestaurantOptionAddonDelete = (restaurantId: string, groupId: string, addonId: string) => void;
    export type OverBudgetOrdersByAddonOption = (
      restaurantId: string,
      groupId: string,
      addonId: string,
      params: QueryOrderOverBudgetByAddonOptionPrice
    ) => void;
  }
  export namespace CustomAct {
    export type RestaurantOptionAddonUpdateList = (optionAddonId: string, thName: string, enName: string, price: number) => void;
    export type RestaurantOptionAddonDeleteList = (optionAddonId: string) => void;
  }
}

interface IRestaurantOptionAddonContextV2 {
  optionAddonList: ActionUnit2<Types.RestaurantOptionAddonList> & { requestAct: Types.RequestAct.RestaurantOptionAddonList };
  refreshOptionAddonList: ActionUnit2<Types.RestaurantOptionAddonList> & { requestAct: Types.RequestAct.RestaurantOptionAddonList };
  optionAddonCreate: ActionUnit2<Types.RestaurantOptionAddonCreate> & { requestAct: Types.RequestAct.RestaurantOptionAddonCreate };
  optionAddonUpdate: ActionUnit2<Types.RestaurantOptionAddonUpdate> & { requestAct: Types.RequestAct.RestaurantOptionAddonUpdate };
  optionAddonDelete: ActionUnit2<Types.RestaurantOptionAddonDelete> & { requestAct: Types.RequestAct.RestaurantOptionAddonDelete };
  overBudgetOrdersByAddonOption: ActionUnit2<Types.OverBudgetOrdersByAddonOption> & { requestAct: Types.RequestAct.OverBudgetOrdersByAddonOption };
  getOptionAddonUpdateList: Types.CustomAct.RestaurantOptionAddonUpdateList;
  getOptionAddonDeleteList: Types.CustomAct.RestaurantOptionAddonDeleteList;
}

const initialState: IRestaurantOptionAddonContextV2 = {
  optionAddonList: { ...initialApiContext2<Types.RestaurantOptionAddonList, Types.RequestAct.RestaurantOptionAddonList>() },
  refreshOptionAddonList: { ...initialApiContext2<Types.RestaurantOptionAddonList, Types.RequestAct.RestaurantOptionAddonList>() },
  optionAddonCreate: { ...initialApiContext2<Types.RestaurantOptionAddonCreate, Types.RequestAct.RestaurantOptionAddonCreate>() },
  optionAddonUpdate: { ...initialApiContext2<Types.RestaurantOptionAddonUpdate, Types.RequestAct.RestaurantOptionAddonUpdate>() },
  optionAddonDelete: { ...initialApiContext2<Types.RestaurantOptionAddonDelete, Types.RequestAct.RestaurantOptionAddonDelete>() },
  overBudgetOrdersByAddonOption: { ...initialApiContext2<Types.OverBudgetOrdersByAddonOption, Types.RequestAct.OverBudgetOrdersByAddonOption>() },
  getOptionAddonUpdateList: (optionAddonId: string, thName: string, enName: string, price: number) => {},
  getOptionAddonDeleteList: (optionAddonId: string) => {},
};

const restaurantOptionAddonContextV2: React.Context<IRestaurantOptionAddonContextV2> = createContext(initialState);

function useRestaurantOptionAddonContextV2() {
  return useContext(restaurantOptionAddonContextV2);
}

const RestaurantOptionAddonContextV2Provider = ({ children }: { children: React.ReactNode }) => {
  const [refreshOptionAddonList, setRefreshOptionAddonList] = useState<Types.RestaurantOptionAddonList>(initialState.optionAddonList.current);
  const [optionAddonList, setOptionAddonList] = useState<Types.RestaurantOptionAddonList>(initialState.optionAddonList.current);
  const [optionAddonCreate, setOptionAddonCreate] = useState<Types.RestaurantOptionAddonCreate>(initialState.optionAddonCreate.current);
  const [optionAddonUpdate, setOptionAddonUpdate] = useState<Types.RestaurantOptionAddonUpdate>(initialState.optionAddonUpdate.current);
  const [optionAddonDelete, setOptionAddonDelete] = useState<Types.RestaurantOptionAddonDelete>(initialState.optionAddonDelete.current);
  const [overBudgetOrdersByAddonOption, setOverBudgetOrdersByAddonOption] = useState<Types.OverBudgetOrdersByAddonOption>({ state: STATE_ENUM.INITIAL });

  const getOptionAddonList: Types.RequestAct.RestaurantOptionAddonList = (restaurantId, groupId, params = { page: 0, itemPerPage: DEFAULT_ROW_PER_PAGE }) => {
    setOptionAddonList({ ...initialState.optionAddonList.current, state: STATE_ENUM.LOADING });
    RestaurantOptionAddonListGetV2<Types.RestaurantOptionAddonList>(restaurantId, groupId, params)
      .then(({ status, data, pagination }) => {
        setOptionAddonList({
          state: STATE_ENUM.READY,
          status,
          data,
          pagination,
        });
      })
      .catch((error) => commonSetError(error, setOptionAddonList));
  };

  const getRefreshOptionAddonList: Types.RequestAct.RestaurantOptionAddonList = (
    restaurantId,
    groupId,
    params = { page: 0, itemPerPage: DEFAULT_ROW_PER_PAGE }
  ) => {
    setOptionAddonList({ ...initialState.optionAddonList.current, state: STATE_ENUM.LOADING });
    RestaurantOptionAddonListGetV2<Types.RestaurantOptionAddonList>(restaurantId, groupId, params)
      .then(({ status, data, pagination }) => {
        setRefreshOptionAddonList({
          state: STATE_ENUM.READY,
          status,
          data,
          pagination,
        });
      })
      .catch((error) => commonSetError(error, setRefreshOptionAddonList));
  };

  const createOptionAddon: Types.RequestAct.RestaurantOptionAddonCreate = (restaurantId, groupId, payload) => {
    setOptionAddonCreate({ ...initialState.optionAddonCreate.current, state: STATE_ENUM.LOADING });
    RestaurantOptionAddonCreate<Types.RestaurantOptionAddonCreate>(restaurantId, groupId, payload)
      .then(({ status, data }) => {
        setOptionAddonCreate({
          state: STATE_ENUM.READY,
          status,
          data,
        });
      })
      .catch((error) => commonSetError(error, setOptionAddonCreate));
  };

  const updateOptionAddon: Types.RequestAct.RestaurantOptionAddonUpdate = (restaurantId, groupId, addonId, payload) => {
    setOptionAddonUpdate({ ...initialState.optionAddonCreate.current, state: STATE_ENUM.LOADING });
    RestaurantOptionAddonUpdate<Types.RestaurantOptionAddonUpdate>(restaurantId, groupId, addonId, payload)
      .then(({ status, data }) => {
        setOptionAddonUpdate({
          state: STATE_ENUM.READY,
          status,
          data,
        });
      })
      .catch((error) => commonSetError(error, setOptionAddonUpdate));
  };

  const deleteOptionAddon: Types.RequestAct.RestaurantOptionAddonDelete = (restaurantId, groupId, addonId) => {
    setOptionAddonDelete({ ...initialState.optionAddonDelete.current, state: STATE_ENUM.LOADING });
    RestaurantOptionAddonDelete<Types.RestaurantOptionAddonDelete>(restaurantId, groupId, addonId)
      .then(({ status, data }) => {
        setOptionAddonDelete({
          state: STATE_ENUM.READY,
          status,
          data,
        });
      })
      .catch((error) => commonSetError(error, setOptionAddonDelete));
  };

  // action for change optionAddon after update
  const getOptionAddonUpdateList: Types.CustomAct.RestaurantOptionAddonUpdateList = (optionAddonId, thName, enName, price) => {
    const startArray = optionAddonList.data;
    const optionAddons = startArray?.addon.map((item: Addon) => (item.id === optionAddonId ? { ...item, thName, enName, price } : { ...item })) as Addon[];

    setOptionAddonList({
      state: STATE_ENUM.READY,
      pagination: optionAddonList.pagination,
      data: {
        restaurant: optionAddonList.data?.restaurant as Restaurant,
        group: optionAddonList.data?.group as Group,
        addon: optionAddons,
      } as GetRestaurantAddOnListV2,
    });
  };

  const getOptionAddonDeleteList = (optionAddonId: string) => {
    const startArray = optionAddonList.data;
    const optionAddons = startArray?.addon.filter((item: any) => (item.id !== optionAddonId ? { ...item } : false));

    setOptionAddonList({
      state: STATE_ENUM.READY,
      pagination: optionAddonList.pagination,
      data: {
        restaurant: optionAddonList.data?.restaurant as Restaurant,
        group: optionAddonList.data?.group as Group,
        addon: optionAddons,
      } as GetRestaurantAddOnListV2,
    });
  };

  const getOverBudgetOrdersByAddonOption = (restaurantId: string, groupId: string, optionAddonId: string, params: QueryOrderOverBudgetByAddonOptionPrice) => {
    setOverBudgetOrdersByAddonOption({ state: 'loading' });
    OrderOverBudgetByOptionCheck(apiPathV1.overBudgetOrdersByOption, restaurantId, groupId, optionAddonId, params)
      .then((data) => {
        setOverBudgetOrdersByAddonOption({
          data: data.data,
          state: 'ready',
          status: data.status,
        });
      })
      .catch((error) => {
        setOverBudgetOrdersByAddonOption({ data: undefined, error: error?.response, state: 'error', status: error?.response?.data?.status });
      });
  };

  const restaurantOptionAddonStore: IRestaurantOptionAddonContextV2 = {
    refreshOptionAddonList: {
      current: refreshOptionAddonList,
      setState: setRefreshOptionAddonList,
      requestAct: getRefreshOptionAddonList,
    },
    optionAddonList: {
      current: optionAddonList,
      setState: setOptionAddonList,
      requestAct: getOptionAddonList,
    },
    optionAddonCreate: {
      current: optionAddonCreate,
      setState: setOptionAddonCreate,
      requestAct: createOptionAddon,
    },
    optionAddonUpdate: {
      current: optionAddonUpdate,
      setState: setOptionAddonUpdate,
      requestAct: updateOptionAddon,
    },
    optionAddonDelete: {
      current: optionAddonDelete,
      setState: setOptionAddonDelete,
      requestAct: deleteOptionAddon,
    },
    overBudgetOrdersByAddonOption: {
      current: overBudgetOrdersByAddonOption,
      setState: setOverBudgetOrdersByAddonOption,
      requestAct: getOverBudgetOrdersByAddonOption,
    },
    getOptionAddonUpdateList,
    getOptionAddonDeleteList,
  };

  useEffect(() => {
    return () => {
      /*
         NOTE: cancel all subscriptions and asynchronous tasks to cleanup function and fix Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
        */
      setOptionAddonList(initialState.optionAddonList.current);
      setOptionAddonCreate(initialState.optionAddonCreate.current);
      setOptionAddonUpdate(initialState.optionAddonUpdate.current);
      setOptionAddonDelete(initialState.optionAddonDelete.current);
    };
  }, []);

  return <restaurantOptionAddonContextV2.Provider value={restaurantOptionAddonStore}>{children}</restaurantOptionAddonContextV2.Provider>;
};

export { useRestaurantOptionAddonContextV2, RestaurantOptionAddonContextV2Provider };
export type { Types as OptionAddonTypes };
