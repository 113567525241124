import { useCallback, useMemo, useState } from 'react';

export const SELECT_ALL_STATE_ENUM = {
  ALL: 'ALL',
  EMPTY: 'EMPTY',
  INDETERMINATE: 'INDETERMINATE',
};

interface useSelectAllProps {
  data: string[];
}

const useSelectAll = (props: useSelectAllProps) => {
  const [selects, setSelects] = useState<string[]>([]);

  const handleSelect = useCallback(
    (value: string) => {
      const haveSelect = selects.find((i) => i === value);
      if (haveSelect) {
        setSelects((prev) => prev.filter((i) => i !== haveSelect));
      } else {
        setSelects((prev) => [...prev, value]);
      }
    },
    [selects]
  );

  const handleSelectAll = useCallback(
    (val: boolean) => {
      if (val) {
        const selectValues = props.data.map((i) => i) || [];
        setSelects((prev) => [...new Set([...prev, ...selectValues])]);
      } else {
        const selectValues = selects.filter((i) => !props.data.includes(i));
        setSelects(selectValues);
      }
    },
    [selects, props.data]
  );

  const handleClearSelect = useCallback(
    (ids?: string[]) => {
      if (ids && ids.length > 0) {
        setSelects((prev) => prev.filter((i) => !ids.includes(i)));
      } else {
        setSelects([]);
      }
    },
    [selects, props.data]
  );

  const selectAllStatus = useMemo(() => {
    const haveInData = props.data.filter((i) => selects.includes(i));
    if (haveInData.length <= 0) {
      return SELECT_ALL_STATE_ENUM.EMPTY;
    }
    if (haveInData.length < props.data.length) {
      return SELECT_ALL_STATE_ENUM.INDETERMINATE;
    }

    return SELECT_ALL_STATE_ENUM.ALL;
  }, [selects, props.data]);

  const currentSelected = useMemo(() => {
    return props.data.filter((i) => selects.includes(i));
  }, [selects, props.data]);

  return {
    selects,
    handleSelect,
    handleSelectAll,
    handleClearSelect,
    selectAllStatus,
    currentSelected,
  };
};

export default useSelectAll;
