import React from 'react';
import { Backdrop, Box, Button, Typography } from '@mui/material';

const modalStyle = {
  position: 'fixed',
  margin: 'auto',
  top: '60px',
  width: 495,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  display: 'grid',
  alignContent: 'stretch',
  justifyContent: 'space - around',
  alignItems: 'start',
  justifyItems: 'center',
};

interface ModalCancelOrderByGroupTypeChangeProps {
  groupTypeChangeOrdersCount: number;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}
export default function ModalCancelOrderByGroupTypeChange(props: ModalCancelOrderByGroupTypeChangeProps) {
  const { groupTypeChangeOrdersCount, open, onConfirm, onCancel } = props;

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <Box sx={modalStyle} className="mt-10">
        <Typography
          sx={{ color: '#000', fontSize: '20px', width: 'inherit', fontWeight: 'bold', marginBottom: '13px' }}
          id="transition-modal-title"
          variant="h5"
          component="h2"
        >
          Group type changing order(s) notice
        </Typography>
        <Typography variant="caption" sx={{ color: '#000', fontSize: '16px' }}>
          There will be {groupTypeChangeOrdersCount} order(s) effected when you update the group type. Are you sure you would like to update the group type and
          cancel the order(s)? Once the group type is updated, the order(s) will be cancelled automatically.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            marginTop: '30px',
            width: 'inherit',
          }}
        >
          <Button id={'btn-modal-cancel-order-by-group-yes'} color={'error'} variant="contained" onClick={onConfirm} sx={{ marginRight: '20px' }}>
            Yes, update
          </Button>
          <Button id={'btn-modal-cancel-order-by-group-no'} color={'error'} variant="outlined" onClick={onCancel}>
            No, keep it
          </Button>
        </Box>
      </Box>
    </Backdrop>
  );
}
