import React, { useEffect, useRef, useState, Dispatch, SetStateAction } from 'react';
import { Box, TextField, Tooltip, Button, FormControl } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAddOnContextV2, useRestaurantContext, useSnackbarContext } from '@/services/index';
import { apiPathV1 } from '@/app.constants/apiCallPaths/api.path.v1';
import { LIMIT_EN_CHAR, LIMIT_TH_CHAR } from '@/app.constants/index';
import { RestaurantAddOnsSchema, addonOrderByType, QueryParamsGetList, Addon } from '@/src/types';
import { PriceInput } from '../sharedPriceInputComponent';
import { STATE_ENUM } from '../../../types/schema/enum/common.enum';
import { updateTablePaginationTotal } from '../../../helpers/tableHelpers';

interface AddRestaurantAddOnsProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setOrderBy: any;
  setOrder: any;
  setPage: Dispatch<SetStateAction<number>>;
  setTotal: Dispatch<SetStateAction<number>>;
  setIsChange: Dispatch<SetStateAction<boolean>>;
  total: number;
  pageNumber: number;
  rowsPerPage: number;
  addonPaginationParams: QueryParamsGetList<addonOrderByType>;
  setAddonPaginationParams: React.Dispatch<SetStateAction<QueryParamsGetList<addonOrderByType>>>;
}

export function AddRestaurantAddOns(props: AddRestaurantAddOnsProps) {
  const { setOpen, setOrderBy, setOrder, setPage, setTotal, total, pageNumber, rowsPerPage, addonPaginationParams, setAddonPaginationParams } = props;
  const { restaurantAddonList, restaurantAddonCreate, restaurantAddonUpdate, restaurantAddonDelete, overBudgetOrdersByAddOn } = useAddOnContextV2();
  const { setRestaurantDropdownList } = useRestaurantContext();
  const { setResponseStatus } = useSnackbarContext();
  const { id } = useParams();
  const [thNameErrorText, setThNameErrorText] = useState<string>('');
  const [enNameErrorText, setEnNameErrorText] = useState<string>('');
  const [priceErrorText, setPriceErrorText] = useState<string>('');
  const [thaiText, setThaiText] = useState<string>('');
  const [engText, setEngText] = useState<string>('');
  const [priceText, setPrice] = useState<string>('');
  const [validate, setValidate] = useState<boolean>(false);
  const [isClickAdd, setIsClickAdd] = useState(false);
  const countThaiText = useRef(0);
  const countEngText = useRef(0);
  const countPrice = useRef(0);
  const thaiChar = LIMIT_TH_CHAR;
  const engChar = LIMIT_EN_CHAR;

  const focusTextField = useRef<HTMLInputElement | null>(null);
  const loopTextField = useRef<HTMLInputElement | null>(null);
  const [checkSaveLoad, setCheckSaveLoad] = useState<boolean>(false);

  const getPayload = () => {
    return {
      thName: thaiText,
      enName: engText,
      price: parseInt(priceText),
    };
  };

  const callAddonCreate = () => {
    restaurantAddonCreate.requestAct(id as string, getPayload());
    setValidate(false);
  };

  const handleClickAdd = () => {
    setValidate(false);
    setIsClickAdd(true);
    if (pageNumber === 0) {
      callAddonCreate();
    } else {
      setAddonPaginationParams((prevState) => {
        return { ...prevState, page: 0, itemPerPage: rowsPerPage };
      });
    }
  };

  useEffect(() => {
    if (restaurantAddonList.current.state === STATE_ENUM.READY && isClickAdd) {
      callAddonCreate();
    } else if (restaurantAddonList.current.state === STATE_ENUM.ERROR) {
      setValidate(true);
      setIsClickAdd(false);
    }
  }, [restaurantAddonList.current]);

  useEffect(() => {
    if (restaurantAddonCreate.current.state === STATE_ENUM.READY) {
      setRestaurantDropdownList(apiPathV1.restaurantDropdownList, id, { types: ['addon', 'group'] });
      updateTablePaginationTotal(total + 1, setTotal, restaurantAddonList);

      setResponseStatus({
        status: 'success',
        message: restaurantAddonCreate.current?.status?.message ?? '',
      });

      setThaiText('');
      setEngText('');
      setPrice('');

      const tempArray: RestaurantAddOnsSchema[] = restaurantAddonList.current.data?.addons || [];
      if (restaurantAddonCreate.current.data) {
        tempArray.unshift(restaurantAddonCreate.current.data as unknown as Addon);
        if (total >= rowsPerPage) {
          tempArray.pop();
        }
      }

      restaurantAddonCreate.setState({ state: STATE_ENUM.INITIAL });

      setOpen(true);
      setOrderBy(addonPaginationParams.orderBy);
      setOrder(addonPaginationParams.sort);
      // TODO: report bug found every table page > should not update page to 0 because data is still the same as current page when added
      setPage(0);
      setIsClickAdd(false);
      focusTextField.current?.focus();
      countThaiText.current = 0;
      countEngText.current = 0;
      countPrice.current = 0;
    } else if (restaurantAddonCreate.current.state === STATE_ENUM.ERROR) {
      setResponseStatus({
        status: restaurantAddonCreate.current.state,
        message: restaurantAddonCreate.current?.status?.message ?? 'Something went wrong. Please try again.',
      });
      restaurantAddonCreate.setState({
        state: 'initial',
        data: undefined,
      });
      setOpen(true);
      setIsClickAdd(false);
    }
  }, [restaurantAddonCreate.current]);

  const handleThaiNameTextChange = (event: any) => {
    countThaiText.current = event.target.value.trim().length;
    setThaiText(event.target.value);
    if (event.target.value.length === 0 || !event.target.value.match(thaiChar)) {
      setThNameErrorText('Thai name is required.');
      setValidate(false);
    } else if (event.target.value.trim().length === 0) {
      setValidate(false);
      setThNameErrorText('Thai name is required.');
    } else if (event.target.value.length > 100) {
      setValidate(false);
      setThNameErrorText('Max 100 characters.');
    } else {
      setThNameErrorText('');
    }
  };
  const handleEngNameTextChange = (event: any) => {
    countEngText.current = event.target.value.trim().length;
    setEngText(event.target.value);
    if (event.target.value.length === 0 || !event.target.value.match(engChar)) {
      setEnNameErrorText('English name is required.');
      setValidate(false);
    } else if (event.target.value.trim().length === 0) {
      setValidate(false);
      setEnNameErrorText('English name is required.');
    } else if (event.target.value.length > 100) {
      setValidate(false);
      setEnNameErrorText('Max 100 characters.');
    } else {
      setEnNameErrorText('');
    }
  };

  const handleOutOfFocusThaiName = () => {
    if (countThaiText.current === 0) {
      setThaiText('');
      setThNameErrorText('');
    } else if (!thaiText.match(thaiChar)) {
      setThNameErrorText('Thai name is required.');
    } else if (countThaiText.current > 100) {
      setThNameErrorText('Max 100 characters.');
    } else {
      setThNameErrorText('');
    }
  };
  const handleOutOfFocusEngName = () => {
    if (countEngText.current === 0) {
      setEngText('');
      setEnNameErrorText('');
    } else if (!engText.match(engChar)) {
      setEnNameErrorText('English name is required.');
    } else if (countEngText.current > 100) {
      setEnNameErrorText('Max 100 characters.');
    } else {
      setEnNameErrorText('');
    }
  };

  useEffect(() => {
    if (
      countEngText.current !== 0 &&
      countThaiText.current !== 0 &&
      countPrice.current !== 0 &&
      thNameErrorText === '' &&
      enNameErrorText === '' &&
      priceErrorText === ''
    ) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  }, [thaiText, engText, priceText]);

  // When click edit add-ons and save, add field will be disable too
  useEffect(() => {
    if (
      restaurantAddonUpdate.current.state === STATE_ENUM.LOADING ||
      restaurantAddonUpdate.current.state === STATE_ENUM.EDITING ||
      restaurantAddonDelete.current.state === STATE_ENUM.LOADING ||
      overBudgetOrdersByAddOn.current.state === STATE_ENUM.LOADING
    ) {
      setCheckSaveLoad(true);
    } else {
      setCheckSaveLoad(false);
    }
  }, [restaurantAddonUpdate, restaurantAddonDelete]);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        mb: 1,
        '.MuiFormControl-root': { mr: 2, mb: 3, p: 0 },
        '> .MuiFormControl-root': { width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' },
      }}
    >
      <FormControl
        variant="outlined"
        size="small"
        color="secondary"
        sx={{
          width: '100%',
        }}
      >
        <TextField
          value={thaiText}
          id="thaiName"
          label="Thai name"
          variant="outlined"
          size="small"
          color="secondary"
          sx={{
            width: '416px',
          }}
          error={thNameErrorText !== ''}
          helperText={thNameErrorText}
          onChange={handleThaiNameTextChange}
          onBlur={handleOutOfFocusThaiName}
          tabIndex={-1}
          inputRef={focusTextField}
          ref={loopTextField}
          disabled={isClickAdd || checkSaveLoad}
          inputProps={{ maxLength: 101 }}
        />
        <TextField
          value={engText}
          id="engName"
          label="English name"
          variant="outlined"
          size="small"
          color="secondary"
          sx={{
            width: '416px',
          }}
          error={enNameErrorText !== ''}
          helperText={enNameErrorText}
          onChange={handleEngNameTextChange}
          onBlur={handleOutOfFocusEngName}
          disabled={isClickAdd || checkSaveLoad}
          inputProps={{ maxLength: 101 }}
        />
        <PriceInput
          price={priceText}
          priceErrorText={priceErrorText}
          setPrice={setPrice}
          setPriceErrorText={setPriceErrorText}
          setValidate={setValidate}
          countPrice={countPrice}
          validate={validate}
          loopTextField={loopTextField}
          disabled={isClickAdd || checkSaveLoad}
        />
        <Tooltip title="Add add-on" placement="bottom-start">
          <Button
            id="btn-add-restaurant-addon"
            variant="contained"
            component="span"
            color="primary"
            onClick={handleClickAdd}
            disabled={!validate || checkSaveLoad}
            onKeyDown={(e: any) => {
              if (e.key === 'Tab') {
                loopTextField.current?.focus();
              }
            }}
          >
            Add
          </Button>
        </Tooltip>
      </FormControl>
    </Box>
  );
}
