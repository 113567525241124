import { Pagination, order } from '@/src/types';
import { Dispatch, SetStateAction, useState } from 'react';
import { useCookies } from 'react-cookie';

export interface IPaginationData {
  page: number;
  onSetPage: Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  onSetRowsPerPage: Dispatch<SetStateAction<number>>;
  responsePagination?: Pagination;
  setResponsePagination?: Dispatch<SetStateAction<Pagination>>;
  search: string;
  onSetSearch: Dispatch<SetStateAction<string>>;
  orderBy?: string;
  onSetOrderBy: Dispatch<SetStateAction<string>>;
  order: order | null;
  onSetOrder: React.Dispatch<React.SetStateAction<order | null>>;
}

export const userPaginationDefault: any = {
  page: 0,
  total: 0,
  rowsPerPage: 10,
  search: '',
  orderBy: '',
  sort: '',
  onSetPage: (_val: SetStateAction<number>) => {},
  onSetRowsPerPage: (_val: SetStateAction<number>) => {},
  onSetSearch: (_val: SetStateAction<string>) => {},
  onSetOrderBy: (_val: SetStateAction<string>) => {},
  onSetort: (_val: SetStateAction<string>) => {},
};

const useUserPagination = (): IPaginationData => {
  const [cookies] = useCookies(['sortingUsers']);
  let newOrder: order | null = 'asc';
  let newOrderBy = 'firstName';
  if (cookies.sortingUsers) {
    newOrder = cookies.sortingUsers.order;
    newOrderBy = cookies.sortingUsers.orderBy;
  }

  const [responsePagination, setResponsePagination] = useState<Pagination>({
    page: userPaginationDefault.page,
    total: userPaginationDefault.total,
    perPage: userPaginationDefault.rowsPerPage,
  });
  const [page, setPage] = useState(userPaginationDefault.page);
  const [rowsPerPage, setRowsPerPage] = useState(userPaginationDefault.rowsPerPage);
  const [search, setSearch] = useState(userPaginationDefault.search);
  const [orderBy, setOrderBy] = useState(newOrderBy);
  const [sort, setSort] = useState(newOrder);
  return {
    page,
    rowsPerPage,
    orderBy,
    order: sort,
    onSetOrderBy: setOrderBy,
    onSetOrder: setSort,
    onSetPage: setPage,
    onSetRowsPerPage: setRowsPerPage,
    responsePagination,
    setResponsePagination,
    search,
    onSetSearch: setSearch,
  };
};

export default useUserPagination;
