import React from 'react';
import { Typography, Box, CardMedia } from '@mui/material';
import { notificationsSchema } from '@/src/types/schema/notifications';
import { getDisplayConfig, printDisplayDate } from '@/src/utils';

type Props = {
  notification: notificationsSchema;
};
export default function ModalViewNotification({ notification }: Props) {
  const fm = getDisplayConfig().dateTimeFormat;
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: `${theme.spacing(4)}`,
        marginBottom: theme.spacing(3),
      })}
    >
      {notification.imageUrl && (
        <Box className="flex w-full justify-center">
          <CardMedia id={`${notification.id}-image`} component="img" sx={{ width: 248, height: 140, objectFit: 'contain' }} image={notification.imageUrl} />
        </Box>
      )}
      <Typography variant="body1" sx={(theme) => ({ color: theme.palette.text.secondary, wordWrap: 'break-word' })}>
        {notification.title}
      </Typography>
      <Typography variant="body1" sx={(theme) => ({ color: theme.palette.text.secondary, wordWrap: 'break-word' })}>
        {notification.description}
      </Typography>
      <Typography variant="body1" sx={(theme) => ({ color: theme.palette.text.secondary })}>
        Delivered Time: {printDisplayDate(notification.scheduledDate, fm)}
      </Typography>
    </Box>
  );
}
