import * as React from 'react';

import { IconButton, Snackbar, Alert } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useSnackbarContext } from '@/services/index';

export function PopupSnackbar(props: any) {
  const { open, setOpen } = props;
  const { responseStatus } = useSnackbarContext();

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color={responseStatus.status === 'success' ? 'success' : 'error'} onClick={handleClose} key="bottom">
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return responseStatus.status || responseStatus.message ? (
    <div>
      <Snackbar
        id="snackbar-alert"
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        action={action}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Alert onClose={handleClose} severity={responseStatus.status === 'success' ? 'success' : 'error'} variant="filled" sx={{ width: '100%' }}>
          {responseStatus.message}
        </Alert>
      </Snackbar>
    </div>
  ) : (
    <></>
  );
}
