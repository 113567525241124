import React, { useEffect, useRef, useState, Dispatch, SetStateAction } from 'react';
import { Box, TextField, Tooltip, Button, FormControl } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSnackbarContext, useRestaurantOptionAddonContextV2 } from '@/services/index';
import { LIMIT_EN_CHAR, LIMIT_TH_CHAR } from '@/app.constants/index';
import { PriceInput } from '../sharedPriceInputComponent';
import { Addon, AddonRequest } from '@/src/types/crud/optionAddon/optionAddon';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import { updateTablePaginationTotal } from '@/src/helpers/tableHelpers';
import { QueryParamsGetList } from '@/src/types';
import { OptionAddonOrderByType } from '@/src/types/crud/optionAddon/optionAddon.queries';

interface AddRestaurantOptionAddonProps {
  setOrderBy: any;
  setOrder: any;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setPage: React.Dispatch<SetStateAction<number>>;
  setTotal: React.Dispatch<SetStateAction<number>>;
  total: number;
  pageNumber: number;
  rowsPerPage: number;
  optionAddonPaginationParams: QueryParamsGetList<OptionAddonOrderByType>;
  setOptionAddonPaginationParams: React.Dispatch<SetStateAction<QueryParamsGetList<OptionAddonOrderByType>>>;
}

export function AddOptionAddon(props: AddRestaurantOptionAddonProps) {
  const { setOpen, setOrderBy, setOrder, setPage, setTotal, total, pageNumber, rowsPerPage, optionAddonPaginationParams, setOptionAddonPaginationParams } =
    props;
  const { setResponseStatus } = useSnackbarContext();
  const { optionAddonList, optionAddonCreate, optionAddonUpdate, optionAddonDelete, overBudgetOrdersByAddonOption } = useRestaurantOptionAddonContextV2();

  const { id, groupId } = useParams();
  const [thNameErrorText, setThNameErrorText] = useState('');
  const [enNameErrorText, setEnNameErrorText] = useState('');
  const [priceErrorText, setPriceErrorText] = useState('');
  const [thaiText, setThaiText] = useState('');
  const [engText, setEngText] = useState('');
  const [priceText, setPrice] = useState('');
  const [validate, setValidate] = useState(false);
  const [isClickAdd, setIsClickAdd] = useState(false);
  const countThaiText = useRef(0);
  const countEngText = useRef(0);
  const countPrice = useRef(0);
  const thaiChar = LIMIT_TH_CHAR;
  const engChar = LIMIT_EN_CHAR;

  const focusTextField = useRef<HTMLInputElement | null>(null);
  const loopTextField = useRef<HTMLInputElement | null>(null);

  const [checkSaveLoad, setCheckSaveLoad] = useState(false);

  useEffect(() => {
    /** Check LOADING and EDITING when edit or deleting to disabled ADD button   */
    if (
      optionAddonUpdate.current.state === STATE_ENUM.LOADING ||
      optionAddonDelete.current.state === STATE_ENUM.LOADING ||
      optionAddonUpdate.current.state === STATE_ENUM.EDITING ||
      overBudgetOrdersByAddonOption.current.state === STATE_ENUM.LOADING
    ) {
      setCheckSaveLoad(true);
    } else {
      setCheckSaveLoad(false);
    }
  }, [optionAddonUpdate, optionAddonDelete]);

  const getPayload = () => {
    return {
      thName: thaiText,
      enName: engText,
      price: parseInt(priceText),
    } as AddonRequest;
  };
  // handle Add function
  const callOptionAddonCreate = () => {
    if (id && groupId) {
      optionAddonCreate.requestAct(id, groupId, getPayload());
    }
    setValidate(false);
  };

  const handleClickAdd = () => {
    setValidate(false);
    setIsClickAdd(true);

    if (pageNumber === 0) {
      callOptionAddonCreate();
    } else {
      setOptionAddonPaginationParams((prevState) => {
        return { ...prevState, page: 0, itemPerPage: rowsPerPage };
      });
    }
  };

  useEffect(() => {
    if (optionAddonList.current.state === STATE_ENUM.READY && isClickAdd) {
      callOptionAddonCreate();
      setPage(0);
    } else if (optionAddonList.current.state === STATE_ENUM.ERROR) {
      setValidate(true);
      setIsClickAdd(false);
    }
  }, [optionAddonList.current]);

  useEffect(() => {
    if (optionAddonCreate.current.state === STATE_ENUM.READY) {
      updateTablePaginationTotal(total + 1, setTotal, optionAddonList);
      if (optionAddonList.current.pagination?.total !== undefined) {
        optionAddonList.current.pagination.total++;
      }

      setResponseStatus({
        status: 'success',
        message: optionAddonCreate?.current.status?.message ?? '',
      });

      setThaiText('');
      setEngText('');
      setPrice('');

      const tempArray: Addon[] = optionAddonList.current.data?.addon || [];
      if (optionAddonCreate.current.data) {
        tempArray.unshift(optionAddonCreate.current.data as unknown as Addon);
        if (total >= rowsPerPage) {
          tempArray.pop();
        }
      }

      optionAddonCreate.setState({ state: STATE_ENUM.INITIAL });

      setOpen(true);
      setOrderBy(optionAddonPaginationParams.orderBy);
      setOrder(optionAddonPaginationParams.sort);
      // TODO: report bug found every table page > should not update page to 0 because data is still the same as current page when added
      setPage(0);
      setIsClickAdd(false);
      focusTextField.current?.focus();
      countThaiText.current = 0;
      countEngText.current = 0;
      countPrice.current = 0;
    } else if (optionAddonCreate.current.state === STATE_ENUM.ERROR) {
      setResponseStatus({
        status: optionAddonCreate.current.state,
        message: optionAddonCreate?.current.status?.message ?? '',
      });

      optionAddonCreate.setState({ state: STATE_ENUM.INITIAL });
      setOpen(true);
      setIsClickAdd(false);
    }
  }, [optionAddonCreate.current]);

  // handle function Text Change
  const handleThaiNameTextChange = (event: any) => {
    countThaiText.current = event.target.value.trim().length;
    setThaiText(event.target.value);
    if (event.target.value.length === 0 || !event.target.value.match(thaiChar)) {
      setThNameErrorText('Thai name is required.');
      setValidate(false);
    } else if (event.target.value.trim().length === 0) {
      setValidate(false);
      setThNameErrorText('Thai name is required.');
    } else if (event.target.value.length > 100) {
      setValidate(false);
      setThNameErrorText('Max 100 characters.');
    } else {
      setThNameErrorText('');
    }
  };

  const handleEngNameTextChange = (event: any) => {
    countEngText.current = event.target.value.trim().length;
    setEngText(event.target.value);
    if (event.target.value.length === 0 || !event.target.value.match(engChar)) {
      setEnNameErrorText('English name is required.');
      setValidate(false);
    } else if (event.target.value.trim().length === 0) {
      setValidate(false);
      setEnNameErrorText('English name is required.');
    } else if (event.target.value.length > 100) {
      setValidate(false);
      setEnNameErrorText('Max 100 characters.');
    } else {
      setEnNameErrorText('');
    }
  };

  const handleOutOfFocusThaiName = () => {
    if (countThaiText.current === 0) {
      setThaiText('');
      setThNameErrorText('');
    } else if (!thaiText.match(thaiChar)) {
      setThNameErrorText('Thai name is required.');
    } else if (countThaiText.current > 100) {
      setThNameErrorText('Max 100 characters.');
    } else {
      setThNameErrorText('');
    }
  };
  const handleOutOfFocusEngName = () => {
    if (countEngText.current === 0) {
      setEngText('');
      setEnNameErrorText('');
    } else if (!engText.match(engChar)) {
      setEnNameErrorText('English name is required.');
    } else if (countEngText.current > 100) {
      setEnNameErrorText('Max 100 characters.');
    } else {
      setEnNameErrorText('');
    }
  };

  useEffect(() => {
    if (
      countEngText.current !== 0 &&
      countThaiText.current !== 0 &&
      countPrice.current !== 0 &&
      thNameErrorText === '' &&
      enNameErrorText === '' &&
      priceErrorText === ''
    ) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  }, [thaiText, engText, priceText]);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        mb: 1,
        '.MuiTextField-root': { mr: 2, mb: 3, p: 0 },
        '> .MuiFormControl-root': { width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' },
      }}
    >
      <FormControl
        variant="outlined"
        size="small"
        color="secondary"
        sx={{
          width: '100%',
        }}
      >
        <TextField
          value={thaiText}
          id="thaiName"
          label="Thai name"
          variant="outlined"
          size="small"
          color="secondary"
          sx={{
            width: '416px',
          }}
          error={thNameErrorText !== ''}
          helperText={thNameErrorText}
          onChange={handleThaiNameTextChange}
          onBlur={handleOutOfFocusThaiName}
          tabIndex={-1}
          inputRef={focusTextField}
          ref={loopTextField}
          disabled={isClickAdd || checkSaveLoad}
          inputProps={{ maxLength: 101 }}
        />
        <TextField
          value={engText}
          id="engName"
          label="English name"
          variant="outlined"
          size="small"
          color="secondary"
          sx={{
            width: '416px',
          }}
          error={enNameErrorText !== ''}
          helperText={enNameErrorText}
          onChange={handleEngNameTextChange}
          onBlur={handleOutOfFocusEngName}
          disabled={isClickAdd || checkSaveLoad}
          inputProps={{ maxLength: 101 }}
        />
        <PriceInput
          price={priceText}
          priceErrorText={priceErrorText}
          setPrice={setPrice}
          setPriceErrorText={setPriceErrorText}
          setValidate={setValidate}
          countPrice={countPrice}
          validate={validate}
          loopTextField={loopTextField}
          disabled={isClickAdd || checkSaveLoad}
        />
        <Tooltip title="Add Choice" placement="bottom-start">
          <Button
            id="btn-add-restaurant-option-addon"
            variant="contained"
            component="span"
            color="primary"
            onClick={() => handleClickAdd()}
            disabled={!validate || checkSaveLoad}
            onKeyDown={(e: any) => {
              if (e.key === 'Tab') {
                loopTextField.current?.focus();
              }
            }}
          >
            Add
          </Button>
        </Tooltip>
      </FormControl>
    </Box>
  );
}
