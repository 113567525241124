/** declare only http service related requests */

import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import { QueryOrderOverBudgetByAddOnPrice, GetOrderOverBudgetByAddOnPrice } from '@/types/crud';
import { RestaurantAddonsBody, addonOrderByType, QueryParamsGetList } from '@/types/index';
import { apiMethodV1 } from '@/app.constants/apiCallMethods/index';
import { paramsQueryString } from '@/src/utils';

/**
 * @private This api is protected and can only be accessed as role=ADMIN
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
const RestaurantAddOnsListGet = <T>(prefix: string, restaurantId: string, params: QueryParamsGetList<addonOrderByType>, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.get, timeout: 12000 }, true, hardRejection);
  const queryUri = paramsQueryString(prefix + restaurantId, params);
  // token not yet set on backend
  return instance.client
    .get(queryUri, instance.config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

const RestaurantAddOnsCreate = <T>(prefix: string, restaurantId: string, body: RestaurantAddonsBody, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.post, timeout: 12000 }, true, hardRejection);
  // token not yet set on backend
  return instance.client
    .post(prefix + restaurantId, JSON.stringify(body), instance.config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

const RestaurantAddOnsUpdate = <T>(prefix: string, restaurantId: string, addonId: string, body: RestaurantAddonsBody, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.put, timeout: 12000 }, true, hardRejection);
  return instance.client
    .put(prefix + restaurantId + '/' + addonId, JSON.stringify(body), instance.config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

const RestaurantAddOnsDelete = <T>(prefix: string, restaurantId: string, addonId: string, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.delete, timeout: 12000 }, true, hardRejection);
  return instance.client
    .delete<T>(prefix + restaurantId + '/' + addonId, instance.config)
    .then((d) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

const OrderOverBudgetByAddOnCheck = (
  prefix: string,
  restaurantId: string,
  addonId: string,
  params: QueryOrderOverBudgetByAddOnPrice,
  hardRejection = true
): Promise<GetOrderOverBudgetByAddOnPrice> => {
  const instance = new AxiosService({ method: apiMethodV1.post, timeout: 12000 }, true, hardRejection);
  const queryUri = paramsQueryString(`${prefix}${restaurantId}/${addonId}`, params);

  return instance.client
    .get(queryUri, instance.config)
    .then((d: AxiosResponse<GetOrderOverBudgetByAddOnPrice>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetOrderOverBudgetByAddOnPrice>;
};

export { RestaurantAddOnsListGet, RestaurantAddOnsCreate, RestaurantAddOnsUpdate, RestaurantAddOnsDelete, OrderOverBudgetByAddOnCheck };
