// @ts-ignore
// @ts-nocheck
/* eslint-disable no-trailing-spaces */
/* eslint-disable @typescript-eslint/comma-dangle */
/* eslint-disable @typescript-eslint/quotes */
import { createTheme, Theme as _Theme } from '@mui/material/styles';
import typography from './typography';
import palette from './palette';
import { makeStyles } from '@mui/styles';

interface Theme extends _Theme {
  status: any;
}
// Create a theme instance.
export const theme: Theme = createTheme({
  status: {
    appState: 'loading',
  },
  typography,
  palette,
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 3000,
    },
  },

  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 10%), 0px 3px 4px 0px rgb(0 0 0 / 8%), 0px 1px 8px 0px rgb(0 0 0 / 6%) !important',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          minHeight: '60px !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          lineHeight: 1,
        },
        sizeMedium: {
          minWidth: '100px',
          height: '40px',
        },
        sizeSmall: {
          height: '36px',
          minWidth: '50px',
        },
        outlined: {
          minWidth: 'unset',
          height: 'auto',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          borderColor: '#E0E0E0',
          padding: '8px 20px',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          fontSize: '16px',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: '1px solid #E0E0E0',
          borderRadius: '4px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px',
          color: '#212121',
          backgroundColor: '#E0E0E0',
          maxWidth: '425px',
        },
        arrow: {
          color: '#E0E0E0',
        },
      },
    },
  },
}) as any;

export const styles = makeStyles({
  wrap_text: {
    whiteSpace: 'unset',
    wordBreak: 'break-all',
  },
});

export default theme;
