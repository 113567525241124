/**
 *
 * TODO account details here will need to come from dynamic script when we start deploy for another environment
 * TODO also and most likely we will need common/shared folder that can be used for both client and backed
 * */

// just use one account for all of application, this account is also used for mobile login to use firebase api
let firebaseConfig;
try {
  // REVIEW it seams ./shared folder by copy will not work for this
  // will opt for better solution on next implementation of craco using global variables on window object
  // firebaseConfig = require('../../shared/client/firebase.config.dev.json');

  firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABSE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINT_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };
} catch (err: any) {
  console.error('[firebaseConfig]', err);
  throw new Error(err.toString());
}

const microsoftTenantId = '06946b4a-272c-4f5b-a599-baa836b48988';
export { firebaseConfig, microsoftTenantId };
