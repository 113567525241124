import { useSnackbarContext } from '@/store/snackbar.store';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export interface IUserSnackbarData {
  snackbarOpen: boolean;
  setSnackbarOpen: Dispatch<SetStateAction<boolean>>;
  handleSnackbar: (status: string, message: string) => void;
}

export const userSnackbarDefault = {
  snackbarOpen: false,
  setSnackbarOpen: () => {},
  handleSnackbar: (_status: string, _message: string) => {},
};

const useUserSnackbar = (): IUserSnackbarData => {
  const [snackbarOpen, setSnackbarOpen] = useState(userSnackbarDefault.snackbarOpen);
  const { setResponseStatus } = useSnackbarContext();
  const handleSnackbar = useCallback((status: string, message: string) => {
    setResponseStatus({ status, message });
  }, []);

  return {
    snackbarOpen,
    setSnackbarOpen,
    handleSnackbar,
  };
};

export default useUserSnackbar;
