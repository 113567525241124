import BarChartComponent from '@/src/components/ui/BarChartComponent';
import { DashBoardSummaryResponse, DashboardView } from '@/src/types/crud/dashboard/dashboard.summary';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import chartNoDataIcon from '@/src/images/chart-nodata-icon.png';

interface DataItem {
  label: string;
  value: number;
}

interface MonthlyData {
  month: string;
  weeklyData: DataItem[];
}

interface Props {
  data: DashBoardSummaryResponse | undefined;
  view: DashboardView;
}

const DashBoardOrderView: FC<Props> = ({ data, view }) => {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const orderValue = data?.chart?.order.filter((item) => item.year !== 2021) ?? [];

  const groupedData: MonthlyData[] = monthNames.map((month, index) => ({
    month,
    weeklyData: orderValue
      .filter((item) => item.month === index + 1 && item.week)
      .map((item) => ({
        label: `Week ${item.week}，${item.startWeek}-${item.endWeek} ${monthNames[item.month - 1]} ${item.year}`,
        value: item.value,
      })),
  }));

  const formattedLabels: DataItem[] = orderValue.map((item) => {
    let label = '';
    switch (view) {
      case 'y':
        label = `${item.year}`;
        break;
      case 'm':
        label = `${monthNames[item.month - 1]}`;
        break;
      case 'd':
        label = `${item.nameDate ? item.nameDate.slice(0, 3) : 'N/A'}.\n${item.day}`;
        break;
    }
    return {
      label,
      value: item.value,
    };
  });

  const formattedTooltip: DataItem[] = orderValue.map((item) => {
    let label = '';
    switch (view) {
      case 'y':
        label = `${item.year}`;
        break;
      case 'm':
        label = `${monthNames[item.month - 1]} ${item.year}`;
        break;
      case 'd':
        label = `${item.nameDate ? item.nameDate : 'Unknown Date'} ${item.day} ${monthNames[item.month - 1]} ${item.year}`;
        break;
    }
    return {
      label,
      value: item.value,
    };
  });

  const values = formattedLabels.map((e, i) => e.value);
  const allValuesAreZero = values.every((value) => value === 0);

  return (
    <Box>
      <Box>
        <Box sx={{ my: 5 }}>
          <Typography variant="h1">Orders</Typography>
        </Box>
        {allValuesAreZero ? (
          <Box sx={{ display: 'grid', justifyContent: 'center', bgcolor: '#E9E9E9', padding: 20 }}>
            <Box sx={{ textAlign: 'center' }}>
              <img src={chartNoDataIcon} alt="Total Orders" style={{ width: 81.25, height: 93.75 }} />
            </Box>
            <Typography textAlign={'center'}>No data available</Typography>
          </Box>
        ) : (
          <Box>
            <BarChartComponent data={formattedLabels} title={'Orders'} groupedData={groupedData} view={view} formattedLabels={formattedTooltip} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DashBoardOrderView;
