import { createContext, Dispatch, SetStateAction } from 'react';
import {
  GetRestaurantList,
  PutRestaurant,
  UpdateRestaurant,
  DeleteRestaurant,
  GetRestaurantDropdownList,
  QueryParamsGetList,
  QueryRestaurantDropdownList,
  restaurantOrderByType,
  RestaurantDetail,
} from '@/types/index';
import { createRestaurantSchema } from '@/types/schema/createRestaurant';
import { updateRestaurantSchema } from '@/types/schema/updateRestaurant';

// TODO: refactor restaurant store
const RestaurantContext: React.Context<{
  restaurantList: GetRestaurantList;
  setRestaurantList: (prefix: string, params?: QueryParamsGetList<restaurantOrderByType>) => void;
  restaurantListForSchedule: GetRestaurantList;
  setRestaurantListData: Dispatch<SetStateAction<GetRestaurantList>>;
  setRestaurantListForSchedule: (prefix: string) => void;

  restaurantCreateData: PutRestaurant;
  setRestaurantCreate: (prefix: string, payload: createRestaurantSchema) => void;
  setRestaurantCreateData: Dispatch<SetStateAction<PutRestaurant>>;

  restaurantUpdateData: UpdateRestaurant;
  setRestaurantUpdate: (prefix: string, payload: updateRestaurantSchema, restaurantId: string) => void;
  setRestaurantUpdateData: Dispatch<SetStateAction<UpdateRestaurant>>;

  getRestaurantIdUpdateList: (id: string, thName: string, enName: string) => void;

  restaurantDeleteData: DeleteRestaurant;
  setRestaurantDelete: (prefix: string, restaurantId: string) => void;
  setRestaurantDeleteData: Dispatch<SetStateAction<DeleteRestaurant>>;

  getRestaurantIdDeleteList: (id: string) => number;

  restaurantDropdownList: GetRestaurantDropdownList;
  setRestaurantDropdownList: (prefix: string, restaurantId: any, params: QueryRestaurantDropdownList) => void;
  setRestaurantDropdownListData: Dispatch<SetStateAction<GetRestaurantDropdownList>>;

  restaurantDetail: RestaurantDetail;
  setRestaurantDetail: (prefix: string, params?: string) => void;
  restaurantDetailForDetail: RestaurantDetail;
  setRestaurantDetailData: Dispatch<SetStateAction<RestaurantDetail>>;
  setRestaurantDetailForDetail: (prefix: string) => void;
}> = createContext({
  // RestaurantListContext
  restaurantList: {
    error: '',
    state: '',
    status: {
      code: 0,
      message: '',
    },
    data: [
      {
        id: '',
        thName: '',
        enName: '',
        favoriteCount: 0,
        menuItems: 0,
        orderCount: 0,
        orderTotal: 0,
        lastOrder: '',
        createdAt: '',
        updatedAt: '',
        ratingAvg: 0,
        ratingCount: 0,
        ratingTotal: 0,
        activeStatus: true,
      },
    ],
    pagination: {
      page: 0,
      total: 0,
      perPage: 0,
    },
  } as GetRestaurantList,

  setRestaurantList: (prefix: string, params?: QueryParamsGetList<restaurantOrderByType>) => {},
  restaurantListForSchedule: { state: '', data: [] } as GetRestaurantList,
  setRestaurantListData: (val: SetStateAction<GetRestaurantList>) => {},
  setRestaurantListForSchedule: (prefix: string) => {},

  // RestaurantCreateContext
  restaurantCreateData: {} as PutRestaurant,
  setRestaurantCreate: (prefix: string, payload: createRestaurantSchema) => {},
  setRestaurantCreateData: (val: SetStateAction<PutRestaurant>) => {},

  // RestaurantUpdateContext
  restaurantUpdateData: {} as UpdateRestaurant,
  setRestaurantUpdate: (prefix: string, payload: updateRestaurantSchema, restaurantId: string) => {},
  setRestaurantUpdateData: (val: SetStateAction<UpdateRestaurant>) => {},

  getRestaurantIdUpdateList: (id: string, thName: string, enName: string) => {},

  // RestaurantDeleteContext
  restaurantDeleteData: {} as DeleteRestaurant,
  setRestaurantDelete: (prefix: string, restaurantId: string) => {},
  setRestaurantDeleteData: (val: SetStateAction<DeleteRestaurant>) => {},

  getRestaurantIdDeleteList: (id: string) => -1,

  restaurantDropdownList: {} as GetRestaurantDropdownList,
  setRestaurantDropdownList: (prefix: string, restaurantId: any, params: QueryRestaurantDropdownList) => {},
  setRestaurantDropdownListData: (val: SetStateAction<GetRestaurantDropdownList>) => {},

  restaurantDetail: {} as RestaurantDetail,
  setRestaurantDetail: (prefix: string) => {},
  restaurantDetailForDetail: {} as RestaurantDetail,
  setRestaurantDetailData: (val: SetStateAction<RestaurantDetail>) => {},
  setRestaurantDetailForDetail: (prefix: string) => {},
});
export { RestaurantContext };
