import { useContext, useEffect, useState } from 'react';
import { RestaurantListGet, RestaurantCreate, RestaurantUpdate, RestaurantDelete, RestaurantDropdownListGet, RestaurantDetailGet } from '@/services/http';
import {
  GetRestaurantList,
  PutRestaurant,
  UpdateRestaurant,
  DeleteRestaurant,
  RestaurantSchema,
  GetRestaurantDropdownList,
  QueryParamsGetList,
  restaurantOrderByType,
  QueryRestaurantDropdownList,
  RestaurantDetail,
} from '@/types/index';
import { RestaurantContext } from './initial.store';
import { createRestaurantSchema } from '@/types/schema/createRestaurant';
import { updateRestaurantSchema } from '@/types/schema/updateRestaurant';
import { DEFAULT_ROW_PER_PAGE } from '@/src/app.constants';
export function useRestaurantContext() {
  return useContext(RestaurantContext);
}

const RestaurantContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [restaurantList, setRestaurantList] = useState<GetRestaurantList>({
    data: [],
    state: 'initial',
    status: {
      code: 0,
      message: '',
    },
  });

  const [restaurantDetail, setRestaurantDetail] = useState<RestaurantDetail>({
    data: undefined,
    state: 'initial',
    status: {
      code: 0,
      message: '',
    },
  });
  const [restaurantListForSchedule, setRestaurantListForSchedule] = useState<GetRestaurantList>({
    data: [],
    state: 'initial',
    status: {
      code: 0,
      message: '',
    },
  });

  const [restaurantCreateData, setRestaurantCreateData] = useState<PutRestaurant>({
    data: undefined,
    state: 'initial',
    status: {
      code: 0,
      message: '',
    },
  });
  const [restaurantUpdateData, setRestaurantUpdateData] = useState<UpdateRestaurant>({
    data: undefined,
    state: 'initial',
    status: {
      code: 0,
      message: '',
    },
  });
  const [restaurantDeleteData, setRestaurantDeleteData] = useState<DeleteRestaurant>({
    data: undefined,
    state: 'initial',
    status: {
      code: 0,
      message: '',
    },
  });

  const [restaurantDropdownList, setRestaurantDropdownList] = useState<GetRestaurantDropdownList>({ state: 'initial' });

  // action for restaurantList
  const getRestaurantList = (prefix: string, params?: QueryParamsGetList<restaurantOrderByType>) => {
    setRestaurantList({
      data: [],
      state: 'loading',
      status: {
        code: 0,
        message: '',
      },
    });
    RestaurantListGet(prefix, params || ({ page: 0, itemPerPage: DEFAULT_ROW_PER_PAGE } as QueryParamsGetList<restaurantOrderByType>))
      .then((d) => {
        setRestaurantList({
          data: d.data,
          state: 'ready',
          status: d.status,
          pagination: d.pagination,
        });
      })
      .catch((error) => {
        setRestaurantList({ data: [], error: error?.response, state: 'error', status: error?.response?.data?.status });
      });
  };

  // restaurant detail
  const getRestaurantDetail = (prefix: string, params?: string) => {
    setRestaurantDetail({
      data: undefined,
      state: 'loading',
      status: {
        code: 0,
        message: '',
      },
    });
    RestaurantDetailGet(prefix, params || '')
      .then((d) => {
        setRestaurantDetail({
          data: d.data,
          state: 'ready',
          status: d.status,
        });
      })
      .catch((error) => {
        setRestaurantDetail({ data: undefined, error: error?.response, state: 'error', status: error?.response?.data?.status });
      });
  };
  // NOTE: action to get restaurantList for dropdown on schedule page, get all restaurantList without pagination or sorting.
  const getRestaurantListForSchedule = (prefix: string) => {
    setRestaurantListForSchedule({ data: [], state: 'loading' });
    RestaurantListGet(prefix, { page: -1 } as QueryParamsGetList<restaurantOrderByType>)
      .then((d) => {
        setRestaurantListForSchedule({
          data: d.data,
          state: 'ready',
          status: d.status,
          pagination: d.pagination,
        });
      })
      .catch((error) => {
        setRestaurantListForSchedule({ data: [], error: error?.response, state: 'error', status: error?.response?.data?.status });
      });
  };

  // action for restaurant create
  const setRestaurantCreate = (prefix: string, payload: createRestaurantSchema) => {
    setRestaurantCreateData({
      data: undefined,
      state: 'loading',
      status: {
        code: 0,
        message: '',
      },
    });
    RestaurantCreate(prefix, payload)
      .then((data) => {
        setRestaurantCreateData({
          data: data.data,
          state: 'ready',
          status: data.status,
        });
      })
      .catch((error) => {
        setRestaurantCreateData({ data: undefined, error: error?.response, state: 'error', status: error?.response?.data?.status });
      });
  };

  // action for restaurant update
  const setRestaurantUpdate = (prefix: string, payload: updateRestaurantSchema, restaurantId: string) => {
    setRestaurantUpdateData({
      data: undefined,
      state: 'loading',
      status: {
        code: 0,
        message: '',
      },
    });
    RestaurantUpdate(prefix, restaurantId, payload)
      .then((data) => {
        setRestaurantUpdateData({
          data: data.data,
          state: 'ready',
          status: data.status,
        });
      })
      .catch((error) => {
        setRestaurantUpdateData({ data: undefined, error: error?.response, state: 'error', status: error?.response?.data?.status });
      });
  };

  // action for change restaurant list after update
  const getRestaurantIdUpdateList = (id: string, thName: string, enName: string) => {
    const startArray = restaurantList;
    const restaurantUpdateList = startArray.data.map((restaurant: RestaurantSchema) => {
      if (restaurant.id === id) {
        return {
          ...restaurant,
          thName,
          enName,
        };
      } else {
        return {
          ...restaurant,
        };
      }
    });
    setRestaurantList({
      data: restaurantUpdateList,
      state: 'ready',
      pagination: restaurantList.pagination,
    });
  };

  // action for restaurant delete
  const setRestaurantDelete = (prefix: string, restaurantId: string) => {
    setRestaurantDeleteData({
      data: undefined,
      state: 'loading',
      status: {
        code: 0,
        message: '',
      },
    });
    RestaurantDelete(prefix, restaurantId)
      .then((data) => {
        setRestaurantDeleteData({
          data: data.data,
          state: 'ready',
          status: data.status,
        });
      })
      .catch((error) => {
        setRestaurantDeleteData({ data: error?.response?.data, error: error?.response, state: 'error', status: error?.response?.data?.status });
      });
  };

  // action for change restaurant list after delete
  const getRestaurantIdDeleteList = (id: string) => {
    const startArray = restaurantList;

    const restaurantDeleteList = startArray.data.filter((restaurant: RestaurantSchema) => {
      return restaurant.id !== id;
    });
    setRestaurantList({
      data: restaurantDeleteList,
      state: 'ready',
      pagination: restaurantList.pagination,
    });
    return restaurantDeleteList.length;
  };

  const getRestaurantDropdownList = (prefix: string, restaurantId: string, params: QueryRestaurantDropdownList) => {
    setRestaurantDropdownList({ state: 'loading' });
    RestaurantDropdownListGet(prefix, restaurantId, params)
      .then((res) => {
        setRestaurantDropdownList({
          data: res.data,
          state: 'ready',
          status: res.status,
        });
      })
      .catch((error) => {
        setRestaurantDropdownList({ data: undefined, error: error?.response, state: 'error', status: error?.response?.data?.status });
      });
  };

  const restaurantStore = {
    restaurantList,
    setRestaurantList: getRestaurantList,
    setRestaurantListData: setRestaurantList,
    restaurantListForSchedule,
    setRestaurantListForSchedule: getRestaurantListForSchedule,

    restaurantCreateData,
    setRestaurantCreate,
    setRestaurantCreateData,

    restaurantUpdateData,
    setRestaurantUpdate,
    setRestaurantUpdateData,

    getRestaurantIdUpdateList,

    restaurantDeleteData,
    setRestaurantDelete,
    setRestaurantDeleteData,

    getRestaurantIdDeleteList,

    restaurantDropdownList,
    setRestaurantDropdownList: getRestaurantDropdownList,
    setRestaurantDropdownListData: setRestaurantDropdownList,

    restaurantDetail,
    setRestaurantDetail: getRestaurantDetail,
    restaurantDetailForDetail: restaurantDetail,
    setRestaurantDetailData: setRestaurantDetail,
    setRestaurantDetailForDetail: (prefix: string) => {},
  };

  useEffect(() => {
    return () => {
      /*
       NOTE: cancel all subscriptions and asynchronous tasks to cleanup function and fix Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
      */
      setRestaurantDeleteData({ state: 'initial', data: undefined });
      setRestaurantUpdateData({ state: 'initial', data: undefined });
      setRestaurantCreateData({ state: 'initial', data: undefined });
      setRestaurantList({ state: 'initial', data: [] });
      setRestaurantDetail({ state: 'initial', data: undefined });
    };
  }, []);

  return <RestaurantContext.Provider value={restaurantStore}>{children}</RestaurantContext.Provider>;
};

export { RestaurantContextProvider };
