import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

type AddPhotoButtonProps = {
  id: string;
  onClick: () => void;
  imgErrorText: string;
  callError?: boolean;
  border?: boolean;
  disabled?: boolean;
};

export const AddPhotoButton = ({ id, onClick, imgErrorText, callError, border, disabled }: AddPhotoButtonProps) => {
  return (
    <Box
      id={`${id}-add-image-area`}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: `${border && 'dashed'}`,
        borderWidth: `${border && '1px'}`,
        borderColor: `${border && '#949494'}`,
      }}
    >
      <Button
        id={`${id}-add-image-button`}
        startIcon={<AddIcon color={disabled ? 'disabled' : 'primary'} />}
        onClick={onClick}
        className="btnArea"
        sx={{ width: `${border && '188px'}`, height: `${border && '72px'}` }}
        disabled={disabled}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          ADD PHOTO
          <Typography noWrap sx={{ fontSize: '12px', color: '#9E9E9E', textTransform: 'capitalize', alignItems: 'flex-start' }}>
            Optional
          </Typography>
        </Box>
      </Button>
      {callError && (
        <Box id={`${id}-add-image-error-text`} width={150} sx={{ whiteSpace: 'normal' }}>
          <Typography sx={{ color: 'red', fontSize: '12px', ml: '10px' }}>{imgErrorText}</Typography>
        </Box>
      )}
    </Box>
  );
};
