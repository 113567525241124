import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartOptions } from 'chart.js';
import { formatNumber } from '@/src/utils/formatNumber/formatNumber';
import { DashboardView } from '@/src/types/crud/dashboard/dashboard.summary';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface DataItem {
  label: string;
  value: number;
}

interface MonthlyData {
  month: string;
  weeklyData: DataItem[];
}

interface BarChartComponentProps {
  data: DataItem[];
  title: string;
  groupedData: MonthlyData[];
  formattedLabels: DataItem[];
  view: DashboardView;
}

interface Datasets {
  label: string;
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
  data: number[];
  skipNull: boolean;
}

interface LabelWeek {
  labels: string[];
}

const BarChartComponent: React.FC<BarChartComponentProps> = ({ data, title, groupedData, view, formattedLabels }) => {
  const backgroundColors = 'rgb(76, 175, 80)';
  const labels = data.map((item) => item.label);
  const values = data.map((item) => item.value);
  const lebelsData = formattedLabels.map((item) => item.label);

  const weekValues = groupedData.map((monthData) => {
    return monthData.weeklyData.map((week) => week.value);
  });

  const nonEmptyWeekValues = weekValues.filter((week) => week.length > 0);

  const weekLabels = groupedData.map((monthData) => {
    return monthData.weeklyData.map((week) => week.label);
  });

  const weekMonth = groupedData.filter((monthData) => monthData.weeklyData.length > 0).map((monthData) => `${monthData.month}\n(week 1 - 4)`);

  const labelsWeek: LabelWeek[] = [];

  const WEEKLY = 4;
  const YEAR = 12;

  const tooltipValueLabel = (title) => {
    if (title === 'Costs') {
      return { label: 'costs', bahtSymbol: ' ฿' };
    } else {
      return { label: 'orders', bahtSymbol: '' };
    }
  };

  const datasets: Datasets[] = [];
  const weeks = 7;

  for (let i = 1; i <= weeks; i++) {
    datasets.push({
      label: `WEEK ${i}`,
      backgroundColor: backgroundColors,
      borderColor: backgroundColors,
      borderWidth: 1,
      data: [],
      skipNull: true,
    });
  }
  if (view === 'w') {
    for (let index = 0; index < WEEKLY; index++) {
      labelsWeek.push({ labels: weekLabels.map((item) => item[index]) });
      datasets[index].data = nonEmptyWeekValues.map((values) => values[index]);
    }
  }

  const labelsDatas: string[][] = [];

  for (let idx = 0; idx < YEAR; idx++) {
    const arrayWeekLabels: string[] = [];
    for (let index = 0; index < WEEKLY; index++) {
      arrayWeekLabels.push(labelsWeek[index]?.labels[idx] || 'undefined');
    }
    labelsDatas.push(arrayWeekLabels);
  }

  const labelsForWeekly = labelsDatas.map((subArray) => subArray.filter((label) => label !== 'undefined')).filter((subArray) => subArray.length > 0);

  let chartData: any = {};

  if (view === 'w') {
    chartData = {
      labels: labelsForWeekly,
      datasets,
    };
  } else {
    chartData = {
      labels: lebelsData,
      datasets: [
        {
          label: '',
          data: values,
          backgroundColor: backgroundColors,
          borderColor: backgroundColors,
          borderWidth: 1,
        },
      ],
    };
  }

  const chartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: title,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.raw as number;
            const formattedValue = formatNumber(value);
            const { label, bahtSymbol } = tooltipValueLabel(title);
            return `Total ${label}: ${formattedValue}${bahtSymbol}`;
          },
          title: (tooltipItem) => {
            const splitArray = tooltipItem[0]?.label.split(',');
            return splitArray[tooltipItem[0]?.datasetIndex];
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return formatNumber(value as number);
          },
        },
      },
      x: {
        ticks: {
          align: 'center',
          callback: function (value, index) {
            if (view === 'w') {
              const weekMonthText = weekMonth[index]?.split('\n') || 'undefined';
              return weekMonthText;
            } else if (data[index].label.includes('\n')) {
              const [nameDate, day] = data[index].label?.split('\n') || 'undefined';
              return [`${nameDate}`, `${day}`];
            } else {
              return labels[index];
            }
          },
        },
      },
    },
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <div style={{ width: '100%', height: '500px' }}>
        <Bar data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default BarChartComponent;
