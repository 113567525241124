import { iENV } from '../types';

/** React environment variables */
export const ENVS: iENV = {
  NODE_ENV: (process.env.NODE_ENV || '') as any,
  ENVIRONMENT: process.env?.REACT_APP_ENVIRONMENT as any,
  API_BASE: process.env?.REACT_APP_API_BASE || '',
  API_PREFIX: process.env?.REACT_APP_API_PREFIX || '',
  BASE_URL: process.env?.REACT_APP_BASE_URL || '',
  IMAGES_PATH: process.env?.REACT_APP_IMAGES_PATH || '',
  REACT_APP_API_TEST_API: (process.env?.REACT_APP_API_TEST_API || '') as any,
  FUNCTIONS_REGION: (process.env?.REACT_APP_FUNCTIONS_REGION || '') as any,
  SENTRY_DSN: (process.env?.SENTRY_DSN || 'https://e7cb5e9937f67a4734f10a9eb59aa7e1@o4506029626097664.ingest.sentry.io/4506030697742336') as any,
};

export * from './common';
export * from './apiCallMethods';
export * from './apiCallPaths/api.path.default';
export * from './apiCallPaths/api.path.v1';
export * from './apiCallPaths/api.path.v2';
