import { Stack, CircularProgress, Typography } from '@mui/material';

export function LoadingIcon(props: any) {
  const { setCenter } = props;

  if (setCenter) {
    return (
      <Stack
        style={{
          position: setCenter ? 'absolute' : 'static',
          top: setCenter ? 0 : 'auto',
          left: setCenter ? 0 : 'auto',
          right: setCenter ? 0 : 'auto',
          bottom: setCenter ? 0 : 'auto',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        spacing={1}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" />
        <Typography color="primary" variant="h6" fontWeight="normal">
          Loading
        </Typography>
      </Stack>
    );
  } else {
    return (
      <Stack spacing={1} direction="column" justifyContent="center" alignItems="center">
        <CircularProgress color="primary" />
        <Typography color="primary" variant="h6" fontWeight="normal">
          Loading
        </Typography>
      </Stack>
    );
  }
}
