import { useContext, useEffect, useState } from 'react';
import {
  RestaurantOptionAddonListGet,
  RestaurantOptionAddonCreate,
  RestaurantOptionAddonUpdate,
  RestaurantOptionAddonDelete,
  OrderOverBudgetByOptionCheck,
} from '@/services/http';
import {
  GetRestaurantOptionAddonList,
  PostRestaurantOptionAddon,
  PutRestaurantOptionAddon,
  DeleteRestaurantOptionAddon,
  RestaurantOptionAddonBody,
  GetOrderOverBudgetByOptionPrice,
  QueryOrderOverBudgetByOptionPrice,
  QueryParamsGetList,
  optionAddonOrderByType,
} from '@/types/index';
import { OptionAddonContext } from './initial.store';
import { RestaurantOptionAddonSchema } from '../../../types/schema/restaurantOptionAddon';
import { DEFAULT_ROW_PER_PAGE } from '@/src/app.constants';

export function useOptionAddonContext() {
  return useContext(OptionAddonContext);
}

const OptionAddonContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [restaurantOptionAddonList, setRestaurantOptionAddonList] = useState<GetRestaurantOptionAddonList>({
    data: { addon: [], id: '' },
    state: 'initial',
    status: {
      code: 0,
      message: '',
    },
  });

  const [restaurantOptionAddonCreateData, setRestaurantOptionAddonCreateData] = useState<PostRestaurantOptionAddon>({
    data: {} as RestaurantOptionAddonSchema,
    state: 'initial',
    status: {
      code: 0,
      message: '',
    },
  });

  const [restaurantOptionAddonUpdateData, setRestaurantOptionAddonUpdateData] = useState<PutRestaurantOptionAddon>({
    data: [],
    state: 'initial',
    status: {
      code: 0,
      message: '',
    },
  });

  const [restaurantOptionAddonDeleteData, setRestaurantOptionAddonDeleteData] = useState<DeleteRestaurantOptionAddon>({
    data: [],
    state: 'initial',
    status: {
      code: 0,
      message: '',
    },
  });

  const [overBudgetOrdersByOption, setOverBudgetOrdersByOption] = useState<GetOrderOverBudgetByOptionPrice>({ state: 'initial' });

  // action for RestaurantOptionAddonList
  const getRestaurantOptionAddonList = (prefix: string, restaurantId: string, groupId: string, params?: QueryParamsGetList<optionAddonOrderByType>) => {
    setRestaurantOptionAddonList({
      data: { addon: [], id: '' },
      state: 'loading',
      status: {
        code: 0,
        message: '',
      },
    });

    RestaurantOptionAddonListGet(
      prefix,
      restaurantId,
      groupId,
      params || ({ page: 0, itemPerPage: DEFAULT_ROW_PER_PAGE } as QueryParamsGetList<optionAddonOrderByType>)
    )
      .then((d) => {
        setRestaurantOptionAddonList({
          thName: d.thName,
          enName: d.enName,
          data: d.data,
          state: 'ready',
          status: d.status,
          pagination: d.pagination,
        });
      })
      .catch((error) => {
        setRestaurantOptionAddonList({
          data: { id: '', addon: [] },
          error: error?.response,
          state: 'error',
          status: error?.response?.data?.status,
        });
      });
  };

  // action for create restaurantOptionAddon
  const setRestaurantOptionAddonCreate = (prefix: string, restaurantId: string, groupId: string, payload: RestaurantOptionAddonBody) => {
    setRestaurantOptionAddonCreateData({
      data: {} as RestaurantOptionAddonSchema,
      state: 'loading',
      status: {
        code: 0,
        message: '',
      },
    });

    RestaurantOptionAddonCreate(prefix, restaurantId, groupId, payload)
      .then((data) => {
        setRestaurantOptionAddonCreateData({
          data: data.data,
          state: 'ready',
          status: data.status,
        });
      })
      .catch((error) => {
        setRestaurantOptionAddonCreateData({
          data: {} as RestaurantOptionAddonSchema,
          error: error?.response,
          state: 'error',
          status: error?.response?.data?.status,
        });
      });
  };

  // action update restaurantOptionAddon
  const setRestaurantOptionAddonUpdate = (prefix: string, restaurantId: string, groupId: string, optionAddonId: string, payload: RestaurantOptionAddonBody) => {
    setRestaurantOptionAddonUpdateData({
      data: [],
      state: 'loading ',
      status: {
        code: 0,
        message: '',
      },
    });
    RestaurantOptionAddonUpdate(prefix, restaurantId, groupId, optionAddonId, payload)
      .then((data) => {
        setRestaurantOptionAddonUpdateData({
          data: data.data,
          state: 'ready',
          status: data.status,
        });
      })
      .catch((error) => {
        setRestaurantOptionAddonUpdateData({ data: [], error: error?.response, state: 'error', status: error?.response?.data?.status });
      });
  };

  // action for change optionAddon after update
  const getRestaurantOptionAddonIdUpdateList = (optionAddonId: string, thName: string, enName: string, price: number) => {
    const startArray = restaurantOptionAddonList;

    const restaurantOptionAddonUpdateList = startArray.data.addon.map((restaurantOptionAddon: any) => {
      if (restaurantOptionAddon.id === optionAddonId) {
        return {
          ...restaurantOptionAddon,
          thName,
          enName,
          price,
        };
      } else {
        return {
          ...restaurantOptionAddon,
        };
      }
    });
    setRestaurantOptionAddonList({
      data: {
        ...startArray.data,
        addon: restaurantOptionAddonUpdateList,
      },
      thName: restaurantOptionAddonList.thName,
      enName: restaurantOptionAddonList.enName,
      state: 'ready',
      pagination: restaurantOptionAddonList.pagination,
    });
  };

  // action for delete optionAddon
  const setRestaurantOptionAddonDelete = (prefix: string, restaurantId: string, groupId: string, optionAddonId: string) => {
    setRestaurantOptionAddonDeleteData({
      data: [],
      state: 'loading',
      status: {
        code: 0,
        message: '',
      },
    });
    RestaurantOptionAddonDelete(prefix, restaurantId, groupId, optionAddonId)
      .then((data) => {
        setRestaurantOptionAddonDeleteData({
          data: data.data,
          state: 'ready',
          status: data.status,
        });
      })
      .catch((error) => {
        setRestaurantOptionAddonDeleteData({ data: error?.response?.data, error: error?.response, state: 'error', status: error?.response?.data?.status });
      });
  };

  // action  for change restaurant optionAddon list after delete
  const getRestaurantOptionAddonIdDeleteList = (optionAddonId: string) => {
    const startArray = restaurantOptionAddonList;
    const restaurantOptionAddonDeleteList = startArray.data.addon.filter((restaurantOptionAddon: any) => {
      if (restaurantOptionAddon.id !== optionAddonId) {
        return {
          ...restaurantOptionAddon,
        };
      }
      return undefined;
    });
    setRestaurantOptionAddonList({
      data: {
        ...startArray.data,
        addon: restaurantOptionAddonDeleteList,
      },
      thName: restaurantOptionAddonList.thName,
      enName: restaurantOptionAddonList.enName,
      state: 'ready',
      pagination: restaurantOptionAddonList.pagination,
    });
  };

  /**
   * action for get Over-Budget Orders before update Option add on price
   * @api /option-addons/orders/over-budget/{restaurantId}/{groupId}/{optionAddonId}?price=
   * */
  const getOverBudgetOrdersByOption = (
    prefix: string,
    restaurantId: string,
    groupId: string,
    optionAddonId: string,
    params: QueryOrderOverBudgetByOptionPrice
  ) => {
    setOverBudgetOrdersByOption({ state: 'loading' });
    OrderOverBudgetByOptionCheck(prefix, restaurantId, groupId, optionAddonId, params)
      .then((data) => {
        setOverBudgetOrdersByOption({
          data: data.data,
          state: 'ready',
          status: data.status,
        });
      })
      .catch((error) => {
        setOverBudgetOrdersByOption({ data: undefined, error: error?.response, state: 'error', status: error?.response?.data?.status });
      });
  };

  const optionAddonStore = {
    restaurantOptionAddonList,
    setRestaurantOptionAddonList: getRestaurantOptionAddonList,
    setRestaurantOptionAddonListData: setRestaurantOptionAddonList,

    restaurantOptionAddonCreateData,
    setRestaurantOptionAddonCreate,
    setRestaurantOptionAddonCreateData,

    restaurantOptionAddonUpdateData,
    setRestaurantOptionAddonUpdateData,
    setRestaurantOptionAddonUpdate,
    getRestaurantOptionAddonIdUpdateList,

    restaurantOptionAddonDeleteData,
    setRestaurantOptionAddonDeleteData,
    setRestaurantOptionAddonDelete,
    getRestaurantOptionAddonIdDeleteList,

    overBudgetOrdersByOption,
    setOverBudgetOrdersByOption: getOverBudgetOrdersByOption,
  };

  useEffect(() => {
    return () => {
      /*
       NOTE: cancel all subscriptions and asynchronous tasks to cleanup function and fix Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
      */
      setRestaurantOptionAddonDeleteData({ state: 'initial', data: [] });
      setRestaurantOptionAddonUpdateData({ state: 'initial', data: [] });
      setRestaurantOptionAddonCreateData({ state: 'initial', data: {} as RestaurantOptionAddonSchema });
      setRestaurantOptionAddonList({ state: 'initial', data: { id: '', addon: [] } });
    };
  }, []);

  return <OptionAddonContext.Provider value={optionAddonStore}> {children}</OptionAddonContext.Provider>;
};

export { OptionAddonContextProvider };
