import { useContext, useEffect, useState } from 'react';
import { responseStatusSchema } from '@/types/schema/responseStatus';
import { SnackbarContext } from './initial.store';

export function useSnackbarContext() {
  return useContext(SnackbarContext);
}

const SnackBarContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [responseStatus, setResponseStatus] = useState({} as responseStatusSchema);

  const editResponseStatus = (val: responseStatusSchema) => {
    setResponseStatus(val);
  };

  const responseStatusStore = {
    responseStatus,
    setResponseStatus: editResponseStatus,
  };

  useEffect(() => {
    return () => {
      /*
       NOTE: cancel all subscriptions and asynchronous tasks to cleanup function and fix Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
      */
      setResponseStatus({} as responseStatusSchema);
    };
  }, []);

  return <SnackbarContext.Provider value={responseStatusStore}>{children}</SnackbarContext.Provider>;
};
const withSnackBarContext = (Component: any) => {
  return function RestaurantsComponent(props: any) {
    return <SnackbarContext.Consumer>{(contexts) => <Component {...props} {...contexts} />}</SnackbarContext.Consumer>;
  };
};

export { SnackBarContextProvider, withSnackBarContext };
