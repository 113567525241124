import React, { Dispatch, SetStateAction, useState } from 'react';
import { Box, TextField, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { QueryParamsGetList, restaurantOrderByType } from '@/types/index';

interface SearchRestaurantProps {
  setPaginationParams: Dispatch<SetStateAction<QueryParamsGetList<restaurantOrderByType>>>;
}

export function SearchRestaurant(props: SearchRestaurantProps) {
  const { setPaginationParams } = props;
  const [preSearchText, setPreSearchText] = useState<string>();

  const handleSearchTextChange = (searchText: string) => {
    const searchTextAllow = searchText.replace(/`/g, '');
    setPreSearchText(searchTextAllow || undefined);
  };

  const handleClickClearSearch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setPreSearchText(undefined);
    setPaginationParams((prev) => {
      return prev.search ? { ...prev, search: undefined, page: 0 } : prev;
    });
  };

  const handleSubmitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPaginationParams((prev) => {
      return preSearchText === undefined && prev.search === preSearchText
        ? prev
        : { ...prev, search: preSearchText, page: 0, orderBy: undefined, sort: undefined };
    });
  };

  return (
    <Box component="form" className=" w-52 !mr-0 float-right relative" noValidate autoComplete="off" onSubmit={handleSubmitSearch}>
      <TextField
        type="text"
        size="small"
        InputProps={{
          id: 'search-restaurant',
          className: '!px-2',
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: preSearchText ? (
            <InputAdornment position="end">
              <IconButton aria-label="clear-search" onClick={handleClickClearSearch} id="clear-search">
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ) : undefined,
        }}
        placeholder="Search restaurants"
        value={preSearchText || ''}
        onChange={(e) => handleSearchTextChange(e.target.value)}
      />
    </Box>
  );
}
