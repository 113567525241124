import * as React from 'react';
import { SelectChangeEvent, Select, MenuItem } from '@mui/material';
import moment from 'moment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect, useState } from 'react';
export function BasicMonthPicker(props: any) {
  const { Month, setMonth, Year } = props;
  const handleChangeMonth = (event: SelectChangeEvent) => {
    setMonth(event.target.value as string);
  };
  const now = moment();
  const currentMonthFormatted = now.format('MMMM');
  const currentMonthIndex = now.get('months');
  const allMonths = moment.months();
  const [ListOfMonth, setListOfMonth] = useState<string[]>(allMonths);

  useEffect(() => {
    if (Year && Year.toString() === now.year().toString()) {
      // if the month was older than current month then set current month instead
      if (allMonths.findIndex((month) => month === Month) < currentMonthIndex) {
        setMonth(currentMonthFormatted);
      }
      setListOfMonth(allMonths.slice(now.month()));
    } else {
      setListOfMonth(allMonths);
    }
  }, [Year]);

  return (
    <Select
      IconComponent={KeyboardArrowDownIcon}
      labelId="demo-simple-select-label"
      id="select-month"
      value={Month}
      defaultValue={now.format('MMMM')}
      onChange={handleChangeMonth}
      inputProps={{
        id: 'select-month-input',
      }}
    >
      {ListOfMonth.map((month, index) => (
        <MenuItem key={index} value={month} id={'month-' + index}>
          {month}
        </MenuItem>
      ))}
    </Select>
  );
}
