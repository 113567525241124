import React from 'react';
import { Button, Box, CardMedia } from '@mui/material';

interface Props {
  id: string;
  onClick: () => void;
  imageURL: string | undefined;
  border?: boolean;
  error?: boolean;
  layout: {
    width: number;
    height: number;
  };
  disabled?: boolean;
}

export const RemoveImageArea = ({ id, onClick, imageURL, border, error, layout, disabled }: Props) => {
  return (
    <Box id={`${id}-image-area`} sx={{ display: 'flex', flexDirection: 'row', mr: '15px' }}>
      <CardMedia
        id={`${id}-image`}
        component="img"
        sx={{
          width: layout.width,
          height: layout.height,
          objectFit: `${border && 'contain'}`,
          border: `${border && 'dashed'}`,
          borderColor: `${border && error ? 'red' : '#949494'}`,
          borderWidth: `${border && '1px'}`,
        }}
        image={imageURL}
      />
      <Button
        id={`${id}-remove-image-button`}
        className="btnArea"
        variant="text"
        size={'small'}
        sx={{ color: 'red', textTransform: 'capitalize', m: 0, p: 0, ml: 1 }}
        onClick={onClick}
        disabled={disabled}
      >
        Remove
      </Button>
    </Box>
  );
};
