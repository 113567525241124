import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import {
  GetRestaurantOptionAddonList,
  PutRestaurantOptionAddon,
  PostRestaurantOptionAddon,
  DeleteRestaurantOptionAddon,
  RestaurantOptionAddonBody,
  QueryOrderOverBudgetByOptionPrice,
  GetOrderOverBudgetByOptionPrice,
  QueryParamsGetList,
  optionAddonOrderByType,
} from '@/types/index';

import { apiMethodV1 } from '@/app.constants/apiCallMethods/index';
import { paramsQueryString } from '@/src/utils';

/**
 * @private This api is protected and can only be accessed as role=ADMIN
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */

// Get List
const RestaurantOptionAddonListGet = (
  prefix: string,
  restaurantId: string,
  groupId: string,
  params: QueryParamsGetList<optionAddonOrderByType>,
  hardRejection = true
): Promise<GetRestaurantOptionAddonList> => {
  const instance = new AxiosService(
    {
      method: apiMethodV1.get,
      timeout: 12000,
    },
    true,
    hardRejection
  );
  const queryUri = paramsQueryString(prefix + restaurantId + '/' + groupId, params);

  const config = {
    ...instance.config,
  };

  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<GetRestaurantOptionAddonList>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetRestaurantOptionAddonList>;
};

// Create
const RestaurantOptionAddonCreate = (
  prefix: string,
  restaurantId: string,
  groupId: string,
  body: RestaurantOptionAddonBody,
  hardRejection = true
): Promise<PostRestaurantOptionAddon> => {
  const instance = new AxiosService({ method: apiMethodV1.post, timeout: 12000 }, true, hardRejection);
  // token not yet set on backend

  const config = {
    ...instance.config,
  };

  return instance.client.post(prefix + restaurantId + '/' + groupId, JSON.stringify(body), config).then((d: AxiosResponse<PostRestaurantOptionAddon>) => {
    return d.data;
  });
};

// Update
const RestaurantOptionAddonUpdate = (
  prefix: string,
  restaurantId: string,
  groupId: string,
  optionAddonId: string,
  body: RestaurantOptionAddonBody,
  hardRejection = true
): Promise<PutRestaurantOptionAddon> => {
  const instance = new AxiosService({ method: apiMethodV1.put, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .put(prefix + restaurantId + '/' + groupId + '/' + optionAddonId, JSON.stringify(body), config)
    .then((d: AxiosResponse<PutRestaurantOptionAddon>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<PutRestaurantOptionAddon>;
};

// Delete
const RestaurantOptionAddonDelete = (
  prefix: string,
  restaurantId: string,
  groupId: string,
  optionAddonId: string,
  hardRejection = true
): Promise<DeleteRestaurantOptionAddon> => {
  const instance = new AxiosService({ method: apiMethodV1.delete, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .delete(prefix + restaurantId + '/' + groupId + '/' + optionAddonId, config)
    .then((d: AxiosResponse<DeleteRestaurantOptionAddon>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<DeleteRestaurantOptionAddon>;
};

const OrderOverBudgetByOptionCheck = (
  prefix: string,
  restaurantId: string,
  groupId: string,
  optionAddonId: string,
  params: QueryOrderOverBudgetByOptionPrice,
  hardRejection = true
): Promise<GetOrderOverBudgetByOptionPrice> => {
  const instance = new AxiosService({ method: apiMethodV1.post, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  /** @api /option-addons/orders/over-budget/{restaurantId}/{groupId}/{optionAddonId}?price= */
  const queryUri = paramsQueryString(`${prefix}${restaurantId}/${groupId}/${optionAddonId}`, params);

  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<GetOrderOverBudgetByOptionPrice>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetOrderOverBudgetByOptionPrice>;
};

export { RestaurantOptionAddonListGet, RestaurantOptionAddonUpdate, RestaurantOptionAddonCreate, RestaurantOptionAddonDelete, OrderOverBudgetByOptionCheck };
