import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import { QueryParamsGetList, optionAddonOrderByType } from '@/types/index';

import { apiMethodV1 } from '@/app.constants/apiCallMethods/index';
import { paramsQueryString } from '@/src/utils';
import { apiPathV2 } from '@/src/app.constants/apiCallPaths/api.path.v2';
import { AddonRequest } from '@/src/types/crud/optionAddon/optionAddon';
import { apiPathV1 } from '@/src/app.constants/apiCallPaths/api.path.v1';

/**
 * @private This api is protected and can only be accessed as role=ADMIN
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */

// Get List
const RestaurantOptionAddonListGetV2 = <T>(
  restaurantId: string,
  groupId: string,
  params: QueryParamsGetList<optionAddonOrderByType>,
  hardRejection = true
): Promise<T> => {
  const instance = new AxiosService(
    {
      method: apiMethodV1.get,
      timeout: 12000,
    },
    true,
    hardRejection
  );
  const queryUri = paramsQueryString(`${apiPathV2.optionAddon}${restaurantId}/${groupId}`, params);
  // token not yet set on backend

  const config = {
    ...instance.config,
  };
  config.headers = {
    ...config.headers,
    'Content-Type': 'application/json',
  };

  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

// Create
const RestaurantOptionAddonCreate = <T>(restaurantId: string, groupId: string, body: AddonRequest, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.post, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .post(`${apiPathV1.optionCreate}${restaurantId}/${groupId}`, JSON.stringify(body), config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

// Update
const RestaurantOptionAddonUpdate = <T>(restaurantId: string, groupId: string, optionAddonId: string, body: AddonRequest, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.put, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .put(`${apiPathV1.optionUpdate}${restaurantId}/${groupId}/${optionAddonId}`, JSON.stringify(body), config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

// Delete
const RestaurantOptionAddonDelete = <T>(restaurantId: string, groupId: string, optionAddonId: string, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.delete, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .delete(`${apiPathV1.optionDelete}${restaurantId}/${groupId}/${optionAddonId}`, config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

export { RestaurantOptionAddonListGetV2, RestaurantOptionAddonUpdate, RestaurantOptionAddonCreate, RestaurantOptionAddonDelete };
