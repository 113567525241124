import React from 'react';
import { Backdrop, Box, Button, Typography } from '@mui/material';

const modalStyle = {
  position: 'fixed',
  margin: 'auto',
  top: '30%',
  left: '50%',
  width: 495,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  display: 'grid',
  alignContent: 'stretch',
  justifyContent: 'space - around',
  transform: 'translate(-50%, -50%)',
  alignItems: 'start',
  justifyItems: 'center',
  'z-index': 1000,
};

interface ModalConfirmChangeRestaurantsProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  oldRestaurant: any | undefined;
  newRestaurant: any | undefined;
}
export default function ModalConfirmChangeRestaurants(props: ModalConfirmChangeRestaurantsProps) {
  const { open, onConfirm, onCancel, oldRestaurant, newRestaurant } = props;

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <Box sx={modalStyle} className="mt-10">
        <Typography
          sx={{ color: '#000', fontSize: '20px', width: 'inherit', fontWeight: 'bold', marginBottom: '13px' }}
          id="transition-modal-title"
          variant="h5"
          component="h2"
        >
          Confirm Restaurant Change?
        </Typography>
        <Typography id="transition-modal-description" variant="caption" sx={{ color: '#000', fontSize: '16px' }}>
          By confirming this change, any existing orders placed with {oldRestaurant?.restaurantThName} ({oldRestaurant?.restaurantEnName}) will be replaced by
          the users fallback meal at {newRestaurant?.thName} ({newRestaurant?.enName}). Users will be notified.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            marginTop: '30px',
            width: 'inherit',
          }}
        >
          <Button id={'btn-modal-cancel-all-order-yes'} variant="contained" onClick={onConfirm} sx={{ marginRight: '20px' }}>
            CONFIRM CHANGE
          </Button>
          <Button id={'btn-modal-cancel-all-order-no'} color={'error'} variant="outlined" onClick={onCancel}>
            CANCEL
          </Button>
        </Box>
      </Box>
    </Backdrop>
  );
}
