import { RemoteConfig } from '../types';

export const ROWS_PER_PAGE_OPTIONS = [10, 20];
export const DEFAULT_ROW_PER_PAGE = 20;
export const LIMIT_TH_CHAR = /^[ก-๏\s 0-9๐-๙!@   #$%^&*()_+\-=~\\[\]{};':"\\|,.<>\\/?]*$/;
export const LIMIT_EN_CHAR = /^[a-zA-Z0-9!@   #$%^&*()_+\-=~\\[\]{};':"\\|,.<>\\/?]*$/;
export const LIMIT_TH_AND_EN_CHAR = /^[ก-๏\s 0-9๐-๙!@   #$%^&*()_+\-=~\\[\]{};':"\\|,.<>\\/? a-zA-Z]*$/;
export const LIMIT_PRICE_CHAR = /^[0-9]*$/;
export const MAX_PURCHASE_LIMIT = 65;
export const LOADING = 'Loading...';
export const LIMIT_TIME_ORDER = 18;
export const LIMIT_TIME_ORDER_STRING = 'T18:00:00+07:00';
export const DEFAULT_DISPLAY_CONFIG = { dateFormat: 'ddd DD MMM YYYY', dateTimeFormat: 'DD MMM YYYY hh:mm A', datePickerFormat: 'MM/DD/YYYY' } as RemoteConfig;
