import React from 'react';
import { TablePagination } from '@mui/material';
import { ROWS_PER_PAGE_OPTIONS } from '@/app.constants/index';
import { QueryParamsGetList } from '@/src/types';

interface EnhancedTablePaginationV2Props {
  page: number;
  rowsPerPage: number;
  total: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  setPaginationParams: React.Dispatch<React.SetStateAction<QueryParamsGetList<any>>>;
  // NOTE: to support some page that have more Rows Per Page Option (ex. orders page show 'all')
  addRowsPerPageOption?: (number | { value: number; label: string })[];
  disabledPagination?: boolean;
  countCurrentRows?: number;
}

export function EnhancedTablePaginationV2(props: EnhancedTablePaginationV2Props) {
  const { page, setPage, rowsPerPage, setRowsPerPage, total, setPaginationParams, addRowsPerPageOption, disabledPagination, countCurrentRows } = props;

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    setPaginationParams((prevState) => {
      return { ...prevState, page: newPage };
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPaginationParams((prevState) => {
      return {
        ...prevState,
        page: event.target.value === '-1' ? -1 : 0,
        itemPerPage: event.target.value === '-1' ? total : parseInt(event.target.value, 10),
      };
    });
  };

  const rowsPerPageOptions: (number | { value: number; label: string })[] = addRowsPerPageOption
    ? [...ROWS_PER_PAGE_OPTIONS, ...addRowsPerPageOption]
    : ROWS_PER_PAGE_OPTIONS;

  return (
    <TablePagination
      id="table-pagination-v2"
      rowsPerPageOptions={rowsPerPageOptions}
      className="export-omit"
      component="div"
      count={total}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      disabled={disabledPagination}
      slotProps={{
        actions: {
          nextButton: {
            id: 'pagination-next',
          },
          previousButton: {
            id: 'pagination-back',
          },
        },
        select: {
          id: 'select-pagination',
          native: true,
        },
      }}
      labelDisplayedRows={({ from, to, count }) => {
        if (countCurrentRows !== undefined && countCurrentRows !== 0) {
          return `${from}–${from + countCurrentRows - 1} of ${count !== -1 ? count : `more than ${to}`}`;
        } else {
          return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
        }
      }}
    />
  );
}
