import React, { useRef } from 'react';
// mui
import { Autocomplete, TextField, FormControl, Checkbox, Theme, SxProps, AutocompleteChangeReason } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ClearIcon from '@mui/icons-material/Clear';

// types + constants
import { RestaurantAddOnsSchema } from '@/types/schema/restaurantAddOns';
import { styles } from '../../../../styles/theme/index';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface GroupAutocompleteProps {
  clearIconId: string;
  key: string;
  id: string;
  sx: SxProps<Theme>;
  name: string;
  multiple: boolean;
  options: any[];
  callback: (event: any, value: any, reason: any, details: any, multiple: boolean, remove?: any) => void;
  order: number;
}

export const GroupAutocomplete = (props: GroupAutocompleteProps) => {
  const { multiple, name, options, callback, sx, clearIconId, order } = props;
  const classes = styles();
  const selectedRef = useRef<any>(null);
  return (
    <FormControl sx={{ ...sx }}>
      {multiple ? (
        <Autocomplete
          clearIcon={<ClearIcon id={clearIconId} />}
          multiple
          id={'autocomplete-select-multi-group'}
          componentsProps={{
            popupIndicator: {
              id: `btn-arrow-select-multi-group-${order}`,
            },
            clearIndicator: {
              id: `btn-clear-select-multi-group-${order}`,
            },
          }}
          disableCloseOnSelect
          options={options}
          getOptionLabel={(option: RestaurantAddOnsSchema) => `${option.thName} [+${option.price}]`}
          popupIcon={<KeyboardArrowDownIcon />}
          renderInput={(params) => <TextField {...params} size={'small'} label={`Select ${name} (Multiple)`} />}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} id={'group-' + options.indexOf(option)} />
              <div className={classes.wrap_text}>
                {`${option.thName} [+${option.price}]`} {options.indexOf(option)}
              </div>
            </li>
          )}
          onChange={(event: React.SyntheticEvent, values: RestaurantAddOnsSchema[], reason: AutocompleteChangeReason, details: any) => {
            callback(event, values, reason, details, multiple);
          }}
        />
      ) : (
        <Autocomplete
          clearIcon={<ClearIcon />}
          id={`autocomplete-pick-one-${order}`}
          componentsProps={{
            popupIndicator: {
              id: `btn-arrow-group-${order}`,
            },
            clearIndicator: {
              id: `btn-clear-group-${order}`,
            },
          }}
          options={options}
          getOptionLabel={(option: RestaurantAddOnsSchema) => `${option.thName} [+${option.price}]`}
          popupIcon={<KeyboardArrowDownIcon />}
          renderInput={(params) => <TextField {...params} size={'small'} label={`Select ${name} (Pick 1)`} />}
          renderOption={(props, option, { selected }) => (
            <li {...props} id={'li-' + options.indexOf(option)}>
              <div id={'pick-' + options.indexOf(option)}>{`${option.thName} [+${option.price}]`}</div>
            </li>
          )}
          onChange={(event: any, value: RestaurantAddOnsSchema | null, reason: AutocompleteChangeReason, details: any) => {
            const selected = selectedRef.current;
            if (reason === 'selectOption' && selected === null) {
              selectedRef.current = value;
              callback(event, value, reason, details, multiple);
            } else if (reason === 'selectOption' && selected !== null) {
              const remove = selectedRef.current;
              selectedRef.current = value;
              // add new selection
              callback(event, value, 'selectOption', details, multiple, remove);
            } else if (reason === 'clear') {
              callback(event, selectedRef.current, 'removeOption', details, multiple);
              selectedRef.current = null;
            }
          }}
        />
      )}
    </FormControl>
  );
};
