import React from 'react';
import { Snackbar, Alert, IconButton, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

export default function SnackBarChangeFallbackMeal({ open, handleClose, message, description }) {
  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" onClick={handleClose} key="bottom">
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      action={action}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: '70%' }}>
        <Typography variant="h6">{message}</Typography>
        <Typography variant="body2">{description}</Typography>
      </Alert>
    </Snackbar>
  );
}
