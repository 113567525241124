import { Dispatch, SetStateAction } from 'react';
import { PaginatedCrud } from '../types/crud/crud';

export const updateTablePaginationTotal = <T>(
  count: number,
  setTotal: Dispatch<SetStateAction<number>>,
  tableList: React.MutableRefObject<PaginatedCrud<T>>
) => {
  setTotal(count);
  if (tableList.current.pagination?.total !== undefined) {
    tableList.current.pagination.total = count;
  }
};
