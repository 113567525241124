import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

export default function ModalForFirstMenuFallbackMeal({ open, handleClose }) {
  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="fallbackMeal" aria-describedby="modal-fallbackMeal">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '5px',
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Notice
          </Typography>
          <Typography id="modal-description" variant="body1" sx={{ mt: 3 }}>
            This menu item has been automatically set as the default fallback meal. To change this, please add more menu items and select a different default
            fallback meal if desired.
          </Typography>
          <Button onClick={handleClose} variant="contained" size="small" sx={{ mt: 3 }}>
            OK
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
