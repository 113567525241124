import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { ActionUnit2, commonSetError, initialApiContext2 } from '../common';
import { CommonCrud } from '@/src/types';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import { getUserStaffList, setSuspend } from '../../http/user.v2';
import { apiPathV2 } from '@/src/app.constants';
import { getCookieByName } from '@/src/utils';
import useUserPagination, { IPaginationData, userPaginationDefault } from './hooks/useUserPagination';
import useUserModal, { IUserModal, userModalDefault } from './hooks/useUserModal';
import useUserSnackbar, { IUserSnackbarData, userSnackbarDefault } from './hooks/useUserSnackbar';

declare namespace ActionTypes {
  export type SuspendUsers = CommonCrud<any>;
  export namespace RequestAct {
    export type SuspendUserAct = (params: any) => Promise<any>;
    export type UpdateSuspendAct = (params: { id: string; suspendValue: boolean }) => Promise<any>;
  }
}

interface IUserStoreContextV2 {
  suspends: string[];
  staffs: any[];
  loading: boolean;
  pagination: IPaginationData & { total: number };
  modal: IUserModal & { onConfirm: (id: string, checked: boolean) => void };
  fetchSuspendUsers: ActionUnit2<ActionTypes.SuspendUsers> & { requestAct: ActionTypes.RequestAct.SuspendUserAct };
  updateSuspend: ActionUnit2<CommonCrud<any>> & { requestAct: ActionTypes.RequestAct.UpdateSuspendAct };
  onSuspendToggle: (id: string, val: boolean) => void;
  snackbar: IUserSnackbarData;
}

const initialState: IUserStoreContextV2 = {
  suspends: [],
  staffs: [],
  loading: true,
  pagination: userPaginationDefault,
  // eslint-disable-next-line prettier/prettier
  modal: { ...userModalDefault, onConfirm: (_id: string, _checked: boolean) => {} },
  fetchSuspendUsers: { ...initialApiContext2<ActionTypes.SuspendUsers, ActionTypes.RequestAct.SuspendUserAct>() },
  updateSuspend: { ...initialApiContext2<CommonCrud<any>, ActionTypes.RequestAct.UpdateSuspendAct>() },
  // eslint-disable-next-line prettier/prettier
  onSuspendToggle: (_id: string, _val: boolean) => {},
  snackbar: userSnackbarDefault,
};

const UserStoreContext: React.Context<IUserStoreContextV2> = createContext(initialState);

const UserStoreContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [suspendUsers, setSuspendUsers] = useState<CommonCrud<any>>(initialState.fetchSuspendUsers.current);
  const [updateSuspendReq, setUpdateSuspendReq] = useState<CommonCrud<any>>(initialState.updateSuspend.current);
  const [suspends, setSuspends] = useState<string[]>([]);
  const userPagination = useUserPagination();

  const userModal = useUserModal();
  const userSnackbar = useUserSnackbar();

  const getUsers = useCallback<ActionTypes.RequestAct.SuspendUserAct>(
    async (params) => {
      const jwtToken = getCookieByName('jwtToken');
      setSuspendUsers({ ...initialState.fetchSuspendUsers.current, state: STATE_ENUM.LOADING });

      try {
        const { status, data, pagination } = await getUserStaffList(apiPathV2.users, jwtToken, params);
        const staffSuspends: string[] = data?.filter((s) => s.suspendDaysLeft > 0).map((s) => s.id) || [];
        setSuspends(staffSuspends);
        setSuspendUsers({ state: STATE_ENUM.READY, status, data });
        if (userPagination.setResponsePagination) {
          userPagination.setResponsePagination(pagination);
        }
      } catch (error) {
        commonSetError(error, setSuspendUsers);
      }
    },
    [userPagination.setResponsePagination]
  );

  const updateSuspend = useCallback<ActionTypes.RequestAct.UpdateSuspendAct>(
    async (params) => {
      setUpdateSuspendReq({ ...initialState.updateSuspend.current, state: STATE_ENUM.LOADING });

      const { status, data } = await setSuspend(apiPathV2.suspendUser, params.id, params.suspendValue);
      if (status && status?.code === 200) {
        setUpdateSuspendReq({ state: STATE_ENUM.READY, status, data });
        getUsers({
          page: userPagination.page,
          itemPerPage: userPagination.rowsPerPage,
          search: userPagination.search,
          orderBy: userPagination.orderBy,
          sort: userPagination.order,
        });
      } else {
        setUpdateSuspendReq({ state: STATE_ENUM.ERROR });
        throw new Error('error');
      }
    },
    [userPagination]
  );

  const onSuspendToggle = useCallback(
    (id: string, val: boolean) => {
      let newSuspend = [...suspends];
      const suspendedIndex = suspends.findIndex((s) => s === id);
      if (suspendedIndex >= 0) {
        newSuspend = newSuspend.filter((s) => s !== id);
      } else {
        newSuspend.push(id);
      }
      setSuspends(newSuspend);
    },
    [suspends, updateSuspend]
  );

  const onConfirmModal = useCallback(
    async (id: string, checked: boolean) => {
      if (id) {
        onSuspendToggle(id, checked);
        try {
          await updateSuspend({ id, suspendValue: checked });
          userSnackbar.handleSnackbar('success', 'update suspend success.');
          userSnackbar.setSnackbarOpen(true);
        } catch (error) {
          userSnackbar.handleSnackbar('error', 'Something went wrong. Please try again.');
          userSnackbar.setSnackbarOpen(true);
        }

        if (userModal?.setOpenModal) {
          userModal.setOpenModal(false);
        }
      }
    },
    [userModal.body.id, userPagination]
  );

  useEffect(() => {
    getUsers({
      itemPerPage: userPagination.rowsPerPage,
      page: userPagination.page,
      search: userPagination.search,
      orderBy: userPagination.orderBy,
      sort: userPagination.order,
    });
  }, [userPagination.page, userPagination.rowsPerPage, userPagination.search, userPagination.orderBy, userPagination.order]);

  return (
    <UserStoreContext.Provider
      value={{
        suspends,
        staffs: suspendUsers?.data || [],
        loading: suspendUsers.state === STATE_ENUM.LOADING,
        fetchSuspendUsers: {
          current: suspendUsers,
          setState: setSuspendUsers,
          requestAct: getUsers,
        },
        updateSuspend: {
          current: updateSuspendReq,
          setState: setUpdateSuspendReq,
          requestAct: updateSuspend,
        },
        pagination: {
          ...userPagination,
          total: userPagination.responsePagination?.total || 0,
        },
        modal: {
          ...userModal,
          onConfirm: onConfirmModal,
        },
        onSuspendToggle,
        snackbar: userSnackbar,
      }}
    >
      {children}
    </UserStoreContext.Provider>
  );
};

const withUserStoreContext = (Component: any) => {
  return function UserStoreComponent(props: any) {
    return <UserStoreContext.Consumer>{(contexts) => <Component {...props} {...contexts} />}</UserStoreContext.Consumer>;
  };
};

const useUserStoreContext = () => {
  return useContext(UserStoreContext);
};

export { UserStoreContextProvider, withUserStoreContext, useUserStoreContext };
