import { useContext, useEffect, useState } from 'react';
import {
  RestaurantGroupsListGet,
  RestaurantGroupsCreate,
  RestaurantGroupsUpdate,
  RestaurantGroupsDelete,
  RestaurantGroupsCountOrderGet,
} from '@/services/http';
import {
  GetRestaurantGroupsList,
  PutRestaurantGroups,
  RestaurantGroupsBody,
  PostRestaurantGroups,
  DeleteRestaurantGroups,
  RestaurantGroupsSchema,
  QueryParamsGetList,
  optionGroupOrderByType,
  GetGroupOrderCount,
} from '@/types/index';
import { GroupContext } from './initial.store';
import { DEFAULT_ROW_PER_PAGE } from '@/src/app.constants';

export function useGroupContext() {
  return useContext(GroupContext);
}
const GroupContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [restaurantGroupList, setRestaurantGroupsList] = useState<GetRestaurantGroupsList>({
    data: [],
    state: 'initial',
    status: {
      code: 0,
      message: '',
    },
  });
  const [restaurantGroupCreateData, setRestaurantGroupCreateData] = useState<PostRestaurantGroups>({
    data: {} as RestaurantGroupsSchema,
    state: 'initial',
    status: {
      code: 0,
      message: '',
    },
  });
  const [restaurantGroupUpdateData, setRestaurantGroupUpdateData] = useState<PutRestaurantGroups>({
    data: [],
    state: 'initial',
    status: {
      code: 0,
      message: '',
    },
  });
  const [restaurantGroupDeleteData, setRestaurantGroupDeleteData] = useState<DeleteRestaurantGroups>({
    data: [],
    state: 'initial',
    status: {
      code: 0,
      message: '',
    },
  });

  const [countOrdersChangeGroupType, setCountOrdersChangeGroupType] = useState<GetGroupOrderCount>({ state: 'initial' });

  // action for RestaurantGroupList
  const getRestaurantGroupsList = (prefix: string, restaurantId: string, params?: QueryParamsGetList<optionGroupOrderByType>) => {
    setRestaurantGroupsList({
      data: [],
      state: 'loading',
      status: {
        code: 0,
        message: '',
      },
    });
    RestaurantGroupsListGet(prefix, restaurantId, params || ({ page: 0, itemPerPage: DEFAULT_ROW_PER_PAGE } as QueryParamsGetList<optionGroupOrderByType>))
      .then((d) => {
        setRestaurantGroupsList({
          thName: d.thName,
          enName: d.enName,
          data: d.data,
          state: 'ready',
          status: d.status,
          pagination: d.pagination,
        });
      })
      .catch((error) => {
        setRestaurantGroupsList({
          data: [],
          error: error?.response,
          state: 'error',
          status: error?.response?.data?.status,
        });
      });
  };
  // action for create restaurantGroup
  const setRestaurantGroupCreate = (prefix: string, restaurantId: string, payload: RestaurantGroupsBody) => {
    setRestaurantGroupCreateData({
      data: {} as RestaurantGroupsSchema,
      state: 'loading',
      status: {
        code: 0,
        message: '',
      },
    });
    RestaurantGroupsCreate(prefix, restaurantId, payload)
      .then((data) => {
        setRestaurantGroupCreateData({
          data: data.data,
          state: 'ready',
          status: data.status,
        });
      })
      .catch((error) => {
        setRestaurantGroupCreateData({ data: {} as RestaurantGroupsSchema, error: error?.response, state: 'error', status: error?.response?.data?.status });
      });
  };
  // action for update restaurantGroup
  const setRestaurantGroupUpdate = (prefix: string, restaurantId: string, groupId: string, payload: RestaurantGroupsBody) => {
    setRestaurantGroupUpdateData({
      data: [],
      state: 'loading',
      status: {
        code: 0,
        message: '',
      },
    });
    RestaurantGroupsUpdate(prefix, restaurantId, groupId, payload)
      .then((data) => {
        setRestaurantGroupUpdateData({
          data: data.data,
          state: 'ready',
          status: data.status,
        });
      })
      .catch((error) => {
        setRestaurantGroupUpdateData({ data: [], error: error?.response, state: 'error', status: error?.response?.data?.status });
      });
  };
  // action for change groups list after update
  const getRestaurantGroupIdUpdateList = (groupId: string, thName: string, enName: string, groupRadio: boolean) => {
    const startArray = restaurantGroupList;
    const restaurantGroupUpdateList = startArray.data.map((restaurantGroup: any) => {
      if (restaurantGroup.id === groupId) {
        return {
          ...restaurantGroup,
          thName,
          enName,
          groupRadio,
        };
      } else {
        return {
          ...restaurantGroup,
        };
      }
    });
    setRestaurantGroupsList({
      data: restaurantGroupUpdateList,
      thName: restaurantGroupList.thName,
      enName: restaurantGroupList.enName,
      state: 'ready',
      pagination: restaurantGroupList.pagination,
    });
  };
  // action for delete groups
  const setRestaurantGroupDelete = (prefix: string, restaurantId: string, groupId: string) => {
    setRestaurantGroupDeleteData({
      data: [],
      state: 'loading',
      status: {
        code: 0,
        message: '',
      },
    });
    RestaurantGroupsDelete(prefix, restaurantId, groupId)
      .then((data) => {
        setRestaurantGroupDeleteData({
          data: data.data,
          state: 'ready',
          status: data.status,
        });
      })
      .catch((error) => {
        setRestaurantGroupDeleteData({ data: error?.response?.data, error: error?.response, state: 'error', status: error?.response?.data?.status });
      });
  };
  // action for change restaurant groups list after delete
  const getRestaurantGroupIdDeleteList = (groupId: string) => {
    const startArray = restaurantGroupList;
    const restaurantGroupDeleteList = startArray.data.filter((restaurantGroup: any) => {
      if (restaurantGroup.id !== groupId) {
        return {
          ...restaurantGroup,
        };
      }
      return undefined;
    });
    setRestaurantGroupsList({
      data: restaurantGroupDeleteList,
      thName: restaurantGroupList.thName,
      enName: restaurantGroupList.enName,
      state: 'ready',
      pagination: restaurantGroupList.pagination,
    });
  };

  /**
   * action for get count Orders before update Option group type
   * @api @get v1/groups/orders/count/{restaurantId}/{groupId}
   * */
  const getCountOrdersChangeGroupType = (prefix: string, restaurantId: string, groupId: string) => {
    setCountOrdersChangeGroupType({ state: 'loading' });
    RestaurantGroupsCountOrderGet(prefix, restaurantId, groupId)
      .then((data) => {
        setCountOrdersChangeGroupType({
          data: data.data,
          state: 'ready',
          status: data.status,
        });
      })
      .catch((error) => {
        setCountOrdersChangeGroupType({ data: undefined, error: error?.response, state: 'error', status: error?.response?.data?.status });
      });
  };

  const groupStore = {
    restaurantGroupList,
    setRestaurantGroupList: getRestaurantGroupsList,

    restaurantGroupCreateData,
    setRestaurantGroupCreate,
    setRestaurantGroupCreateData,

    restaurantGroupUpdateData,
    setRestaurantGroupUpdate,
    setRestaurantGroupUpdateData,
    getRestaurantGroupIdUpdateList,

    restaurantGroupDeleteData,
    setRestaurantGroupDelete,
    setRestaurantGroupDeleteData,
    getRestaurantGroupIdDeleteList,

    countOrdersChangeGroupType,
    setCountOrdersChangeGroupType: getCountOrdersChangeGroupType,
  };

  useEffect(() => {
    return () => {
      /*
       NOTE: cancel all subscriptions and asynchronous tasks to cleanup function and fix Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
      */
      setRestaurantGroupDeleteData({ state: 'initial', data: [] });
      setRestaurantGroupUpdateData({ state: 'initial', data: [] });
      setRestaurantGroupCreateData({ state: 'initial', data: {} as RestaurantGroupsSchema });
      setRestaurantGroupsList({ state: 'initial', data: [] });
    };
  }, []);

  return <GroupContext.Provider value={groupStore}>{children}</GroupContext.Provider>;
};
export { GroupContextProvider };
