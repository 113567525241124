import React, { useState } from 'react';
import alarmClock from '@/images/alarmClock.png';
import { Backdrop, Box, Button, Typography } from '@mui/material';
const modalStyle = {
  position: 'fixed',
  margin: 'auto',
  top: '60px',
  width: 495,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  display: 'grid',
  alignContent: 'stretch',
  justifyContent: 'space - around',
  alignItems: 'start',
  justifyItems: 'center',
};
interface Props {
  logoutCallback: () => void;
  forceCloseModal?: boolean;
}

export function ModalSessionExpired(props: Props) {
  const { logoutCallback, forceCloseModal } = props;
  const [open, setOpen] = useState(true);
  const [onCallback, setCallback] = useState(false);
  const handleClose = () => {
    // NOTE if we close it, will go to white screen
    if (forceCloseModal) setOpen(false);
    logoutCallback();
    setCallback(true);
  };
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <Box sx={modalStyle} className="mt-10">
        <Box component="img" src={alarmClock} alt="alarmClock" sx={{ height: 94, weight: 94 }} />
        <Typography sx={{ color: '#000' }} id="transition-modal-title" variant="h5" component="h2">
          Your session has expired
        </Typography>
        <Typography sx={{ color: '#000' }} variant="caption">
          The session you are using has expired. Please login again.
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: '30px' }}>
          <Button id={'btn-modal-session-expired-login'} disabled={onCallback} color={'success'} variant="contained" onClick={handleClose}>
            LOGIN
          </Button>
        </Box>
      </Box>
    </Backdrop>
  );
}
