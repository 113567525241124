import React from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { formatValue } from 'react-currency-input-field';
import { order } from '@/types/index';
import { ordersHistorySchema } from '@/types/schema/ordersHistory';
import { printDisplayDate } from '@/utils/index';
import { useNavigate } from 'react-router-dom';
import { useOrdersContextV2 } from '@/services/index';

export function EnhancedTableBodyOrderHistory(props: any) {
  const { rows, order, orderBy } = props;
  const navigate = useNavigate();
  const { selectedOrderDate, ordersSummaryList } = useOrdersContextV2();

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleClickTable = (date: string) => {
    ordersSummaryList.setState({ state: 'initial' });
    selectedOrderDate.setState(new Date(date));
    navigate('/orders');
  };

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator<Key extends keyof any>(
    order: order,
    orderBy: Key
    // eslint-disable-next-line no-unused-vars
  ): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  }

  return (
    <TableBody id="tbody-order-history">
      {rows
        .slice()
        .sort(getComparator(order, orderBy))
        .map((row: ordersHistorySchema, index: number) => {
          return (
            <TableRow
              id={'tr-order-history-' + index}
              hover
              tabIndex={-1}
              key={`${row.thName}+ ${index}`}
              sx={{ width: 20, cursor: 'pointer' }}
              onClick={() => handleClickTable(row.date)}
            >
              <TableCell id={'td-order-history-col1-' + index} align="left">
                {printDisplayDate(row.date)}
              </TableCell>
              <TableCell id={'td-order-history-col2-' + index} align="left">
                {row.restaurantId !== 'Public holiday' ? `${row.thName} (${row.enName})` : 'Public holiday'}
              </TableCell>
              <TableCell id={'td-order-history-col3-' + index} align="right">
                {row.restaurantId !== 'Public holiday' ? row.totalOrder : '-'}
              </TableCell>
              <TableCell id={'td-order-history-col4-' + index} align="right">
                {row.restaurantId !== 'Public holiday' ? formatValue({ value: `${row.totalPrice || '0'}` }) : '-'}
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
}
