import { TableBody, TableRow, TableCell, Box } from '@mui/material';
import React from 'react';

interface TableBodyNoDataProps {
  page: string;
  src: string;
  customHeight?: number;
  customWidth?: number;
  title: string;
  description?: string;
  colSpan?: number;
}

export default function TableBodyNodata(props: TableBodyNoDataProps) {
  const { page, src, title, description, customHeight, customWidth, colSpan } = props;
  return (
    <TableBody>
      <TableRow id={'tr-' + page + '-no-data'}>
        <TableCell align="center" id={'td-' + page + '-no-data'} colSpan={colSpan || 8} className="h-[50vh]">
          <Box component="img" src={src} sx={{ height: customHeight || 49, width: customWidth || 49, marginBottom: '24px' }} />
          {title && <Box className={`text-base ${description ? '' : 'text-[#9E9E9E]'}`}>{title}</Box>}
          {description && <Box className="text-base text-[#9E9E9E]">{description}</Box>}
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
