import { AxiosService } from '@/utils/axios';
import { apiMethodV1 } from '@/app.constants/apiCallMethods/index';
import { paramsQueryString } from '@/src/utils';

export const userOrdersHistoryListGet = async (
  prefix: string,
  id: string,
  params: { includeLastOrder?: boolean; month: number; year: number; orderBy?: string; sort?: string },
  hardRejection = true
) => {
  const instance = new AxiosService({ method: apiMethodV1.get, timeout: 12000 }, true, hardRejection);
  const queryUri = paramsQueryString(`${prefix}${id}`, params);

  const config = {
    ...instance.config,
  };

  try {
    const res = await instance.client.get(queryUri, config);
    return res.data;
  } catch (error) {
    return instance.defaultHandleError;
  }
};
