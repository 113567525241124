import React, { useState, useEffect } from 'react';
import { Box, Paper, Table, TableContainer } from '@mui/material';
import { useRestaurantOptionAddonContextV2 } from '@/services/index';
import {
  EnhancedTableHead,
  EnhancedTableBodyOptionAddon,
  LoadingIcon,
  AddOptionAddon,
  PopupSnackbar,
  EnhancedTablePaginationV2,
  ConditionalWrapper,
} from '@/components/index';

import { useParams } from 'react-router-dom';
import { DEFAULT_ROW_PER_PAGE } from '@/app.constants/index';
import { order, QueryParamsGetList, sortType } from '@/types/index';
import { headCellOptionAddon } from '@/static/optionAddon';
import { useCookies } from 'react-cookie';
import { cookieOptions } from '@/src/utils';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import { OptionAddonOrderByType } from '@/src/types/crud/optionAddon/optionAddon.queries';
import TableBodyNodata from '@/src/components/ui/tableBodyNoData';
import noOne from '@/images/noOne.png';

export function OptionAddon(props: any) {
  // SORTING / PAGINATION

  const { optionAddonList } = useRestaurantOptionAddonContextV2();

  let [order, setOrder] = useState<order | null>('asc');
  let [orderBy, setOrderBy] = useState<OptionAddonOrderByType>('thName');
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [isChange, setIsChange] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROW_PER_PAGE);
  const [paginationParams, setPaginationParams] = useState<QueryParamsGetList<OptionAddonOrderByType>>({ page, itemPerPage: rowsPerPage });
  const [isInitialGetData, setIsInitialGetData] = useState(true);

  const [open, setOpen] = useState(false);
  const { id, groupId } = useParams();
  const [cookies, setCookie] = useCookies(['sortingChoice']);
  const sorting: any = {};

  useEffect(() => {
    return () => {
      optionAddonList.setState({
        data: { restaurant: { thName: '', enName: '' }, group: { id: '', thName: '', enName: '', groupRadio: false }, addon: [] },
        state: STATE_ENUM.INITIAL,
      });
    };
  }, []);

  useEffect(() => {
    if (optionAddonList.current.state === STATE_ENUM.INITIAL) {
      if (typeof cookies.sortingChoice !== 'undefined') {
        orderBy = cookies.sortingChoice?.orderBy;
        order = cookies.sortingChoice?.sort;
      }

      setOrderBy(orderBy);
      setOrder(order);

      setIsInitialGetData(false);
      optionAddonList.requestAct(id || '', groupId || '', {
        page,
        itemPerPage: rowsPerPage,
        orderBy: orderBy as OptionAddonOrderByType,
        sort: order as sortType,
      });
    }
  }, [optionAddonList.current]);

  useEffect(() => {
    if (optionAddonList.current.state !== STATE_ENUM.INITIAL && id && !isInitialGetData) {
      if (typeof paginationParams.orderBy !== 'undefined') sorting.orderBy = paginationParams.orderBy;
      if (typeof paginationParams.sort !== 'undefined') sorting.sort = paginationParams.sort || 'asc';

      setOrderBy(paginationParams.orderBy || sorting.orderBy);
      setOrder(paginationParams.sort || sorting.sort);

      paginationParams.orderBy = paginationParams.orderBy || sorting.orderBy;
      paginationParams.sort = paginationParams.sort || sorting.sort;

      setCookie('sortingChoice' as any, JSON.stringify(sorting), cookieOptions());
      optionAddonList.requestAct(id || '', groupId || '', paginationParams);
    }
  }, [paginationParams]);

  useEffect(() => {
    if (optionAddonList.current.state === STATE_ENUM.READY && !isChange) {
      setTotal(optionAddonList.current.pagination?.total || 0);
    }
  }, [optionAddonList.current]);

  return (
    <Box sx={{ paddingTop: '27px' }}>
      <AddOptionAddon
        setOpen={setOpen}
        setOrderBy={setOrderBy}
        setOrder={setOrder}
        setPage={setPage}
        setTotal={setTotal}
        total={total}
        pageNumber={page}
        rowsPerPage={rowsPerPage}
        optionAddonPaginationParams={paginationParams}
        setOptionAddonPaginationParams={setPaginationParams}
      />
      {/* TABLE */}
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
        <ConditionalWrapper condition={optionAddonList.current.state === STATE_ENUM.READY} alternativeNode={<LoadingIcon setCenter={false} />}>
          <Paper elevation={0} sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="small"
                sx={{
                  minWidth: 750,
                }}
                id="option-group-sup-table"
              >
                <EnhancedTableHead
                  headCells={headCellOptionAddon}
                  order={order}
                  orderBy={orderBy}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  disabledSorting={optionAddonList.current.data?.addon.length === 0}
                  setPaginationParams={setPaginationParams}
                  respectSortAble
                />
                <ConditionalWrapper
                  condition={optionAddonList.current.data?.addon.length !== 0}
                  alternativeNode={<TableBodyNodata page="order" src={noOne} title="No results found." />}
                >
                  <EnhancedTableBodyOptionAddon
                    rows={optionAddonList.current.data?.addon}
                    order={order}
                    orderBy={orderBy}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    {...props}
                    setOrderBy={setOrderBy}
                    setOrder={setOrder}
                    setOpen={setOpen}
                    setTotal={setTotal}
                    setIsChange={setIsChange}
                    total={total}
                    setPage={setPage}
                    setPaginationParams={setPaginationParams}
                  />
                </ConditionalWrapper>
              </Table>
              <EnhancedTablePaginationV2
                total={total}
                rowsPerPage={rowsPerPage}
                page={page}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                setPaginationParams={setPaginationParams}
                disabledPagination={optionAddonList.current.data?.addon.length === 0}
              />
            </TableContainer>
            <PopupSnackbar open={open} setOpen={setOpen}></PopupSnackbar>
          </Paper>
        </ConditionalWrapper>
      </Box>
    </Box>
  );
}
