import palette from './palette';

const font = "'Prompt', sans-serif";

export default {
  fontFamily: font,
  fontWeight: 400,
  h1: {
    fontWeight: 500,
    fontSize: '30px',
  },
  h2: {
    fontWeight: 500,
    fontSize: '26px',
  },
  h3: {
    fontWeight: 500,
    fontSize: '22px',
  },
  h4: {
    fontWeight: 500,
    fontSize: '20px',
  },
  h5: {
    fontWeight: 500,
    fontSize: '18px',
  },
  h6: {
    fontWeight: 500,
    fontSize: '16px',
  },
  body: {
    fontSize: '16px',
  },
  button: {
    fontSize: '14px',
    fontWeight: '500',
  },
  a: {
    color: palette.text.primary,
  },
};
