import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, Container, MenuItem, Paper, Select, Table, TableContainer, Typography } from '@mui/material';

import { LoadingIcon, ActionButton, EnhancedTableHead, PopupSnackbar, EnhancedTablePaginationV2, ConditionalWrapper } from '@/components/index';
import { order, QueryParamsGetList } from '@/types/index';

import noOne from '@/images/noOne.png';
import AddIcon from '@mui/icons-material/Add';
import { notificationOrderByType } from '@/types/crud/notifications/notifications.queries';
import { headCellsNotifications } from '@/static/notifications';
import { EnhancedTableBodyNotifications } from '@/components/ui/tableBodyNotifications';
import { cookieOptions } from '@/src/utils';
import { useCookies } from 'react-cookie';
import ModalAddNotification from '@/src/components/ui/modal/notification/addNotification';
import ModalUI from '@/src/components/ui/modal/modalUI';
import { useSnackbarContext } from '@/store/snackbar.store';
import TableBodyNodata from '@/src/components/ui/tableBodyNoData';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import { useNotificationsContextV2 } from '@/store/notifications.v2.store';
import { DEFAULT_ROW_PER_PAGE } from '@/src/app.constants/common';
import { updateTablePaginationTotal } from '@/src/helpers/tableHelpers';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { NotificationStatus } from '@/src/types/schema/enum/notification.enum';
import useSelectAll from '@/src/components/hocs/useSelectAll';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalDeleteNotification from '@/src/components/ui/modal/notification/deleteNotification';

const PAGE_TITLE = 'Notifications';

type OpeningModal = 'ADD' | 'DEL' | null;

export function Notification(props: any) {
  const [openingModal, setOpeningModal] = useState<OpeningModal>();
  const { notificationList, notificationCreate, notificationMultiDelete } = useNotificationsContextV2();
  const [openAlert, setOpenAlert] = useState(false);
  const { setResponseStatus } = useSnackbarContext();
  const [cookies, setCookie] = useCookies(['sortingNotifications']);
  const [status, setStatus] = useState<string | NotificationStatus>(NotificationStatus.ALL);
  const [paginationParams, setPaginationParams] = useState<QueryParamsGetList<notificationOrderByType>>({
    sort: 'desc',
    orderBy: 'createdDate' as any,
    page: 0,
    itemPerPage: DEFAULT_ROW_PER_PAGE,
    status,
  } as QueryParamsGetList<notificationOrderByType>);
  const [payload, setPayload] = useState<any>(null);
  const [validate, setValidate] = useState<boolean>(false);
  const [CurrentImageData, setCurrentImageData] = useState('');
  const [isProcessingAdd, setIsProcessingAdd] = useState(false);
  const [currentTotal, setCurrentTotal] = useState(0);
  const { handleSelect, handleSelectAll, handleClearSelect, selectAllStatus, currentSelected } = useSelectAll({
    data: notificationList.current?.data?.map((i) => i.id) || [],
  });

  const openModalAction = (modal: NonNullable<OpeningModal>) => {
    setOpeningModal(modal);
  };

  const createNotification = () => {
    notificationCreate.requestAct(payload, CurrentImageData, status);
  };

  const handleClickAdd = () => {
    // Disable ADD button & close ADD model
    setIsProcessingAdd(true);
    setValidate(false);
    setOpeningModal(null);
    if (paginationParams.page === 0) {
      createNotification();
    } else {
      setPaginationParams((prevState) => ({ ...prevState, page: 0, itemPerPage: paginationParams.itemPerPage }));
    }
  };

  const handleDelete = useCallback(async () => {
    if (currentSelected.length > 0) {
      notificationMultiDelete.requestAct(currentSelected);
    }

    setOpeningModal(null);
  }, [currentSelected]);

  useEffect(() => {
    if (notificationList.current.state !== STATE_ENUM.INITIAL) return;
    if (cookies.sortingNotifications) {
      const newParams = {
        ...paginationParams,
        sort: cookies.sortingNotifications.sort,
        orderBy: cookies.sortingNotifications.orderBy,
        status:
          cookies.sortingNotifications.status === 0
            ? NotificationStatus.QUEUED
            : cookies.sortingNotifications.status === 1
            ? NotificationStatus.SENT
            : NotificationStatus.ALL,
      } as QueryParamsGetList<notificationOrderByType>;
      setStatus(
        cookies.sortingNotifications.status === 0
          ? NotificationStatus.QUEUED
          : cookies.sortingNotifications.status === 1
          ? NotificationStatus.SENT
          : NotificationStatus.ALL
      );
      setPaginationParams(newParams);
      notificationList.requestAct(newParams);
      return;
    }
    notificationList.requestAct(paginationParams);
  }, []);

  useEffect(() => {
    if (notificationList.current.state !== STATE_ENUM.INITIAL) {
      const newParams = {
        sort: 'desc',
        orderBy: 'createdDate' as any,
        page: 0,
        itemPerPage: paginationParams.itemPerPage || DEFAULT_ROW_PER_PAGE,
        status,
      } as QueryParamsGetList<notificationOrderByType>;
      setPaginationParams(newParams);
      notificationList.requestAct(newParams);
      handleClearSelect();
    }
  }, [status]);

  useEffect(() => {
    setCookie('sortingNotifications', JSON.stringify(paginationParams), cookieOptions());
    if (notificationList.current.state === STATE_ENUM.INITIAL || notificationList.current.state === STATE_ENUM.LOADING) return;
    notificationList.requestAct(paginationParams);
  }, [paginationParams]);

  // set pagination params field by return function that mimics React set state
  const setPaginationParamsField =
    <F extends keyof QueryParamsGetList<notificationOrderByType>>(field: F) =>
    (
      value:
        | QueryParamsGetList<notificationOrderByType>[F]
        | ((prevState: QueryParamsGetList<notificationOrderByType>[F]) => QueryParamsGetList<notificationOrderByType>[F])
    ) => {
      setPaginationParams(
        (old) =>
          ({
            ...old,
            [field]: value instanceof Function ? value(paginationParams[field] as QueryParamsGetList<notificationOrderByType>[F]) : value,
          } as QueryParamsGetList<notificationOrderByType>)
      );
    };

  const handleAlertMessage = useCallback((isShow: boolean, notification: any) => {
    setOpenAlert(isShow);
    notification.setState({
      state: STATE_ENUM.INITIAL,
    });
  }, []);

  useEffect(() => {
    if (notificationMultiDelete.current.state === STATE_ENUM.READY) {
      setOpeningModal(null);
      let toPage = notificationList.current.pagination?.page!;

      const total = notificationList.current.pagination?.total || 0;
      const itemPerPage = notificationList.current.pagination?.itemPerPage || 0;
      const lastPageNumber = Math.ceil(total / itemPerPage) - 1;
      if (
        notificationList.current.data?.length === currentSelected.length &&
        notificationList.current.pagination?.page! > 0 &&
        notificationList.current.pagination?.page! === lastPageNumber
      ) {
        toPage = notificationList.current.pagination?.page! - 1;
      }

      setPaginationParamsField('page')(toPage);
      setPaginationParams((prevState) => {
        return { ...prevState, page: toPage };
      });

      setResponseStatus({
        status: 'success',
        message: notificationMultiDelete.current?.status?.message ?? '',
      });
      handleAlertMessage(true, notificationMultiDelete);
      handleClearSelect([...currentSelected]);
    } else if (notificationMultiDelete.current.state === STATE_ENUM.ERROR) {
      setResponseStatus({
        status: notificationMultiDelete.current.state,
        message: notificationMultiDelete.current?.status?.message ?? 'Something went wrong. Please try again.',
      });
      handleAlertMessage(true, notificationMultiDelete);
    }
  }, [notificationMultiDelete.current, currentSelected]);

  useEffect(() => {
    if (notificationCreate.current.state === STATE_ENUM.READY) {
      updateTablePaginationTotal(notificationList.current.pagination?.total! + 1, setCurrentTotal, notificationList);
      setResponseStatus({
        status: 'success',
        message: notificationCreate.current?.status?.message ?? '',
      });
      notificationCreate.setState({ state: STATE_ENUM.INITIAL });
      setOpenAlert(true);
      setIsProcessingAdd(false);
      handleClearSelect();
    } else if (notificationCreate.current.state === STATE_ENUM.ERROR) {
      setResponseStatus({
        status: notificationCreate.current.state,
        message: notificationCreate.current?.status?.message ?? 'Something went wrong. Please try again.',
      });
      notificationCreate.setState({ state: STATE_ENUM.INITIAL });
      setOpenAlert(true);
      setIsProcessingAdd(false);
    }
  }, [notificationCreate.current]);

  useEffect(() => {
    // create notification after notification list was fetched
    if (notificationList.current.state === STATE_ENUM.READY && isProcessingAdd) {
      createNotification();
      handleClearSelect();
    } else if (notificationList.current.state === STATE_ENUM.ERROR) {
      setValidate(false);
      setIsProcessingAdd(false);
    }
  }, [notificationList.current]);

  return (
    // <ThemeProvider theme={globalTheme}>
    <Container maxWidth="xl">
      <Box className="pt-8 pb-4 flex flex-row justify-between items-start">
        <Typography variant="h1">{PAGE_TITLE}</Typography>
        <ActionButton variant="contained" text="Add Notification" startIcon={<AddIcon />} onClick={() => openModalAction('ADD')} />
      </Box>
      <ConditionalWrapper
        condition={notificationList.current.state !== STATE_ENUM.LOADING && !isProcessingAdd}
        alternativeNode={<LoadingIcon setCenter={false}></LoadingIcon>}
      >
        <div className="flex justify-between mt-6">
          <Select
            className="mb-4 px-1 !w-[220px] h-10"
            labelId="react-dropdown-select-input"
            id="react-dropdown-select-input"
            defaultValue={status}
            variant="outlined"
            color="secondary"
            IconComponent={KeyboardArrowDownIcon}
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem id="react-dropdown-select-input-all-status" value={NotificationStatus.ALL}>
              All statuses
            </MenuItem>
            <MenuItem id="react-dropdown-select-input-queued" value={NotificationStatus.QUEUED}>
              Queued
            </MenuItem>
            <MenuItem id="react-dropdown-select-input-delivered" value={NotificationStatus.SENT}>
              Delivered
            </MenuItem>
          </Select>
          {currentSelected.length > 0 && (
            <Box sx={{ typography: 'h6' }}>
              <span className="mr-3">{currentSelected.length} row(s) selected</span>
              <Button id="btn-delete-all" color="error" variant="outlined" onClick={() => openModalAction('DEL')}>
                <DeleteIcon className="mr-3" /> DELETE ALL
              </Button>
            </Box>
          )}
        </div>
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
          <Paper elevation={0} sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="small"
                sx={{
                  minWidth: 750,
                  tableLayout: 'fixed',
                }}
                id="notification-table"
              >
                <EnhancedTableHead
                  headCells={headCellsNotifications}
                  order={paginationParams.sort ?? null}
                  orderBy={paginationParams.orderBy}
                  setOrder={setPaginationParamsField('sort') as React.Dispatch<React.SetStateAction<order | null>>}
                  setOrderBy={setPaginationParamsField('orderBy')}
                  setPaginationParams={setPaginationParams}
                  disabledSorting={!notificationList.current.data?.length}
                  respectSortAble
                  selectAll={{
                    onSelectAllClick: handleSelectAll,
                    numSelected: currentSelected.length,
                    rowCount: notificationList.current.data?.length || 0,
                    onClear: handleClearSelect,
                    selectAllStatus,
                  }}
                />
                {notificationList.current.state === STATE_ENUM.READY && notificationList.current.data?.length ? (
                  <EnhancedTableBodyNotifications
                    rows={notificationList.current.data}
                    page={paginationParams.page}
                    rowsPerPage={paginationParams.itemPerPage!}
                    setOpenAlert={setOpenAlert}
                    setPage={setPaginationParamsField('page')}
                    setPaginationParams={setPaginationParams}
                    filter={status}
                    selectBox={{
                      selects: currentSelected,
                      setSelects: handleSelect,
                      onClear: handleClearSelect,
                    }}
                  />
                ) : (
                  <TableBodyNodata page={'notification'} colSpan={9} src={noOne} title="No results found." />
                )}
              </Table>
              <EnhancedTablePaginationV2
                total={notificationList.current.pagination?.total || currentTotal || 0}
                rowsPerPage={paginationParams.itemPerPage || DEFAULT_ROW_PER_PAGE}
                page={paginationParams.page}
                setPage={setPaginationParamsField('page')}
                setRowsPerPage={setPaginationParamsField('itemPerPage') as React.Dispatch<React.SetStateAction<number>>}
                setPaginationParams={setPaginationParams}
                disabledPagination={!notificationList.current.data?.length}
              />
            </TableContainer>
            <PopupSnackbar open={openAlert} setOpen={setOpenAlert} />
          </Paper>
        </Box>
      </ConditionalWrapper>
      {openingModal === 'ADD' ? (
        <ModalUI
          id={'add-notification'}
          header="Add notification"
          openModal={true}
          type={'ADD'}
          setOpenModal={setOpenAlert}
          onClose={() => {
            setOpeningModal(null);
            setValidate(false);
          }}
          onClickConfirm={handleClickAdd}
          disabledBtn={validate}
          child={<ModalAddNotification onValidateChange={setValidate} validate={validate} onPayloadChange={setPayload} onImageChange={setCurrentImageData} />}
        />
      ) : openingModal === 'DEL' ? (
        <ModalUI
          id={'delete-notification'}
          type={'DELETE'}
          openModal={true}
          width={590}
          setOpenModal={setOpenAlert}
          onClose={() => {
            setOpeningModal(null);
          }}
          onClickConfirm={handleDelete}
          child={
            <ModalDeleteNotification
              open
              onClose={() => setOpeningModal(null)}
              title="Are you sure you want to delete the notification(s)?"
              description={`${currentSelected.length} notification(s) will be deleted. This action will permanently delete the notification(s), and cannot be undone`}
            />
          }
        />
      ) : (
        <></>
      )}
    </Container>
    // </ThemeProvider>
  );
}
