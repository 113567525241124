import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Fade, Box, Typography, Button } from '@mui/material';

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export default function ModalComponent(props: any) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [buttonCancelText, setButtonCancelText] = useState('');
  const [temp, setTemp] = useState('');
  const [color, setColor] = useState('');
  const { openModal, setOpenModal, bookingId, orderId, setOrderDelete } = props;

  useEffect(() => {
    if (props.type === 'DELETE') {
      setTitle('Are you sure you want to delete this order?');
      setDescription('This action will permanently delete the order and cannot be undone.');
      setButtonText('DELETE');
      setButtonCancelText('CANCEL');
      setTemp('#F44336');
      setColor('#ffffff');
    }
  }, [props.type, temp, color]);

  const handleClose = () => {
    setOpenModal(false);
    document.body.style.overflow = 'visible';
  };

  const handleClickDelete = () => {
    setOpenModal(false);
    document.body.style.overflow = 'visible';
    setOrderDelete(bookingId, orderId);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={handleClose}
      closeAfterTransition
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={openModal}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h5" component="h2">
            {title}
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{ color: '#212121' }}>
            {description}
          </Typography>

          <Button id={'btn-modal-order-' + buttonText} style={{ backgroundColor: temp, color }} sx={{ mt: 2 }} onClick={handleClickDelete}>
            {buttonText}
          </Button>

          <Button
            id={'btn-modal-order-' + buttonCancelText}
            style={{ backgroundColor: '#ffff', color: temp, border: '1px solid ' + temp }}
            sx={{ mt: 2, ml: 2 }}
            onClick={handleClose}
          >
            {buttonCancelText}
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
}
