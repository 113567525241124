import { Box, Container, Typography } from '@mui/material';
import UserTableData from './userTableData';
import UserModal from './userModal';
import { PopupSnackbar } from '@/src/components';
import { useUserStoreContext } from '@/store/user.v2.store';
import { SearchUser } from './searchUser';

const UserPage = () => {
  const { snackbar, pagination } = useUserStoreContext();
  return (
    <Container maxWidth="xl">
      <Box className="flex flex-row justify-between items-center">
        <Typography className="pt-8" variant="h1">
          Users
        </Typography>
      </Box>
      <SearchUser setPaginationParams={pagination} />
      <div className="mt-[50px]">
        <UserTableData />
      </div>
      <UserModal />
      <PopupSnackbar open={snackbar.snackbarOpen} setOpen={snackbar.setSnackbarOpen}></PopupSnackbar>
    </Container>
  );
};

export default UserPage;
