import { HeadCell } from '@/types/enhancedTable.d';
export const headCellsRestaurantGroups: HeadCell[] = [
  {
    id: 'thName',
    numeric: false,
    label: 'Group name (TH)',
    disablePadding: false,
    sortAble: true,
    width: 1 / 4,
  },
  {
    id: 'enName',
    numeric: false,
    label: 'Group name (EN)',
    disablePadding: false,
    sortAble: true,
    width: 1 / 4,
  },
  {
    id: 'groupRadio',
    label: 'Type',
    numeric: false,
    disablePadding: false,
    sortAble: true,
    width: 0.5 / 4,
  },
  {
    id: 'choices',
    label: 'Choices',
    numeric: false,
    disablePadding: false,
    sortAble: false,
    width: 1.5 / 4,
  },
  {
    id: 'actions',
    label: '',
    numeric: false,
    disablePadding: false,
    sortAble: false,
    width: 1 / 4,
  },
];
