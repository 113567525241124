import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Box, TextField, Tooltip, Button, FormControl, MenuItem, OutlinedInput, Select, InputLabel } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useGroupContextV2, useSnackbarContext } from '@/services/index';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LIMIT_EN_CHAR, LIMIT_TH_CHAR } from '@/app.constants/index';
import { CommonCrud, optionGroupOrderByType, QueryParamsGetList } from '@/types/index';
import { RestaurantGroupCreateResponse } from '@/src/types/crud/restaurantGroup/restaurantGroup.response';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import { updateTablePaginationTotal } from '@/src/helpers/tableHelpers';
import { RestaurantGroupsSchemaV2 } from '@/src/types/schema/restaurantGroupsSchemaV2';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const GroupProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface AddRestaurantGroupsProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setOrderBy: any;
  setOrder: any;
  setPage: React.Dispatch<SetStateAction<number>>;
  setTotal: React.Dispatch<SetStateAction<number>>;
  total: number;
  page: number;
  rowsPerPage: number;
  groupPaginationParams: QueryParamsGetList<optionGroupOrderByType>;
  setGroupPaginationParams: React.Dispatch<React.SetStateAction<QueryParamsGetList<optionGroupOrderByType>>>;
}

export function AddRestaurantGroups(props: AddRestaurantGroupsProps) {
  const { setOpen, setOrderBy, setOrder, setPage, setTotal, total, groupPaginationParams, setGroupPaginationParams, page, rowsPerPage } = props;
  const { restaurantGroupList, restaurantGroupCreate, restaurantGroupUpdate, restaurantGroupDelete, restaurantGroupOrderCount } = useGroupContextV2();
  const { setResponseStatus } = useSnackbarContext();
  const { id } = useParams();

  const [thaiText, setThaiText] = React.useState('');
  const [engText, setEngText] = React.useState('');
  const thaiChar = LIMIT_TH_CHAR;
  const engChar = LIMIT_EN_CHAR;
  const countThaiText = useRef(0);
  const countEngText = useRef(0);
  const [thNameErrorText, setThNameErrorText] = React.useState('');
  const [enNameErrorText, setEnNameErrorText] = React.useState('');

  const [validate, setValidate] = React.useState(false);
  const focusTextField = useRef<HTMLInputElement | null>(null);
  const loopTextField = useRef<HTMLInputElement | null>(null);

  const [selected, setSelected] = useState('');

  const [isClickAdd, setIsClickAdd] = useState(false);

  const [checkSaveLoad, setCheckSaveLoad] = useState(false);

  useEffect(() => {
    /** Check LOADING and EDITING when edit or deleting to disabled ADD button   */
    if (
      restaurantGroupUpdate.current.state === STATE_ENUM.LOADING ||
      restaurantGroupUpdate.current.state === STATE_ENUM.EDITING ||
      restaurantGroupDelete.current.state === STATE_ENUM.LOADING ||
      restaurantGroupOrderCount.current.state === STATE_ENUM.LOADING
    ) {
      setCheckSaveLoad(true);
    } else {
      setCheckSaveLoad(false);
    }
  }, [restaurantGroupUpdate, restaurantGroupDelete]);

  // handle onClick function
  const callRestaurantCreateGroup = () => {
    const payload = {
      thName: thaiText,
      enName: engText,
      groupRadio: selected === 'true',
    };
    restaurantGroupCreate.requestAct(id as string, payload);
  };

  const handleClickAdd = () => {
    setValidate(false);
    setIsClickAdd(true);
    if (page === 0) {
      callRestaurantCreateGroup();
    } else {
      setGroupPaginationParams((prevState) => {
        return { ...prevState, page: 0, itemPerPage: rowsPerPage };
      });
    }
  };

  useEffect(() => {
    // due to when we add from other page more than 0 we want to load fisrt page and place the new item on the top of table
    if (restaurantGroupList.current.state === STATE_ENUM.READY && isClickAdd) {
      callRestaurantCreateGroup();
      setPage(0);
    } else if (restaurantGroupList.current.state === STATE_ENUM.ERROR) {
      setIsClickAdd(false);
      setValidate(true);
    }
  }, [restaurantGroupList.current]);

  useEffect(() => {
    if (restaurantGroupCreate.current.state === STATE_ENUM.READY) {
      updateTablePaginationTotal(total + 1, setTotal, restaurantGroupList);
      setResponseStatus({
        status: 'success',
        message: restaurantGroupCreate.current?.status?.message ?? '',
      });
      setThaiText('');
      setEngText('');
      setSelected('');

      const tempResult: RestaurantGroupsSchemaV2 = {
        enName: restaurantGroupCreate.current?.data?.enName!,
        id: restaurantGroupCreate.current?.data?.id!,
        thName: restaurantGroupCreate.current?.data?.thName!,
        addons: [],
        groupRadio: restaurantGroupCreate.current?.data?.groupRadio!,
      };
      const tempArray: RestaurantGroupsSchemaV2[] = restaurantGroupList.current.data?.groups || [];
      tempArray.unshift(tempResult);
      if (tempArray.length > rowsPerPage) {
        tempArray.pop();
      }
      restaurantGroupCreate.setState({
        state: STATE_ENUM.INITIAL,
        data: {},
      } as CommonCrud<RestaurantGroupCreateResponse>);
      setOpen(true);
      setOrderBy(groupPaginationParams.orderBy);
      setOrder(groupPaginationParams.sort);
      setPage(0);
      setIsClickAdd(false);
      focusTextField.current?.focus();
      countThaiText.current = 0;
      countEngText.current = 0;
    } else if (restaurantGroupCreate.current.state === STATE_ENUM.ERROR) {
      setResponseStatus({
        status: restaurantGroupCreate.current.state,
        message: restaurantGroupCreate.current?.status?.message ?? 'Something went wrong. Please try again.',
      });
      restaurantGroupCreate.setState({
        state: STATE_ENUM.INITIAL,
        data: {},
      } as CommonCrud<RestaurantGroupCreateResponse>);
      setOpen(true);
      setIsClickAdd(false);
    }
  }, [restaurantGroupCreate.current]);

  // handle name function

  const handleThaiNameTextChange = (event: any) => {
    countThaiText.current = event.target.value.trim().length;
    setThaiText(event.target.value);
    if (event.target.value.length === 0 || !event.target.value.match(thaiChar)) {
      setThNameErrorText('Thai name is required.');
      setValidate(false);
    } else if (event.target.value.trim().length === 0) {
      setValidate(false);
      setThNameErrorText('Thai name is required.');
    } else if (event.target.value.length > 100) {
      setValidate(false);
      setThNameErrorText('Max 100 characters.');
    } else {
      setThNameErrorText('');
    }
  };
  const handleEngNameTextChange = (event: any) => {
    countEngText.current = event.target.value.trim().length;
    setEngText(event.target.value);
    if (event.target.value.length === 0 || !event.target.value.match(engChar)) {
      setEnNameErrorText('English name is required.');
      setValidate(false);
    } else if (event.target.value.trim().length === 0) {
      setValidate(false);
      setEnNameErrorText('English name is required.');
    } else if (event.target.value.length > 100) {
      setValidate(false);
      setEnNameErrorText('Max 100 characters.');
    } else {
      setEnNameErrorText('');
    }
  };
  const handleOutOfFocusThaiName = () => {
    if (countThaiText.current === 0) {
      setThaiText('');
      setThNameErrorText('');
    } else if (!thaiText.match(thaiChar)) {
      setThNameErrorText('Thai name is required.');
    } else if (countThaiText.current > 100) {
      setThNameErrorText('Max 100 characters.');
    } else {
      setThNameErrorText('');
    }
  };
  const handleOutOfFocusEngName = () => {
    if (countEngText.current === 0) {
      setEngText('');
      setEnNameErrorText('');
    } else if (!engText.match(engChar)) {
      setEnNameErrorText('English name is required.');
    } else if (countEngText.current > 100) {
      setEnNameErrorText('Max 100 characters.');
    } else {
      setEnNameErrorText('');
    }
  };

  const handleChangeType = (event: any) => {
    setSelected(event.target.value);
  };

  useEffect(() => {
    if (countThaiText.current !== 0 && countEngText.current !== 0 && thNameErrorText.length === 0 && enNameErrorText.length === 0 && selected.length > 0) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  }, [thaiText, engText, selected]);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        mb: 1,
        '.MuiFormControl-root': { mr: 2, mb: 3, p: 0 },
        '> .MuiFormControl-root': { width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' },
      }}
    >
      <FormControl
        variant="outlined"
        size="small"
        color="secondary"
        sx={{
          width: '100%',
        }}
      >
        <TextField
          value={thaiText}
          id="thaiName"
          label="Group name (TH)"
          variant="outlined"
          size="small"
          color="secondary"
          sx={{
            width: '416px',
          }}
          error={thNameErrorText !== ''}
          helperText={thNameErrorText}
          onChange={handleThaiNameTextChange}
          onBlur={handleOutOfFocusThaiName}
          tabIndex={-1}
          inputRef={focusTextField}
          ref={loopTextField}
          disabled={isClickAdd || checkSaveLoad}
          inputProps={{ maxLength: 101 }}
        />
        <TextField
          value={engText}
          id="engName"
          label="Group name (EN)"
          variant="outlined"
          size="small"
          color="secondary"
          sx={{
            width: '416px',
          }}
          error={enNameErrorText !== ''}
          helperText={enNameErrorText}
          onChange={handleEngNameTextChange}
          onBlur={handleOutOfFocusEngName}
          disabled={isClickAdd || checkSaveLoad}
          inputProps={{ maxLength: 101 }}
        />
        <FormControl sx={{ width: '266px' }} size="small">
          <InputLabel htmlFor="react-dropdown-select-input">Select type</InputLabel>
          <Select
            labelId="react-dropdown-select-input"
            id="react-dropdown-select-input"
            value={selected}
            variant="outlined"
            input={<OutlinedInput label="Select type" />}
            MenuProps={GroupProps}
            color="secondary"
            IconComponent={KeyboardArrowDownIcon}
            onChange={handleChangeType}
            disabled={isClickAdd || checkSaveLoad}
          >
            <MenuItem value={'false'}>Optional</MenuItem>
            <MenuItem value={'true'}>Pick 1 item </MenuItem>
          </Select>
        </FormControl>
        <Tooltip title="Add option group" placement="bottom-start">
          <Button
            id="btn-add-restaurant-group"
            variant="contained"
            component="span"
            color="primary"
            onClick={handleClickAdd}
            disabled={!validate || checkSaveLoad}
            onKeyDown={(e: any) => {
              if (e.key === 'Tab') {
                loopTextField.current?.focus();
              }
            }}
          >
            Add
          </Button>
        </Tooltip>
      </FormControl>
    </Box>
  );
}
