import React, { useState, useEffect } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { getDisplayConfig, printDisplayDate } from '@/utils/index';
import { notificationsSchema } from '@/types/schema/notifications.d';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Checkbox, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import ModalDeleteNotification from '@/components/ui/modal/notification/deleteNotification';
import ModalViewNotification from '../modal/notification/viewNotification';
import ModalEditNotification from '../modal/notification/editNotification';
import { NotificationTypes, useNotificationsContextV2 } from '@/store/notifications.v2.store';
import { useSnackbarContext } from '@/store/snackbar.store';
import ModalUI from '../modal/modalUI';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import { ActionUnit2 } from '@/store/common';
import { QueryParamsGetList } from '@/src/types';
import { NotificationStatus } from '@/src/types/schema/enum/notification.enum';

type Props = {
  rows: notificationsSchema[];
  page: number;
  rowsPerPage: number;
  setOpenAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setPaginationParams: React.Dispatch<React.SetStateAction<QueryParamsGetList<any>>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  filter: string | NotificationStatus;
  selectBox?: {
    selects: string[];
    setSelects: (value: string) => void;
    onClear?: () => void;
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  ellipsis: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  buttonAction: {
    minWidth: 50,
    width: 50,
  },
  btnBox: {
    display: 'flex',
    gap: theme.spacing(4),
    justifyContent: 'start',
  },
  imgContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  sentViewBtn: {
    textDecoration: 'underline !important',
    textTransform: 'none',
  },
  selected: {
    backgroundColor: '#fdeeed',
  },
}));

type OpeningModal = 'VIEW' | 'EDIT' | 'DELETE' | null;

export function EnhancedTableBodyNotifications({ rows, page, rowsPerPage, setOpenAlert, setPaginationParams, setPage, filter, selectBox }: Props) {
  const fm = getDisplayConfig().dateTimeFormat;

  const classes = useStyles();
  const [selectingNotification, setSelectingNotification] = useState<notificationsSchema | undefined>();
  const [openingModal, setOpeningModal] = useState<OpeningModal>(null);
  // eslint-disable-next-line no-unused-vars
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [validate, setValidate] = useState(false);
  const [payload, setPayload] = useState<any>(null);
  const [CurrentImageData, setCurrentImageData] = useState('');

  const { notificationDelete, notificationUpdate, notificationList } = useNotificationsContextV2();
  const { setResponseStatus } = useSnackbarContext();

  const openModalAction = (modal: NonNullable<OpeningModal>, notification: notificationsSchema) => {
    setSelectingNotification(notification);
    setOpeningModal(modal);
  };

  const statusText = (status: number) => {
    switch (status) {
      case 0:
        return 'Queued';
      case 1:
        return 'Delivered';
      default:
        return '';
    }
  };

  const handleClickDeleteNotification = () => {
    notificationDelete.requestAct(selectingNotification?.id || '');
    setButtonDisabled(true);
    if (selectBox?.onClear) {
      selectBox?.onClear();
    }
  };

  const handleClickEditNotification = () => {
    notificationUpdate.requestAct(payload, CurrentImageData, filter);
    setValidate(false);
  };

  const handleAlertMessage = (
    isShow: boolean,
    notification: ActionUnit2<NotificationTypes.NotificationUpdate> | ActionUnit2<NotificationTypes.NotificationDelete>
  ) => {
    setOpenAlert(isShow);
    notification.setState({
      state: STATE_ENUM.INITIAL,
    });
  };

  useEffect(() => {
    if (notificationDelete.current.state === STATE_ENUM.READY) {
      setOpeningModal(null);
      setButtonDisabled(false);
      if (notificationList.current.data?.length === 1 && notificationList.current.pagination?.page! > 0) {
        setPage(notificationList.current.pagination?.page! - 1);
        setPaginationParams((prevState) => {
          return { ...prevState, page: notificationList.current.pagination?.page! - 1 };
        });
      } else {
        setPage(notificationList.current.pagination?.page!);
        setPaginationParams((prevState) => {
          return { ...prevState, page: notificationList.current.pagination?.page! };
        });
      }
      setResponseStatus({
        status: 'success',
        message: notificationDelete.current?.status?.message ?? '',
      });
      handleAlertMessage(true, notificationDelete);
    } else if (notificationDelete.current.state === STATE_ENUM.ERROR) {
      setResponseStatus({
        status: notificationDelete.current.state,
        message: notificationDelete.current?.status?.message ?? 'Something went wrong. Please try again.',
      });
      handleAlertMessage(true, notificationDelete);
    }
  }, [notificationDelete.current]);

  useEffect(() => {
    if (notificationUpdate.current.state === STATE_ENUM.READY) {
      setOpeningModal(null);
      setResponseStatus({
        status: 'success',
        message: notificationUpdate.current?.status?.message ?? '',
      });
      handleAlertMessage(true, notificationUpdate);
    } else if (notificationUpdate.current.state === STATE_ENUM.ERROR) {
      setResponseStatus({
        status: notificationUpdate.current.state,
        message: notificationUpdate.current?.status?.message ?? 'Something went wrong. Please try again.',
      });
      handleAlertMessage(true, notificationUpdate);
    }
  }, [notificationUpdate.current]);

  return (
    <>
      <TableBody>
        {rows.map((row: notificationsSchema, index: number) => {
          return (
            <TableRow hover tabIndex={-1} key={row.id} className={selectBox?.selects.includes(row.id) ? classes.selected : ''}>
              {selectBox && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="error"
                    checked={selectBox.selects.includes(row.id)}
                    onClick={() => selectBox.setSelects(row.id)}
                    inputProps={{
                      'aria-labelledby': row.id,
                    }}
                    id={`checkbox-notification-${index}`}
                  />
                </TableCell>
              )}
              <TableCell align="right">{page * rowsPerPage + index + 1}</TableCell>
              <TableCell align="left">
                <Typography className={classes.ellipsis}>{row.title}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className={classes.ellipsis}>{row.description}</Typography>
              </TableCell>
              <TableCell align="left">
                <div className={classes.imgContainer}>
                  {row.imageUrl && <img src={row.imageUrl} alt={row.title + 'notification image'} style={{ objectFit: 'cover' }} width={44} height={44} />}
                </div>
              </TableCell>
              <TableCell align="left">{row.createdBy}</TableCell>
              <TableCell align="left">{statusText(row.status)}</TableCell>
              <TableCell align="left">{printDisplayDate(row.scheduledDate, fm)}</TableCell>
              <TableCell align="left">
                <div className={classes.btnBox}>
                  {row.status === 0 ? (
                    <Button
                      id={`btn-edit-notification-${index}`}
                      variant="outlined"
                      className={classes.buttonAction}
                      onClick={() => openModalAction('EDIT', row)}
                    >
                      <ModeEditIcon />
                    </Button>
                  ) : (
                    <Button
                      id={`btn-view-notification-${index}`}
                      variant="text"
                      className={clsx(classes.buttonAction, classes.sentViewBtn)}
                      onClick={() => openModalAction('VIEW', row)}
                    >
                      View
                    </Button>
                  )}
                  <Button
                    id={`btn-delete-notification-${index}`}
                    color="error"
                    variant="outlined"
                    className={classes.buttonAction}
                    onClick={() => openModalAction('DELETE', row)}
                  >
                    <DeleteIcon />
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      {selectingNotification &&
        (openingModal === 'VIEW' ? (
          <ModalUI
            id={'view-notification'}
            type={'CANTDELETE'}
            header="View Notification"
            openModal={true}
            onClose={() => {
              setOpeningModal(null);
              setValidate(false);
            }}
            onClickConfirm={() => {
              setOpeningModal(null);
              setValidate(false);
            }}
            setOpenModal={setOpenAlert}
            child={<ModalViewNotification notification={selectingNotification} />}
          />
        ) : openingModal === 'EDIT' ? (
          <ModalUI
            id={'edit-notification'}
            type={'UPDATE'}
            header="Edit Notification"
            openModal={true}
            setOpenModal={setOpenAlert}
            onClose={() => {
              setOpeningModal(null);
              setValidate(false);
            }}
            disabledBtn={validate}
            onClickConfirm={handleClickEditNotification}
            child={
              <ModalEditNotification
                notification={selectingNotification}
                onValidateChange={setValidate}
                onPayloadChange={setPayload}
                onImageChange={setCurrentImageData}
                validate={validate}
              />
            }
          />
        ) : openingModal === 'DELETE' ? (
          <ModalUI
            id={'delete-notification'}
            type={'DELETE'}
            openModal={true}
            width={569}
            setOpenModal={setOpenAlert}
            onClose={() => {
              setOpeningModal(null);
            }}
            onClickConfirm={handleClickDeleteNotification}
            child={<ModalDeleteNotification notification={selectingNotification} open onClose={() => setOpeningModal(null)} />}
          />
        ) : (
          <></>
        ))}
    </>
  );
}
