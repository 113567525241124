// For object's values type extraction.
type ObjectValues<T> = T[keyof T];

/**
 * Enum for state of context's action state.
 * @example
 * const fstState: StateEnum = 'loading';
 * const sndState: StateEnum = STATE_ENUM.READY;
 */

/**
 * INITIAL : first load, cancel action back to initial
 * LOADING : pending response from API
 * READY : after API response
 * ERROR : if there is any error from API
 * EDITING : (Optional state), if you need to trigger edit by user [[BEFORE SAVE/UPDATE/DELETE, BEFORE loading]]
 */
export const STATE_ENUM = {
  INITIAL: 'initial',
  READY: 'ready',
  LOADING: 'loading',
  ERROR: 'error',
  EDITING: 'editing',
} as const;

export type StateEnum = ObjectValues<typeof STATE_ENUM>;
