import { Box, Container, Link, Typography } from '@mui/material';
import React from 'react';
import TableUserOderHistory from './tableData';
import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';

const UserOrderHistory = () => {
  const location = useLocation();
  const displayName = location.state?.displayName;
  const navigrate = useNavigate();
  function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();
  }

  const clickBackUsersPage = () => {
    navigrate('/users');
  };
  return (
    <Container maxWidth="xl">
      <Box sx={{ mt: 4 }} role="presentation" onClick={handleClick}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link sx={{ cursor: 'pointer' }} onClick={clickBackUsersPage}>
            Users
          </Link>
          <Typography color="no-color">{displayName}</Typography>
        </Breadcrumbs>
      </Box>
      <Box className="flex flex-row justify-between items-center">
        <Typography className="pt-8" variant="h1">
          {displayName}
        </Typography>
      </Box>
      <div className="my-[50px]">
        <TableUserOderHistory key="user-order-history" />
      </div>
    </Container>
  );
};

export default UserOrderHistory;
