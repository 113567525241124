import React from 'react';
import { Backdrop, Box, Button, Typography } from '@mui/material';

const modalStyle = {
  position: 'fixed',
  margin: 'auto',
  top: '60px',
  width: 495,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  display: 'grid',
  alignContent: 'stretch',
  justifyContent: 'space - around',
  alignItems: 'start',
  justifyItems: 'center',
};

interface ModalCancelAllOrderProps {
  targetDate: string;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}
export default function ModalCancelAllOrder(props: ModalCancelAllOrderProps) {
  const { targetDate, open, onConfirm, onCancel } = props;

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <Box sx={modalStyle} className="mt-10">
        <Typography
          sx={{ color: '#000', fontSize: '20px', width: 'inherit', fontWeight: 'bold', marginBottom: '13px' }}
          id="transition-modal-title"
          variant="h5"
          component="h2"
        >
          Are you sure you would like to cancel all orders on {targetDate}?
        </Typography>
        <Typography id="transition-modal-description" variant="caption" sx={{ color: '#000', fontSize: '16px' }}>
          This action will permanently cancel the placed orders, and cannot be undone.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            marginTop: '30px',
            width: 'inherit',
          }}
        >
          <Button id={'btn-modal-cancel-all-order-yes'} color={'error'} variant="contained" onClick={onConfirm} sx={{ marginRight: '20px' }}>
            Yes, cancel
          </Button>
          <Button id={'btn-modal-cancel-all-order-no'} color={'error'} variant="outlined" onClick={onCancel}>
            No, keep it
          </Button>
        </Box>
      </Box>
    </Backdrop>
  );
}
