import { useCallback } from 'react';
import IOSSwitch from '@/src/components/ui/IOSSwitch';
import TableBodyNodata from '@/src/components/ui/tableBodyNoData';
import { useUserStoreContext } from '@/store/user.v2.store';
import { TableBody, TableCell, TableRow } from '@mui/material';
import Link from '@mui/material/Link';
import dayjs from 'dayjs';
import searchNotFound from '@/images/search-not-found.png';
import { getDisplayConfig } from '@/src/utils';
import { useNavigate } from 'react-router-dom';
const UserTableBody = () => {
  const { staffs, pagination, loading, modal } = useUserStoreContext();
  const DATE_FORMAT = getDisplayConfig().dateFormat;

  const navigate = useNavigate();

  const clickViewOrderHistory = (userId: string, displayName: string) => {
    navigate(`/users/${userId}/order-history`, { state: { displayName } });
  };

  const formateAndShowDate = useCallback((dateValue: string, daysLeft?: number) => {
    if (!daysLeft) {
      return '-';
    }
    return dateValue ? dayjs(dateValue).format(DATE_FORMAT) : '';
  }, []);

  const handelToggle = useCallback((staff, checked) => {
    const unText = checked ? '' : 'un';
    const body = {
      header: `Are you sure you want to ${unText}suspend the member?`,
      buttonText: `${unText}SUSPEND`.toUpperCase(),
      description: checked
        ? `You are about to give ${staff?.displayName || ''} a 3-day suspension.`
        : `You are about to lift ${staff?.displayName || ''}'s suspension.`,
      subDescription: checked
        ? 'This action will cancel all existing orders placed by the user within this period and cannot be undone. The user will be notified.'
        : 'This action will allow the user to place orders again. The user will be notified.',
      id: staff.id,
      checked,
    };

    modal.onOpen({ body });
  }, []);

  if (!loading && staffs.length <= 0) {
    return (
      <TableBodyNodata page="user" src={searchNotFound} title="No results found." customWidth={200} customHeight={200} description="Try different keywords?" />
    );
  }
  const replaceHighlight = (value: string) => {
    if (pagination.search === undefined) return value;
    const foundStartAt = value.toLowerCase().indexOf(pagination.search.toLowerCase());
    if (foundStartAt !== -1) {
      const foundEndAt = foundStartAt + pagination.search.length;
      return (
        value.substring(0, foundStartAt) +
        '<span class="text-[#4caf50]" >' +
        value.substring(foundStartAt, foundEndAt) +
        '</span >' +
        value.substring(foundEndAt)
      );
    } else return value;
  };

  return (
    <TableBody>
      {staffs.map((staff) => (
        <TableRow key={staff.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell align="left" key={`${staff.id}_name`}>
            <div dangerouslySetInnerHTML={{ __html: replaceHighlight(staff.displayName) }} />
          </TableCell>
          <TableCell align="left">{staff.role.name}</TableCell>
          <TableCell align="left">{staff.lastedLogedInAt ? dayjs(staff.lastedLogedInAt).format(DATE_FORMAT) : '-'}</TableCell>
          <TableCell align="center">
            <IOSSwitch
              sx={{ m: 1 }}
              checked={staff.suspendStatus}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handelToggle(staff, event.target.checked);
              }}
            />
          </TableCell>
          <TableCell key={`${staff.id}_suspended_until`} align="left">
            {formateAndShowDate(staff.suspendEndDate, staff.suspendDaysLeft)}
          </TableCell>
          <TableCell key={`${staff.id}_last_order`} align="left">
            {staff.lastOrder ? dayjs(staff.lastOrder).format(DATE_FORMAT) : '-'}
          </TableCell>
          <TableCell key={`${staff.id}_order_history`} align="left">
            <Link
              style={{
                color: staff.hasOrderHistory ? '#4CAF50' : '#949494',
                cursor: staff.hasOrderHistory ? 'pointer' : 'default',
                textDecoration: staff.hasOrderHistory ? 'underline' : 'none',
              }}
              onClick={() => {
                if (staff.hasOrderHistory) {
                  clickViewOrderHistory(staff.userId, staff.displayName);
                }
              }}
            >
              View
            </Link>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default UserTableBody;
