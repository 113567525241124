import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Fade, Box, Typography, Button } from '@mui/material';
import { apiPathV1 } from '@/app.constants/apiCallPaths/api.path.v1';
import { useRestaurantBookingContext } from '@/services/index';

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 420,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export default function ModalComponent(props: any) {
  const { openModal, setOpenModal, bookingId, type } = props;
  const { setRestaurantBookingStatusUpdate } = useRestaurantBookingContext();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [buttonCancelText, setButtonCancelText] = useState('');
  const [temp, setTemp] = useState('');
  const [color, setColor] = useState('');

  useEffect(() => {
    if (type === 'MARK AS ORDERED') {
      setTitle('Do you wish to change the status of this order summary to Ordered?');
      setDescription('This action cannot be undone.');
      setButtonText('MARK AS ORDERED');
      setButtonCancelText('CANCEL');
      setTemp('#4CAF50');
      setColor('#ffffff');
    }
  }, [type, temp, color]);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleClickChange = () => {
    setOpenModal(false);
    setRestaurantBookingStatusUpdate(apiPathV1.restaurantBookingStatusUpdate, { status: 'Ordered' }, bookingId);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={handleClose}
      closeAfterTransition
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={openModal}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h5" component="h2">
            {title}
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{ color: '#212121' }}>
            {description}
          </Typography>

          <Button id={'btn-modal-order-summary' + buttonText} style={{ backgroundColor: temp, color }} sx={{ mt: 2 }} onClick={handleClickChange}>
            {buttonText}
          </Button>

          <Button
            id={'btn-modal-order-summary' + buttonCancelText}
            style={{ backgroundColor: '#ffff', color: temp, border: '1px solid ' + temp }}
            sx={{ mt: 2, ml: 2 }}
            onClick={handleClose}
          >
            {buttonCancelText}
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
}
