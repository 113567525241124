import React from 'react';
import { TextField } from '@mui/material';
import { LIMIT_PRICE_CHAR } from '@/src/app.constants';

interface PriceInputProps {
  price: string;
  priceErrorText: string;
  setPrice: React.Dispatch<React.SetStateAction<string>>;
  setPriceErrorText: React.Dispatch<React.SetStateAction<string>>;
  setValidate: React.Dispatch<React.SetStateAction<boolean>>;
  countPrice: React.MutableRefObject<number>;
  validate?: boolean;
  loopTextField?: React.MutableRefObject<HTMLInputElement | null>;
  // NOTE: if editRowSetup is undefined, means this one use in add item mode
  editRowSetup?: {
    rowIndex: number;
    rowPrice: number;
    editRowIndex: number | undefined;
    setEditRowIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  };
  disabled?: boolean;
}

export function PriceInput(props: PriceInputProps) {
  const { price, priceErrorText, setPrice, setPriceErrorText, setValidate, countPrice, validate, loopTextField, editRowSetup, disabled } = props;

  const isEditMode = !!editRowSetup;
  const isEditingRow = editRowSetup ? editRowSetup.editRowIndex === editRowSetup.rowIndex : false;

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(event.key)) {
      // if correct key pressed, then remove error
      setPriceErrorText('');
    } else if (
      event.altKey ||
      event.ctrlKey ||
      event.metaKey ||
      event.shiftKey ||
      ['Escape', 'CapsLock', 'Backspace', 'Tab', 'Enter', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)
    ) {
      // do nothing, just allow special key with no error
    } else {
      // if incorrect key pressed, then set error
      setPriceErrorText('Number only.');
    }

    // NOTE: to prevent number-type input allow typing e - + .
    if (['E', 'e', '-', '+', '.', 'ArrowUp', 'ArrowDown'].includes(event.key)) event.preventDefault();

    // NOTE: for tab loop event
    if (loopTextField !== undefined && validate !== undefined) {
      if (event.key === 'Tab' && validate === false) {
        loopTextField.current?.focus();
      }
    }
  };

  const handlePaste: React.ClipboardEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    const valuePaste = event.clipboardData.getData('Text').trim();
    if (valuePaste.match(LIMIT_PRICE_CHAR)) {
      if (valuePaste.length < 4) {
        setPrice(valuePaste);
        countPrice.current = valuePaste.length;
      } else {
        setPrice(valuePaste.slice(0, 3));
        countPrice.current = 3;
      }
    } else {
      setPriceErrorText('Number only.');
      setValidate(false);
    }
  };

  const handlePriceTextChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const value = event.target.value;
    if (value.trim().length < 4) {
      countPrice.current = value.trim().length;
      if (value) {
        setPriceErrorText('');
      } else {
        setPriceErrorText('Number only.');
        setValidate(false);
      }
      setPrice(value ? parseInt(value).toString() : value);
    }
    if (editRowSetup) {
      editRowSetup.setEditRowIndex(editRowSetup.rowIndex);
    }
  };

  const handleOutOfFocusPrice: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const value = event.target.value;
    if (countPrice.current === 0 && !isEditMode) {
      setPrice('');
      setPriceErrorText('');
    } else if (value) {
      setPriceErrorText('');
    }
  };

  return (
    <TextField
      id={'input-shared-price'}
      type="number"
      variant="outlined"
      size="small"
      color="secondary"
      label={isEditMode ? undefined : 'Price'}
      className="hide-input-number-arrows text-field-price-limit"
      value={isEditMode ? (isEditingRow ? price : editRowSetup.rowPrice) : price}
      inputProps={{ min: 0, max: 999 }}
      sx={{ width: isEditMode ? '100%' : '128px' }}
      error={isEditMode ? isEditingRow && priceErrorText !== '' : priceErrorText !== ''}
      helperText={isEditMode ? (isEditingRow ? priceErrorText : '') : priceErrorText}
      onKeyDown={handleKeyDown}
      onChange={handlePriceTextChange}
      onBlur={handleOutOfFocusPrice}
      onPaste={handlePaste}
      disabled={disabled}
    />
  );
}
