import { ActionUnit, commonSetError, initialApiContext, initialApiContext2, initialStateContext } from '../common';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { CommonCrud, CommonCrudWithError, DeleteFailByOrdersError, GetRestaurantAddOnsListV2, PaginatedCrud } from '@/src/types/crud/crud';
import {
  RestaurantAddonsBody,
  RestaurantAddOnsSchema,
  GetOrderOverBudgetByAddOnPrice,
  QueryOrderOverBudgetByAddOnPrice,
  addonOrderByType,
  QueryParamsGetList,
} from '@/types/index';
import { RestaurantAddOnsListGet, RestaurantAddOnsCreate, RestaurantAddOnsUpdate, RestaurantAddOnsDelete, OrderOverBudgetByAddOnCheck } from '@/services/http';

import { apiPathV1 } from '@/src/app.constants/apiCallPaths/api.path.v1';
import { apiPathV2 } from '@/src/app.constants/apiCallPaths/api.path.v2';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';

type DeletedMark = { deleted: string } | undefined;
type CreatedMark = { id: string; thName: string; enName: string; price: number; createdAt: string } | undefined;
type UpdatedMark = { id: string; createdAt: string } | undefined;

interface IRestaurantAddonList extends ActionUnit<Types.RestaurantAddonList> {
  requestAct: Types.RequestAct.RestaurantAddonList;
}

interface IRestaurantAddonCreate extends ActionUnit<Types.RestaurantAddonCreate> {
  requestAct: Types.RequestAct.RestaurantAddonCreate;
}

interface IRestaurantAddonUpdate extends ActionUnit<Types.RestaurantAddonUpdate> {
  requestAct: Types.RequestAct.RestaurantAddonUpdate;
}

interface IRestaurantAddonDelete extends ActionUnit<Types.RestaurantAddonDelete> {
  requestAct: Types.RequestAct.RestaurantAddonDelete;
}

interface IOverBudgetOrdersByAddOn extends ActionUnit<Types.OverBudgetOrdersByAddOn> {
  requestAct: Types.RequestAct.OverBudgetOrdersByAddOn;
}

declare namespace Types {
  export type RestaurantAddonList = PaginatedCrud<GetRestaurantAddOnsListV2>;
  export type RestaurantAddonCreate = CommonCrud<CreatedMark>;
  export type RestaurantAddonUpdate = CommonCrud<UpdatedMark>;
  export type RestaurantAddonDelete = CommonCrudWithError<DeletedMark, CommonCrud<DeleteFailByOrdersError>>;
  export type OverBudgetOrdersByAddOn = GetOrderOverBudgetByAddOnPrice;
  export namespace RequestAct {
    export type RestaurantAddonList = (restaurantId: string, params?: QueryParamsGetList<addonOrderByType>) => void;
    export type RestaurantAddonCreate = (restaurantId: string, body: RestaurantAddonsBody) => void;
    export type RestaurantAddonUpdate = (restaurantId: string, addonId: string, payload: RestaurantAddonsBody) => void;
    export type RestaurantAddonDelete = (restaurantId: string, addonId: string) => void;
    export type OverBudgetOrdersByAddOn = (restaurantId: string, addonId: string, params: QueryOrderOverBudgetByAddOnPrice) => void;
    export type getRestaurantAddonIdDeleteList = (addonId: string) => void;
    export type getRestaurantAddonIdUpdateList = (addonId: string, thName: string, enName: string, price: number) => void;
    export type updateRestaurantAddonEditing = () => void;
  }
}

interface IAddonContextV2 {
  restaurantAddonList: IRestaurantAddonList;
  restaurantAddonCreate: IRestaurantAddonCreate;
  restaurantAddonUpdate: IRestaurantAddonUpdate;
  restaurantAddonDelete: IRestaurantAddonDelete;
  getRestaurantAddonIdDeleteList: Types.RequestAct.getRestaurantAddonIdDeleteList;
  getRestaurantAddonIdUpdateList: Types.RequestAct.getRestaurantAddonIdUpdateList;
  overBudgetOrdersByAddOn: IOverBudgetOrdersByAddOn;
  updateRestaurantAddonEditing: Types.RequestAct.updateRestaurantAddonEditing;
}

const AddOnContextV2: React.Context<IAddonContextV2> = createContext({
  restaurantAddonList: {
    ...initialStateContext<Types.RestaurantAddonList>(),
    requestAct: (restaurantId: string, params?: QueryParamsGetList<addonOrderByType>) => {},
  },
  restaurantAddonCreate: {
    ...initialApiContext<Types.RestaurantAddonCreate>(),
    requestAct: (restaurantId: string, body: RestaurantAddonsBody) => {},
  },
  restaurantAddonUpdate: {
    ...initialApiContext<Types.RestaurantAddonUpdate>(),
    requestAct: (restaurantId: string, addonId: string, payload: RestaurantAddonsBody) => {},
  },
  restaurantAddonDelete: {
    ...initialApiContext2<Types.RestaurantAddonDelete, Types.RequestAct.RestaurantAddonDelete>(),
  },
  updateRestaurantAddonEditing: () => {},
  getRestaurantAddonIdDeleteList: (addonId: string) => {},
  getRestaurantAddonIdUpdateList: (addonId: string, thName: string, enName: string, price: number) => {},
  overBudgetOrdersByAddOn: {
    ...initialStateContext<Types.OverBudgetOrdersByAddOn>(),
    requestAct: (restaurantId: string, addonId: string, params: QueryOrderOverBudgetByAddOnPrice) => {},
  },
});

function useAddOnContextV2() {
  return useContext(AddOnContextV2);
}

const AddOnContextV2Provider = ({ children }: { children: React.ReactNode }) => {
  const initialState = useAddOnContextV2();
  const [restaurantAddonList, setRestaurantAddOnsList] = useState<Types.RestaurantAddonList>(initialState.restaurantAddonList.current);

  const [restaurantAddonCreateData, setRestaurantAddonCreateData] = useState<Types.RestaurantAddonCreate>(initialState.restaurantAddonCreate.current);

  const [restaurantAddonUpdateData, setRestaurantAddonUpdateData] = useState<Types.RestaurantAddonUpdate>(initialState.restaurantAddonUpdate.current);

  const [restaurantAddonDeleteData, setRestaurantAddonDeleteData] = useState<Types.RestaurantAddonDelete>(initialState.restaurantAddonDelete.current);

  const [overBudgetOrdersByAddOn, setOverBudgetOrdersByAddOn] = useState<Types.OverBudgetOrdersByAddOn>({ state: STATE_ENUM.INITIAL });

  // action for RestaurantAddOnList
  const getRestaurantAddOnsList = (restaurantId: string, params?: QueryParamsGetList<addonOrderByType>) => {
    setRestaurantAddOnsList({
      ...initialState.restaurantAddonList.current,
      state: STATE_ENUM.LOADING,
    });
    RestaurantAddOnsListGet<Types.RestaurantAddonList>(
      apiPathV2.addonList,
      restaurantId,
      params || ({ page: 0, itemPerPage: 20 } as QueryParamsGetList<addonOrderByType>)
    )
      .then(({ status, data, pagination }) => {
        setRestaurantAddOnsList({
          data,
          state: STATE_ENUM.READY,
          status,
          pagination,
        });
      })
      .catch((error) => commonSetError(error, setRestaurantAddOnsList));
  };

  // action for create restaurantAddon
  const createRestaurantAddon = (restaurantId: string, payload: RestaurantAddonsBody) => {
    setRestaurantAddonCreateData({
      ...initialState.restaurantAddonCreate.current,
      state: STATE_ENUM.LOADING,
    });
    RestaurantAddOnsCreate<Types.RestaurantAddonCreate>(apiPathV1.addonCreate, restaurantId, payload)
      .then(({ status, data }) => {
        setRestaurantAddonCreateData({
          state: STATE_ENUM.READY,
          status,
          data,
        });
      })
      .catch((error) => commonSetError(error, setRestaurantAddonCreateData));
  };

  // action for update restaurantAddon
  const updateRestaurantAddon = (restaurantId: string, addonId: string, payload: RestaurantAddonsBody) => {
    setRestaurantAddonUpdateData({
      ...initialState.restaurantAddonUpdate.current,
      state: STATE_ENUM.LOADING,
    });
    RestaurantAddOnsUpdate<Types.RestaurantAddonUpdate>(apiPathV1.addonUpdate, restaurantId, addonId, payload)
      .then(({ status, data }) => {
        setRestaurantAddonUpdateData({
          data,
          state: STATE_ENUM.READY,
          status,
        });
      })
      .catch((error) => commonSetError(error, setRestaurantAddonUpdateData));
  };

  // action for update editing
  const updateRestaurantAddonEditing = () => {
    setRestaurantAddonUpdateData({
      ...initialState.restaurantAddonUpdate.current,
      state: STATE_ENUM.EDITING,
    });
  };
  // action for change add-ons list after update
  const getRestaurantAddonIdUpdateList = (addonId: string, thName: string, enName: string, price: number) => {
    const startArray = restaurantAddonList;
    const restaurantAddonUpdateList = startArray.data!.addons.map<RestaurantAddOnsSchema>((restaurantAddon: any) => {
      if (restaurantAddon.id === addonId) {
        return {
          ...restaurantAddon,
          thName,
          enName,
          price,
        };
      } else {
        return {
          ...restaurantAddon,
        };
      }
    });
    setRestaurantAddOnsList({
      data: { restaurant: restaurantAddonList.data?.restaurant!, addons: restaurantAddonUpdateList },
      state: STATE_ENUM.READY,
      status: restaurantAddonList.status,
      pagination: restaurantAddonList.pagination,
    });
  };

  // action for delete add-on
  const deleteRestaurantAddon = (restaurantId: string, addonId: string) => {
    setRestaurantAddonDeleteData({
      ...initialState.restaurantAddonDelete.current,
      state: STATE_ENUM.LOADING,
    });
    RestaurantAddOnsDelete<Types.RestaurantAddonDelete>(apiPathV1.addonDelete, restaurantId, addonId)
      .then(({ status, data }) => setRestaurantAddonDeleteData({ data, state: STATE_ENUM.READY, status }))
      .catch((error) => commonSetError(error, setRestaurantAddonDeleteData));
  };

  // action for change restaurant add-ons list after delete
  const getRestaurantAddonIdDeleteList = (addonId: string) => {
    const startArray = restaurantAddonList;
    const restaurantAddonDeleteList = startArray.data!.addons.filter((restaurantAddon: any) => {
      if (restaurantAddon.id !== addonId) {
        return {
          ...restaurantAddon,
        };
      }
      return undefined;
    });
    setRestaurantAddOnsList({
      data: {
        restaurant: restaurantAddonList.data?.restaurant!,
        addons: restaurantAddonDeleteList,
      },
      state: STATE_ENUM.READY,
      status: restaurantAddonList.status,
      pagination: restaurantAddonList.pagination,
    });
  };

  /**
   * action for get Over-Budget Orders before update AddOn price
   * @api /addons/orders/over-budget/{restaurantId}/{addonId}?price=
   * */
  const getOverBudgetOrdersByAddOn = (restaurantId: string, addonId: string, params: QueryOrderOverBudgetByAddOnPrice) => {
    setOverBudgetOrdersByAddOn({ state: STATE_ENUM.LOADING });
    OrderOverBudgetByAddOnCheck(apiPathV1.overBudgetOrdersByAddOn, restaurantId, addonId, params)
      .then((data) => {
        setOverBudgetOrdersByAddOn({
          data: data.data,
          state: STATE_ENUM.READY,
          status: data.status,
        });
      })
      .catch((error) => {
        setOverBudgetOrdersByAddOn({ data: undefined, error: error?.response, state: STATE_ENUM.ERROR, status: error?.response?.data?.status });
      });
  };

  const addonStore: IAddonContextV2 = {
    restaurantAddonList: {
      current: restaurantAddonList,
      setState: setRestaurantAddOnsList,
      requestAct: getRestaurantAddOnsList,
    },
    restaurantAddonCreate: {
      current: restaurantAddonCreateData,
      setState: setRestaurantAddonCreateData,
      requestAct: createRestaurantAddon,
    },
    restaurantAddonUpdate: {
      current: restaurantAddonUpdateData,
      setState: setRestaurantAddonUpdateData,
      requestAct: updateRestaurantAddon,
    },
    restaurantAddonDelete: {
      current: restaurantAddonDeleteData,
      setState: setRestaurantAddonDeleteData,
      requestAct: deleteRestaurantAddon,
    },
    updateRestaurantAddonEditing,
    getRestaurantAddonIdUpdateList,
    getRestaurantAddonIdDeleteList,

    overBudgetOrdersByAddOn: {
      current: overBudgetOrdersByAddOn,
      setState: setOverBudgetOrdersByAddOn,
      requestAct: getOverBudgetOrdersByAddOn,
    },
  };

  useEffect(() => {
    return () => {
      /*
       NOTE: cancel all subscriptions and asynchronous tasks to cleanup function and fix Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
      */
      setRestaurantAddonDeleteData(initialState.restaurantAddonDelete.current);
      setRestaurantAddonUpdateData(initialState.restaurantAddonUpdate.current);
      setRestaurantAddonCreateData(initialState.restaurantAddonCreate.current);
      setRestaurantAddOnsList(initialState.restaurantAddonList.current);
    };
  }, []);
  return <AddOnContextV2.Provider value={addonStore}>{children}</AddOnContextV2.Provider>;
};

export { useAddOnContextV2, AddOnContextV2Provider };
export type { Types as OrderStoreType };
