import React, { useEffect, useState } from 'react';
import { SelectChangeEvent, Select, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import dayjs from 'dayjs';
import { useUserOrderHistoryList } from '@/store/userOderHistory.store';

export function YearPicker(props: any) {
  const { year, setYear, yearOptions = [] } = props;
  const { fetchUserOrderHistoryList } = useUserOrderHistoryList();

  const handleChangeYear = (event: SelectChangeEvent) => {
    setYear(event.target.value as string);
  };

  const now = dayjs();
  const [arrayYear, setArrayYear] = useState<string[]>(yearOptions.length ? yearOptions : [now.year().toString()]);

  useEffect(() => {
    if (fetchUserOrderHistoryList.current.state === STATE_ENUM.READY) {
      const fetchedArrayYear = fetchUserOrderHistoryList.current.data?.arrayYear;
      setArrayYear(fetchedArrayYear?.length ? fetchedArrayYear : [now.year().toString()]);
    }
  }, [fetchUserOrderHistoryList.current]);

  return (
    <Select
      IconComponent={KeyboardArrowDownIcon}
      labelId="select-year"
      id="select-year"
      value={year || now.year().toString()}
      defaultValue={now.year().toString()}
      onChange={handleChangeYear}
      inputProps={{
        id: 'select-year-input',
      }}
    >
      {arrayYear.map((value, index) => (
        <MenuItem value={value} key={value} id={'year-' + index}>
          {value}
        </MenuItem>
      ))}
    </Select>
  );
}
