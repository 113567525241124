/** declare only http service related requests */
import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import { QueryParamsGetList } from '@/types/index';
import { apiMethodV1 } from '@/app.constants/apiCallMethods/index';
import { paramsQueryString } from '@/src/utils';
import { OptionGroupOrderByType } from '@/src/types/crud/restaurantGroup/restaurantGroup.queries';
import { RestaurantGroupsRequest } from '@/src/types/crud/restaurantGroup/restaurantGroup';
/**
 * @private This api is protected and can only be accessed as role=ADMIN
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
const RestaurantGroupsListGet = <T>(
  prefix: string,
  restaurantId: string,
  params: QueryParamsGetList<OptionGroupOrderByType>,
  hardRejection = true
): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.get, timeout: 12000 }, true, hardRejection);
  const queryUri = paramsQueryString(prefix + restaurantId, params);
  // token not yet set on backend
  const config = {
    ...instance.config,
  };
  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};
const RestaurantGroupsCreate = <T>(prefix: string, restaurantId: string, body: RestaurantGroupsRequest, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.post, timeout: 12000 }, true, hardRejection);
  const config = {
    ...instance.config,
  };
  return instance.client
    .post(prefix + restaurantId, JSON.stringify(body), config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};
const RestaurantGroupsUpdate = <T>(prefix: string, restaurantId: string, groupId: string, body: RestaurantGroupsRequest, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.put, timeout: 12000 }, true, hardRejection);
  const config = {
    ...instance.config,
  };

  return instance.client
    .put(prefix + restaurantId + '/' + groupId, JSON.stringify(body), config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};
const RestaurantGroupsDelete = <T>(prefix: string, jwtToken: string, restaurantId: string, groupId: string, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.delete, timeout: 12000 }, true, hardRejection);

  return instance.client
    .delete(prefix + restaurantId + '/' + groupId, instance.config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

/** @api @GET /v1/groups/orders/count/{restaurantId}/{groupId} */
const RestaurantGroupsCountOrderGet = <T>(prefix: string, jwtToken: string, restaurantId: string, groupId: string, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.get, timeout: 12000 }, true, hardRejection);
  const config = instance.config;
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded';

  return instance.client
    .get(prefix + restaurantId + '/' + groupId, config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};
export { RestaurantGroupsListGet, RestaurantGroupsCreate, RestaurantGroupsUpdate, RestaurantGroupsDelete, RestaurantGroupsCountOrderGet };
