import React, { useState } from 'react';
import { Container, Typography, Button, Link } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { AnonymProps } from '@/types/index';
import { BrowserHistory } from 'history';
import { fullSessionLogout } from '@/src/utils';
import { ModalSessionExpired } from '../../components/ui/modal/modalSessionexpired';

interface RedirectProps extends AnonymProps {
  history: BrowserHistory;
}

export function Error(props: RedirectProps) {
  const search = useLocation().search;
  const code = parseInt(new URLSearchParams(search).get('code') || '');
  const [onLogout, setLogout] = useState<boolean>(false);
  let title = code.toString();
  let message = '';
  let choice: number;

  switch (code) {
    case 400:
      message = 'Bad Request.';
      choice = 400;
      break;
    case 401:
      message = 'Unauthorized user.';
      choice = 401;
      break;
    case 404:
      message = 'Page not found.';
      choice = 404;
      break;
    case 416: // RANGE_NOT_SATISFIABLE
      message = 'Missing Access Token';
      choice = 416;
      break;
    case 423: // Locked
      message = 'Invalid user credentials';
      choice = 423;
      break;
    case 424: // FAILED_DEPENDENCY
      message = 'Please enable cookies';
      choice = 424;
      break;

    case 425:
      message = 'Incognito mode not supported';
      choice = 425;
      break;

    case 500:
      message = 'Internal server error. Please contact system admin.';
      choice = 500;
      break;
    default:
      title = 'Unhandled Error';
      message = 'Please contact system admin.';
      choice = 0;
      break;
  }

  const DisplayInvalidSession = (_props: { useOldMessage: boolean }) => {
    if (_props.useOldMessage) {
      return (
        <Button
          disabled={onLogout}
          onClick={() => {
            fullSessionLogout(props.history, true);
            setLogout(true);
          }}
          variant="outlined"
          color="secondary"
          className="tracking-widest no-underline"
        >
          Try login again
        </Button>
      );
    } else
      return (
        <ModalSessionExpired
          logoutCallback={() => {
            fullSessionLogout(props.history, true);
          }}
        />
      );
  };

  const onlyShow = choice !== 423 && choice !== 416;
  return (
    <div className="App">
      <Container className="flex  min-h-screen">
        <div className="text-center py-4 m-auto">
          {onlyShow && (
            <>
              <div className="mb-6">
                <svg className="w-20 mb-2 fill-red-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM256 304c13.25 0 24-10.75 24-24v-128C280 138.8 269.3 128 256 128S232 138.8 232 152v128C232 293.3 242.8 304 256 304zM256 337.1c-17.36 0-31.44 14.08-31.44 31.44C224.6 385.9 238.6 400 256 400s31.44-14.08 31.44-31.44C287.4 351.2 273.4 337.1 256 337.1z" />
                </svg>
              </div>
              <Typography variant="h2" className="text-black font-bold tracking-wider mb-5">
                {title}
              </Typography>
              <Typography variant="body1" className="text-black mb-8">
                {message}
              </Typography>
            </>
          )}

          {choice === 423 || choice === 416 ? (
            <DisplayInvalidSession useOldMessage={false} />
          ) : choice === 424 ? (
            <Button
              disabled={onLogout}
              onClick={() => {
                fullSessionLogout(props.history, true);
                setLogout(true);
              }}
              variant="outlined"
              color="secondary"
              className="tracking-widest no-underline "
            >
              You need to enable cookies, and try again
            </Button>
          ) : (
            <Link href="/orders" className="no-underline">
              <Button variant="outlined" color="secondary" className="tracking-widest">
                Go to orders page
              </Button>
            </Link>
          )}

          {/* <Link href="/orders" className="no-underline">
            <Button variant="outlined" color="secondary" className="tracking-widest">
            
            </Button>
          </Link> */}
        </div>
      </Container>
    </div>
  );
}
