import React, { ReactNode } from 'react';

interface ConditionalWrapperProps {
  children: ReactNode;
  condition: boolean;
  alternativeNode?: ReactNode;
}
/**
 * Return wrapped component if given condition is true, otherwise return alternative node (if given else return fragment).
 * @example
 * <ConditionalWrapper condition={isLoaded()} alternativeNode={<LoadingIcon />}>
 *    <>Content</>
 * </ConditionalWrapper>
 */
export const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({ children, condition, alternativeNode }) => {
  return <>{condition ? children : alternativeNode || <></>}</>;
};
