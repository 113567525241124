import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export interface IModalBody {
  header: string;
  buttonText: string;
  description: string;
  subDescription: string;
  id?: string;
  checked?: boolean;
}
export interface IOpenModal {
  body: IModalBody;
  callbackOpen?: Function;
  callbackConfirm?: Function;
}
export interface IUserModal {
  body: IModalBody;
  openModal: boolean;
  onOpen: (prop?: IOpenModal) => void;
  onClose: (callback?: Function) => void;
  setOpenModal?: Dispatch<SetStateAction<boolean>>;
}

export const userModalDefault = {
  body: {
    header: '',
    buttonText: '',
    description: '',
    subDescription: '',
    id: undefined,
    checked: undefined,
  },
  openModal: false,
  onOpen: () => {},
  onClose: () => {},
};

const useUserModal = (): IUserModal => {
  const [openModal, setOpenModal] = useState(userModalDefault.openModal);
  const [modalBody, setModalBody] = useState<IModalBody>(userModalDefault.body);

  const onOpenModal = useCallback((params?: IOpenModal) => {
    if (params) {
      setModalBody(params?.body);
    }
    setOpenModal(true);
    if (params?.callbackOpen) {
      params?.callbackOpen();
    }
  }, []);

  const onCloseModal = useCallback(() => {
    setModalBody(userModalDefault.body);
    setOpenModal(false);
  }, []);

  return {
    body: modalBody,
    openModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
    setOpenModal,
  };
};

export default useUserModal;
