import { HeadCell } from '@/types/enhancedTable.d';

export const headCellsRestaurantMenu: HeadCell[] = [
  {
    id: 'thName',
    numeric: false,
    label: 'Thai name',
    disablePadding: false,
    sortAble: true,
    width: 2.5 / 10,
  },
  {
    id: 'enName',
    label: 'English name',
    numeric: false,
    disablePadding: false,
    sortAble: true,
    width: 2.5 / 10,
  },
  {
    id: 'price',
    numeric: true,
    label: 'Price',
    disablePadding: false,
    sortAble: true,
    width: 0.8 / 10,
  },
  {
    id: 'addons',
    numeric: false,
    label: 'Add-ons',
    disablePadding: false,
    sortAble: false,
    width: 1.2 / 10,
  },
  {
    id: 'optionGroup',
    numeric: false,
    label: 'Option Groups',
    disablePadding: false,
    sortAble: false,
    width: 1 / 10,
  },
  {
    id: 'image',
    numeric: false,
    label: 'Image',
    disablePadding: false,
    sortAble: false,
    width: 0.5 / 10,
  },
  {
    id: 'fallbackMeal',
    numeric: false,
    label: 'Fallback meal',
    disablePadding: false,
    sortAble: true,
    width: 1.2 / 10,
  },
  {
    id: 'actions',
    label: '',
    numeric: false,
    disablePadding: false,
    sortAble: false,
    width: 1 / 10,
  },
];
